import { MatterPresenter } from '@/presenters/matterPresenter'
import { apiRequest } from './apiUtils'
import { mockMatterFelids } from '@/constants/mockMaterTemplate'
import { formFields } from '@/constants/matterTemplates'

// Get all for table
export const getAllMatters = async ({
  per_page,
  page,
  keyword,
}: {
  per_page: number
  page?: number
  keyword?: string
}) => {
  const response = await apiRequest('get', '/legal_matters', null, {
    per_page,
    page,
    keyword,
  })

  if (!response.success) {
    return response
  }

  const list = response.data?.list
  if (!Array.isArray(list)) {
    return response
  }

  return {
    ...response,
    data: {
      ...response.data,
      list: list.map((item: MatterPresenter) => new MatterPresenter(item)),
    },
  }
}

export const getMatterById = async (id: string) => {
  const response = await apiRequest('get', `/legal_matters/${id}`)
  if (response.success) {
    return {
      ...response,
      data: new MatterPresenter(response.data),
    }
  } else {
    return response
  }
}

export const addMatter = (body: any) => {
  return apiRequest('post', '/legal_matters', body)
}

export const updateMatter = (id: string, body: any) => {
  return apiRequest('put', `/legal_matters/${id}`, body)
}

export const deleteMatter = (id: string) => {
  return apiRequest('delete', `/legal_matters/${id}`)
}

export const getTemplate = () => {
  const templateFormFields = mockMatterFelids.map((rowFields) => {
    return rowFields.map((field) => {
      const fieldData = formFields.find((f) => f.name === field.name)

      return {
        ...fieldData,
        ...field,
      }
    })
  })

  return templateFormFields
}
