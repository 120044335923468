import { defineComponent } from 'vue'
import SearchAndViews from '@/components/searchAndViews/index.vue'
import ActionsBar from '@/components/actionsBar/index.vue'
import {
  addStageItem,
  deleteStageItem,
  getAllStages,
  getStageItems,
  updateStageItem,
} from '@/services/stages'
import { StagesPresenter } from '@/presenters/StagesPresenter'
import KanbanInfiniteLoad from '@/components/KanbanInfiniteLoad/index.vue'
import UpdateLayoutColumns from '@/components/UpdateLayoutColumns/index.vue'
import AllContactsTable from '@/components/contacts/AllContacts/Table/index.vue'
import AddLeadModal from '@/components/contacts/AddLeadModal/index.vue'
import { TableColumnInterface } from '@/Interfaces/Constants'

export default defineComponent({
  name: 'ContactsLeadsSection',
  props: {
    leadsId: {
      type: Number,
      required: true,
    },
    isShowUpdateLayoutModal: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array as () => TableColumnInterface[],
      default: [] as TableColumnInterface[],
    },
  },
  emits: ['handleToggleUpdateLayoutModal'],
  data() {
    return {
      activeView: 'list',

      searchKeyword: '',
      debounceTimeout: null as ReturnType<typeof setTimeout> | null, // Holds the debounce timeout ID

      isStagesLoading: false,
      stages: [] as StagesPresenter[],

      isLeadsLoading: false,

      isUpdateLayoutLoading: false,
      isDeleteLayoutLoading: false,

      tableLoading: false,
      contactsActiveMatterSearchKeyword: '',
      contactsActiveMattersItemPerPage: 10,
      contactsActiveMattersSortField: '' as
        | 'individual_first_name'
        | 'individual_last_name'
        | 'organization_name'
        | undefined,
      contactsActiveMattersSortDirection: '' as 'asc' | 'desc' | undefined,
      contactsActiveMatterPage: 1,
      contactsActiveMatterTotalItems: 0,

      selectedRows: [],

      addLeadLoading: false,
      isAddLeadForm: false,
    }
  },
  methods: {
    handleSearchData(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        // this.handleGetContacts(search)
      }, 500)
    },

    handleChangeView(view: string) {
      this.activeView = view
    },

    handleTicketMoved(data: any) {
      // this.handleUpdateLeadsToStage(data.id, data)
    },
    getSelectedRows(rows: any) {
      this.selectedRows = rows
    },

    ///////////////////////////////////////////////////////////////////////

    async handleGetLeadsByStage(leadsId: number, id: number) {
      this.isLeadsLoading = true
      const response = await getStageItems(leadsId, id)
      const tickets = response.data?.list
      const stage = this.stages?.find((s: StagesPresenter) => s.id === id)
      if (stage) {
        stage.section_stage_items = tickets
      }
      this.isLeadsLoading = false
    },

    async handleGetStages(section_id: number) {
      this.isStagesLoading = true

      const response = await getAllStages(section_id)
      if (response.success) {
        this.stages = response.data?.list

        await Promise.all(
          this.stages.map((stage: StagesPresenter) =>
            this.handleGetLeadsByStage(section_id, stage.id),
          ),
        )
        this.$stores.matter.stages = response.data?.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isStagesLoading = false
    },

    ////////////////////////////// layout modal /////////////////////////////////////////

    handleToggleUpdateLayoutModal() {
      this.$emit('handleToggleUpdateLayoutModal')
    },

    async handleUpdateLayout(body: StagesPresenter) {
      this.isUpdateLayoutLoading = true
      const { id, name, sort_order, color } = body

      let response
      if (id) {
        response = await updateStageItem(this.leadsId, id, {
          name,
          sort_order,
          color,
        })
      } else {
        response = await addStageItem(this.leadsId, {
          name,
          sort_order,
          color,
        })
      }

      if (response.success) {
        this.handleGetStages(this.leadsId)
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isUpdateLayoutLoading = false
    },

    async handleDeleteLayout(stageId: number) {
      this.isDeleteLayoutLoading = true

      const response = await deleteStageItem(this.leadsId, stageId)

      if (response.success) {
        this.handleGetStages(this.leadsId)
        this.$vaToast.init({
          message: 'Stage Delete Successfully',
          color: 'success',
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isDeleteLayoutLoading = false
    },

    /////////////////////////////////////// table //////////////////////////////

    async handlePaginationContactsActiveMatters({
      page,
      itemsPerPage,
      sortBy,
    }: any) {
      this.contactsActiveMattersItemPerPage = itemsPerPage
      this.contactsActiveMatterPage = page
      if (sortBy.length > 0) {
        this.contactsActiveMattersSortField = sortBy[0].key.split('.')[1]
        this.contactsActiveMattersSortDirection = sortBy[0].order
      } else {
        this.contactsActiveMattersSortField = undefined
        this.contactsActiveMattersSortDirection = undefined
      }

      // await this.handleGetContactsActiveMatters()
    },

    /////////////////////////////////// modal actions ////////////////////////////////

    handleCreateLead(data: { id: string }) {
      console.log(data)
    },

    toggleAddLeadForm() {
      this.isAddLeadForm = !this.isAddLeadForm
    },
  },

  mounted() {
    this.handleGetStages(this.leadsId)
    console.log({ headers: this.headers })
  },
  components: {
    SearchAndViews,
    ActionsBar,
    KanbanInfiniteLoad,
    UpdateLayoutColumns,
    AllContactsTable,
    AddLeadModal,
  },
})
