import { camelCaseToNormal, formatDateRange } from '@/lib/utils'
import { CalendarItemsPresenter } from '@/presenters/calendarItemsPresenter'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LinkToCalendarEvent',
  props: {
    calendarItems: {
      type: Array as () => CalendarItemsPresenter[],
      required: true,
    },
  },
  emits: ['handleLinkTo'],
  data() {
    return {
      camelCaseToNormal,
      formatDateRange,

      globalSearchKeyword: '',
      debounceTimeout: null as ReturnType<typeof setTimeout> | null,
    }
  },
  watch: {},
  methods: {
    handleLinkTo(data: CalendarItemsPresenter) {
      this.$emit('handleLinkTo', data)
    },
  },
})
