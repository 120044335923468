import { defineComponent } from 'vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import TabsBar from '@/components/tabsBar/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import TimeRange from '@/components/TimeRange/index.vue'
import MatterTasksSection from './Sections/Tasks/index.vue'
import Milestones from './Sections/Milestones/index.vue'
import DatePickerModal from '@/components/DatePickerModal/index.vue'
import ForItemTimeRecordModal from '@/components/TimeRecordModal/ForItemTimeRecordModal/index.vue'
import DetailsTimeLogs from '@/components/DetailsTimeLogs/index.vue'
import {
  getOptionFullName,
  getOptionName,
  getOptionTruncateName110,
  truncateText,
  validateInput,
  validateSelect,
} from '@/lib/utils'
import { getAllCountries } from '@/services/address'
import { getAllStages } from '@/services/stages'
import {
  getAllMatters,
  getMatterById,
  getTemplate,
  updateMatter,
} from '@/services/legalMatter'
import { MatterPresenter } from '@/presenters/matterPresenter'
import { useTabs } from '@/composables/useTabs'
import { calendarItemsFormInterface } from '@/Interfaces/layout'
import { CalendarItemsPresenter } from '@/presenters/calendarItemsPresenter'
import { getAllCalendarItems } from '@/services/calendarItem'
import { paginationInterface } from '@/Interfaces/Services'
import { CountryPresenter } from '@/presenters/AddressPresenter/CountryPresenter'
import DynamicForm from '@/components/DynamicForm/index.vue'
import { MatterTemplatePresenter } from '@/presenters/TemplatePresenter/Matter'
import { StagesPresenter } from '@/presenters/StagesPresenter'

export default defineComponent({
  name: 'LegalMattersDetails',
  components: {
    SharedHeader,
    TabsBar,
    TimeRange,
    DatePickerModal,
    ComingSoon,
    MatterTasksSection,
    ForItemTimeRecordModal,
    DetailsTimeLogs,
    Milestones,
    DynamicForm,
  },
  data() {
    const taskBarTabs = [
      { id: 0, name: 'DASHBOARD', icon: 'dashboard' },
      { id: 1, name: 'MATTER INFO', icon: 'info' },
      { id: 2, name: 'LINKED INFO', icon: 'task' },
      { id: 3, name: 'TASKS', icon: 'assignment' },
      { id: 4, name: 'CALENDAR', icon: 'calendar_month' },
      { id: 5, name: 'MILESTONES', icon: 'flag' },
      { id: 6, name: 'FILES', icon: 'file_copy' },
      { id: 7, name: 'FINANCIAL', icon: 'attach_money' },
      { id: 8, name: 'HEARINGS', icon: 'gavel' },
      { id: 9, name: 'ENGAGEMENT LETTER', icon: 'article' },
      { id: 10, name: 'EMAILS', icon: 'mail' },
      { id: 11, name: 'KYC DOCUMENTS', icon: 'article' },
      { id: 12, name: 'TIME LOG', icon: 'schedule' },
    ]

    const { activeTabId, activeTab, changeTab } = useTabs(taskBarTabs, 1)

    return {
      validateInput,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionTruncateName110,

      selectedDate: null,
      tabs: taskBarTabs,
      activeTab,
      activeTabId,
      changeTab,
      relationships: [
        { name: 'Legal Matter', details: 'Recent 2', subDetail: 'child' },
        { name: 'Legal Matter', details: 'Recent 2', subDetail: 'child' },
      ],

      case_personal_of_counsel: false,

      countriesLoading: false,
      stagesLoading: false,
      loading: false,
      loadingUpdate: false,
      companyConfigLoading: false,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,

      presentationTypeOption: ['Plaintiff', 'Defendant'],

      countries: [] as CountryPresenter[],
      truncateText,

      stages: [] as StagesPresenter[],

      formFields: [] as MatterTemplatePresenter[][],

      form: {} as MatterPresenter,

      isLinkMatter: false,
      mattersModal: [] as MatterPresenter[],
      mattersItemPerPageModal: 10,
      matterPageModal: 1,
      matterSortFieldModal: '' as any,
      matterSortDirectionModal: '' as 'asc' | 'desc' | undefined,
      matterSearchKeywordModal: '',
      matterTotalItemsModal: 0,
      mattersLoading: false,
      selectedMatter: null as MatterPresenter | null,

      startRecordLoading: false,
      deleteRecordLoading: false,
      updateRecordLoading: false,
      manualFormLoading: false,
      loadingStates: [] as boolean[],
      getTimeDataType: {
        linked_item_type: 'LegalMatter' as
          | 'Hearing'
          | 'Task'
          | 'Milestone'
          | 'LegalMatter',
        linked_item_id: Number(this.$route.params.id),
      },

      timeLogs: [] as CalendarItemsPresenter[],
      timeLogsItemPerPage: 10,
      timeLogPage: 1,
      timeLogSortDirection: 'desc' as 'asc' | 'desc',
      timeLogTotalItems: 0,
      selectedTimeLogRows: [],
      timeLogTableLoading: false,

      calendarItemsRangeFormLoading: false,

      toggleSubmit: false,
      DynamicFormLoading: false,
    }
  },
  watch: {
    form: {
      handler(data) {
        console.log({
          form: data,
        })
      },
      deep: true,
    },
    '$route.params.id'(newId) {
      // Logic to handle ID change
      this.handleGetMattersByID(newId)
      this.handleGetContacts()
      this.handleGetAllCountries()
      this.handleGetTimeRecords(newId)
    },
    isLinkMatter: {
      handler(data) {
        if (data === true) {
          this.handleGetMattersModal()
        }
      },
    },
    activeTab: {
      handler(tabName) {
        if (tabName === 'TIME LOG') {
          this.handleGetTimeLog()
        }
      },
    },
  },
  mounted() {
    this.handleGetContacts()
    this.handleGetAllCountries()
    this.handleGetMembers()
    this.handleGetMattersByID(this.$route.params.id as string)
    this.handleGetTimeRecords(this.$route.params.id as string)
  },
  methods: {
    handleSelectedDate(date: { start: string; end: string }) {
      this.form.start_date = date.start
      this.form.end_date = date.end
    },
    handleSelectDueDate(date: { date: string }) {
      this.form.due_date = date.date
    },

    async handleToggleSubmit() {
      this.toggleSubmit = !this.toggleSubmit
    },

    /////////////////////////////////////////////////////////////

    handleStageMenuItemClick(stage: any) {
      if (
        this.form &&
        this.form.section_stage_items &&
        this.form.section_stage_items[0] &&
        this.form.section_stage_items[0]?.section_stage
      ) {
        this.form.section_stage_items[0].section_stage = stage
      }
    },

    handleBackToParent(id: number) {
      this.$router.push({ path: `/legal-matters/${id}` })
    },

    handleStatusMenuItemClick(status: any) {
      this.form.status = status
    },

    /////////////////////////////////////////////////////////////

    async handleGetMattersByID(id: string) {
      const fieldsToExclude = ['section_stage', 'status', 'parent_id']

      this.loading = true
      const response = await getMatterById(id)

      if (response.success) {
        this.form = response.data

        console.log({ form: this.form })

        this.formFields = this.form.form_columns
          .map((group) =>
            group.filter((field) => !fieldsToExclude.includes(field.name)),
          )
          .filter((group) => group.length > 0)

        if (response.data.section_stage_items[0]?.section_stage.section_id) {
          this.handleGetStagesSection(
            response.data.section_stage_items[0]?.section_stage.section_id,
          )
        } else {
          this.stages = []
        }
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.loading = false
    },

    /////////////////////////////////////////////////////////////

    async handleUpdate(dynamicForm: MatterPresenter) {
      this.loadingUpdate = true

      const {
        representation_type,
        parent,
        court,
        judge,
        representing_contact,
        opponent_contact,
        status,
        specialization,
        category,
        sector,
        jurisdriction,
        case_manager,
        personnel,
        section_stage,
        legal_matter_users,
        section_stage_items,
        ...updatedForm
      } = dynamicForm

      const response = await updateMatter(this.$route.params.id as string, {
        ...updatedForm,
        due_date: this.form.due_date,
        start_date: this.form.start_date,
        end_date: this.form.end_date,
        section_stage_id: this.form.section_stage_items[0]?.section_stage.id,
        status_id: this.form.status?.id,
        parent_id: this.form.parent?.id,

        representation_type: representation_type?.toLocaleLowerCase(),

        court_id: court?.id,
        judge_id: judge?.id,
        representing_contact_id: representing_contact?.id,
        opponent_contact_id: opponent_contact?.id,
        specialization_id: specialization?.id,
        category_id: category?.id,
        sector_id: sector?.id,
        jurisdriction_id: jurisdriction?.id,
        case_manager_ids: case_manager?.map((e) => e.id),
        personnel_ids: personnel?.map((e) => e.id),
      })

      if (response.success) {
        this.$vaToast.init({
          message: 'Matter updates successfully!',
          color: 'success',
        })
        this.handleGetMattersByID(this.$route.params.id as string)
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.loadingUpdate = false
    },

    /////////////////////////////////////////////////////////////

    async handleGetContacts(search?: string) {
      this.$stores.contacts.handleGetContacts({
        keyword: search,
      })
    },

    handleSearchContacts(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts(search)
      }, 500)
    },

    /////////////////////////////////////////////////////////////

    async handleGetAllCountries() {
      this.countriesLoading = true
      const response = await getAllCountries()

      if (response.success) {
        this.countries = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.countriesLoading = false
    },

    /////////////////////////////////////////////////////////////

    async handleGetStagesSection(section_id: number) {
      this.stagesLoading = true
      const response = await getAllStages(section_id)
      if (response.success) {
        this.stages = response.data?.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.stagesLoading = false
    },

    /////////////////////////////////////////////////////////////

    async handleGetMembers(search?: string) {
      this.$stores.usersData.handleGetMembers({
        keyword: search,
      })
    },

    handleSearchMembers(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMembers(search)
      }, 500)
    },

    /////////////////////////// Modal ///////////////////////////

    toggleLinkMatter() {
      this.isLinkMatter = !this.isLinkMatter
      this.selectedMatter = null
      this.mattersModal = [] as MatterPresenter[]
    },

    async handleGetMattersModal() {
      this.mattersLoading = true

      const response = await getAllMatters({
        per_page: this.mattersItemPerPageModal,
        page: this.matterPageModal,
        keyword: this.matterSearchKeywordModal,
      })
      if (response.success) {
        this.mattersModal = response.data?.list.filter(
          (mat: MatterPresenter) => mat.id !== Number(this.$route.params.id),
        )
        this.matterTotalItemsModal =
          response.data.total_pages * this.mattersItemPerPageModal
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.mattersLoading = false
    },

    async handlePaginationMattersModal({
      page,
      itemsPerPage,
      sortBy,
    }: paginationInterface) {
      this.mattersItemPerPageModal = itemsPerPage
      this.matterPageModal = page
      if (sortBy.length > 0) {
        this.matterSortFieldModal = sortBy[0]?.key.split('.')[1]
        this.matterSortDirectionModal = sortBy[0]?.order
      } else {
        this.matterSortFieldModal = undefined
        this.matterSortDirectionModal = undefined
      }

      await this.handleGetMattersModal()
    },

    searchMatterData() {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMattersModal()
      }, 500)
    },

    handleSelectMatter(matter: MatterPresenter | null) {
      this.selectedMatter = matter
    },

    handleUnSelectMatter() {
      this.form.parent = {} as MatterPresenter
      console.log(this.form.parent, this.selectedMatter)
    },

    async handleLinkMatter() {
      this.form.parent = this.selectedMatter as MatterPresenter
      this.toggleLinkMatter()
    },

    ///////////////////////////////////////////// Time Record /////////////////////////////////////////
    async handleGetTimeRecords(id: string) {
      await this.$stores.mockTimer.handleGetTimesForItem({
        linked_item_type: 'LegalMatter',
        linked_item_id: Number(id),
      })
    },

    async handleStartEndTime(start: boolean) {
      this.startRecordLoading = true

      if (!start) {
        await this.$stores.mockTimer.handleUpdateTimerForItem(
          {
            id: this.$stores.mockTimer?.specificCalendarItems[0]?.id,
            end_time: new Date(),
          },
          this.getTimeDataType,
        )
      } else {
        await this.$stores.mockTimer.handleStartTimerForItem(
          {
            start_time: new Date().toISOString(),
            calendar_item_linked_items_attributes: [this.getTimeDataType],
          },
          this.getTimeDataType,
        )
      }
      if (this.activeTab === 'TIME LOG') {
        this.handleGetTimeLog()
      }

      this.startRecordLoading = false
    },

    async handleStartEndRecord({
      record,
      index,
    }: {
      record: calendarItemsFormInterface
      index: number
    }) {
      const { id, end_time, description, billable } = record

      this.loadingStates[index] = true

      if (end_time == null) {
        await this.$stores.mockTimer.handleUpdateTimerForItem(
          {
            id,
            end_time: new Date(),
          },
          this.getTimeDataType,
        )
      } else {
        await this.$stores.mockTimer.handleStartTimerForItem(
          {
            description,
            start_time: new Date().toISOString(),
            billable,
            calendar_item_linked_items_attributes: [this.getTimeDataType],
            id,
          },
          this.getTimeDataType,
        )
      }
      if (this.activeTab === 'TIME LOG') {
        this.handleGetTimeLog()
      }

      this.loadingStates[index] = false
    },

    async handleUpdateRecord(record: calendarItemsFormInterface) {
      this.updateRecordLoading = true

      await this.$stores.mockTimer.handleUpdateTimerForItem(
        record,
        this.getTimeDataType,
      )
      if (this.activeTab === 'TIME LOG') {
        this.handleGetTimeLog()
      }

      this.updateRecordLoading = false
    },

    async handleDeleteRecord(id: number) {
      this.deleteRecordLoading = true

      await this.$stores.mockTimer.handleDeleteTimerForItem(
        id,
        this.getTimeDataType,
      )
      if (this.activeTab === 'TIME LOG') {
        this.handleGetTimeLog()
      }

      this.deleteRecordLoading = false
    },

    async handleCreateManual(manualForm: calendarItemsFormInterface) {
      this.manualFormLoading = true

      await this.$stores.mockTimer.handleStartTimerForItem(
        {
          ...manualForm,
          calendar_item_linked_items_attributes: [this.getTimeDataType],
        },
        this.getTimeDataType,
      )
      if (this.activeTab === 'TIME LOG') {
        this.handleGetTimeLog()
      }
      this.manualFormLoading = false
    },

    async handleAddTimeRange(
      calendarItemsRangeForm: calendarItemsFormInterface,
    ) {
      this.calendarItemsRangeFormLoading = true

      await this.$stores.mockTimer.handleStartTimerForItem(
        {
          ...calendarItemsRangeForm,
          calendar_item_linked_items_attributes: [this.getTimeDataType],
        },
        this.getTimeDataType,
      )

      if (this.activeTab === 'TIME LOG') {
        this.handleGetTimeLog()
      }
      this.calendarItemsRangeFormLoading = false
    },

    ///////////////////////////////////////////// Time Log /////////////////////////////////////////

    async handleGetTimeLog() {
      this.timeLogTableLoading = true

      const response = await getAllCalendarItems({
        per_page: this.timeLogsItemPerPage,
        page: this.timeLogPage,
        sort_by_direction: this.timeLogSortDirection,
        sort_by_field: 'start_time',
        linked_item_type: 'LegalMatter',
        linked_item_id: Number(this.$route.params.id),
        for_whole_company: true,
      })
      if (response.success) {
        this.timeLogs = response.data?.list
        this.timeLogTotalItems =
          response.data.total_pages * this.timeLogsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.timeLogTableLoading = false
    },

    async handlePaginationTimeLog({ page, itemsPerPage }: paginationInterface) {
      this.timeLogsItemPerPage = itemsPerPage
      this.timeLogPage = page

      await this.handleGetTimeLog()
    },
  },
})
