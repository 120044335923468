export class CountryPresenter {
  id!: number | string
  class_name!: string
  created_at!: string // ISO date string
  name!: string
  updated_at!: string

  constructor(data: CountryPresenter) {
    Object.assign(this, data)
  }
}
