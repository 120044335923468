import { formFields } from '@/constants/matterTemplates'
import { CompanyConfigurationPresenter } from '../companyConfigPresenters'
import { IndividualContactPresenter } from '../contactPresenter'
import { LegalMatterUser, TaskRelationship } from '../interface'
import { TaskPresenter } from '../taskPresenter'
import { TeamMemberPresenter } from '../teamMember'
import { MatterTemplatePresenter } from '../TemplatePresenter/Matter'
import { StagesPresenter } from '../StagesPresenter'
import { mockMatterFelids } from '@/constants/mockMaterTemplate'

export class MatterPresenter {
  id!: number
  category!: CompanyConfigurationPresenter
  sector!: CompanyConfigurationPresenter

  class_name!: string
  company_id!: number
  court_case_number!: string

  created_at!: string
  due_date!: string
  end_date!: string

  legal_matter_users!: LegalMatterUser[]
  name!: string
  notes!: string

  opponent_contact!: IndividualContactPresenter
  case_manager!: TeamMemberPresenter[]
  personnel!: TeamMemberPresenter[]
  parent!: MatterPresenter
  region!: string
  representation_type!: string
  representing_contact!: IndividualContactPresenter

  specialization!: CompanyConfigurationPresenter

  start_date!: string
  status!: CompanyConfigurationPresenter

  updated_at!: string
  court!: CompanyConfigurationPresenter
  judge!: IndividualContactPresenter
  jurisdriction!: CompanyConfigurationPresenter
  task_relationships!: TaskRelationship[]
  tasks!: TaskPresenter[]

  section_stage_items!: StagesPresenter[]
  section_stage!: any

  prependIcon?: string
  path_to?: string

  form_columns!: MatterTemplatePresenter[][]

  constructor(data: MatterPresenter) {
    Object.assign(this, data)

    if (data.representing_contact) {
      this.representing_contact = new IndividualContactPresenter(
        data.representing_contact,
      )
    }
    if (data.judge) {
      this.judge = new IndividualContactPresenter(data.judge)
    }

    if (data.opponent_contact) {
      this.opponent_contact = new IndividualContactPresenter(
        data.opponent_contact,
      )
    }

    if (data.parent) {
      this.parent = new MatterPresenter(data.parent)
    }

    this.personnel =
      this.legal_matter_users
        ?.filter((user) => user?.relationship_type === 'personnel')
        ?.map((user) => {
          const teamMember = new TeamMemberPresenter(user?.user)
          return teamMember
        }) || []

    this.case_manager =
      this.legal_matter_users
        ?.filter((user) => user?.relationship_type === 'case_manager')
        ?.map((user) => {
          const teamMember = new TeamMemberPresenter(user.user)
          return teamMember
        }) || []

    if (data.section_stage_items) {
      this.section_stage_items = data.section_stage_items.map(
        (item) => new StagesPresenter(item),
      )

      this.form_columns = this.section_stage_items[0]?.section.form_columns
    }

    //////////////////////////// related tasks /////////////////////////////

    this.tasks = this.task_relationships?.map((item: TaskRelationship) => {
      return {
        ...new TaskPresenter(item.task),
        task_relation_id: item.id,
      }
    })

    this.path_to = `/legal-matters/${this.id}`
    this.prependIcon = 'inventory_2'
  }
}
