import {
  addCompanyConfigType,
  addCompanyConfiguration,
  CompanyConfigurationsResponse,
  getCompanyConfigurations,
} from '@/services/companyConfigurations'
import { getGeneralData } from '@/services/generalData'
import { defineStore } from 'pinia'
import { useToast } from 'vuestic-ui/web-components'

export const generalDataStore = defineStore({
  id: 'generalData',
  state: () => ({
    global_loading: false,
    companyConfigs: JSON.parse(
      localStorage.getItem('companyConfigs') as string,
    ) as CompanyConfigurationsResponse,
    // countries: JSON.parse(localStorage.getItem('countries') as string) ?? [],
    // currencies: JSON.parse(localStorage.getItem('currencies') as string) ?? [],

    vaToast: useToast(),
    countries: [] as { name: string }[],

    isAddGlobalConfigLoading: false,
  }),

  actions: {
    async handleGetCompanyConfigurations() {
      const response = await getCompanyConfigurations()
      if (response.success) {
        this.companyConfigs = response.data
        localStorage.setItem('companyConfigs', JSON.stringify(response.data))

        return true
      } else {
        this.vaToast.init({ message: `${response.message}`, color: 'danger' })
        return false
      }
    },
    async handleGetGeneralData() {
      const response = await getGeneralData()
      if (response.success) {
        this.companyConfigs = response.data
        // localStorage.setItem(
        //   'countries',
        //   JSON.stringify(response.data.countries),
        // );
        // localStorage.setItem(
        //   'currencies',
        //   JSON.stringify(response.data.currencies),
        // );
        return true
      } else {
        this.vaToast.init({ message: `${response.message}`, color: 'danger' })
        return false
      }
    },

    async handleAddGlobalConfig(body: addCompanyConfigType) {
      this.isAddGlobalConfigLoading = true

      const response = await addCompanyConfiguration(body)
      if (response.success) {
        await this.handleGetCompanyConfigurations()
      } else {
        this.vaToast.init({
          message: `${response.message}`,
          color: 'danger',
        })
      }

      this.isAddGlobalConfigLoading = false
    },
  },
})
