import { StagesPresenter } from '@/presenters/StagesPresenter'
import { apiRequest } from './apiUtils'
import { StageItemBodyInterface } from '@/Interfaces/Services'

export const getAllStages = async (section_id: number) => {
  const response = await apiRequest('get', `/sections/${section_id}/stages`)

  if (!response.success) {
    return response
  }

  const list = response.data?.list
  if (!Array.isArray(list)) {
    return response
  }

  return {
    ...response,
    data: {
      ...response.data,
      list: list.map((item: StagesPresenter) => new StagesPresenter(item)),
    },
  }
}

export const getStageItems = async (section_id: number, stage_id: number) => {
  const response = await apiRequest(
    'get',
    `/sections/${section_id}/stages/${stage_id}/items`,
  )

  if (!response.success) {
    return response
  }

  const list = response.data?.list
  if (!Array.isArray(list)) {
    return response
  }

  return {
    ...response,
    data: {
      ...response.data,
      list: list.map((item: StagesPresenter) => new StagesPresenter(item)),
    },
  }
}

export const addStageItem = (
  section_id: number,
  body: StageItemBodyInterface,
) => {
  return apiRequest('post', `/sections/${section_id}/stages`, body)
}

export const updateStageItem = (
  section_id: number,
  stage_id: number,
  body: StageItemBodyInterface,
) => {
  return apiRequest('put', `/sections/${section_id}/stages/${stage_id}`, body)
}

export const deleteStageItem = (section_id: number, stage_id: number) => {
  return apiRequest('delete', `/sections/${section_id}/stages/${stage_id}`)
}
