import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'NotFound',
  setup() {
    const router = useRouter()

    onMounted(() => {
      setTimeout(() => {
        router.back()
      }, 1000)
    })
  },
}
