import { defineStore } from 'pinia'

export const useUIStore = defineStore({
  id: 'ui',
  state: () => ({
    showAddCalendarPopup: false,
    showFilterDrawer: false,
    showAddTaskPopup: false,

    showSideDrawer: false,
    showCalendarSideDrawer: false,
  }),
})
