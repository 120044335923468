import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'
import { user_form, user_pass } from './interface'
import { updateUserInfo, updateUserPass } from '@/services/users'
import { validateInput } from '@/lib/utils'
import TabsBar from '@/components/tabsBar/index.vue'

export default defineComponent({
  name: 'InfoSecurity',
  components: {
    TabsBar,
  },
  data() {
    const { validate: validateInfo } = useForm('form')
    const { reset: resetPass } = useForm('pass_form')

    return {
      validateInput,

      validateInfo,
      resetPass,

      user_form: {} as user_form,
      updateUserLoading: false,

      pass_form: {} as user_pass,
      updateUserPassLoading: false,

      lengthIconColor: '#ECF0F1',
      caseIconColor: '#ECF0F1',
      numberIconColor: '#ECF0F1',
    }
  },
  computed: {
    isFormValid(): boolean {
      const isCurrentPasswordValid = this.validateCurrentPassword() === true
      const isNewPasswordValid = this.validateNewPassword() === true
      const isConfirmPasswordValid = this.validateConfirmPassword() === true

      return (
        isCurrentPasswordValid && isNewPasswordValid && isConfirmPasswordValid
      )
    },
  },
  mounted() {
    this.handlePopulateUserInfo()
  },
  methods: {
    /////////////////////////////////////////////////////////////////////////////
    validateCurrentPassword() {
      if (this.pass_form.old_password?.length > 0) {
        return true
      } else {
        return 'Current password is required'
      }
    },
    validateNewPassword() {
      const password = this.pass_form.new_password
      const minLength = password?.length >= 8
      const hasUpperCase = /[A-Z]/.test(password)
      const hasLowerCase = /[a-z]/.test(password)

      this.lengthIconColor = minLength ? '#4CAF50' : '#ECF0F1'
      this.caseIconColor = hasUpperCase && hasLowerCase ? '#4CAF50' : '#ECF0F1'

      if (minLength && hasUpperCase && hasLowerCase) {
        return true
      } else {
        return 'New password must be at least 8 characters long and include both upper and lowercase letters'
      }
    },
    validateConfirmPassword() {
      const confirmPassword = this.pass_form.confirm_new_password
      const password = this.pass_form.new_password
      const matchesPassword = confirmPassword === password
      const hasNumber = confirmPassword && /\d/.test(confirmPassword)
      const hasSpecialChar =
        confirmPassword && /[!@#$%^&*]/.test(confirmPassword)

      this.numberIconColor = hasNumber && hasSpecialChar ? '#4CAF50' : '#ECF0F1'

      if (matchesPassword && hasNumber && hasSpecialChar) {
        return true
      } else {
        return 'Passwords must match and include at least one number and one special character'
      }
    },
    ///////////////////////////////////////////////////////////////////
    handlePopulateUserInfo() {
      const user = this.$stores.auth.user

      if (user) {
        this.user_form.title = user.title as string
        this.user_form.first_name = user.first_name as string
        this.user_form.middle_name = user.middle_name as string
        this.user_form.last_name = user.last_name as string
      }
    },

    ///////////////////////////////////////////////////////////////////

    async handleUpdateUserInfo() {
      this.updateUserLoading = true

      const response = await updateUserInfo(this.user_form)
      if (response.success) {
        this.$stores.auth.$patch((state) => {
          state.user = { ...response.data }
        })

        localStorage.setItem('haqqUser', JSON.stringify(response?.data))

        this.$vaToast.init({
          message: 'Info Updated successfully!',
          color: 'success',
        })

        this.handlePopulateUserInfo()
      } else {
        this.$vaToast.init({
          message: `${response.message}`,
          color: 'danger',
        })
      }

      this.updateUserLoading = false
    },

    async handleUpdateUserPass() {
      const isCurrentPasswordValid = this.validateCurrentPassword()
      const isNewPasswordValid = this.validateNewPassword()
      const isConfirmPasswordValid = this.validateConfirmPassword()

      if (
        isCurrentPasswordValid === true &&
        isNewPasswordValid === true &&
        isConfirmPasswordValid === true
      ) {
        this.updateUserPassLoading = true

        const { confirm_new_password, ...form } = this.pass_form
        console.log({ confirm_new_password })

        const response = await updateUserPass(form)
        if (response.success) {
          this.$vaToast.init({
            message: 'Info Updated successfully!',
            color: 'success',
          })
          this.resetPass()
        } else {
          this.$vaToast.init({
            message: `${response.message}`,
            color: 'danger',
          })
        }

        this.updateUserPassLoading = false
      }
    },
    ///////////////////////////////////////////////////////////////////
  },
})
