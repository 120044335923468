import { AnnouncementPresenter } from '@/presenters/AnnouncementPresenter'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'
import { TeamMemberPresenter } from '@/presenters/teamMember'
import { CurrenciesPresenter } from '@/presenters/CurrenciesPresenter'
import { timezoneOptions } from './timezones'
import { TimezonesInterface } from '@/Interfaces/Constants'

// Helper to mock currencies
const mockCurrency = (code: string, name: string): CurrenciesPresenter => ({
  code,
  name,
  id: 0,
  class_name: '',
  company_id: 0,
  conversion_rate_against_us_dollars: '',
  created_at: '',
  updated_at: ''
})

// Helper to mock a team member
const mockTeamMember = (
  id: number,
  firstName: string,
  lastName: string,
  email: string,
  title: string
): TeamMemberPresenter =>
  new TeamMemberPresenter({
    id,
    avatar: {
      url: `https://example.com/avatar-${id}.jpg`,
      id: null,
      large_url: null,
      medium_url: null,
      thumbnail_url: null
    },
    class_name: 'TeamMember',
    email,
    first_name: firstName,
    last_name: lastName,
    middle_name: null,
    title,
    company_users: [],
    fullName: `${firstName} ${lastName}`,
    avatar_letter: `${firstName.charAt(0)}${lastName.charAt(0)}`,
    avatar_first_letter: `${firstName.charAt(0)}`
  })

// Mock timezones
const mockTimezones = ['America/New_York', 'Europe/London', 'Asia/Tokyo']

// Utility to get filtered timezone options
const getTimezoneOptions = (timezones: string[]) =>
  timezones
    .map(tz => timezoneOptions.find(option => option.timezone === tz))
    .filter((tz): tz is TimezonesInterface => tz !== undefined)

// Mock array of CompanyUserPresenter
const mockCompanyUsers: CompanyUserPresenter[] = [
  new CompanyUserPresenter({
    id: 1,
    basic_salary_amount: 6000,
    basic_salary_currency: mockCurrency('USD', 'US Dollar'),
    basic_salary_frequency: 'monthly',
    billable_hourly_rate_charging_amount: 80,
    billable_hourly_rate_charging_currency: mockCurrency('USD', 'US Dollar'),
    billable_hourly_rate_earning_amount: 70,
    billable_hourly_rate_earning_currency: mockCurrency('USD', 'US Dollar'),
    calendar_timezones: mockTimezones.slice(0, 2),
    calendar_timezones_options: getTimezoneOptions(mockTimezones.slice(0, 2)),
    class_name: 'CompanyUser',
    created_at: '2023-12-31T12:00:00Z',
    updated_at: '2024-01-10T12:00:00Z',
    role: 'Manager',
    company_id: 1,
    company: {
      id: 1,
      name: 'Example Inc.',
      class_name: '',
      company_users_count: 5,
      created_at: '',
      guid: 'team1',
      updated_at: ''
    },
    roleable_type: 'User',
    user_id: 1,
    fullName: 'John Doe',
    avatar_letter: 'JD',
    user: mockTeamMember(
      1,
      'John',
      'Doe',
      'john.doe@example.com',
      'Software Engineer'
    )
  }),
  new CompanyUserPresenter({
    id: 2,
    basic_salary_amount: 4500,
    basic_salary_currency: mockCurrency('EUR', 'Euro'),
    basic_salary_frequency: 'monthly',
    billable_hourly_rate_charging_amount: 100,
    billable_hourly_rate_charging_currency: mockCurrency('EUR', 'Euro'),
    billable_hourly_rate_earning_amount: 90,
    billable_hourly_rate_earning_currency: mockCurrency('EUR', 'Euro'),
    calendar_timezones: mockTimezones.slice(1, 3),
    calendar_timezones_options: getTimezoneOptions(mockTimezones.slice(1, 3)),
    class_name: 'CompanyUser',
    created_at: '2023-11-01T08:00:00Z',
    updated_at: '2024-01-05T08:00:00Z',
    role: 'Developer',
    company_id: 2,
    company: {
      id: 2,
      name: 'Tech Solutions Ltd.',
      class_name: '',
      company_users_count: 3,
      created_at: '',
      guid: 'team2',
      updated_at: ''
    },
    roleable_type: 'User',
    user_id: 2,
    fullName: 'Jane Smith',
    avatar_letter: 'JS',
    user: mockTeamMember(
      2,
      'Jane',
      'Smith',
      'jane.smith@example.com',
      'UI/UX Designer'
    )
  }),
  new CompanyUserPresenter({
    id: 3,
    basic_salary_amount: 7000,
    basic_salary_currency: mockCurrency('GBP', 'British Pound'),
    basic_salary_frequency: 'biweekly',
    billable_hourly_rate_charging_amount: 120,
    billable_hourly_rate_charging_currency: mockCurrency(
      'GBP',
      'British Pound'
    ),
    billable_hourly_rate_earning_amount: 110,
    billable_hourly_rate_earning_currency: mockCurrency('GBP', 'British Pound'),
    calendar_timezones: mockTimezones.slice(0, 1),
    calendar_timezones_options: getTimezoneOptions(mockTimezones.slice(0, 1)),
    class_name: 'CompanyUser',
    created_at: '2023-10-15T14:30:00Z',
    updated_at: '2024-01-09T12:00:00Z',
    role: 'Project Manager',
    company_id: 3,
    company: {
      id: 3,
      name: 'Creative Agency',
      class_name: '',
      company_users_count: 7,
      created_at: '',
      guid: 'team3',
      updated_at: ''
    },
    roleable_type: 'User',
    user_id: 3,
    fullName: 'Michael Brown',
    avatar_letter: 'MB',
    user: mockTeamMember(
      3,
      'Michael',
      'Brown',
      'michael.brown@example.com',
      'Project Manager'
    )
  }),
  new CompanyUserPresenter({
    id: 4,
    basic_salary_amount: 8000,
    basic_salary_currency: mockCurrency('USD', 'US Dollar'),
    basic_salary_frequency: 'annually',
    billable_hourly_rate_charging_amount: 150,
    billable_hourly_rate_charging_currency: mockCurrency('USD', 'US Dollar'),
    billable_hourly_rate_earning_amount: 140,
    billable_hourly_rate_earning_currency: mockCurrency('USD', 'US Dollar'),
    calendar_timezones: mockTimezones.slice(2, 3),
    calendar_timezones_options: getTimezoneOptions(mockTimezones.slice(2, 3)),
    class_name: 'CompanyUser',
    created_at: '2022-09-10T10:00:00Z',
    updated_at: '2024-01-01T08:00:00Z',
    role: 'Senior Consultant',
    company_id: 4,
    company: {
      id: 4,
      name: 'Enterprise Solutions',
      class_name: '',
      company_users_count: 10,
      created_at: '',
      guid: 'team4',
      updated_at: ''
    },
    roleable_type: 'User',
    user_id: 4,
    fullName: 'Laura Wilson',
    avatar_letter: 'LW',
    user: mockTeamMember(
      4,
      'Laura',
      'Wilson',
      'laura.wilson@example.com',
      'Senior Consultant'
    )
  }),
  new CompanyUserPresenter({
    id: 5,
    basic_salary_amount: 3000,
    basic_salary_currency: mockCurrency('CAD', 'Canadian Dollar'),
    basic_salary_frequency: 'weekly',
    billable_hourly_rate_charging_amount: 50,
    billable_hourly_rate_charging_currency: mockCurrency(
      'CAD',
      'Canadian Dollar'
    ),
    billable_hourly_rate_earning_amount: 45,
    billable_hourly_rate_earning_currency: mockCurrency(
      'CAD',
      'Canadian Dollar'
    ),
    calendar_timezones: mockTimezones.slice(0, 1),
    calendar_timezones_options: getTimezoneOptions(mockTimezones.slice(0, 1)),
    class_name: 'CompanyUser',
    created_at: '2023-06-20T11:45:00Z',
    updated_at: '2024-01-11T09:00:00Z',
    role: 'Analyst',
    company_id: 5,
    company: {
      id: 5,
      name: 'Data Insights',
      class_name: '',
      company_users_count: 4,
      created_at: '',
      guid: 'team5',
      updated_at: ''
    },
    roleable_type: 'User',
    user_id: 5,
    fullName: 'Sarah Johnson',
    avatar_letter: 'SJ',
    user: mockTeamMember(
      5,
      'Sarah',
      'Johnson',
      'sarah.johnson@example.com',
      'Analyst'
    )
  })
]

export const mockSentAnnouncements: AnnouncementPresenter[] = [
  {
    id: 1,
    title: 'June 2024 Target',
    description:
      'Fellow lawyers, lets rise to the challenge and exceed our target for June 2024. Our dedication and teamwork will lead us to victory.',
    dateAndTimeOfDispatching: '2025-01-15T10:00:00Z', // ISO 8601 format.
    date: '2025-01-15T10:00:00Z',
    requireConfirmation: false,
    recipients: [
      { ...mockCompanyUsers[0] },
      { ...mockCompanyUsers[1] },
      { ...mockCompanyUsers[2] }
    ]
  },
  {
    id: 2,
    title: 'Warning',
    description:
      'KPIs not met for third consecutive time, firm will take action if this repeats. Please be notified.',
    dateAndTimeOfDispatching: '2025-01-10T02:00:00Z',
    date: '2025-01-15T10:00:00Z',
    requireConfirmation: false,
    recipients: [{ ...mockCompanyUsers[0] }, { ...mockCompanyUsers[4] }]
  },
  {
    id: 3,
    title: 'Warning2',
    description:
      'KPIs not met for third consecutive time, firm will take action if this repeats. Please be notified.',
    dateAndTimeOfDispatching: '2025-01-10T02:00:00Z',
    date: '2025-01-10T02:00:00Z',
    requireConfirmation: false,
    recipients: [{ ...mockCompanyUsers[3] }]
  }
]

export const mockDraftAnnouncements: AnnouncementPresenter[] = [
  {
    id: 3,
    title: 'June 2024 Target',
    description:
      'Fellow lawyers, lets rise to the challenge and exceed our target for June 2024. Our dedication and teamwork will lead us to victory.',
    dateAndTimeOfDispatching: '2025-01-15T10:00:00Z', // ISO 8601 format.
    date: '2025-01-15T10:00:00Z',
    requireConfirmation: true,
    recipients: [
      { ...mockCompanyUsers[0] },
      { ...mockCompanyUsers[1] },
      { ...mockCompanyUsers[2] }
    ]
  },
  {
    id: 4,
    title: 'Warning',
    description:
      'KPIs not met for third consecutive time, firm will take action if this repeats. Please be notified.',
    dateAndTimeOfDispatching: '2025-01-10T02:00:00Z',
    date: '2025-01-10T02:00:00Z',
    requireConfirmation: false,
    recipients: [{ ...mockCompanyUsers[2] }, { ...mockCompanyUsers[3] }]
  }
]
