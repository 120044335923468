<template>
  <SharedHeader title-width="30rem" title="Financials">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left" />
      <div class="sub-SharedHeader-right" />
    </div>
  </SharedHeader>
  <TabsBar :tabs="tabs" :active-tab-id="activeTabId" @changeTab="changeTab" />

  <div class="scroll-container">
    <div v-if="activeTab === 'Invoices'">
      <SearchAndViews
        :active-view="activeView"
        :search="invoiceSearchKeyword"
        @search="searchInvoicesData"
      />
      <ActionsBar
        :show-select-button="true"
        :show-download-icon="true"
        :show-archive-icon="true"
        :show-label-icon="true"
        :show-share-icon="true"
        :edit-columns="true"
        :second-add-button="$t('actionsBar.addInvoice')"
        @SecondButtonAction="toggleAddInvoice"
      />

      <AddInvoiceModal
        :add-invoice-loading="addInvoiceLoading"
        :is-add-invoice="isAddInvoice"
        @handleToggleAddModal="toggleAddInvoice"
        @handleCreateInvoice="handleCreateInvoice"
      />

      <InvoicesTable
        :loading="invoiceTableLoading"
        :items-per-page="invoicesItemPerPage"
        :total-items="invoiceTotalItems"
        :invoices="invoices"
        @selectedRows="getInvoiceSelectedRows"
        @loadMore="handlePaginationInvoices"
        @handleApproveInvoice="handleApproveInvoice"
      />
    </div>

    <div v-else-if="activeTab === 'Expenses'">
      <SearchAndViews
        :active-view="activeView"
        :search="expenseSearchKeyword"
        @search="searchExpensesData"
      />
      <ActionsBar
        :show-select-button="true"
        :show-download-icon="true"
        :show-archive-icon="true"
        :show-label-icon="true"
        :show-share-icon="true"
        :edit-columns="true"
        :second-add-button="$t('actionsBar.addExpense')"
        @SecondButtonAction="toggleAddExpense"
      />

      <AddExpenseModal
        :add-invoice-loading="addInvoiceLoading"
        :is-add-expense="isAddExpense"
        @handleToggleAddModal="toggleAddExpense"
        @handleCreateExpense="handleCreateExpense"
      />

      <ExpensesTable
        :loading="expenseTableLoading"
        :total-items="invoiceTotalItems"
        :expenses="expenses"
        @selectedRows="getExpenseSelectedRows"
        @loadMore="handlePaginationExpenses"
      />

      <!-- <div class="financials-kanban">
      <KanbanColumn
        v-for="(list, index) in lists"
        :key="index"
        :expenses-list="list.list"
        :isFinancials="true"
        :title="list.name"
      />
    </div> -->
    </div>

    <!-- <div v-else-if="activeTab === 'Dashboard'">
    <FinancialsDashboard />
  </div>

  <div v-else-if="activeTab === 'Payments History' && activeView === 'list'">
    <PaymentsHistoryTable
      :headers="paymentsHistoryHeaders"
      :list="paymentsHistoryList"
    />
  </div>
  <div v-else-if="activeTab === 'On Retainer' && activeView === 'list'">
    <DefaultTable />
  </div> -->
    <div v-else>
      <div class="container">
        <ComingSoon />
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
