<template>
  <VaModal
    v-model="isAddHearingBool"
    size="auto"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
    @update:modelValue="handleToggleAddModal"
  >
    <template #header>
      <div class="create-hearing-form-header">
        <h1>Create Hearing</h1>
        <VaIcon
          class="header-icon press-button"
          name="close"
          size="30px"
          @click="handleToggleAddModal"
        />
      </div>
    </template>
    <VaForm ref="form" class="create-hearing-form">
      <VaInput
        v-model="form.name"
        background="#ECF0F1"
        label="name"
        required-mark
        :rules="[validateInput]"
        placeholder="Name"
      />
      <div class="date">
        <VaDateInput
          v-model="form.date"
          placeholder="MM/DD/YYYY"
          label="Invoice Date"
          background="#ECF0F1"
          manual-input
          :rules="[validateInput]"
        />
      </div>
      <VaSelect
        v-model="form.legal_matter_id"
        placeholder="Link To Matter"
        clearable
        background="#ECF0F1"
        label="Select Matters"
        searchable
        :loading="mattersLoading"
        :options="matters"
        :highlight-matched-text="false"
        :text-by="getOptionName"
        :max-visible-options="3"
        value-by="id"
      >
        <template #prepend>
          <VaIcon name="link" class="mr-2" color="secondary" />
        </template>
      </VaSelect>
      <div class="case-assign">
        <div style="width: 70%">
          <VaSelect
            v-model="form.assigned_to_id"
            background="#ECF0F1"
            label="assign to"
            required-mark
            :rules="[validateSelect]"
            clearable
            searchable
            :highlight-matched-text="false"
            :options="$stores.usersData.teamMembers"
            :text-by="getOptionFullName"
            :loading="$stores.usersData.membersLoading"
            placeholder="Assign To"
            value-by="id"
            @update:search="handleSearchMembers"
          />
        </div>
        <VaCheckbox
          disabled
          style="height: 0.5rem"
          class="mb-6"
          label="Of Counsel"
        />
      </div>
      <VaSelect
        v-model="form.representing_contact_id"
        background="#ECF0F1"
        label="client"
        searchable
        :highlight-matched-text="false"
        :options="$stores.contacts.contacts"
        :text-by="getOptionFullName"
        :loading="$stores.contacts.contactsLoading"
        placeholder="Select Client"
        value-by="id"
        @update:search="handleSearchContacts"
      />
      <VaSelect
        v-model="form.opponent_contact_id"
        background="#ECF0F1"
        label="opponent name"
        clearable
        searchable
        :highlight-matched-text="false"
        :options="$stores.contacts.contacts"
        :text-by="getOptionFullName"
        :loading="$stores.contacts.contactsLoading"
        placeholder="Select The Opponent"
        value-by="id"
        @update:search="handleSearchContacts"
      />
      <div class="court-section">
        <VaSelect
          v-model="form.court_id"
          :options="$stores.generalData.companyConfigs.courtOptions"
          label="Court"
          required-mark
          :rules="[validateSelect]"
          style="width: 80%"
          background="#ECF0F1"
          placeholder="Court Type"
          :text-by="getOptionTruncateName110"
          value-by="id"
          :loading="companyConfigLoading"
        />

        <VaInput
          v-model="form.court_case_number"
          background="#ECF0F1"
          style="width: 20%"
          placeholder="Court ID"
          required-mark
          :rules="[validateInput]"
          label="Court ID "
        />
      </div>
      <VaSelect
        v-model="form.judge_id"
        background="#ECF0F1"
        label="judge name"
        clearable
        searchable
        :highlight-matched-text="false"
        :options="$stores.contacts.contacts"
        :text-by="getOptionFullName"
        :loading="$stores.contacts.contactsLoading"
        placeholder="Select The Judge"
        value-by="id"
        @update:search="handleSearchContacts"
      />
      <VaSelect
        v-model="form.jurisdriction_id"
        placeholder="Jurisdictions"
        background="#ECF0F1"
        :loading="countriesLoading"
        label="Jurisdictions"
        required-mark
        searchable
        :options="countries"
        :highlight-matched-text="false"
        :text-by="getOptionName"
        :rules="[validateSelect]"
        value-by="id"
      />
      <VaInput
        v-model="form.region"
        style="width: 100%"
        background="#ECF0F1"
        label="Region"
        placeholder="Region"
      />
      <VaSelect
        v-model="form.participant_contact_ids"
        background="#ECF0F1"
        label="participation clients"
        searchable
        :highlight-matched-text="false"
        :options="$stores.contacts.contacts"
        :text-by="getOptionFullName"
        :loading="$stores.contacts.contactsLoading"
        placeholder="Select Clients"
        value-by="id"
        multiple
        :max-visible-options="3"
        @update:search="handleSearchContacts"
      />
      <VaSelect
        v-model="form.lawyer_user_ids"
        background="#ECF0F1"
        label="participation Lawyers"
        required-mark
        :rules="[validateSelect]"
        clearable
        searchable
        :highlight-matched-text="false"
        :options="$stores.usersData.teamMembers"
        :text-by="getOptionFullName"
        :loading="$stores.usersData.membersLoading"
        placeholder="Select Lawyers"
        multiple
        :max-visible-options="3"
        value-by="id"
        @update:search="handleSearchMembers"
      />
      <VaSelect
        v-model="form.status_id"
        label="Status"
        background="#ECF0F1"
        :options="$stores.generalData.companyConfigs.matterStatusOption"
        :text-by="getOptionName"
        value-by="id"
        placeholder="Select Status"
        :loading="companyConfigLoading"
      />
      <VaInput
        v-model="form.notes"
        placeholder="Note"
        background="#ECF0F1"
        style="width: 100%"
        label="note"
      />
    </VaForm>
    <template #footer>
      <div class="add-hearing-form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleToggleAddModal"
        >
          Cancel
        </VaButton>
        <VaButton
          :loading="addHearingLoading"
          @click="validate() && handleCreateHearing()"
        >
          Save
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
