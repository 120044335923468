<template>
  <div class="actions-bar">
    <div class="select-actions">
      <Button
        v-if="showSelectButton && !select"
        button-text="Select"
        :text-color="'#66023c'"
        :custom-styles="{
          padding: '10px 15px',
          borderRadius: '5px',
          fontSize: '10px',
          fontWeight: '700',
          backgroundColor: '#e5eaf0',
          border: 'none',
        }"
        :hover-styles="{
          backgroundColor: '#66023C',
          color: '#e5eaf0',
        }"
      />

      <Button
        v-else-if="showSelectButton && select"
        button-text="Deselect"
        :text-color="'#e5eaf0'"
        :custom-styles="{
          padding: '10px 15px',
          borderRadius: '5px',
          fontSize: '10px',
          fontWeight: '700',
          backgroundColor: '#002c6c',
          border: 'none',
        }"
        :hover-styles="{
          backgroundColor: '#e5eaf0',
          color: '#002c6c',
        }"
      />

      <div :class="{ actions: true, disabled: !select }">
        <va-icon
          v-if="showDownloadIcon"
          name="download"
          color="primary"
          class="icon"
          :class="{ 'disabled-icon': !select }"
        />
        <va-icon
          v-if="showArchiveIcon"
          name="inventory_2"
          color="primary"
          class="icon"
          :class="{ 'disabled-icon': !select }"
        />
        <va-icon
          v-if="showLabelIcon"
          name="label"
          color="primary"
          class="icon"
          :class="{ 'disabled-icon': !select }"
        />
        <va-icon
          v-if="showShareIcon"
          name="share"
          color="primary"
          class="icon"
          :class="{ 'disabled-icon': !select }"
        />
      </div>
    </div>
    <div class="buttons">
      <Button
        v-if="addButton"
        :button-text="addButton"
        :text-color="'#fff'"
        :icon-after="addIcon"
        :custom-styles="{
          padding: '10px 15px',
          borderRadius: '5px',
          fontSize: '12px',
          fontWeight: '500',
          color: '#66023c',
          backgroundColor: '#fff',
          border: '0.5px solid #E2E8F0',
          maxHeight: '37px',
        }"
        :hover-styles="{
          border: '0.5px solid #66023c',
        }"
        @click="addButtonAction"
      />

      <Button
        v-if="deleteButton && select"
        :button-text="deleteButton"
        :text-color="'#fff'"
        :icon-after="deleteIcon"
        :custom-styles="{
          padding: '10px 15px',
          borderRadius: '5px',
          fontSize: '12px',
          fontWeight: '500',
          backgroundColor: '#66023C',
          border: '1px solid #66023C',
          maxHeight: '37px',
        }"
        :hover-styles="{
          border: '1px solid #fff',
        }"
        @click="addContact"
      />

      <Button
        v-if="secondAddButton && !select"
        :button-text="secondAddButton"
        :text-color="'#fff'"
        :icon-after="whiteAddIcon"
        :custom-styles="{
          padding: '10px 15px',
          borderRadius: '5px',
          fontSize: '12px',
          fontWeight: '500',
          backgroundColor: '#66023C',
          border: '1px solid #66023C',
          maxHeight: '37px',
        }"
        :hover-styles="{
          border: '1px solid #fff',
        }"
        @click="secondButtonAction"
      />

      <Button
        v-if="updateButton && select"
        :button-text="updateButton"
        :text-color="'#fff'"
        :custom-styles="{
          padding: '10px 15px',
          borderRadius: '5px',
          fontSize: '12px',
          fontWeight: '500',
          color: '#66023c',
          backgroundColor: '#fff',
          border: '0.5px solid #E2E8F0',
          maxHeight: '37px',
        }"
        :hover-styles="{
          border: '0.5px solid #66023c',
        }"
        @click="addLead"
      />
      <Button
        v-if="editColumns"
        button-text=""
        :text-color="'#fff'"
        :icon-before="editColumnsIcon"
        :custom-styles="{
          padding: '10px',
          borderRadius: '5px',
          fontSize: '12px',
          fontWeight: '500',
          backgroundColor: '#fff',
          width: 'fit-content',
          border: '0.5px solid #E2E8F0',
        }"
        :hover-styles="{
          border: '0.5px solid #66023c',
        }"
        :only-icon="true"
      />
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
