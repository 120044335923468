import { defineComponent } from 'vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import TabsBar from '@/components/tabsBar/index.vue'
import DatePickerModal from '@/components/DatePickerModal/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import DetailsTimeLogs from '@/components/DetailsTimeLogs/index.vue'
import { useForm } from 'vuestic-ui'
import {
  truncateText,
  getOptionFullName,
  getOptionName,
  getOptionTruncateName50,
  validateInput,
  validateInputEmail,
  validateSelect,
  getOptionTruncateName110,
} from '@/lib/utils'
import { getAllCountries } from '@/services/address'
import { getHearingById, updateHearing } from '@/services/hearings'
import { HearingsPresenter } from '@/presenters/hearingsPresenter'
import { useTabs } from '@/composables/useTabs'
import { MatterPresenter } from '@/presenters/matterPresenter'
import { getAllMatters } from '@/services/legalMatter'
import ForItemTimeRecordModal from '@/components/TimeRecordModal/ForItemTimeRecordModal/index.vue'
import { calendarItemsFormInterface } from '@/Interfaces/layout'
import { CalendarItemsPresenter } from '@/presenters/calendarItemsPresenter'
import { getAllCalendarItems } from '@/services/calendarItem'
import { paginationInterface } from '@/Interfaces/Services'
import { CountryPresenter } from '@/presenters/AddressPresenter/CountryPresenter'

export default defineComponent({
  name: 'HearingsDetails',
  components: {
    SharedHeader,
    TabsBar,
    DatePickerModal,
    ComingSoon,
    ForItemTimeRecordModal,
    DetailsTimeLogs,
  },
  data() {
    const { isValid, validate, reset, resetValidation } = useForm('form')
    const taskBarTabs = [
      { id: 0, name: 'DASHBOARD', icon: 'dashboard' },
      { id: 1, name: 'INFO', icon: 'info' },
      { id: 2, name: 'LINKED INFO', icon: 'task' },
      { id: 3, name: 'TASKS', icon: 'assignment' },
      { id: 4, name: 'CALENDAR', icon: 'calendar_month' },
      { id: 5, name: 'MILESTONES', icon: 'flag' },
      { id: 6, name: 'FILES', icon: 'file_copy' },
      { id: 7, name: 'FINANCIAL', icon: 'attach_money' },
      { id: 8, name: 'HEARINGS', icon: 'gavel' },
      { id: 9, name: 'ENGAGEMENT LETTER', icon: 'article' },
      { id: 10, name: 'EMAILS', icon: 'mail' },
      { id: 11, name: 'KYC DOCUMENTS', icon: 'article' },
      { id: 12, name: 'TIME LOG', icon: 'schedule' },
    ]

    const { activeTabId, activeTab, changeTab } = useTabs(taskBarTabs, 1)

    return {
      validateInput,
      validateInputEmail,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionTruncateName50,
      getOptionTruncateName110,

      tabs: taskBarTabs,
      activeTab,
      activeTabId,
      changeTab,
      relationships: [
        { name: 'Legal Matter', details: 'Recent 2', subDetail: 'child' },
        { name: 'Legal Matter', details: 'Recent 2', subDetail: 'child' },
      ],

      isValid,
      validate,
      reset,
      resetValidation,

      form: {} as HearingsPresenter,

      countriesLoading: false,
      statesLoading: false,
      contactsLoading: false,
      companyConfigLoading: false,
      addHearingLoading: false,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,

      countries: [] as CountryPresenter[],
      states: [] as CountryPresenter[],

      truncateText,

      isLinkMatter: false,
      mattersModal: [] as MatterPresenter[],
      mattersItemPerPageModal: 10,
      matterPageModal: 1,
      matterSortFieldModal: '' as any,
      matterSortDirectionModal: '' as 'asc' | 'desc' | undefined,
      matterSearchKeywordModal: '',
      matterTotalItemsModal: 0,
      mattersLoading: false,
      selectedMatter: null as MatterPresenter | null,

      startRecordLoading: false,
      deleteRecordLoading: false,
      updateRecordLoading: false,
      manualFormLoading: false,
      loadingStates: [] as boolean[],
      getTimeDataType: {
        linked_item_type: 'Hearing' as
          | 'Hearing'
          | 'Task'
          | 'Milestone'
          | 'LegalMatter',
        linked_item_id: Number(this.$route.params.id),
      },

      timeLogs: [] as CalendarItemsPresenter[],
      timeLogsItemPerPage: 10,
      timeLogPage: 1,
      timeLogSortDirection: 'desc' as 'asc' | 'desc',
      timeLogTotalItems: 0,
      selectedTimeLogRows: [],
      timeLogTableLoading: false,

      calendarItemsRangeFormLoading: false,
    }
  },
  watch: {
    form: {
      handler(data) {
        console.log({
          form: data,
        })
      },
      deep: true,
    },
    '$route.params.id'(newId) {
      this.handleGetHearingByID(newId)
      this.handleGetContacts()
      this.handleGetAllCountries()
      this.handleGetTimeRecords(newId)
    },
    isLinkMatter: {
      handler(data) {
        if (data === true) {
          this.handleGetMattersModal()
        }
      },
    },
    activeTab: {
      handler(tabName) {
        if (tabName === 'TIME LOG') {
          this.handleGetTimeLog()
        }
      },
    },
  },
  mounted() {
    this.handleGetHearingByID(this.$route.params.id as string)
    this.handleGetContacts()
    this.handleGetAllCountries()
    this.handleGetMembers()
    this.handleGetTimeRecords(this.$route.params.id as string)
  },
  methods: {
    ///////////////////////////////////////////////////////////
    handleSelectDueDate(date: { date: string }) {
      this.form.start_date = date.date
    },
    handleStatusMenuItemClick(status: any) {
      this.form.status = status
    },
    ///////////////////////////////////////////////////////////
    async handleGetHearingByID(id: string) {
      this.countriesLoading = true
      const response = await getHearingById(id)

      if (response.success) {
        this.form = { ...response.data }
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.countriesLoading = false
    },

    async handleCreateHearing() {
      this.addHearingLoading = true

      const {
        id,
        name,
        legal_matter,
        assigned_to,
        court,
        court_case_number,
        judge,
        representing_contact,
        opponent_contact,
        status,
        jurisdriction,
        region,
        notes,
        start_date,
        hearing_users_users,
        hearing_contacts_users,
      } = this.form

      const response = await updateHearing(id?.toString(), {
        name,
        legal_matter_id: legal_matter?.id || '',
        assigned_to_id: assigned_to?.id,
        court_id: court?.id,
        court_case_number,
        judge_id: judge?.id,
        representing_contact_id: representing_contact?.id,
        opponent_contact_id: opponent_contact?.id,
        status_id: status?.id,
        jurisdriction_id: jurisdriction?.id,
        region,
        notes,
        start_date,
        lawyer_user_ids: hearing_users_users.map((e) => e.id),
        participant_contact_ids: hearing_contacts_users.map((e) => e.id),
      })

      if (response.success) {
        this.$vaToast.init({
          message: 'Hearing updates successfully!',
          color: 'success',
        })
        this.handleGetHearingByID(this.$route.params.id as string)
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.addHearingLoading = false
    },

    ///////////////////////////////////////////////////////////
    async handleGetContacts(search?: string) {
      this.$stores.contacts.handleGetContacts({
        keyword: search,
      })
    },
    handleSearchContacts(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts(search)
      }, 500)
    },
    ////////////////////////////////////////////////////
    async handleGetAllCountries() {
      this.countriesLoading = true
      const response = await getAllCountries()

      if (response.success) {
        this.countries = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.countriesLoading = false
    },
    ////////////////////////////////////////////////////

    async handleGetMembers(search?: string) {
      this.$stores.usersData.handleGetMembers({
        keyword: search,
      })
    },

    handleSearchMembers(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMembers(search)
      }, 500)
    },

    /////////////////////////// Modal ///////////////////////////

    toggleLinkMatter() {
      this.isLinkMatter = !this.isLinkMatter
      this.selectedMatter = null
    },
    async handleGetMattersModal() {
      this.mattersLoading = true

      const response = await getAllMatters({
        per_page: this.mattersItemPerPageModal,
        page: this.matterPageModal,
        keyword: this.matterSearchKeywordModal,
      })
      if (response.success) {
        this.mattersModal = response.data?.list
        this.matterTotalItemsModal =
          response.data.total_pages * this.mattersItemPerPageModal
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.mattersLoading = false
    },
    async handlePaginationMattersModal({
      page,
      itemsPerPage,
      sortBy,
    }: paginationInterface) {
      this.mattersItemPerPageModal = itemsPerPage
      this.matterPageModal = page
      if (sortBy.length > 0) {
        this.matterSortFieldModal = sortBy[0].key.split('.')[1]
        this.matterSortDirectionModal = sortBy[0].order
      } else {
        this.matterSortFieldModal = undefined
        this.matterSortDirectionModal = undefined
      }

      await this.handleGetMattersModal()
    },
    searchMatterData() {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMattersModal()
      }, 500)
    },
    handleSelectMatter(matter: MatterPresenter | null) {
      this.selectedMatter = matter
    },
    handleUnSelectMatter() {
      this.form.legal_matter = null
      console.log(this.form.legal_matter, this.selectedMatter)
    },
    async handleLinkMatter() {
      this.form.legal_matter = this.selectedMatter
      this.toggleLinkMatter()
    },
    ///////////////////////////////////////////// Time Record /////////////////////////////////////////
    async handleGetTimeRecords(id: string) {
      await this.$stores.mockTimer.handleGetTimesForItem({
        linked_item_type: 'Hearing',
        linked_item_id: Number(id),
      })
    },

    async handleStartEndTime(start: boolean) {
      this.startRecordLoading = true

      if (!start) {
        await this.$stores.mockTimer.handleUpdateTimerForItem(
          {
            id: this.$stores.mockTimer?.specificCalendarItems[0].id,
            end_time: new Date(),
          },
          this.getTimeDataType,
        )
      } else {
        await this.$stores.mockTimer.handleStartTimerForItem(
          {
            start_time: new Date().toISOString(),
            calendar_item_linked_items_attributes: [this.getTimeDataType],
          },
          this.getTimeDataType,
        )
      }

      if (this.activeTab === 'TIME LOG') {
        this.handleGetTimeLog()
      }

      this.startRecordLoading = false
    },

    async handleStartEndRecord({
      record,
      index,
    }: {
      record: calendarItemsFormInterface
      index: number
    }) {
      const { id, end_time, description, billable } = record

      this.loadingStates[index] = true

      if (end_time === null) {
        await this.$stores.mockTimer.handleUpdateTimerForItem(
          {
            id,
            end_time: new Date(),
          },
          this.getTimeDataType,
        )
      } else {
        await this.$stores.mockTimer.handleStartTimerForItem(
          {
            description,
            start_time: new Date().toISOString(),
            billable,
            calendar_item_linked_items_attributes: [this.getTimeDataType],
            id,
          },
          this.getTimeDataType,
        )
      }
      if (this.activeTab === 'TIME LOG') {
        this.handleGetTimeLog()
      }

      this.loadingStates[index] = false
    },

    async handleUpdateRecord(record: calendarItemsFormInterface) {
      this.updateRecordLoading = true

      await this.$stores.mockTimer.handleUpdateTimerForItem(
        record,
        this.getTimeDataType,
      )

      if (this.activeTab === 'TIME LOG') {
        this.handleGetTimeLog()
      }
      this.updateRecordLoading = false
    },

    async handleDeleteRecord(id: number) {
      this.deleteRecordLoading = true

      await this.$stores.mockTimer.handleDeleteTimerForItem(
        id,
        this.getTimeDataType,
      )

      if (this.activeTab === 'TIME LOG') {
        this.handleGetTimeLog()
      }
      this.deleteRecordLoading = false
    },

    async handleCreateManual(manualForm: calendarItemsFormInterface) {
      this.manualFormLoading = true

      await this.$stores.mockTimer.handleStartTimerForItem(
        {
          ...manualForm,
          calendar_item_linked_items_attributes: [this.getTimeDataType],
        },
        this.getTimeDataType,
      )

      if (this.activeTab === 'TIME LOG') {
        this.handleGetTimeLog()
      }
      this.manualFormLoading = false
    },

    async handleAddTimeRange(
      calendarItemsRangeForm: calendarItemsFormInterface,
    ) {
      this.calendarItemsRangeFormLoading = true

      await this.$stores.mockTimer.handleStartTimerForItem(
        {
          ...calendarItemsRangeForm,
          calendar_item_linked_items_attributes: [this.getTimeDataType],
        },
        this.getTimeDataType,
      )

      if (this.activeTab === 'TIME LOG') {
        this.handleGetTimeLog()
      }
      this.calendarItemsRangeFormLoading = false
    },

    ///////////////////////////////////////////// Time Log /////////////////////////////////////////
    async handleGetTimeLog() {
      this.timeLogTableLoading = true

      const response = await getAllCalendarItems({
        per_page: this.timeLogsItemPerPage,
        page: this.timeLogPage,
        sort_by_direction: this.timeLogSortDirection,
        sort_by_field: 'start_time',
        linked_item_type: 'Hearing',
        linked_item_id: Number(this.$route.params.id),
        for_whole_company: true,
      })
      if (response.success) {
        this.timeLogs = response.data?.list
        this.timeLogTotalItems =
          response.data.total_pages * this.timeLogsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.timeLogTableLoading = false
    },

    async handlePaginationTimeLog({ page, itemsPerPage }: paginationInterface) {
      this.timeLogsItemPerPage = itemsPerPage
      this.timeLogPage = page

      await this.handleGetTimeLog()
    },
  },
})
