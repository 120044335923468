<template>
  <v-data-table-server
    v-model:selection="selectedRows"
    :loading="loading"
    :headers="headers"
    :items="expenses.list"
    show-select
    class="custom-table"
    :items-length="totalItems"
    :items-per-page="itemsPerPage"
    :items-per-page-options="[10, 25, 50]"
    @update:options="handleLoadMore"
  >
    <!-- <template #[`item.approved`]="{ item }">
      <div class="approved">
        <img
          v-if="item.approved"
          class="icon"
          src="@/assets/icons/approved-icon.svg"
        />
      </div>
    </template> -->

    <template #[`item.name`]="{ item }">
      <router-link :to="`/financial/expense/${item?.id}`">
        <p class="name">
          {{ item.name }}
        </p>
      </router-link>
    </template>

    <template #[`item.description`]="{ item }">
      <p class="description">
        {{ item.description }}
      </p>
    </template>
    <template #[`item.currency`]="{ item }">
      <p class="currency">
        {{ item.currency.code }}
      </p>
    </template>

    <template #[`item.linkTo`]="{ item }">
      <p class="linkTo">
        {{ item?.linkTo || '-' }}
      </p>
    </template>

    <template #[`item.billed_to`]="{ item }">
      <p class="billed_to">
        {{
          item.billed_to
            ? `${item.billed_to.specific_data?.individual_first_name ?? ''} ${
                item.billed_to.specific_data?.individual_last_name ?? ''
              }`.trim() || 'N/A'
            : 'N/A'
        }}
      </p>
    </template>

    <template #[`item.from_wallet`]="{ item }">
      <p class="from_wallet">
        {{
          item.from_wallet
            ? `${item.from_wallet.specific_data?.individual_first_name ?? ''} ${
                item.from_wallet.specific_data?.individual_last_name ?? ''
              }`.trim() || 'N/A'
            : 'N/A'
        }}
      </p>
    </template>

    <template #[`item.status`]="{ item }">
      <div class="status" :class="item?.status.toLowerCase()">
        {{ item.status.split('_').join(' ') }}
      </div>
    </template>

    <template #[`item.expense_type`]="{ item }">
      <p class="type">
        {{ item.expense_type }}
      </p>
    </template>

    <template #[`item.due_date`]="{ item }">
      <p class="from_wallet">
        {{ formatDate(item.due_date) }}
      </p>
    </template>

    <template #[`item.action`]="{ item }">
      <div class="table-action-td">
        <va-dropdown :offset="[0, -70]" close-on-content-click>
          <template #anchor>
            <img
              :src="MoreVertIcon"
              alt="MoreVertIcon"
              class="press-button-2"
            />
          </template>
          <va-dropdown-content>
            <p class="table-action-option" @click="viewProfile(item)">
              <va-icon name="person" /> View Invoice
            </p>
          </va-dropdown-content>
        </va-dropdown>
      </div>
    </template>
  </v-data-table-server>
</template>

<script lang="ts" src="./script.ts"></script>
<style scoped lang="scss" src="./styles.scss"></style>
