import { defineComponent } from 'vue'
import { CompanyConfigurationPresenter } from '@/presenters/companyConfigPresenters'

export default defineComponent({
  name: 'TypeSelectModal',
  props: {},
  emits: ['handleAssignType'],
  data() {
    return {
      typeSearchKeyword: '', // Search keyword entered by the user
      calendarTypesOption: [] as CompanyConfigurationPresenter[] | undefined,
    }
  },
  computed: {
    filteredCalendarTypes(): CompanyConfigurationPresenter[] {
      return (
        this.calendarTypesOption?.filter((type) =>
          type.name
            .toLowerCase()
            .includes(this.typeSearchKeyword.toLowerCase()),
        ) || []
      )
    },
  },
  created() {
    this.calendarTypesOption =
      this.$stores.generalData.companyConfigs.calendarTypesOption
  },
  methods: {
    handleAssignType(data: CompanyConfigurationPresenter) {
      this.$emit('handleAssignType', data)
    },
  },
})
