import { InvoicePresenter } from '@/presenters/InvoicesPresenter'
import { useAuthStore } from '@/stores/auth'
import { defineComponent, ref, PropType } from 'vue'
import { useVueToPrint } from 'vue-to-print'

const tlaLogoImage = new URL(
  '@/assets/images/tribonian_law_advisors_logo.png',
  import.meta.url,
).href
const logoImage = new URL('@/assets/icons/logo.svg', import.meta.url).href

export default defineComponent({
  name: 'InvoiceComponent',
  props: {
    form: {
      type: Object as PropType<InvoicePresenter>,
      required: true,
    },
    subtotal: {
      type: String,
      required: true,
    },
    total: {
      type: String,
      required: true,
    },
    convertToSecondaryCurrency: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    console.log({ formPrint: props.form })

    // Create ref for the DOM element
    const componentRef = ref<HTMLElement | null>(null)

    const authStore = useAuthStore()
    const { user, company } = authStore

    // Use vue-to-print
    const { handlePrint } = useVueToPrint({
      content: () => componentRef.value as HTMLElement, // Cast to ensure type safety
      documentTitle: 'AwesomeFileName', // Title of the printed document
    })

    const totalAmount = (
      quantity: string | number,
      price: string | number,
      tax_percentage: string | number,
      discount_type: string,
      discount_type_amount: string | number,
    ) => {
      // Calculate the subtotal (price * quantity)
      const subtotal = parseFloat((+quantity * +price)?.toFixed(4))

      // Calculate the discount based on the discount type
      let discountedSubtotal = subtotal
      if (discount_type === 'percentage') {
        const discountAmount = parseFloat(
          ((subtotal * +discount_type_amount) / 100)?.toFixed(4),
        )
        discountedSubtotal -= discountAmount
      } else if (discount_type === 'amount') {
        const discountAmount = parseFloat((+discount_type_amount)?.toFixed(4))
        discountedSubtotal -= discountAmount
      }

      // Ensure the discounted subtotal is not negative
      discountedSubtotal = Math.max(0, discountedSubtotal)

      // Calculate the tax on the discounted subtotal
      const taxAmount = parseFloat(
        (discountedSubtotal * (+tax_percentage / 100))?.toFixed(4),
      )

      // Add tax to the discounted subtotal to get the total
      const total = discountedSubtotal + taxAmount

      // Round to 4 decimal places and return the total
      return parseFloat(total?.toFixed(4))
    }

    return {
      componentRef,
      handlePrint,
      tlaLogoImage,
      logoImage,
      user,
      company,
      totalAmount,
    }
  },
})
