import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5beabdc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-form-header" }
const _hoisted_2 = { class: "select-all" }
const _hoisted_3 = { class: "form-modal-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VaIcon = _resolveComponent("VaIcon")!
  const _component_VaCheckbox = _resolveComponent("VaCheckbox")!
  const _component_VaButton = _resolveComponent("VaButton")!
  const _component_VaModal = _resolveComponent("VaModal")!

  return (_openBlock(), _createBlock(_component_VaModal, {
    modelValue: _ctx.isTableConfigUpdateBool,
    "onUpdate:modelValue": [
      _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isTableConfigUpdateBool) = $event)),
      _ctx.handleToggleTableConfig
    ],
    size: "small",
    "hide-default-actions": "",
    "fixed-layout": "",
    "no-outside-dismiss": ""
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.selectedSection?.name) + "'s Table Headers View", 1),
        _createVNode(_component_VaIcon, {
          class: "press-button",
          name: "close",
          size: "30px",
          onClick: _ctx.handleToggleTableConfig
        }, null, 8, ["onClick"])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_VaButton, {
          preset: "secondary",
          "border-color": "primary",
          onClick: _ctx.handleToggleTableConfig
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Cancel ")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_VaButton, {
          loading: _ctx.isTableConfigUpdateLoading,
          onClick: _ctx.handleSubmitTableConfig
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Save ")
          ]),
          _: 1
        }, 8, ["loading", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_VaCheckbox, {
          modelValue: _ctx.isAllSelected,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isAllSelected) = $event)),
          label: _ctx.isAllSelected ? 'Unselect All' : 'Select All',
          class: "mb-4 select-all"
        }, null, 8, ["modelValue", "label"])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableColumns.map((column) => column.title || ''), (item) => {
        return (_openBlock(), _createElementBlock("div", { key: item }, [
          _createVNode(_component_VaCheckbox, {
            modelValue: _ctx.selectedHeaders,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedHeaders) = $event)),
            "array-value": item,
            label: item,
            class: "mb-4"
          }, null, 8, ["modelValue", "array-value", "label"])
        ]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "onUpdate:modelValue"]))
}