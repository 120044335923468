<template>
  <div class="table">
    <div class="col-8">
      <table class="table">
        <thead class="table-header">
          <tr class="header-row">
            <th v-for="header in headers" :key="header" scope="col">
              {{ header.name }}
            </th>
          </tr>
        </thead>
        <draggable v-model="list" tag="tbody" item-key="name">
          <template #item="{ element }">
            <tr>
              <td v-for="header in headers" :key="header.feild">
                <img
                  v-if="header.feild === 'approved' && element[header.feild]"
                  class="icon"
                  src="@/assets/icons/approved-icon.svg"
                />
                <div
                  v-else-if="
                    header.feild === 'approved' && !element[header.feild]
                  "
                />
                <div v-else-if="header.feild === 'linkTo'" class="link">
                  <router-link :to="`/legal-matter/${element.id}`">
                    {{ element[header.feild] }}
                  </router-link>
                </div>
                <div v-else-if="header.feild === 'actions'" class="actions">
                  <img
                    :src="MoreVertIcon"
                    alt="MoreVertIcon"
                    class="press-button-2"
                  />
                </div>
                <div v-else class="cell">
                  {{ element[header.feild] }}
                </div>
              </td>
            </tr>
          </template>
        </draggable>
      </table>
    </div>

    <rawDisplayer class="col-3" :value="list" title="List" />
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style scoped lang="scss" src="./styles.scss"></style>
