export class StorageFoldersPresenter {
  id?: number
  class_name!: string
  created_at!: string
  updated_at!: string
  description!: string
  files_count!: number
  folders_count!: number
  name!: string
  storage_files!: any
  storage_folders!: StorageFoldersPresenter[]
  total_size_cache!: number

  constructor(data: StorageFoldersPresenter) {
    Object.assign(this, data)

    if (data.storage_folders) {
      this.storage_folders = data.storage_folders.map(
        (item: StorageFoldersPresenter) => new StorageFoldersPresenter(item),
      )
    }
  }
}
