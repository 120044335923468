import GlobalConfigModal from '@/components/GlobalConfigModal/index.vue'

import { optionType, selectedOptionType } from '@/Interfaces/SettingsInterfaces'
import { validateInput } from '@/lib/utils'
import { CompanyConfigurationPresenter } from '@/presenters/companyConfigPresenters'
import {
  addCompanyConfigType,
  addCompanyConfiguration,
  deleteCompanyConfiguration,
  updateCompanyConfiguration,
} from '@/services/companyConfigurations'
import { generalDataStore } from '@/stores/generalData'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TaskGlobal',
  components: { GlobalConfigModal },
  data() {
    return {
      store: generalDataStore(),

      validateInput,

      isGlobalConfig: false,
      globalConfig: [] as CompanyConfigurationPresenter[] | undefined,
      selectedOption: {} as selectedOptionType,

      newOptionForm: {} as addCompanyConfigType,
      isAddGlobalConfigLoading: false,
      isDeleteGlobalConfigLoading: false,

      showConfirmConfigId: null as number | null,
      loadingConfigId: null as number | null,

      isUpdateGlobalConfigLoading: false,
    }
  },
  computed: {
    iconName(): string | null {
      const { option } = this.selectedOption

      return option === 'taskPriorityOptions' ? 'task' : null
    },
  },
  methods: {
    /////////////////////////////////////////////////////////////////////////////////////////

    async handleAddGlobalConfig() {
      this.isAddGlobalConfigLoading = true

      const response = await addCompanyConfiguration(this.newOptionForm)
      if (response.success) {
        const res = await this.store.handleGetCompanyConfigurations()
        if (res) {
          this.globalConfig =
            this.$stores.generalData.companyConfigs[
              this.selectedOption.option as optionType
            ]
        }
        this.newOptionForm.name = ''
        this.newOptionForm.color = ''
        this.$vaToast.init({
          message: `
          ${this.selectedOption.title?.slice(0, -1)} created successfully!`,
          color: 'success',
        })
      } else {
        this.$vaToast.init({
          message: `${response.message}`,
          color: 'danger',
        })
      }
      this.isAddGlobalConfigLoading = false
    },

    async handleDeleteGlobalConfig(configId: number) {
      this.loadingConfigId = configId

      const response = await deleteCompanyConfiguration(configId)

      if (response.success) {
        const res = await this.store.handleGetCompanyConfigurations()
        if (res) {
          this.globalConfig =
            this.$stores.generalData.companyConfigs[
              this.selectedOption.option as optionType
            ]
        }
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.loadingConfigId = null
    },

    async handleUpdateGlobalConfig(config: CompanyConfigurationPresenter) {
      this.isUpdateGlobalConfigLoading = true

      console.log({ configUpdate: config })

      const response = await updateCompanyConfiguration(config)

      if (response.success) {
        const res = await this.store.handleGetCompanyConfigurations()
        if (res) {
          this.globalConfig =
            this.$stores.generalData.companyConfigs[
              this.selectedOption.option as optionType
            ]
        }
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isUpdateGlobalConfigLoading = false
    },

    /////////////////////////////////////////////////////////////////////////////////////////

    toggleGlobalConfig(data?: selectedOptionType) {
      this.isGlobalConfig = !this.isGlobalConfig

      if (data) {
        this.selectedOption = data

        console.log(
          JSON.parse(localStorage.getItem('companyConfigs') as string),
        )

        this.globalConfig =
          this.$stores.generalData.companyConfigs[data.option as optionType]

        this.newOptionForm.configuration_type = data.configuration_type
      }
    },

    handleSearchGlobalConfig(event: InputEvent): void {
      const inputElement = event.target as HTMLInputElement
      const searchQuery = inputElement.value.trim().toLowerCase()

      if (
        this.globalConfig &&
        Array.isArray(this.globalConfig) &&
        searchQuery.length > 0
      ) {
        console.log({ searchQuery })
        const filteredConfigs = this.globalConfig.filter(
          (config: CompanyConfigurationPresenter) =>
            config.name?.toLowerCase().includes(searchQuery),
        )
        this.globalConfig = filteredConfigs
      } else {
        const { companyConfigs } = this.$stores.generalData
        const selectedOptionKey = this.selectedOption.option as optionType
        this.globalConfig = companyConfigs[selectedOptionKey]
      }
    },

    // handleSearchGlobalConfig(event: InputEvent) {
    //   const inputElement = event.target as HTMLInputElement
    //   const searchValue = inputElement.value.toLowerCase()

    //   if (
    //     this.globalConfig &&
    //     Array.isArray(this.globalConfig) &&
    //     searchValue.length > 0
    //   ) {
    //     this.globalConfig = this.globalConfig.filter(
    //       (config: CompanyConfigurationPresenter) =>
    //         config.name?.toLowerCase().includes(searchValue)
    //     )
    //   } else {
    //     this.globalConfig =
    //       this.$stores.generalData.companyConfigs[
    //         this.selectedOption.option as optionType
    //       ]
    //   }
    // },
    /////////////////////////////////////////////////////////////////////////////////////////

    handleCheckDeletable(config: CompanyConfigurationPresenter) {
      const roleId = localStorage.getItem('RoleId') as string
      if (config?.company_id?.toString() === roleId) {
        return true
      }
    },

    toggleConfirm(configId: number) {
      this.showConfirmConfigId =
        this.showConfirmConfigId === configId ? null : configId
    },
    confirmDelete(configId: number) {
      this.loadingConfigId = configId

      this.handleDeleteGlobalConfig(configId)
      this.showConfirmConfigId = null
    },
    cancelDelete() {
      this.showConfirmConfigId = null
    },
    /////////////////////////////////////////////////////////////////////////////////////////
  },
})
