import { TeamMemberPresenter } from '@/presenters/teamMember'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'
import { getMembers } from '@/services/company'
import { defineStore } from 'pinia'
import { useAuthStore } from './auth'
import { useToast } from 'vuestic-ui/web-components'
import { getMembersInterfaces } from '@/Interfaces/Services'

export const useUsersStore = defineStore({
  id: 'users',
  state: () => ({
    vaToast: useToast(),
    authStore: useAuthStore(),
    membersLoading: false,
    teamMembers: [] as TeamMemberPresenter[] | CompanyUserPresenter[],
  }),
  actions: {
    async handleGetMembers({
      as_company_user,
      keyword,
    }: getMembersInterfaces = {}) {
      this.membersLoading = true
      const response = await getMembers({
        company_id: this.authStore.company.company_id,
        as_company_user,
        keyword,
        per_page: 20,
      })
      if (response.success) {
        this.teamMembers = response.data?.list
      } else {
        this.vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.membersLoading = false
    },
  },
})
