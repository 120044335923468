<template>
  <ActionsBar
    second-add-button="Add Member"
    @secondButtonAction="toggleAddMember"
  />
  <VaModal
    v-model="isAddMember"
    size="small"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
  >
    <template #header>
      <div class="create-hearing-form-header">
        <h1>Add Member</h1>
        <VaIcon
          class="header-icon press-button"
          name="close"
          size="30px"
          @click="toggleAddMember"
        />
      </div>
    </template>
    <VaForm ref="form" class="create-hearing-form" @submit.prevent="validate">
      <div class="form-inputs">
        <VaInput
          v-model="form.title"
          placeholder="Title"
          background="#ECF0F1"
          label="Title"
        />
        <VaInput
          v-model="form.first_name"
          placeholder="First Name"
          background="#ECF0F1"
          label="First Name"
        />
      </div>
      <div class="form-inputs">
        <VaInput
          v-model="form.middle_name"
          placeholder="Middle Name"
          background="#ECF0F1"
          label="Middle Name"
        />
        <VaInput
          v-model="form.last_name"
          placeholder="Last Name"
          background="#ECF0F1"
          label="Last Name"
        />
      </div>

      <VaInput
        v-model="form.email"
        placeholder="Primary Email"
        background="#ECF0F1"
        :rules="[validateInput, validateInputEmail]"
        label="Primary Email"
        required-mark
      />

      <VaSelect
        v-model="form.role"
        placeholder="Relationship Owner"
        background="#ECF0F1"
        label="Relationship Owner"
        required-mark
        searchable
        :options="rolesOptions"
        :highlight-matched-text="false"
        :rules="[validateSelect]"
      />
    </VaForm>
    <template #footer>
      <div class="add-hearing-form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="toggleAddMember"
        >
          Cancel
        </VaButton>
        <VaButton
          :loading="addMemberLoading"
          @click="validate() && handleAddMember()"
        >
          Save
        </VaButton>
      </div>
    </template>
  </VaModal>

  <SearchAndViews @search="searchMembersData" :search="memberSearchKeyword" />
  <div class="members-list-container">
    <div>
      <div
        v-for="(user, index) in teamMembers"
        :key="index"
        class="members-list"
      >
        <div class="user-container">
          <!-- <img :src="user.user.avatar" alt="user avatar" /> -->
          <router-link :to="`/firm/members/${user?.id}`" :userData="user">
            <va-avatar color="#ECF0F1">
              <span>{{ user.user.avatar_letter }}</span>
            </va-avatar>
          </router-link>
          <div class="details-container">
            <router-link :to="`/firm/members/${user?.id}`" :userData="user">
              <h1>{{ user.user.fullName }}</h1>
              <p class="title">{{ user.role }}</p>
              <p>
                {{ user.user.email }}
              </p>
            </router-link>
          </div>
          <div class="action-option">
            <va-dropdown :offset="[0, -70]" close-on-content-click>
              <template #anchor>
                <img
                  :src="MoreVertIcon"
                  class="matter-action-option-img press-button-2"
                  alt="MoreVertIcon"
                />
              </template>
              <va-dropdown-content>
                <router-link :to="`/firm/members/${user?.id}`" :userData="user">
                  <p class="matter-action-option">View Profile</p>
                </router-link>
                <p class="matter-action-option">Assign Matter</p>
                <p class="matter-action-option">KPI & Targets</p>
              </va-dropdown-content>
            </va-dropdown>
          </div>
        </div>
        <VaDivider />
      </div>
    </div>

    <div class="pagination-container">
      <VaPagination
        v-model="memberPage"
        :visible-pages="7"
        :total="memberTotalItems"
        :page-size="membersItemPerPage"
        boundary-numbers
        class="mb-3 justify-center sm:justify-start"
      >
        <template #prevPageLink="{ onClick, disabled }">
          <VaButton
            preset="primary"
            :disabled="disabled"
            aria-label="go prev page"
            @click="onClick"
          >
            Previous
          </VaButton>
        </template>
        <template #nextPageLink="{ onClick, disabled }">
          <VaButton
            preset="primary"
            :disabled="disabled"
            aria-label="go next page"
            @click="onClick"
          >
            Next
          </VaButton>
        </template>
      </VaPagination>
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
