import {
  getOptionFullName,
  getOptionName,
  getOptionTruncateName50,
  truncateText,
  validateInput,
  validateInputEmail,
  validateSelect,
} from '@/lib/utils'
import { CountryPresenter } from '@/presenters/AddressPresenter/CountryPresenter'
import { getAllCities, getAllCountries, getAllStates } from '@/services/address'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'

const UploadAvatarIcon = new URL(
  '@/assets/icons/upload-avatar-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'AddContactForm',
  props: {
    addContactLoading: {
      type: Boolean,
      default: false,
    },
    isAddContactForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { isValid, validate, reset, resetValidation } = useForm('form')
    const form = {
      individual_title: '',
      individual_job_title: '',
      individual_first_name: '',
      individual_middle_name: '',
      individual_last_name: '',
      individual_suffix: '',
      individual_gender: 'male',
      individual_nationality: '',
      individual_date_of_birth: null,
      individual_tax_identification_number: '',
      email_addresses: [
        {
          label: 'Primary',
          email_address: '',
        },
      ],
      linkedin_url: '',
      phone_numbers: [
        {
          label: 'Primary',
          phone_number: '',
        },
      ],
      organization_contact_id: '' as any,
      relationship_owner_id: '',
      assigned_to_id: '',
      referred_by_id: '' as any,
      notes: '',

      contact_addresses_attributes: [
        {
          city_id: '',
          floor: '',
          zip_code: '',
          street_name: '',
          building_name: '',
          phone_number: '',
          google_maps_url: '',
        },
      ],
      province_state: '',
      country: '',
      address_tag: '',
      kyc_completed: false,
      kyc_notes: '',
      extra_notes: '',
      website_url: '',
    }

    return {
      validateInput,
      validateInputEmail,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionTruncateName50,

      isAddContactFormBool: this.isAddContactForm,
      isValid,
      validate,
      reset,
      resetValidation,
      form,
      sexOptions: ['male', 'female'],
      files: [],
      kyc_documents: [],
      UploadAvatarIcon,
      isAddressAccordion: false,
      countriesLoading: false,
      statesLoading: false,
      citiesLoading: false,
      countries: [] as CountryPresenter[],
      states: [] as CountryPresenter[],
      cities: [] as CountryPresenter[],

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,
      truncateText,
    }
  },
  watch: {
    isAddContactForm: {
      handler(data) {
        this.isAddContactFormBool = data
        if (data) {
          this.handleGetMembers()
          this.handleGetAllCountries()
          this.handleGetContacts()
          this.handleGetOrganizations()
        }
      },
    },
    form: {
      handler(newForm) {
        console.log('Form updated:', newForm)
      },
      deep: true,
    },
  },
  methods: {
    toggleAddContactForm() {
      this.isAddContactFormBool = !this.isAddContactFormBool
      this.$emit('toggleAddContactForm')
    },
    handleCreateContact() {
      const { referred_by_id, organization_contact_id, ...updatedForm } =
        this.form

      this.$emit('handleCreateContact', {
        referred_by_id: referred_by_id.id,
        organization_contact_id: organization_contact_id.id,
        ...updatedForm,
      })
    },
    async handleGetMembers(search?: string) {
      this.$stores.usersData.handleGetMembers({
        keyword: search,
      })
    },
    async handleGetAllCountries() {
      this.countriesLoading = true
      const response = await getAllCountries()

      if (response.success) {
        this.countries = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.countriesLoading = false
    },
    async handleGetState(id: string) {
      this.statesLoading = true
      const response = await getAllStates(id)

      if (response.success) {
        this.states = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.statesLoading = false
    },
    async handleGetCities(id: string) {
      this.citiesLoading = true
      const response = await getAllCities(id)

      if (response.success) {
        this.cities = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.citiesLoading = false
    },
    ////////////////////////////////////////////////////////////////
    async handleGetContacts(search?: string) {
      this.$stores.contacts.handleGetContacts({
        keyword: search,
      })
    },
    handleSearchContacts(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts(search)
      }, 500)
    },

    handleSearchMembers(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMembers(search)
      }, 500)
    },

    /////////////////////////////////////////////////////////////////////
    async handleGetOrganizations(search?: string) {
      this.$stores.contacts.handleGetOrganizations({
        keyword: search,
      })
    },
    handleSearchOrganizations(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetOrganizations(search)
      }, 500)
    },
  },
})
