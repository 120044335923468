import { defineComponent } from 'vue'
import DynamicForm from '@/components/DynamicForm/index.vue'
import { MatterTemplatePresenter } from '@/presenters/TemplatePresenter/Matter'
import { formFieldsMostUsed, formFields } from '@/constants/matterTemplates'
import { MatterTemplateInterface } from '@/Interfaces/Constants'
import draggable from 'vuedraggable'
import { validateInput } from '@/lib/utils'
import { SectionsPresenter } from '@/presenters/SectionsPresenter'

export default defineComponent({
  name: 'MatterTemplateModal',

  props: {
    addMatterTempLoading: {
      type: Boolean,
      default: false,
    },
    isAddMatterTemp: {
      type: Boolean,
      default: false,
    },
    isUpdateTemplate: {
      type: Boolean,
      default: false,
    },
    selectedTemplate: {
      type: Object as () => SectionsPresenter,
      required: false,
    },
  },
  emits: [
    'handleToggleAddModalTemp',
    'handleAddTemplate',
    'handleUpdateTemplate',
  ],
  data() {
    const templatesFound = [
      {
        id: 1,
        name: 'Most Used',
        temps: [
          {
            id: 1,
            name: 'Litigation Case',
          },
          {
            id: 2,
            name: 'Non-Litigation Case',
          },
          {
            id: 3,
            name: 'Divorce Case',
          },
        ],
      },
      {
        id: 1,
        name: 'Haqq Templates',
        temps: [
          {
            id: 1,
            name: 'Template 1',
          },
          {
            id: 2,
            name: 'Template 2',
          },
          {
            id: 3,
            name: 'Template 3',
          },
        ],
      },
      {
        id: 1,
        name: 'Your Saved Templates',
        temps: [
          {
            id: 1,
            name: 'Template 1',
          },
          {
            id: 2,
            name: 'Template 2',
          },
          {
            id: 3,
            name: 'Template 3',
          },
        ],
      },
      {
        id: 1,
        name: 'Marketplace Templates',
        temps: [
          {
            id: 1,
            name: 'Template 1',
          },
          {
            id: 2,
            name: 'Template 2',
          },
          {
            id: 3,
            name: 'Template 3',
          },
          {
            id: 4,
            name: 'Template 4',
          },
          {
            id: 5,
            name: 'Template 5',
          },
          {
            id: 6,
            name: 'Template 6',
          },
        ],
      },
    ]

    return {
      form: {},
      selectedFields: [] as MatterTemplatePresenter[][],
      templateForm: {} as SectionsPresenter,

      isAddMatterTempBool: false,
      isSelectTemplate: false,
      templatesFound,
      validateInput,

      isSelectFields: false,
      isShowSelectFields: false,
      formFieldsMostUsed,
      formFields,
      selectedFieldIndex: null as number | null,
    }
  },

  methods: {
    handleToggleAddModalTemp() {
      this.isAddMatterTempBool = !this.isAddMatterTempBool
      this.$emit('handleToggleAddModalTemp')
    },

    handleToggleSelectTemplate() {
      this.isSelectTemplate = !this.isSelectTemplate
    },

    handleSubmitTemplate() {
      if (this.isUpdateTemplate) {
        const { table_columns, ...templateForm } = this.templateForm
        this.$emit('handleUpdateTemplate', {
          id: templateForm.id,
          body: {
            ...templateForm,
          },
        })
      } else {
        this.$emit('handleAddTemplate', {
          name: this.templateForm?.name,
          form_columns: this.selectedFields,
        })
      }
    },

    searchMatterData(search: string) {
      console.log({ search })
    },

    ///////////////////////////// Add Field Modal /////////////////////////////
    handleToggleSelectFields(index?: number) {
      if (index !== null && index !== undefined) {
        this.selectedFieldIndex = index
      } else {
        this.selectedFieldIndex = null
      }
      this.isSelectFields = !this.isSelectFields
    },
    handleToggleShowSelectFields() {
      this.isShowSelectFields = !this.isShowSelectFields
    },

    handleAddField(field: MatterTemplateInterface) {
      if (this.selectedFieldIndex !== null) {
        this.selectedFields[this.selectedFieldIndex].push(
          field as MatterTemplatePresenter,
        )
      } else {
        this.selectedFields?.push([field as MatterTemplatePresenter])
      }
      this.handleToggleSelectFields()
    },

    handleRemoveRowField(index: number) {
      this.selectedFields.splice(index, 1)
    },

    handleRemoveField(row: number, field: number) {
      // this.selectedFields.splice(index, 1);
      this.selectedFields[row].splice(field, 1)
    },

    onFieldDragEnd(event: { newIndex: number; oldIndex: number }) {
      console.log({ selectedFields: this.selectedFields })
    },
  },
  computed: {
    modalTitle(): string {
      if (this.isUpdateTemplate) return 'Modify Template'
      const title = !this.isSelectTemplate
        ? 'Create Template From Scratch'
        : 'Select Template'
      return title
    },
    firstButton(): string {
      return this.isSelectTemplate ? 'Create From Scratch' : 'Select Template'
    },
    filteredFormFields(): MatterTemplateInterface[] {
      return this.formFields.filter((field) => {
        const isExist = this.selectedFields.some((selectedField) =>
          selectedField.some((selected) => selected.label === field.label),
        )
        return !isExist
      })
    },
    isFieldSelected(): (field: MatterTemplateInterface) => boolean {
      return (field: MatterTemplateInterface): boolean => {
        return !this.selectedFields.some((selectedField) =>
          selectedField.some((selected) => selected.label === field.label),
        )
      }
    },
    isFieldSelectedText(): (field: MatterTemplateInterface) => string {
      return (field: MatterTemplateInterface): string => {
        return this.selectedFields.some((selectedField) =>
          selectedField.some((selected) => selected.label === field.label),
        )
          ? 'Selected'
          : ''
      }
    },
  },
  watch: {
    isAddMatterTemp: {
      handler(data) {
        this.isAddMatterTempBool = data
        if (!data) {
          this.selectedFields = []
          this.templateForm = {} as SectionsPresenter
        }
      },
    },
    addMatterTempLoading: {
      handler(data) {
        if (!data) {
          this.isAddMatterTempBool = false
        }
      },
    },
    selectedTemplate: {
      handler(data) {
        if (data?.name) {
          this.templateForm = data
          this.selectedFields = data.form_columns
          console.log({ selectedTemplate: data })
        }
      },
      immediate: true,
    },
  },
  components: {
    DynamicForm,
    draggable,
  },
})
