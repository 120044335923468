<template>
  <div>
    <div class="tabs-bar">
      <TabsBar
        :tabs="tabs"
        :activeTabId="activeTabId"
        :fullTab="true"
        @changeTab="changeTab"
      />
    </div>

    <AddAnnouncementModal
      :isAddAnnouncementModalOpen="isAddAnnouncementModalOpen"
      @toggleAddAnnouncementModal="toggleAddAnnouncementModal"
      @handleAddAnnouncement="handleAddAnnouncement"
    />

    <div class="search-bar">
      <SearchAndViews
        class="custom-search"
        @search="handleSearch"
        :search="searchKeyword"
      />
      <ActionsBar
        class="inline-actions-bar"
        :secondAddButton="$t('actionsBar.addAnnouncement')"
        @secondButtonAction="toggleAddAnnouncementModal"
      />
    </div>
    <div>
      <VaSelect
        placeholder="View by"
        v-model="selectedOption"
        :options="[...sortByOptions]"
        :text-by="'text'"
        :value-by="'value'"
      >
        <template #prependInner>
          <span>View:</span>
        </template>
      </VaSelect>
    </div>
    <div class="scrollable-container">
      <VaAccordion multiple v-model="isAnnouncementsAccordion">
        <VaCollapse
          v-for="(item, index) in groupedAnnouncements"
          :header="`${item.titleDate}`"
          :key="`${index} ${item.titleDate} ${activeTabId}`"
          solid
          v-model="isAnnouncementsAccordion[index]"
        >
          <template #header="{ value, attrs, iconAttrs }">
            <div v-bind="attrs" class="collapse-header">
              <VaIcon
                :name="!value ? 'va-arrow-down' : 'va-arrow-up'"
                v-bind="iconAttrs"
              />
              <h2 class="header-title">
                {{ item.titleDate }}
              </h2>
              <h2 v-if="activeTabId === 1" class="dot-indicator">•</h2>
              <h2 v-if="activeTabId === 1" class="draft-title">
                {{ item.draftTitle }}
              </h2>
            </div>
            <VaDivider class="divider" />
          </template>
          <template #body>
            <div class="body-container">
              <div class="header-container">
                <div class="header-content">Content</div>

                <div class="icon-container">
                  <VaIcon name="more_vert" class="press-button-2 more-icon" />
                </div>
              </div>

              <div v-for="(announcement, index) in item.data" :key="index">
                <div class="announcement-item">
                  <h4 class="announcement-title">{{ announcement.title }}</h4>
                  <p class="announcement-description">
                    "{{ announcement.description }}"
                  </p>
                </div>

                <div
                  class="announcement-recipients"
                  v-if="
                    announcement.recipients &&
                    announcement.recipients.length > 0
                  "
                >
                  <div class="recipients-header">Recipients</div>

                  <div class="recipients-list">
                    <div
                      class="recipient-card"
                      v-for="(recipient, rIndex) in announcement.recipients"
                      :key="rIndex"
                    >
                      <div>
                        <div class="recipient-details recipient-container">
                          <va-avatar color="#ECF0F1">
                            {{ recipient.avatar_letter }}
                          </va-avatar>

                          <div>
                            <div v-if="recipient.fullName">
                              {{ recipient.fullName }}
                            </div>
                            <div class="recipient-role" v-if="recipient.role">
                              {{ recipient.role }}
                            </div>
                            <div class="recipient-details">
                              <div
                                class="recipient-info"
                                v-if="recipient?.company?.name"
                              >
                                <div class="recipient-company">
                                  {{ recipient?.company?.name }}
                                </div>
                              </div>
                              <div
                                class="recipient-team-info"
                                v-if="recipient?.company?.guid"
                              >
                                <VaIcon
                                  name="groups"
                                  class="team-icon"
                                  size="12px"
                                />
                                <div class="recipient-info">
                                  <p class="team-label">Team member:</p>
                                  <p class="team-guid">
                                    {{ recipient?.company?.guid }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="recipient-status">
                        <p class="user-read-info">
                          <!-- {{ recipient.usersAndReadData }} -->
                          {{ recipient?.company?.company_users_count }} user -
                          Read
                        </p>
                        <VaIcon
                          v-if="activeTabId === 0"
                          size="10px"
                          :name="true ? 'done_all' : 'check'"
                          :color="
                            true
                              ? 'rgba(49, 130, 206, 1)'
                              : 'rgba(74, 85, 104, 1)'
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <VaDivider v-if="index < item.data.length - 1" />
              </div>
            </div>
          </template>
        </VaCollapse>
      </VaAccordion>
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
