<template>
  <div class="tasks-table-container">
    <v-data-table-server
      v-model:selection="selectedRows"
      :loading="loading"
      :headers="headers"
      :items="tasks"
      show-select
      class="custom-table"
      :items-length="localTotalItems"
      :items-per-page="itemsPerPage"
      :items-per-page-options="[10, 25, 50]"
      @update:options="handleLoadMore"
    >
      <template #[`item.name`]="{ item }">
        <router-link class="link" :to="`/tasks/${item.id}`">
          <p class="tasks-description-td">
            {{ item.name }}
          </p>
        </router-link>
      </template>

      <template #[`item.description`]="{ item }">
        <p class="tasks-description-td">
          {{ item.description }}
        </p>
      </template>

      <template #[`item.assigned_to`]="{ item }">
        <td class="tasks-assignedTo-td">
          <va-avatar v-if="item.assigned_to?.avatar_letter" color="#ECF0F1">
            <span>{{ item.assigned_to?.avatar_letter }}</span>
          </va-avatar>
          {{ item.assigned_to?.fullName }}
        </td>
      </template>

      <template #[`item.owner`]="{ item }">
        <td class="tasks-taskOwner-td">
          <va-avatar v-if="item.owner?.avatar_letter" color="#ECF0F1">
            <span>{{ item.owner?.avatar_letter }}</span>
          </va-avatar>
          {{ item.owner?.fullName }}
        </td>
      </template>

      <template #[`item.status`]="{ item }">
        <td
          :class="`tasks-status-td status-${item.status
            ?.toLowerCase()
            .replace(' ', '-')}`"
        >
          {{ item.status }}
        </td>
      </template>

      <template #[`item.start_date`]="{ item }">
        <p class="tasks-description-td">
          {{ item.start_date ? formattedDate(item.start_date) : '-' }}
        </p>
      </template>

      <template #[`item.end_date`]="{ item }">
        <p class="tasks-description-td">
          {{ item.start_date ? formattedDate(item.end_date) : '-' }}
        </p>
      </template>

      <template #[`item.priority`]="{ item }">
        <td v-if="item.priority" class="tasks-priority-td">
          <VaButton
            round
            icon="flag"
            size="small"
            :color="item.priority?.color"
          />
          {{ item.priority?.name }}
        </td>
      </template>

      <template #[`item.action`]="{ item }">
        <td class="table-action-td">
          <va-dropdown :offset="[0, -50]">
            <template #anchor>
              <img
                :src="MoreVertIcon"
                alt="MoreVertIcon"
                class="press-button-2"
              />
            </template>
            <VaDropdownContent v-if="!matterTasksSection">
              <p
                class="table-action-option"
                @click="handleButtonClick(item, 'open-with')"
              >
                Open with
              </p>
              <p
                class="table-action-option"
                @click="handleButtonClick(item, 'request_signature')"
              >
                Request Signature
              </p>
              <p
                class="table-action-option"
                @click="handleButtonClick(item, 'preview')"
              >
                Preview
              </p>
              <p
                class="table-action-option"
                @click="handleButtonClick(item, 'rename')"
              >
                Rename
              </p>
              <p
                class="table-action-option"
                @click="handleButtonClick(item, 'file_info')"
              >
                File Info
              </p>
              <p
                class="table-action-option"
                @click="handleButtonClick(item, 'make_a_copy')"
              >
                Make A Copy
              </p>
              <p
                class="table-action-option"
                @click="handleButtonClick(item, 'access_permissions')"
              >
                Access Permissions
              </p>
              <p
                class="table-action-option"
                @click="handleButtonClick(item, 'share')"
              >
                Share
              </p>
              <p
                class="table-action-option"
                @click="handleButtonClick(item, 'move')"
              >
                Move
              </p>
              <p
                class="table-action-option"
                @click="handleButtonClick(item, 'make_available_online')"
              >
                Make Available Offline
              </p>
              <p
                class="table-action-option"
                @click="handleButtonClick(item, 'delete')"
              >
                delete
              </p>
            </VaDropdownContent>

            <VaDropdownContent v-else>
              <p
                class="table-action-option"
                @click="handleButtonClick(item, 'remove')"
              >
                Remove
              </p>
            </VaDropdownContent>
          </va-dropdown>
        </td>
      </template>
    </v-data-table-server>
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" scoped></style>
