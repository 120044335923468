import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Button',
  props: {
    buttonText: {
      type: String,
      default: 'Button',
    },
    textColor: {
      type: String,
      default: '#fff',
    },
    backgroundColor: {
      type: String,
      default: '#66023c',
    },
    border: {
      type: String,
      default: '1px solid #66023c',
    },
    hoverBackgroundColor: {
      type: String,
      default: '#97537a',
    },
    iconBefore: {
      type: String,
      default: null,
    },
    iconAfter: {
      type: String,
      default: null,
    },
    customStyles: {
      type: Object,
      default: () => ({}),
    },
    hoverStyles: {
      type: Object,
      default: () => ({}),
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    onlyIcon: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  data() {
    return {
      isHovered: false,
    }
  },
  computed: {
    computedClasses(): Record<string, string> {
      return {
        'with-icon-before': this.iconBefore,
        'with-icon-after': this.iconAfter,
      }
    },
    computedStyles(): Record<string, string> {
      return {
        color: this.textColor,
        backgroundColor: this.backgroundColor,
        border: this.border,
        ...this.customStyles,
        ...(this.isHovered ? this.hoverStyles : {}),
      }
    },
  },
})
