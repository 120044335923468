import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cf28074"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search" }
const _hoisted_2 = { class: "cal-link-body" }
const _hoisted_3 = { class: "cal-list" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VaIcon = _resolveComponent("VaIcon")!
  const _component_VaInput = _resolveComponent("VaInput")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    height: "15rem",
    class: "option-card"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_VaInput, {
          modelValue: _ctx.typeSearchKeyword,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.typeSearchKeyword) = $event)),
          placeholder: "Search to link..."
        }, {
          prependInner: _withCtx(() => [
            _createVNode(_component_VaIcon, {
              name: "search",
              color: "secondary"
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredCalendarTypes, (type, index) => {
            return (_openBlock(), _createElementBlock("ul", { key: index }, [
              _createElementVNode("li", {
                class: "press-button",
                onClick: ($event: any) => (_ctx.handleAssignType(type))
              }, [
                _createVNode(_component_font_awesome_icon, {
                  icon: type.icon_name,
                  size: "22px",
                  color: type.color
                }, null, 8, ["icon", "color"]),
                _createTextVNode(" " + _toDisplayString(type.name), 1)
              ], 8, _hoisted_4)
            ]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }))
}