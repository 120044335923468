<template>
  <div class="date-picker-wrapper">
    <v-menu
      v-model="showDatePicker"
      :close-on-content-click="false"
      location="bottom center"
    >
      <template #activator="{ props }">
        <VaButton
          v-if="shape == 'button'"
          preset="secondary"
          v-bind="props"
          class="open-button"
        >
          <span class="open-button">
            <p>Due Date</p>
            <p
              v-if="(isDateSaved || isDateInitialized) && formattedSelectedDate"
            >
              {{ formattedSelectedDate }}
            </p>
            <p v-else class="dueDate-empty-state">Choose date and time</p>
          </span>
        </VaButton>

        <VaIcon
          v-else-if="shape == 'icon'"
          name="calendar_month"
          size="small"
          color="primary"
          v-bind="props"
        />
        <VaButton
          v-else-if="shape == 'button-icon'"
          v-bind="props"
          color="#ECF0F1"
        >
          <img :src="StartDateImage" alt="" />
        </VaButton>

        <VaButton
          v-else-if="shape == 'time-record'"
          v-bind="props"
          preset="secondary"
          class="time-record-button"
          size="small"
        >
          <p
            v-if="(isDateSaved || isDateInitialized) && formattedSelectedDate"
            class="time-record-p"
          >
            {{ formattedSelectedDate }}
          </p>
          <p v-else class="dueDate-empty-state">Choose time</p>
        </VaButton>
        <div
          v-else-if="shape == 'time-record-small'"
          class="time-record-button-small"
        >
          <VaButton
            v-bind="props"
            preset="secondary"
            class="time-record-button-small"
            size="2rem"
          >
            <p
              v-if="(isDateSaved || isDateInitialized) && formattedSelectedDate"
              class="time-record-p"
            >
              {{ formattedSelectedDate }}
            </p>
            <p v-else class="dueDate-empty-state-small">Choose time</p>
          </VaButton>
        </div>
      </template>

      <v-card width="auto" class="pa-4">
        <div class="date-picker-container">
          <VaDatePicker
            v-model="selectedDate"
            :min-date="new Date()"
            :stateful="true"
            style="margin: 0.5rem"
            @close="showDatePicker = false"
          />
        </div>

        <!-- Time selection inputs for hours and minutes -->
        <div class="time-picker-container">
          <fieldset>
            <legend>Time:</legend>
            <div class="time-input-group">
              <input
                v-model="hour"
                type="number"
                min="0"
                max="23"
                placeholder="HH"
                aria-label="Hour"
              />
              <span>:</span>
              <input
                v-model="minute"
                type="number"
                min="0"
                max="59"
                placeholder="MM"
                aria-label="Minute"
              />
            </div>
          </fieldset>
        </div>

        <div class="date-picker-wrapper-buttons">
          <VaButton preset="primary" style="margin: 0.5rem" @click="resetDate">
            Reset
          </VaButton>
          <VaButton
            preset="primary"
            :disabled="!formattedSelectedDate"
            style="margin: 0.5rem"
            @click="saveDate"
          >
            Save
          </VaButton>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script lang="ts" src="./script.ts" />

<style lang="scss" scoped src="./styles.scss"></style>
