import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cc2a20f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "financial-dashboard" }
const _hoisted_2 = {
  key: 0,
  class: "general-container"
}
const _hoisted_3 = { class: "left" }
const _hoisted_4 = { class: "balance-sheet-container" }
const _hoisted_5 = { class: "table left-table" }
const _hoisted_6 = { class: "table right-table" }
const _hoisted_7 = { class: "invoice-summary" }
const _hoisted_8 = { class: "summary" }
const _hoisted_9 = { class: "summary-item" }
const _hoisted_10 = { class: "summary-item" }
const _hoisted_11 = { class: "green" }
const _hoisted_12 = { class: "summary-item" }
const _hoisted_13 = { class: "red" }
const _hoisted_14 = { class: "chart" }
const _hoisted_15 = { class: "right" }
const _hoisted_16 = { class: "rows" }
const _hoisted_17 = { class: "rows" }
const _hoisted_18 = { class: "rows" }
const _hoisted_19 = {
  key: 1,
  class: "client-container"
}
const _hoisted_20 = { class: "top" }
const _hoisted_21 = {
  key: 2,
  class: "time-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineTabs = _resolveComponent("InlineTabs")!
  const _component_ColumnsChart = _resolveComponent("ColumnsChart")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Row = _resolveComponent("Row")!
  const _component_Table = _resolveComponent("Table")!
  const _component_DonutChart = _resolveComponent("DonutChart")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InlineTabs, {
      tabs: _ctx.tabs,
      "active-tab-id": _ctx.activeTabId,
      onChangeTab: _ctx.changeTab
    }, null, 8, ["tabs", "active-tab-id", "onChangeTab"]),
    (_ctx.activeTabId === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Card, {
              title: _ctx.$t('card.totalTimeBilled')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ColumnsChart)
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_Card, {
              title: _ctx.$t('card.balanceSheet')
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_Row, {
                      title: _ctx.$t('row.assets')
                    }, null, 8, ["title"]),
                    _createVNode(_component_Table, {
                      header: _ctx.$t('table.header.currentAssets'),
                      items: _ctx.currentAssets,
                      "total-value": _ctx.totalCurrentAssets
                    }, null, 8, ["header", "items", "total-value"]),
                    _createVNode(_component_Table, {
                      header: _ctx.$t('table.header.nonCurrentAssets'),
                      items: _ctx.nonCurrentAssets,
                      "total-value": _ctx.totalNonCurrentAssets,
                      "is-last": true
                    }, null, 8, ["header", "items", "total-value"]),
                    _createVNode(_component_Row, {
                      "is-total": true,
                      title: _ctx.$t('row.total') + ' ' + _ctx.$t('row.assets'),
                      value: _ctx.totalAssets,
                      class: "total-row"
                    }, null, 8, ["title", "value"])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_Row, {
                      title: _ctx.$t('row.liabilitiesAndEquity')
                    }, null, 8, ["title"]),
                    _createVNode(_component_Table, {
                      header: _ctx.$t('table.header.currentLiabilities'),
                      items: _ctx.currentLiabilities,
                      "total-value": _ctx.totalCurrentLiabilities
                    }, null, 8, ["header", "items", "total-value"]),
                    _createVNode(_component_Table, {
                      header: _ctx.$t('table.header.nonCurrentLiabilities'),
                      items: _ctx.nonCurrentLiabilities,
                      "total-value": _ctx.totalNonCurrentLiabilities
                    }, null, 8, ["header", "items", "total-value"]),
                    _createVNode(_component_Table, {
                      header: _ctx.$t('table.header.equity'),
                      items: _ctx.equity,
                      "total-value": _ctx.totalEquity,
                      "is-last": true
                    }, null, 8, ["header", "items", "total-value"]),
                    _createVNode(_component_Row, {
                      "is-total": true,
                      title: _ctx.$t('row.total') + ' ' + _ctx.$t('row.assets'),
                      value: _ctx.totalLiabilitiesAndEquity,
                      class: "total-row"
                    }, null, 8, ["title", "value"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_Card, {
              title: _ctx.$t('card.invoiceSummary')
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("h4", null, "$" + _toDisplayString(_ctx.invoiced), 1),
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('invoiceSummuary.invoiced')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("h4", _hoisted_11, "$" + _toDisplayString(_ctx.received), 1),
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('invoiceSummuary.received')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("h4", _hoisted_13, "$" + _toDisplayString(_ctx.outstanding), 1),
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('invoiceSummuary.outstanding')), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_DonutChart)
                  ])
                ])
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_Card, {
              title: _ctx.$t('card.partnerOrLawyerProductivity')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DataTable, {
                  headers: _ctx.partnerOrLawyerHeaders,
                  list: _ctx.partnerOrLawyerList
                }, null, 8, ["headers", "list"])
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_Card, {
              title: _ctx.$t('card.profitAndLoss')
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_Row, {
                    title: _ctx.$t('row.revenue'),
                    value: _ctx.profitAndLoss.revenue
                  }, null, 8, ["title", "value"]),
                  _createVNode(_component_Row, {
                    title: _ctx.$t('row.expenses'),
                    value: _ctx.profitAndLoss.expenses
                  }, null, 8, ["title", "value"]),
                  _createVNode(_component_Row, {
                    "is-total": true,
                    title: _ctx.$t('row.netIncome'),
                    value: _ctx.profitAndLoss.netIncome
                  }, null, 8, ["title", "value"])
                ])
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_Card, {
              title: _ctx.$t('card.expenseSummary')
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_Row, {
                    "is-total": true,
                    title: _ctx.$t('row.total'),
                    value: _ctx.expenseSummary.total
                  }, null, 8, ["title", "value"])
                ])
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_Card, {
              title: _ctx.$t('card.clientProfitability')
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_component_Row, {
                    title: _ctx.$t('row.revenue'),
                    value: _ctx.clientProfitability.revenue
                  }, null, 8, ["title", "value"]),
                  _createVNode(_component_Row, {
                    title: _ctx.$t('row.expenses'),
                    value: _ctx.clientProfitability.expenses
                  }, null, 8, ["title", "value"]),
                  _createVNode(_component_Row, {
                    "is-total": true,
                    title: _ctx.$t('row.netIncome'),
                    value: _ctx.clientProfitability.netIncome
                  }, null, 8, ["title", "value"])
                ])
              ]),
              _: 1
            }, 8, ["title"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeTabId === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_Card, {
              title: _ctx.$t('card.outstandingInvoiceReport'),
              tag: _ctx.outstandingInvoiceReportTag
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DataTable, {
                  headers: _ctx.outstandingInvoiceReportHeaders,
                  list: _ctx.outstandingInvoiceReportList
                }, null, 8, ["headers", "list"])
              ]),
              _: 1
            }, 8, ["title", "tag"]),
            _createVNode(_component_Card, {
              title: _ctx.$t('card.paymentHistoryReport'),
              tag: _ctx.paymentHistoryReportTag
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DataTable, {
                  headers: _ctx.paymentHistoryReportHeaders,
                  list: _ctx.paymentHistoryReportList
                }, null, 8, ["headers", "list"])
              ]),
              _: 1
            }, 8, ["title", "tag"])
          ]),
          _createVNode(_component_Card, {
            title: _ctx.$t('card.clientActivityReport')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DataTable, {
                headers: _ctx.clientActivityReportHeaders,
                list: _ctx.clientActivityReportList
              }, null, 8, ["headers", "list"])
            ]),
            _: 1
          }, 8, ["title"]),
          _createVNode(_component_Card, {
            title: _ctx.$t('card.agingReports'),
            tag: _ctx.agingReportsTag
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DataTable, {
                headers: _ctx.agingReportsHeaders,
                list: _ctx.agingReportsList
              }, null, 8, ["headers", "list"])
            ]),
            _: 1
          }, 8, ["title", "tag"]),
          _createVNode(_component_Card, {
            title: _ctx.$t('card.detailsBillingReports')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DataTable, {
                headers: _ctx.detailsBillingReportsHeaders,
                list: _ctx.detailsBillingReportsList
              }, null, 8, ["headers", "list"])
            ]),
            _: 1
          }, 8, ["title"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeTabId === 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
          _createVNode(_component_Card, {
            title: _ctx.$t('card.totalTimeBilled'),
            class: "time-card",
            tag: _ctx.totalTimeBilledTag
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DataTable, {
                headers: _ctx.totalTimeBilledHeaders,
                list: _ctx.totalTimeBilledList
              }, null, 8, ["headers", "list"])
            ]),
            _: 1
          }, 8, ["title", "tag"]),
          _createVNode(_component_Card, {
            title: _ctx.$t('card.nonBillableHours'),
            class: "time-card",
            tag: _ctx.nonBillableHoursTag
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DataTable, {
                headers: _ctx.nonBillableHoursHeaders,
                list: _ctx.nonBillableHoursList
              }, null, 8, ["headers", "list"])
            ]),
            _: 1
          }, 8, ["title", "tag"]),
          _createVNode(_component_Card, {
            title: _ctx.$t('card.matterTimeBreakdown'),
            class: "time-card",
            tag: _ctx.matterTimeBreakdownTag
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DataTable, {
                headers: _ctx.matterTimeBreakdownHeaders,
                list: _ctx.matterTimeBreakdownList
              }, null, 8, ["headers", "list"])
            ]),
            _: 1
          }, 8, ["title", "tag"]),
          _createVNode(_component_Card, {
            title: _ctx.$t('card.utilizationrate'),
            class: "time-card",
            tag: _ctx.utilizationrateTag
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DataTable, {
                headers: _ctx.utilizationrateHeaders,
                list: _ctx.utilizationrateList
              }, null, 8, ["headers", "list"])
            ]),
            _: 1
          }, 8, ["title", "tag"])
        ]))
      : _createCommentVNode("", true)
  ]))
}