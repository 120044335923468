import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { setupPinia } from '../pinia-setup'
import { createI18n } from 'vue-i18n'
import arLocale from '../locales/ar.json'
import enLocale from '../locales/en.json'
import frLocale from '../locales/fr.json'
import { Language } from './stores/lang'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'

if (typeof window !== 'undefined') {
  window.addEventListener('error', (event) => {
    if (
      event.message ===
      'ResizeObserver loop completed with undelivered notifications.'
    ) {
      event.stopImmediatePropagation()
      console.warn('Suppressed ResizeObserver loop error')
    }
  })
}

const messages = {
  en: enLocale,
  ar: arLocale,
  fr: frLocale,
}

export const i18n = createI18n({
  legacy: false,
  locale: 'en',
  messages,
})

i18n.global.locale.value = (localStorage.getItem('locale') || 'en') as Language

// vuestic-ui
import { createVuestic } from 'vuestic-ui'
import 'vuestic-ui/css'
import 'material-design-icons-iconfont/dist/material-design-icons.min.css'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as labsComponents from 'vuetify/labs/components'
import * as directives from 'vuetify/directives'

const app = createApp(App)
setupPinia(app)
const vuetify = createVuetify({
  components: {
    ...components,
    ...labsComponents,
  },
  directives,
})

app
  .use(
    createVuestic({
      config: {
        colors: {
          variables: {
            primary: '#66023c',
            dark_blue: '#002c6c',
          },
        },
      },
    }),
  )
  .use(router)
  .use(i18n)
  .use(vuetify)

library.add(fas)
app.component('FontAwesomeIcon', FontAwesomeIcon)

app.mount('#app')
