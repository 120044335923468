export const leadsTemplateHeaders = [
  { title: '.', key: 'img', align: 'start', sortable: false },
  { title: 'CONTACT PERSON', key: 'person', align: 'start', sortable: false },
  {
    title: 'ORGANIZATION',
    key: 'organization',
    align: 'start',
    sortable: false,
  },
  { title: 'VALUE', key: 'value', align: 'start', sortable: false },
  { title: 'STAGE', key: 'stage', align: 'start', sortable: false },
  { title: 'LABEL', key: 'label', align: 'start', sortable: false },
  { title: 'PROBABILITY', key: 'probability', align: 'start', sortable: false },
  { title: 'CLOSE DATE', key: 'title', align: 'start', sortable: false },
  { title: 'OWNER', key: 'owner', align: 'start', sortable: false },
  { title: 'RENEWAL DATE', key: 'date', align: 'start', sortable: false },
  { title: 'OPT', key: 'action', align: 'start', sortable: false },
]
