import { defineStore } from 'pinia'
import { TeamMemberPresenter } from '@/presenters/teamMember'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    token: '123',
    user: JSON.parse(
      localStorage.getItem('haqqUser') || '{}',
    ) as TeamMemberPresenter,
    company: JSON.parse(
      localStorage.getItem('haqqCompany') || '{}',
    ) as CompanyUserPresenter,
  }),
  actions: {},
})
