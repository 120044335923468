import { CompanyConfigurationPresenter } from '@/presenters/companyConfigPresenters'
import { apiRequest } from './apiUtils'

export type CompanyConfigurationsResponse = {
  courtOptions?: CompanyConfigurationPresenter[]
  matterStatusOption?: CompanyConfigurationPresenter[]
  matterSpecializationOptions?: CompanyConfigurationPresenter[]
  matterCategoryOptions?: CompanyConfigurationPresenter[]
  taskPriorityOptions?: CompanyConfigurationPresenter[]
  hearingStatusOptions?: CompanyConfigurationPresenter[]
  milestonesStatusOption?: CompanyConfigurationPresenter[]
  calendarTypesOption?: CompanyConfigurationPresenter[]
  matterSectorsOption?: CompanyConfigurationPresenter[]
}

export type addCompanyConfigType = {
  id?: number
  configuration_type?: string
  name: string
  color?: string
  icon?: string
}

export const getCompanyConfigurations = async () => {
  const response = await apiRequest('get', 'company_configurations')

  if (response.success && Array.isArray(response.data?.list)) {
    const data = response.data?.list.reduce(
      (acc: any, config: CompanyConfigurationPresenter) => {
        const { configuration_type } = config
        if (!acc[configuration_type]) {
          acc[configuration_type] = []
        }
        acc[configuration_type]?.push(new CompanyConfigurationPresenter(config))
        return acc
      },
      {} as any,
    )

    const roleId = localStorage.getItem('RoleId') as string

    // Sorting function: move items with matching company_id to the top
    const sortByRoleId = (items: CompanyConfigurationPresenter[] = []) => {
      return items.sort((a, b) => {
        const aIsRoleMatch = a?.company_id?.toString() === roleId ? -1 : 1
        const bIsRoleMatch = b?.company_id?.toString() === roleId ? -1 : 1
        return aIsRoleMatch - bIsRoleMatch
      })
    }

    return {
      ...response,
      data: {
        courtOptions:
          sortByRoleId(
            data?.court?.map(
              (item: CompanyConfigurationPresenter) =>
                new CompanyConfigurationPresenter(item),
            ),
          ) || [],

        matterStatusOption:
          sortByRoleId(
            data?.legal_matter_status?.map(
              (item: CompanyConfigurationPresenter) =>
                new CompanyConfigurationPresenter(item),
            ),
          ) || [],

        matterSpecializationOptions:
          sortByRoleId(
            data?.legal_matter_specialization?.map(
              (item: CompanyConfigurationPresenter) =>
                new CompanyConfigurationPresenter(item),
            ),
          ) || [],

        matterCategoryOptions:
          sortByRoleId(
            data?.legal_matter_category?.map(
              (item: CompanyConfigurationPresenter) =>
                new CompanyConfigurationPresenter(item),
            ),
          ) || [],

        taskPriorityOptions:
          sortByRoleId(
            data?.task_priority?.map(
              (item: CompanyConfigurationPresenter) =>
                new CompanyConfigurationPresenter(item),
            ),
          ) || [],

        hearingStatusOptions:
          sortByRoleId(
            data?.hearing_status?.map(
              (item: CompanyConfigurationPresenter) =>
                new CompanyConfigurationPresenter(item),
            ),
          ) || [],

        milestonesStatusOption:
          sortByRoleId(
            data?.milestone_status?.map(
              (item: CompanyConfigurationPresenter) =>
                new CompanyConfigurationPresenter(item),
            ),
          ) || [],

        calendarTypesOption:
          sortByRoleId(
            data?.calendar_item_type?.map(
              (item: CompanyConfigurationPresenter) =>
                new CompanyConfigurationPresenter(item),
            ),
          ) || [],

        matterSectorsOption:
          sortByRoleId(
            data?.legal_matter_sector?.map(
              (item: CompanyConfigurationPresenter) =>
                new CompanyConfigurationPresenter(item),
            ),
          ) || [],
      },
    }
  } else {
    return response
  }
}

// export const getCompanyConfiguration = async () => {
//   const response = await apiRequest('get', 'company_configurations');

//   if (!response.success || !Array.isArray(response.data?.list)) {
//     return response;
//   }

//   // Transform the API response into a grouped configuration object
//   const groupedConfigurations = response.data.list.reduce(
//     (acc: Record<string, CompanyConfigurationPresenter[]>, config: any) => {
//       const { configuration_type } = config;
//       if (!acc[configuration_type]) {
//         acc[configuration_type] = [];
//       }
//       acc[configuration_type].push(new CompanyConfigurationPresenter(config));
//       return acc;
//     },
//     {}
//   );

//   const roleId = localStorage.getItem('RoleId') || '';

//   // Helper function to sort items, prioritizing those that match the RoleId
//   const sortByRoleId = (items: CompanyConfigurationPresenter[] = []): CompanyConfigurationPresenter[] => {
//     return items.sort((a, b) => {
//       const aIsRoleMatch = a?.company_id?.toString() === roleId ? -1 : 1;
//       const bIsRoleMatch = b?.company_id?.toString() === roleId ? -1 : 1;
//       return aIsRoleMatch - bIsRoleMatch;
//     });
//   };

//   // Helper function to transform and sort configurations
//   const transformOptions = (key: string) =>
//     sortByRoleId(groupedConfigurations[key]?.map(item => new CompanyConfigurationPresenter(item)) || []);

//   return {
//     ...response,
//     data: {
//       courtOptions: transformOptions('court'),
//       matterStatusOption: transformOptions('legal_matter_status'),
//       matterSpecializationOptions: transformOptions('legal_matter_specialization'),
//       matterCategoryOptions: transformOptions('legal_matter_category'),
//       taskPriorityOptions: transformOptions('task_priority'),
//       hearingStatusOptions: transformOptions('hearing_status'),
//       milestonesStatusOption: transformOptions('milestone_status'),
//       calendarTypesOption: transformOptions('calendar_item_type'),
//       matterSectorsOption: transformOptions('legal_matter_sector'),
//     },
//   };
// };

export const addCompanyConfiguration = (body: addCompanyConfigType) => {
  return apiRequest('post', 'company_configurations', body)
}

export const updateCompanyConfiguration = (body: addCompanyConfigType) => {
  return apiRequest('put', `company_configurations/${body.id}`, body)
}

export const deleteCompanyConfiguration = (id: number) => {
  return apiRequest('delete', `company_configurations/${id}`)
}
