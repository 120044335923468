import { defineComponent } from 'vue'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'
import ActionsBar from '@/components/actionsBar/index.vue'

export default defineComponent({
  name: 'MemberProfilePage',
  props: {
    currentMember: {
      type: CompanyUserPresenter,
      required: true,
    },
  },
  data() {
    return {}
  },
  emits: [],
  methods: {},
  computed: {},
  components: { ActionsBar },
})
