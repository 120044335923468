<template>
  <div class="legal-matter-details-page">
    <SharedHeader :back-button="true" :title="`${form?.name}`">
      <div class="sub-SharedHeader">
        <div class="sub-SharedHeader-left" />
        <div class="sub-SharedHeader-right">
          <TimeRange
            :date="{ start: form?.start_date, end: form?.end_date }"
            @update:date="handleSelectedDate"
          />
          <DatePickerModal
            :date="form?.due_date"
            @update:date="handleSelectDueDate"
          />
          <ForItemTimeRecordModal
            :start-record-loading="startRecordLoading"
            :loading-states="loadingStates"
            :delete-record-loading="deleteRecordLoading"
            :update-record-loading="updateRecordLoading"
            :manual-form-loading="manualFormLoading"
            :calendar-items-range-form-loading="calendarItemsRangeFormLoading"
            @handleAddTimeRange="handleAddTimeRange"
            @handleStartEndTime="handleStartEndTime"
            @handleStartEndRecord="handleStartEndRecord"
            @handleDeleteRecord="handleDeleteRecord"
            @handleUpdateRecord="handleUpdateRecord"
            @handleCreateManual="handleCreateManual"
          />
        </div>
      </div>
    </SharedHeader>

    <VaModal
      v-model="isLinkMatter"
      size="small"
      hide-default-actions
      fixed-layout
    >
      <template #header>
        <div class="add-matter-header">
          <h1>Add Links</h1>
          <VaIcon
            class="header-icon press-button"
            name="close"
            size="30px"
            color="#66023c"
            @click="toggleLinkMatter"
          />
        </div>

        <div class="search">
          <VaInput
            v-model="matterSearchKeywordModal"
            placeholder="Search Legal Matters..."
            @input="searchMatterData"
          >
            <template #prependInner>
              <VaIcon name="search" color="secondary" />
            </template>
          </VaInput>
        </div>
      </template>

      <div class="add-matter-body">
        <h2>
          {{ selectedMatter?.name }}
          <p v-if="selectedMatter" @click="handleSelectMatter(null)">
            Remove
            <VaIcon name="cancel" />
          </p>
        </h2>

        <h1>Recent</h1>
        <VaInnerLoading :loading="mattersLoading">
          <div class="matters-list">
            <ul v-for="(matter, index) in mattersModal" :key="index">
              <li @click="handleSelectMatter(matter)">
                <VaIcon name="inventory_2" color="primary" /> {{ matter.name }}
              </li>
            </ul>
          </div>
        </VaInnerLoading>
      </div>

      <template #footer>
        <div class="add-matter-buttons">
          <VaButton
            preset="secondary"
            border-color="primary"
            @click="toggleLinkMatter"
          >
            Cancel
          </VaButton>
          <VaButton :disabled="!selectedMatter" @click="handleLinkMatter()">
            Link
          </VaButton>
        </div>
      </template>
    </VaModal>

    <div class="info-container">
      <h2 v-if="stages.length > 0" class="info-container-stages">
        Stage :
        <VaMenu>
          <template #anchor>
            <VaButton
              icon="arrow_drop_down"
              round
              size="small"
              :color="form?.section_stage_items[0]?.section_stage?.color"
            >
              {{ form?.section_stage_items[0]?.section_stage.name }}
            </VaButton>
          </template>
          <VaMenuItem
            v-for="(stage, index) in stages"
            :key="index"
            @click="handleStageMenuItemClick(stage)"
          >
            <VaIcon name="circle" :color="stage.color" />
            {{ stage.name }}
          </VaMenuItem>
        </VaMenu>
      </h2>
      <!-- <h2 v-else-if="form.parent_id">
        <VaButton
          icon="arrow_back"
          round
          @click="handleBackToParent(form?.parent_id)"
        >
          Parent
        </VaButton>
      </h2> -->
      <h2 v-else />

      <h2 class="info-container-status">
        Status :
        <VaMenu>
          <template #anchor>
            <VaButton
              icon="arrow_drop_down"
              round
              size="small"
              :color="form?.status?.color"
              :loading="companyConfigLoading"
            >
              {{ form?.status?.name }}
            </VaButton>
          </template>
          <VaMenuItem
            v-for="(stage, index) in $stores.generalData.companyConfigs
              .matterStatusOption"
            :key="index"
            @click="handleStatusMenuItemClick(stage)"
          >
            <VaIcon name="circle" :color="stage.color" />
            {{ stage.name }}
            <VaDivider />
          </VaMenuItem>
          <VaMenuItem
            v-if="
              ($stores.generalData.companyConfigs?.matterStatusOption || [])
                .length < 1
            "
            @click="$router.push({ path: '/settings' })"
          >
            Add some Statuses from the setting page
          </VaMenuItem>
        </VaMenu>
      </h2>
    </div>
    <div class="taskbar-container">
      <TabsBar
        :tabs="tabs"
        :active-tab-id="activeTabId"
        :add-btn="false"
        @changeTab="changeTab"
      />
    </div>
    <div class="matters-scrollable-container">
      <div v-if="activeTab === 'MATTER INFO'" class="matter-info">
        <div class="matter-add-field-button-container">
          <VaButton
            icon-right="add"
            preset="secondary"
            border-color="primary"
            icon-color="#812E9E"
            disabled
          >
            Add Field
          </VaButton>
        </div>
        <!-- <p class="matter-info-text">
          <va-icon size="large" name="info" />
          You can change the layout of the fields here.
          <VaIcon name="edit" /> It will affect the layout of the table also.
        </p> -->
        <div class="matter-info-relationships">
          <h6 v-if="form.parent?.id">Relationship with *</h6>
          <ol>
            <li v-if="form.parent?.id">
              <p>
                <va-icon size="large" name="subdirectory_arrow_right" />
                <VaButton
                  icon-="link"
                  preset="secondary"
                  text-color="red"
                  icon-color="red"
                  class="matter-relation-name"
                >
                  <router-link :to="`/legal-matters/${form.parent?.id}`">
                    {{ form.parent?.name }}
                  </router-link>
                </VaButton>

                <!-- <span class="matter-relation-subDetail"
                  >({{ relation.subDetail }})</span
                > -->
              </p>
              <VaButton
                icon-="link"
                size="small"
                preset="secondary"
                text-color="red"
                icon-color="red"
                @click="handleUnSelectMatter"
              >
                Remove
              </VaButton>
            </li>
          </ol>
          <VaButton
            :disabled="form.parent?.id"
            icon="link"
            preset="secondary"
            icon-color="#812E9E"
            @click="toggleLinkMatter"
          >
            Link to another legal matter
          </VaButton>
        </div>

        <DynamicForm
          :fields="formFields"
          :initial-values="form"
          :toggle-submit="toggleSubmit"
          :loading="DynamicFormLoading"
          @submit="handleUpdate"
          @handleSearchContacts="handleSearchContacts"
          @handleSearchMembers="handleSearchMembers"
        />

        <div class="add-matter-form-modal-buttons">
          <VaButton
            preset="secondary"
            border-color="primary"
            @click="console.log('cancel')"
          >
            Cancel
          </VaButton>
          <VaButton :loading="loadingUpdate" @click="handleToggleSubmit()">
            Save
          </VaButton>
        </div>
      </div>

      <div v-else-if="activeTab === 'TASKS'">
        <MatterTasksSection
          :tasks="form.tasks"
          @handleGetMattersByID="handleGetMattersByID"
        />
      </div>

      <div v-else-if="activeTab === 'TIME LOG'">
        <DetailsTimeLogs
          :time-log="timeLogs"
          :loading="timeLogTableLoading"
          :items-per-page="timeLogsItemPerPage"
          :total-items="timeLogTotalItems"
          :update-time-log-loading="updateRecordLoading"
          :delete-time-log-loading="deleteRecordLoading"
          @loadMore="handlePaginationTimeLog"
          @handleUpdateTimeLog="handleUpdateRecord"
          @handleDeleteTimeLog="handleDeleteRecord"
        />
      </div>

      <div v-else-if="activeTab === 'MILESTONES'">
        <Milestones />
      </div>

      <div v-else class="coming-soon-container">
        <ComingSoon />
      </div>
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
