//  ctr + shift + r on a nested type to extract it outside the class

import { CompanyUserPresenter } from '../CompanyUserPresenter'
import { Avatar } from '../interface'

export class TeamMemberPresenter {
  id!: number | null
  avatar!: Avatar
  class_name!: string | null
  email!: string | null
  first_name!: string | null
  last_name!: string | null
  middle_name!: string | null
  title!: string | null
  fullName!: string
  avatar_letter!: string
  avatar_first_letter!: string
  company_users!: CompanyUserPresenter[]

  constructor(data: TeamMemberPresenter) {
    Object.assign(this, data)

    if (data?.company_users) {
      this.company_users = data.company_users.map(
        (item) => new CompanyUserPresenter(item),
      )
    } else {
      this.company_users = []
    }

    this.fullName = [this.first_name, this.middle_name, this.last_name]
      .filter(Boolean)
      .join(' ')

    this.avatar_letter = [this.first_name?.charAt(0), this.last_name?.charAt(0)]
      .filter(Boolean)
      .join(' ')

    this.avatar_first_letter = [this.first_name?.charAt(0)]
      .filter(Boolean)
      .join(' ')
  }
}
