<template>
  <div
    class="card"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <div class="card-header">
      <div class="avatar-container">
        <img
          v-if="contact?.img"
          :src="contact.img"
          alt="Avatar"
          class="avatar"
        />
        <div v-else class="avatar-fallback">
          {{ getInitials(contact?.first_name || '', contact?.last_name || '') }}
        </div>
      </div>
      <h2>
        {{ contact?.first_name || 'N/A' }} {{ contact?.last_name || 'N/A' }}
      </h2>
      <p class="contact-title">
        {{ contact?.title || 'N/A' }}, {{ contact?.suffix || 'N/A' }}
      </p>

      <!-- Verified Badge -->
      <span v-if="contact?.kyc_completed" class="badge verified">Verified</span>
      <span v-else class="badge not-verified">Not Verified</span>

      <!-- Type Indicator (Person/Organization) -->
      <span class="badge type">Person</span>
    </div>

    <div class="card-body">
      <p><strong>Email:</strong> {{ contact?.primary_email || 'N/A' }}</p>
      <p>
        <strong>Phone:</strong> {{ contact?.primary_phone_number || 'N/A' }}
      </p>
      <p><strong>Company:</strong> {{ contact?.organization || 'N/A' }}</p>
      <p>
        <strong>Assigned To:</strong
        >{{ contact?.assigned_to?.first_name || 'N/A' }}
        {{ contact?.assigned_to?.last_name || 'N/A' }}
      </p>
      <p>
        <strong>Referred By:</strong>
        {{ contact?.referred_by?.first_name || 'N/A' }}
        {{ contact?.referred_by?.last_name || 'N/A' }}
      </p>
      <p>
        <strong>Address:</strong> {{ contact?.office_building || 'N/A' }},
        {{ contact?.office_officeNumber || 'N/A' }}/Floor
        {{ contact?.office_floor || 'N/A' }},
        {{ contact?.office_street || 'N/A' }},
        {{ contact?.office_city || 'N/A' }},
        {{ contact?.office_provinceState || 'N/A' }},
        {{ contact?.office_country || 'N/A' }}
      </p>
    </div>

    <!-- Footer with Chat Button -->
    <div class="card-footer">
      <router-link :to="`/contacts/${contact?.id || ''}`">
        <button class="chat-btn">View Contact</button>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" scoped></style>
