import { defineComponent } from 'vue'
import Tree from '@/components/tree/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import TabsBar from '@/components/tabsBar/index.vue'
import Accordion from '@/components/FirmTabs/accordion/index.vue'
import TimeSheet from './TimeSheet/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import WorkspaceTeamsMembers from '@/views/firm/WorkspaceTeamsMembers/index.vue'
import Announcements from '@/views/firm/Announcements/index.vue'
import { useTabs } from '@/composables/useTabs'
import { CalendarItemsPresenter } from '@/presenters/calendarItemsPresenter'
import { getAllCalendarItems } from '@/services/calendarItem'
import { paginationInterface } from '@/Interfaces/Services'
import { calendarItemsFormInterface } from '@/Interfaces/layout'
import DetailsTimeLogs from '@/components/DetailsTimeLogs/index.vue'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'
import { getOptionFullName, getOptionName } from '@/lib/utils'

export default defineComponent({
  name: 'HomePage',
  data() {
    const tabs = [
      { id: 0, name: 'Time Sheet' },
      { id: 1, name: 'Organizational Structure' },
      { id: 2, name: 'Workspaces, Teams & Members' },
      { id: 3, name: 'Files' },
      { id: 4, name: 'About Us' },
      { id: 5, name: 'Hiring' },
      { id: 6, name: 'Dashboard' },
      { id: 7, name: 'Announcements' },
    ]
    const { activeTabId, activeTab, changeTab } = useTabs(tabs, 2)

    const typesOption = [
      { name: 'All Types', id: null, color: null },
      ...(this.$stores.generalData.companyConfigs.calendarTypesOption ?? []),
    ]

    const userFiltrationOptions = [
      { id: 107070701, fullName: 'ALL Users' },
      ...((this.$stores.usersData.teamMembers as CompanyUserPresenter[]) || []),
    ]
    const userFiltration = {
      id: 107070701,
      fullName: 'ALL Users',
    } as CompanyUserPresenter

    const activeType = { name: 'All Types', id: null, color: null }

    return {
      tabs,
      activeTabId,
      activeTab,
      changeTab,

      getOptionName,
      getOptionFullName,

      timeLogs: [] as CalendarItemsPresenter[],
      timeLogsItemPerPage: 10,
      timeLogPage: 1,
      timeLogSortDirection: 'desc' as 'asc' | 'desc',
      timeLogTotalItems: 0,
      selectedTimeLogRows: [],
      timeLogTableLoading: false,

      updateRecordLoading: false,
      deleteRecordLoading: false,

      typesOption,
      userFiltrationOptions,
      userFiltration,
      activeType,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,
    }
  },
  methods: {
    ///////////////////////////////////////////// Time Log /////////////////////////////////////////
    async handleGetTimeLog() {
      this.timeLogTableLoading = true

      const response = await getAllCalendarItems({
        per_page: this.timeLogsItemPerPage,
        page: this.timeLogPage,
        sort_by_direction: this.timeLogSortDirection,
        sort_by_field: 'start_time',
        ...(this.userFiltration.id === 107070701
          ? { for_whole_company: true }
          : { company_user_id: this.userFiltration.id }),
        item_type_id: this.activeType?.id || undefined,
      })
      if (response.success) {
        this.timeLogs = response.data?.list

        this.timeLogTotalItems =
          response.data.total_pages * this.timeLogsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.timeLogTableLoading = false
    },

    async handlePaginationTimeLog({ page, itemsPerPage }: paginationInterface) {
      this.timeLogsItemPerPage = itemsPerPage
      this.timeLogPage = page

      await this.handleGetTimeLog()
    },

    async handleUpdateRecord(record: calendarItemsFormInterface) {
      this.updateRecordLoading = true

      await this.$stores.mockTimer.handleUpdateTimer(record, {
        per_page: this.timeLogsItemPerPage,
        page: this.timeLogPage,
        sort_by_direction: this.timeLogSortDirection,
        sort_by_field: 'start_time',
        ...(this.userFiltration.id === 107070701
          ? { for_whole_company: true }
          : { company_user_id: this.userFiltration.id }),
        item_type_id: this.activeType?.id || undefined,
      })

      if (this.activeTab === 'Time Sheet') {
        this.handleGetTimeLog()
      }
      this.updateRecordLoading = false
    },

    async handleDeleteRecord(id: number) {
      this.deleteRecordLoading = true

      await this.$stores.mockTimer.handleDeleteTimer(id, {
        per_page: this.timeLogsItemPerPage,
        page: this.timeLogPage,
        sort_by_direction: this.timeLogSortDirection,
        sort_by_field: 'start_time',
        ...(this.userFiltration.id === 107070701
          ? { for_whole_company: true }
          : { company_user_id: this.userFiltration.id }),
        item_type_id: this.activeType?.id || undefined,
      })

      if (this.activeTab === 'Time Sheet') {
        this.handleGetTimeLog()
      }
      this.deleteRecordLoading = false
    },
    /////////////////////////////////////////////// Members //////////////////////////////////////////

    async handleGetMembers (search?: string) {
      this.$stores.usersData.handleGetMembers({
        keyword: search,
        as_company_user: true,
      })
    },

    handleSearchMembers (search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMembers(search)
      }, 500)
    },

    ///////////////////////////////////////// filters //////////////////////////////

    handleSelectTypeFilter() {
      this.handleGetTimeLog()
    },

    handleSelectUserFilter() {
      this.handleGetTimeLog()
    },
  },
  watch: {
    '$route.query.tab': {
      handler (tabName) {
        if (tabName == 'Time Sheet') {
          this.handleGetTimeLog()
          this.handleGetMembers()
        }
      },
      immediate: true
    },
    '$stores.usersData.teamMembers': {
      handler (data) {
        this.userFiltrationOptions = [
          { id: 107070701, fullName: 'ALL Users' },
          ...data
        ]
      },
      deep: true,
      immediate: true
    }
  },
  components: {
    Tree,
    SharedHeader,
    TabsBar,
    Accordion,
    ComingSoon,
    TimeSheet,
    WorkspaceTeamsMembers,
    DetailsTimeLogs,
    Announcements
  }
})
