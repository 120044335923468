<template>
  <div
    class="tabs-bar"
    :style="{ gap: gapSize }"
    :class="{ 'full-tab': fullTab }"
  >
    <div v-if="loading" class="tab" :style="{ gap: '1rem' }">
      <VaSkeleton
        v-for="tab in [1, 2, 3, 4, 5, 6]"
        :key="tab"
        variant="rounded"
        inline
        width="64px"
        height="32px"
      />
    </div>
    <div
      v-for="tab in tabs"
      v-else
      :key="tab.id"
      class="tab"
      :class="{ active: tab.id === activeTabId }"
      @mouseover="hoveredTabId = tab.id"
      @mouseout="hoveredTabId = null"
      @click="changeTab(tab.id)"
    >
      <VaIcon v-if="tab.icon" :name="tab.icon" size="small" color="primary" />

      <p v-if="tab.name">
        {{ tab.name }}
      </p>
    </div>

    <VaButton
      v-if="addButton"
      round
      icon="add"
      preset="secondary"
      border-color="primary"
      size="small"
      @click="handleAddButtonFunction"
    />

    <!-- <div class="tabs-buttons">
      <VaButton
        round
        icon="add"
        preset="secondary"
        border-color="primary"
        size="small"
        @click="handleOpenAddTab"
      />
      <VaButton
        round
        icon="edit"
        preset="secondary"
        border-color="primary"
        size="small"
        @click="handleEditTab"
      />
    </div> -->
  </div>
  <VaModal v-model="IsTabOpen" ok-text="Save" size="auto" :ok="handleAddTab()">
    <h6 class="va-h3">Add New Tab</h6>
    <VaInput v-model="value" placeholder="Enter Tab Name" />
  </VaModal>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
