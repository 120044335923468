import { ExpensesPresenter } from '@/presenters/ExpensesPresenter'
import { apiRequest } from './apiUtils'
import {
  addExpenseFormInterface,
  getExpensesInterface,
  updateExpenseFormInterface,
} from '@/Interfaces/Services'

export const getAllExpenses = async ({
  per_page,
  page,
  status,
  keyword,
}: getExpensesInterface) => {
  const response = await apiRequest('get', '/expenses', null, {
    per_page,
    page,
    status,
    keyword,
  })

  if (!response.success) {
    return response
  }

  const list = response.data?.list
  if (!Array.isArray(list)) {
    return response
  }

  return {
    ...response,
    data: {
      ...response.data,
      list: list.map((item: ExpensesPresenter) => new ExpensesPresenter(item)),
    },
  }
}

export const getExpenseById = async (id: string) => {
  const response = await apiRequest('get', `/expenses/${id}`)
  if (response.success) {
    return {
      ...response,
      data: new ExpensesPresenter(response.data),
    }
  } else {
    return response
  }
}

export const addExpense = (body: addExpenseFormInterface) => {
  return apiRequest('post', '/expenses', body)
}

export const updateExpense = (id: string, body: updateExpenseFormInterface) => {
  return apiRequest('put', `/expenses/${id}`, body)
}

export const deleteExpense = (id: string) => {
  return apiRequest('delete', `/expenses/${id}`)
}
