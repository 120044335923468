import { paginationInterface } from '@/Interfaces/Services'
import { defineComponent } from 'vue'
const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'AllInvoicesTable',
  props: {
    invoices: {
      type: Array as any,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    totalItems: {
      type: Number,
      default: 10,
    },
  },
  emits: ['selectedRows', 'loadMore', 'handleApproveInvoice'],
  data() {
    return {
      localTotalItems: this.totalItems,
      selectedRows: [],
      MoreVertIcon,
      deleteDialog: false,
      currentPage: 1,
      currentSortBy: [] as any,
      currentItemsPerPage: this.itemsPerPage,
      headers: [
        {
          title: '#',
          align: 'start',
          key: 'invoice_number',
          sortable: false,
        },
        {
          title: 'Invoice Name',
          align: 'start',
          key: 'customer_name',
          sortable: false,
        },
        {
          title: 'Email',
          align: 'start',
          key: 'customer_email',
          sortable: false,
        },
        {
          title: 'VAT',
          align: 'center',
          key: 'tax_amount',
          sortable: false,
        },
        {
          title: 'CUR.',
          align: 'center',
          key: 'currency',
          sortable: false,
        },
        {
          title: 'Created At',
          align: 'center',
          key: 'created_at',
          sortable: false,
        },
        {
          title: 'Due Date',
          align: 'center',
          key: 'payment_due_date',
          sortable: false,
        },
        {
          title: 'Modified Date',
          align: 'center',
          key: 'invoice_date',
          sortable: false,
        },
        {
          title: 'Status',
          align: 'start',
          key: 'status',
          sortable: false,
        },
        {
          title: 'Approved',
          align: 'center',
          key: 'approved',
          sortable: false,
        },
        {
          title: '',
          align: 'center',
          key: 'action',
          sortable: false,
        },
      ],
    }
  },
  watch: {
    selectedRows() {
      this.$emit('selectedRows', this.selectedRows)
    },
    totalItems(newValue) {
      this.localTotalItems = newValue
    },
  },
  methods: {
    handleEdit(item: any) {
      console.log('Editing', item)
    },

    viewProfile(item: { id: number }) {
      this.$router.push(`/financial/invoice/${item?.id}`)
    },

    handleApproveInvoice(id: number) {
      this.$emit('handleApproveInvoice', id)
    },

    handleLoadMore({ page, itemsPerPage, sortBy }: paginationInterface) {
      const { currentPage, currentItemsPerPage, currentSortBy } = this

      // Check if there are any changes in pagination or sorting
      const hasPaginationChanged =
        page !== currentPage || itemsPerPage !== currentItemsPerPage
      const hasSortingChanged =
        JSON.stringify(sortBy) !== JSON.stringify(currentSortBy) // Compare sorting objects

      // Only emit if there's a change in pagination or sorting
      if (hasPaginationChanged || hasSortingChanged) {
        this.$emit('loadMore', { page, itemsPerPage, sortBy })

        // Update state after the change
        this.currentPage = page
        this.currentItemsPerPage = itemsPerPage
        this.currentSortBy = sortBy
      }
    },
  },
})
