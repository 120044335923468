<template>
  <button
    :class="computedClasses"
    :style="computedStyles"
    :disabled="isDisabled"
    @click="$emit('click')"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <img
      v-if="iconBefore"
      class="icon-before"
      :class="{ 'no-margin': onlyIcon }"
      :src="iconBefore"
      alt="Icon before text"
    />
    <span class="text">{{ buttonText }}</span>
    <img
      v-if="iconAfter"
      :class="{ 'no-margin': onlyIcon }"
      class="icon-after"
      :src="iconAfter"
      alt="Icon after text"
    />
  </button>
</template>

<script lang="ts" src="./script.ts"></script>
<style src="./styles.scss" lang="scss" scoped></style>
