import { defineComponent } from 'vue'
import KanbanColumn from '@/components/kanbanColumn/index.vue'
import TabsBar from '@/components/tabsBar/index.vue'
import SearchAndViews from '@/components/searchAndViews/index.vue'
import ActionsBar from '@/components/actionsBar/index.vue'
import OrganizationTable from '@/components/Organizations/table/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import AllContactsTable from '@/components/contacts/AllContacts/Table/index.vue'
import AddContactModal from '@/components/contacts/AddContactModal/index.vue'
import AddOrganizationModal from '@/components/Organizations/AddOrganizationModal/index.vue'
import ContactCard from '@/components/contacts/AllContacts/Card/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import {
  addIndividualContact,
  addOrganizationContact,
  getContacts,
} from '@/services/contacts'
import { useTabs } from '@/composables/useTabs'
import { ContactType } from '@/Interfaces/Services'
import { getAllSections } from '@/services/sections'

import { SectionsPresenter } from '@/presenters/SectionsPresenter'
import { LocationQueryValue } from 'vue-router'
import { TableColumnInterface } from '@/Interfaces/Constants'

import { peopleTemplateHeaders } from '@/constants/Tables/peopleTemplate'
import { organizationsTemplateHeaders } from '@/constants/Tables/organizationsTemplate'

import SectionLeads from '@/views/Contacts/Sections/Leads/index.vue'

const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url,
).href
const UploadAvatarIcon = new URL(
  '@/assets/icons/upload-avatar-icon.svg',
  import.meta.url,
).href
const UploadLogoIcon = new URL(
  '@/assets/icons/upload-logo-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'ContactsPage',

  data() {
    const tabs = [
      { id: 0, name: 'Dashboard' },
      { id: 33, name: 'People' },
      { id: 2, name: 'Organizations' },
      { id: 4, name: 'Leads' },
      { id: 3, name: 'Clients' },
      { id: 5, name: 'Current Matters' },
      { id: 6, name: 'Retainers' },
      { id: 7, name: 'Suppliers' },
      { id: 8, name: 'KYC' },
    ]

    const sections = [] as SectionsPresenter[]

    const { activeTabId, activeTab } = useTabs(sections)

    return {
      addContactLoading: false,
      tabs,
      activeTabId,
      activeTab,
      headers: [] as TableColumnInterface[],
      activeView: 'list',
      selectedRows: [],
      isSelect: false,
      isImportContacts: false,
      uploadFilesList: [],
      isAddTabModal: false,
      isAddOrganizationForm: false,
      isAddContact: false,
      isAddContactForm: false,
      files: [],
      kyc_documents: [],
      contactTypeOptions: ['Person', 'Organization'],
      sexOptions: ['male', 'female'],
      UploadAvatarIcon,
      UploadLogoIcon,
      tableLoading: false,

      contactSearchKeyword: '',
      contactsItemPerPage: 10,
      contactSortField: '' as
        | 'individual_first_name'
        | 'individual_last_name'
        | 'organization_name'
        | undefined,
      contactSortDirection: '' as 'asc' | 'desc' | undefined,
      contactPage: 1,
      contactTotalItems: 0,

      organizationSearchKeyword: '',
      organizationsItemPerPage: 10,
      organizationsSortField: '' as
        | 'individual_first_name'
        | 'individual_last_name'
        | 'organization_name'
        | undefined,
      organizationsSortDirection: '' as 'asc' | 'desc' | undefined,
      organizationPage: 1,
      organizationTotalItems: 0,

      clientSearchKeyword: '',
      clientsItemPerPage: 10,
      clientsSortField: '' as
        | 'individual_first_name'
        | 'individual_last_name'
        | 'organization_name'
        | undefined,
      clientsSortDirection: '' as 'asc' | 'desc' | undefined,
      clientPage: 1,
      clientTotalItems: 0,

      contactsActiveMatterSearchKeyword: '',
      contactsActiveMattersItemPerPage: 10,
      contactsActiveMattersSortField: '' as
        | 'individual_first_name'
        | 'individual_last_name'
        | 'organization_name'
        | undefined,
      contactsActiveMattersSortDirection: '' as 'asc' | 'desc' | undefined,
      contactsActiveMatterPage: 1,
      contactsActiveMatterTotalItems: 0,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null, // Holds the debounce timeout ID

      isSectionsLoading: false,
      sections,

      peopleTemplateHeaders,
      organizationsTemplateHeaders,
      MoreVertIcon,

      isShowUpdateLayoutModal: false,
    }
  },
  watch: {
    '$route.query.tab': async function (newTab) {
      const theFoundTab = this.sections.find((tab) => tab.name === newTab)

      if (theFoundTab) {
        this.activeTabId = theFoundTab.id as number
        this.activeTab = theFoundTab.name
        this.headers = theFoundTab.table_columns
        this.checkTabAndLoadData()
      }
    },
  },

  mounted() {
    this.checkTabAndLoadData()
    this.handleGetAllSections()
  },
  methods: {
    changeView(view: string) {
      this.activeView = view
    },

    getSelectedRows(rows: any) {
      this.selectedRows = rows
    },
    handleToggleImportContacts() {
      this.isImportContacts = true
    },
    handleAddTab(payload: string) {
      console.log(payload)
    },

    async handleTabChange(section: SectionsPresenter) {
      // Update active section data
      this.activeTabId = section.id as number
      this.activeTab = section.name
      this.headers = section.table_columns

      // Update the query parameter in the route
      this.$router.push({ query: { tab: section.name } })
    },

    handleToggleUpdateLayoutModal() {
      this.isShowUpdateLayoutModal = !this.isShowUpdateLayoutModal
    },

    ////////////////////////////////////////////////////////////////////

    searchContactData(search: string) {
      this.contactSearchKeyword = search

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts()
      }, 500)
    },

    searchOrganizationData(search: string) {
      this.organizationSearchKeyword = search

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetOrganizations()
      }, 500)
    },

    searchClientData(search: string) {
      this.clientSearchKeyword = search

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetClients()
      }, 500)
    },

    searchContactsActiveMatterData(search: string) {
      this.contactsActiveMatterSearchKeyword = search

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetContactsActiveMatters()
      }, 500)
    },

    ////////////////////////////////////////////////////////////////////

    toggleAddContactForm() {
      this.isAddContact = false
      this.isAddContactForm = true
    },
    cancelForm() {
      this.isAddContactForm = false
      this.isAddOrganizationForm = false
    },

    async handleGetContacts() {
      this.tableLoading = true

      const response = await getContacts({
        contact_type: ContactType.IndividualContact,
        per_page: this.contactsItemPerPage,
        page: this.contactPage,
        sort_by_field: this.contactSortField,
        sort_by_direction: this.contactSortDirection,
        keyword: this.contactSearchKeyword,
      })

      if (response.success) {
        this.$stores.contacts.allContacts = response.data?.list

        this.contactTotalItems =
          response.data.total_pages * this.contactsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.tableLoading = false
    },

    async handleGetOrganizations() {
      this.tableLoading = true
      const response = await getContacts({
        contact_type: ContactType.OrganizationContact,
        per_page: this.organizationsItemPerPage,
        page: this.organizationPage,
        sort_by_field: this.organizationsSortField,
        sort_by_direction: this.organizationsSortDirection,
        keyword: this.organizationSearchKeyword,
      })

      if (response.success) {
        this.$stores.contacts.allOrganizations = response.data?.list

        this.organizationTotalItems =
          response.data.total_pages * this.organizationsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.tableLoading = false
    },

    async handleGetClients() {
      this.tableLoading = true
      const response = await getContacts({
        contact_type: ContactType.IndividualContact,
        per_page: this.clientsItemPerPage,
        page: this.clientPage,
        sort_by_field: this.clientsSortField,
        sort_by_direction: this.clientsSortDirection,
        keyword: this.clientSearchKeyword,
        client: true,
      })

      if (response.success) {
        this.$stores.contacts.clients = response.data?.list
        this.clientTotalItems =
          response.data.total_pages * this.clientsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.tableLoading = false
    },

    async handleGetContactsActiveMatters() {
      this.tableLoading = true
      const response = await getContacts({
        contact_type: ContactType.IndividualContact,
        per_page: this.contactsActiveMattersItemPerPage,
        page: this.contactsActiveMatterPage,
        sort_by_field: this.contactsActiveMattersSortField,
        sort_by_direction: this.contactsActiveMattersSortDirection,
        keyword: this.contactsActiveMatterSearchKeyword,
        has_active_legal_matters: true,
      })

      if (response.success) {
        this.$stores.contacts.contactsActiveMatters = response.data?.list
        this.contactsActiveMatterTotalItems =
          response.data.total_pages * this.contactsActiveMattersItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.tableLoading = false
    },

    ////////////////////////////////////////////////////////////////////////////

    async handleCreateContact(form: any) {
      this.addContactLoading = true
      const response = await addIndividualContact(form)
      if (response.success) {
        this.handleGetContacts()
        this.$vaToast.init({
          message: 'Contact created successfully!',
          color: 'success',
        })
        this.cancelForm()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.addContactLoading = false
    },

    async handleCreateOrganization(form: any) {
      this.addContactLoading = true
      const response = await addOrganizationContact(form)
      if (response.success) {
        this.$vaToast.init({
          message: 'Organization created successfully!',
          color: 'success',
        })
        this.handleGetOrganizations()
        this.cancelForm()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.addContactLoading = false
    },

    ////////////////////////////////////////////////////////////////////////////

    async handlePaginationContacts({ page, itemsPerPage, sortBy }: any) {
      this.contactsItemPerPage = itemsPerPage
      this.contactPage = page
      if (sortBy.length > 0) {
        this.contactSortField = sortBy[0].key.split('.')[1]
        this.contactSortDirection = sortBy[0].order
      } else {
        this.contactSortField = undefined
        this.contactSortDirection = undefined
      }

      await this.handleGetContacts()
    },

    async handlePaginationOrganizations({ page, itemsPerPage, sortBy }: any) {
      this.organizationsItemPerPage = itemsPerPage
      this.organizationPage = page

      if (sortBy?.length > 0) {
        this.organizationsSortField = sortBy[0].key.split('.')[1]
        this.organizationsSortDirection = sortBy[0].order
      } else {
        this.organizationsSortField = undefined
        this.organizationsSortDirection = undefined
      }
      await this.handleGetOrganizations()
    },

    async handlePaginationClients({ page, itemsPerPage, sortBy }: any) {
      this.clientsItemPerPage = itemsPerPage
      this.clientPage = page
      if (sortBy.length > 0) {
        this.clientsSortField = sortBy[0].key.split('.')[1]
        this.clientsSortDirection = sortBy[0].order
      } else {
        this.clientsSortField = undefined
        this.clientsSortDirection = undefined
      }

      await this.handleGetClients()
    },

    async handlePaginationContactsActiveMatters({
      page,
      itemsPerPage,
      sortBy,
    }: any) {
      this.contactsActiveMattersItemPerPage = itemsPerPage
      this.contactsActiveMatterPage = page
      if (sortBy.length > 0) {
        this.contactsActiveMattersSortField = sortBy[0].key.split('.')[1]
        this.contactsActiveMattersSortDirection = sortBy[0].order
      } else {
        this.contactsActiveMattersSortField = undefined
        this.contactsActiveMattersSortDirection = undefined
      }

      await this.handleGetContactsActiveMatters()
    },

    ////////////////////////////////////////////////////////////////////////////

    async handleGetAllSections() {
      this.isSectionsLoading = true
      const response = await getAllSections('contacts')
      if (response.success) {
        this.sections = response.data?.list

        this.activeTabId = this.sections[1]?.id
        this.activeTab = this.sections[1]?.name
        this.headers = this.sections[1]?.table_columns

        // it should be in this function cuz its on mount
        const queryTab = this.$route.query.tab
        const foundTab = queryTab
          ? this.sections.find(
              (tab: { name: string | LocationQueryValue[] }) =>
                tab.name === queryTab,
            )
          : null

        if (foundTab) {
          this.activeTabId = foundTab.id
          this.activeTab = foundTab.name
          this.headers = foundTab.table_columns
        } else {
          this.activeTabId = this.sections[1]?.id as number
          this.activeTab = this.sections[1]?.name
          this.headers = this.sections[1]?.table_columns
        }
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isSectionsLoading = false
    },

    ////////////////////////////////////////////////////////////////////////////
    checkTabAndLoadData() {
      const tab = this.$route.query.tab

      if (tab === 'People' || !tab) {
        this.handleGetContacts()
      } else if (tab === 'Organizations') {
        this.handleGetOrganizations()
      } else if (tab === 'Clients') {
        this.handleGetClients()
      } else if (tab === 'Current Matters') {
        this.handleGetContactsActiveMatters()
      }
    },

    ////////////////////////////////////////////////////////////////////////////
    getHeaders(tableType?: string) {
      if (this.headers && this.headers.length) {
        return this.headers
      }
      switch (tableType) {
        case 'organization':
          return this.organizationsTemplateHeaders
        default:
          return this.peopleTemplateHeaders
      }
    },
  },

  components: {
    KanbanColumn,
    TabsBar,
    SearchAndViews,
    ActionsBar,
    ComingSoon,
    SharedHeader,
    AllContactsTable,
    ContactCard,
    OrganizationTable,
    AddContactModal,
    AddOrganizationModal,

    SectionLeads,
  },
})
