import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a8c5e572"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scroll-container" }
const _hoisted_2 = {
  key: 1,
  class: "container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabsBar = _resolveComponent("TabsBar")!
  const _component_VaDivider = _resolveComponent("VaDivider")!
  const _component_MilestoneGlobal = _resolveComponent("MilestoneGlobal")!
  const _component_ComingSoon = _resolveComponent("ComingSoon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TabsBar, {
      tabs: _ctx.tabs,
      "active-tab-id": _ctx.activeTabId,
      onChangeTab: _ctx.changeTab
    }, null, 8, ["tabs", "active-tab-id", "onChangeTab"]),
    _createVNode(_component_VaDivider),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.activeTab === 'GLOBAL MILESTONE')
        ? (_openBlock(), _createBlock(_component_MilestoneGlobal, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_ComingSoon)
          ]))
    ])
  ], 64))
}