import { StorageFoldersPresenter } from '@/presenters/StorageFoldersPresenter'
import { apiRequest } from './apiUtils'
import { StorageFoldersFormInterface } from '@/presenters/interface'

export const getAllStorageFolders = async () => {
  const response = await apiRequest('get', '/storage_folders')

  if (!response.success) {
    return response
  }

  const list = response.data?.list
  if (!Array.isArray(list)) {
    return response
  }

  return {
    ...response,
    data: {
      ...response.data,
      list: list.map(
        (item: StorageFoldersPresenter) => new StorageFoldersPresenter(item),
      ),
    },
  }
}

export const getStorageFolderById = async (id: number) => {
  const response = await apiRequest('get', `/storage_folders/${id}`)
  if (response.success) {
    return {
      ...response,
      data: new StorageFoldersPresenter(response.data),
    }
  } else {
    return response
  }
}

export const addStorageFolder = (body: StorageFoldersFormInterface) => {
  return apiRequest('post', '/storage_folders', body)
}

export const updateStorageFolder = (
  id: number | undefined,
  body: StorageFoldersFormInterface,
) => {
  return apiRequest('put', `/storage_folders/${id}`, body)
}

export const deleteStorageFolder = (id: number) => {
  return apiRequest('delete', `/storage_folders/${id}`)
}
