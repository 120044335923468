<!-- <template>
  <div class="relative h-52">
    <VaSidebar
      v-model="$stores.ui.showSideDrawer"
      class="absolute right-0 left-auto"
      animated="right"
    >
      <VaSidebarItem
        v-for="item in items"
        :key="item.title"
        :active="item.active"
      >
        <VaSidebarItemContent>
          <VaIcon :name="item.icon" />
          <VaSidebarItemTitle>
            {{ item.title }}
          </VaSidebarItemTitle>
        </VaSidebarItemContent>
      </VaSidebarItem>
    </VaSidebar>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" /> -->

<template>
  <v-navigation-drawer
    v-model="$stores.ui.showSideDrawer"
    location="right"
    temporary
    :persistent="false"
    style="width: 300px"
  />
</template>

<script lang="ts">
export default {
  data() {
    return {}
  },
}
</script>
