import { defineComponent, ref, computed, watch } from 'vue'

const StartDateImage = new URL(
  '@/assets/icons/start-date-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'DatePickerModal',
  props: {
    date: {
      type: String,
      required: true,
    },
    shape: {
      type: String,
      default: 'button',
      validator: (value: string) => {
        return ['button', 'icon', 'button-icon'].includes(value)
      },
    },
  },
  setup(props, { emit }) {
    const showDatePicker = ref<boolean>(false)
    const isDateInitialized = ref<boolean>(false)
    const isDateSaved = ref<boolean>(false)

    // console.log({ date: props.date })

    // Parse date function
    const parseDate = (dateString: string): Date | null => {
      if (dateString) {
        const parsedDate = new Date(dateString)
        return isNaN(parsedDate.getTime()) ? null : parsedDate
      }
      return null
    }

    // Initialize selectedDate with parsed date from props
    const selectedDate = ref<any>(parseDate(props.date) ?? null)
    const hour = ref<number>(selectedDate.value?.getHours() ?? 0)
    const minute = ref<number>(selectedDate.value?.getMinutes() ?? 0)

    // Watch for changes in props.date
    watch(
      () => props.date,
      (newDate: string) => {
        const parsedDate = parseDate(newDate)
        if (parsedDate) {
          selectedDate.value = parsedDate
          hour.value = parsedDate.getHours()
          minute.value = parsedDate.getMinutes()
          isDateInitialized.value = true
        }
      },
      { immediate: true },
    )

    // Computed property for formatted date
    const formattedSelectedDate = computed(() => {
      const date = selectedDate.value
      if (date) {
        const options: Intl.DateTimeFormatOptions = {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        }
        return new Intl.DateTimeFormat('en-US', options).format(new Date(date))
      }
      return null
    })

    const resetDate = (): void => {
      selectedDate.value = null
      hour.value = 0
      minute.value = 0
      isDateSaved.value = false
      isDateInitialized.value = false
      emit('update:date', '')
    }

    const saveDate = (): void => {
      const date = selectedDate.value
      if (date) {
        date.setHours(hour.value)
        date.setMinutes(minute.value)
      }
      console.log({ date, toISOString: date.toISOString() })

      const dateUTC = date ? date.toISOString() : null
      emit('update:date', { date: dateUTC })
      showDatePicker.value = false
      isDateSaved.value = true
    }

    return {
      showDatePicker,
      selectedDate,
      hour,
      minute,
      formattedSelectedDate,
      resetDate,
      saveDate,
      isDateSaved,
      isDateInitialized,
      StartDateImage,
    }
  },
})
