<template>
  <div id="print" ref="componentRef">
    <div class="container-top">
      <div class="header">
        <div class="company-info">
          <strong>{{ company.company?.name }}</strong
          ><br />
          <!-- {{ companyInfo?.address }}<br /> -->
          TRN:
          <!-- {{ companyInfo?.trn }} -->
          <br />
          Telephone:
          <!-- {{ companyInfo?.phone }} -->
        </div>
        <!-- <img :src="companyInfo?.logoUrl" alt="Company Logo" /> -->
        <img :src="tlaLogoImage" alt="Logo" width="100rem" height="100rem" />
      </div>

      <div class="invoice-title">INVOICE</div>

      <div class="client-info">
        <div class="invoice-details">
          <strong> Client :</strong> {{ form?.individual_contact?.fullName }}
          <br />
          <strong>Matter :</strong>
          <!-- {{ invoiceDetails?.matterNumber }}<br /> -->
          <!-- {{ invoiceDetails?.description }} -->
        </div>

        <div class="client-info-invoice">
          <strong>Invoice Date:</strong>
          {{ form?.invoice_date }}<br />
          <strong>Invoice No.:</strong> {{ form?.invoice_number }}
        </div>
      </div>

      <table class="invoice-items">
        <thead>
          <tr>
            <!-- <th>Date</th> -->
            <th>Description</th>
            <th>Quantity</th>
            <th>Type</th>
            <th>Price</th>
            <th>Discount</th>
            <th>Tax</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in form.invoice_items" :key="index">
            <!-- <td>{{ item?.created_at.split('T')[0] }}</td> -->
            <td>{{ item?.description }}</td>
            <td>{{ item?.quantity }}</td>
            <td>
              {{ item?.billable?.class_name == 'CalendarItem' ? 'HOURS' : '' }}
            </td>
            <td>{{ item?.discount_type_amount }}</td>
            <td>{{ item?.tax_percentage }}</td>
            <td>{{ item?.unit_price }}</td>
            <td>
              {{
                totalAmount(
                  item.quantity,
                  item.unit_price,
                  item.tax_percentage,
                  item.discount_type,
                  item.discount_type_amount,
                )
              }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="invoice-summary-container">
        <table class="invoice-summary-table">
          <tbody>
            <tr>
              <td><strong>Subtotal:</strong></td>
              <td>{{ subtotal }} {{ form.currency?.code }}</td>
            </tr>
            <tr>
              <td><strong>Discount:</strong></td>
              <td>
                {{ form.discount_type == 'percentage' ? '%' : '' }}
                {{ form.discount_type_amount }}
              </td>
            </tr>
            <tr class="no-underline">
              <td><strong>Taxes:</strong></td>
              <td />
            </tr>
            <tr
              v-for="(tax, index) in form.invoice_tax_items"
              :key="index"
              class="no-underline"
            >
              <td>&#9675; {{ tax.name }} :</td>
              <td>
                {{ tax.tax_type == 'percentage' ? '%' : '' }}
                {{ tax.tax_type_amount }}
              </td>
            </tr>
            <tr class="no-underline">
              <td />
            </tr>
            <tr>
              <td />
            </tr>
            <tr class="no-underline final-amount">
              <td><strong>TOTAL AMOUNT:</strong></td>
              <td>
                <strong>{{ total }} {{ form.currency?.code }}</strong>
              </td>
            </tr>
            <tr
              v-if="convertToSecondaryCurrency != null"
              class="no-underline final-amount"
            >
              <td />
              <td>
                <strong
                  >{{ convertToSecondaryCurrency }}
                  {{ form.secondary_currency?.code }}</strong
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="container-bottom">
      <div class="logo-section">
        <img :src="logoImage" width="30rem" alt="LOGO" /> Billed through MyHAQQ
      </div>
    </div>
  </div>
  <VaButton preset="primary" icon="print" @click="handlePrint">
    Preview Invoice
  </VaButton>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
