import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionsBar = _resolveComponent("ActionsBar")!
  const _component_AddLinkModal = _resolveComponent("AddLinkModal")!
  const _component_MatterTable = _resolveComponent("MatterTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ActionsBar, {
      "second-add-button": "Assign to Matter",
      onSecondButtonAction: _ctx.toggleAddMatter
    }, null, 8, ["onSecondButtonAction"]),
    _createVNode(_component_AddLinkModal, {
      "is-add-item": _ctx.isAddMatter,
      "items-list-loading": _ctx.mattersLoading,
      "items-list": _ctx.mattersModal,
      "add-link-loading": _ctx.linkMatterLoading,
      onHandleToggleAddLink: _ctx.toggleAddMatter,
      onHandleSearch: _ctx.searchMatterData,
      onHandleLinkItem: _ctx.handleLinkMatter
    }, null, 8, ["is-add-item", "items-list-loading", "items-list", "add-link-loading", "onHandleToggleAddLink", "onHandleSearch", "onHandleLinkItem"]),
    _createVNode(_component_MatterTable, {
      loading: _ctx.matterTableLoading,
      "items-per-page": _ctx.mattersItemPerPage,
      "total-items": _ctx.matterTotalItems,
      matters: _ctx.matters,
      "un-link": true,
      onSelectedRows: _ctx.getSelectedMatterRows,
      onLoadMore: _ctx.handlePaginationMatters,
      onUnLinkMatter: _ctx.handleUnlinkMatter
    }, null, 8, ["loading", "items-per-page", "total-items", "matters", "onSelectedRows", "onLoadMore", "onUnLinkMatter"])
  ], 64))
}