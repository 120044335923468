import {
  addInvoiceFormInterface,
  invoiceItemAttributesInterface,
} from '@/Interfaces/Services'
import {
  deepCopy,
  getOptionCurrency,
  getOptionFullName,
  getOptionName,
  getOptionTruncateName50,
  validateInput,
  validateInputEmail,
  validateSelect,
} from '@/lib/utils'
import { getNextInvoiceNumber } from '@/services/invoices'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'
import TimeLinkModal from '@/components/TimeRecordModal/TimeLinkModal/index.vue'
import LinkToCalendarEvent from '@/components/financial/LinkToCalendarEvent/index.vue'
import LinkExpense from '@/components/financial/LinkExpenses/index.vue'
import { getAllCalendarItems } from '@/services/calendarItem'
import { CalendarItemsPresenter } from '@/presenters/calendarItemsPresenter'
import { ExpensesPresenter } from '@/presenters/ExpensesPresenter'
import { getAllExpenses } from '@/services/expenses'
import { getAllCurrencies } from '@/services/currencies'
import { CurrenciesPresenter } from '@/presenters/CurrenciesPresenter'
import { IndividualContactPresenter } from '@/presenters/contactPresenter'

interface IconDetails {
  icon: string
  tooltip: string
}
type ClassName = 'CalendarItem' | 'Expense' | 'Other'

export default defineComponent({
  name: 'AddInvoiceForm',

  components: {
    TimeLinkModal,
    LinkToCalendarEvent,
    LinkExpense,
  },
  props: {
    addInvoiceLoading: {
      type: Boolean,
      default: false,
    },
    isAddInvoice: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { validate } = useForm('form')

    const todayDate = new Date().toISOString()

    const currencies = [] as CurrenciesPresenter[]

    const paymentDueOptions = [
      {
        label: 'Due Today',
        value: new Date().toLocaleDateString(),
      },
      {
        label: 'Net 15',
        value: new Date(
          new Date().setDate(new Date().getDate() + 15),
        ).toLocaleDateString(),
      },
      {
        label: 'Net 30',
        value: new Date(
          new Date().setDate(new Date().getDate() + 30),
        ).toLocaleDateString(),
      },
      {
        label: 'Net 60',
        value: new Date(
          new Date().setDate(new Date().getDate() + 60),
        ).toLocaleDateString(),
      },
    ]

    const form = {
      status: 'pending',
      invoice_date: todayDate,
      payment_due_date: paymentDueOptions[0].value,
      discount_type: 'percentage',
      discount_type_amount: 0,

      invoice_tax_items_attributes: [
        {
          name: 'Income Tax',
          tax_type: 'percentage',
          tax_type_amount: 0,
        },
      ],
    } as addInvoiceFormInterface

    const iconMappings = {
      CalendarItem: { icon: 'calendar_month', tooltip: 'Calendar Item' },
      Expense: { icon: 'paid', tooltip: 'Expense' },
    } as Record<ClassName, IconDetails>

    return {
      validateInput,
      validateInputEmail,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionTruncateName50,
      getOptionCurrency,

      isAddInvoiceBool: this.isAddInvoice,

      validate,
      deepCopy,

      contactsLoading: false,
      invoiceNumberLoading: false,

      invoice_number: '',
      todayDate,

      form,
      invoiceItemsAttributes: [] as invoiceItemAttributesInterface[],

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,

      isItemAssignEvent: false,
      isCalendarItemsLoading: false,
      calendarItems: [] as CalendarItemsPresenter[],

      isItemAssignExpense: false,
      isExpensesLoading: false,
      expenses: [] as ExpensesPresenter[],

      taxTypeOptions: [
        { label: 'Percent', value: 'percentage' },
        { label: 'Amount', value: 'amount' },
      ],

      currencyLoading: false,
      currencies,

      isShowDueDate: false,
      datePickerValue: new Date(),
      paymentDueOptions,

      iconMappings,
    }
  },
  computed: {
    convertToSecondaryCurrency(): string | number | null {
      if (!this.form?.secondary_currency_conversion_rate_against_us_dollars) {
        return null
      }
      if (
        this.form?.currency_conversion_rate_against_us_dollars ===
        this.form?.secondary_currency_conversion_rate_against_us_dollars
      ) {
        return null
      }
      const amountInUSD =
        this.computedGrandTotal() /
        Number(this.form?.currency_conversion_rate_against_us_dollars)
      const amountInSecondaryCurrency =
        amountInUSD *
        Number(this.form?.secondary_currency_conversion_rate_against_us_dollars)
      return amountInSecondaryCurrency.toFixed(4)
    },
  },

  watch: {
    isAddInvoice: {
      handler(data) {
        this.isAddInvoiceBool = data
        if (data) {
          this.handleGetContacts()
          this.handleGetNextInvoiceNumber()
          this.handleGetEvents()
          this.handleGetExpenses()
          this.handleGetCompanyCurrencies()
        }
      },
    },

    'form.currency': {
      handler(data) {
        this.form.currency_conversion_rate_against_us_dollars =
          data?.conversion_rate_against_us_dollars
      },
    },
    'form.secondary_currency': {
      handler(data) {
        this.form.secondary_currency_conversion_rate_against_us_dollars =
          data?.conversion_rate_against_us_dollars
      },
    },
  },
  methods: {
    async handleGetNextInvoiceNumber() {
      this.invoiceNumberLoading = true
      const response = await getNextInvoiceNumber()
      if (response.success) {
        this.invoice_number = response.data.next_invoice_number
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.invoiceNumberLoading = false
    },

    //////////////////////////////////////////////////////////////////////////////////////

    async handleGetContacts(search?: string) {
      this.$stores.contacts.handleGetContacts({
        keyword: search,
      })
    },

    handleSearchContacts(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts(search)
      }, 500)
    },

    handleSelectContact(contact: IndividualContactPresenter) {
      this.form.customer_email =
        contact?.email_addresses && contact?.email_addresses[0]?.email_address
    },

    //////////////////////////////////////////////////////////////////////////////////////

    handleToggleAddModal() {
      this.$emit('handleToggleAddModal')
      this.form = {
        status: 'pending',
        invoice_date: this.todayDate,
        payment_due_date: this.todayDate,
        discount_type: 'percentage',
        discount_type_amount: 0,
        invoice_items_attributes: [
          {
            quantity: 1,
            tax_percentage: 0,
            discount_type: 'percentage',
            discount_type_amount: 0,
            unit_price: 0,
          },
        ],
        invoice_tax_items_attributes: [
          {
            name: 'Income Tax',
            tax_type: 'percentage',
            tax_type_amount: 0,
          },
        ],
      } as addInvoiceFormInterface
    },

    async handleCreateInvoice() {
      const { individual_contact_id, currency, secondary_currency, ...form } =
        this.form

      this.$emit('handleCreateInvoice', {
        ...form,
        invoice_items_attributes: [...this.invoiceItemsAttributes],
        individual_contact_id:
          typeof individual_contact_id === 'object' &&
          individual_contact_id?.id,
        currency_id: currency?.id,
        secondary_currency_id: secondary_currency?.id,
      })
    },

    ///////////////////////////////////////////////////////////////////////////////////

    addInvoiceItem() {
      this.invoiceItemsAttributes.push({
        quantity: 1,
        tax_percentage: 0,
        discount_type: 'percentage',
        discount_type_amount: 0,
        unit_price: 0,
      } as invoiceItemAttributesInterface)
    },

    removeInvoiceItem(index: number) {
      if (this.invoiceItemsAttributes.length > 0) {
        this.invoiceItemsAttributes.splice(index, 1)
      }
    },

    ///////////////////////////////////////////////////////////////////////////////////

    totalAmount(
      quantity: string | number,
      price: string | number,
      tax_percentage: string | number,
      discount_type: string,
      discount_type_amount: string | number,
    ) {
      // Calculate the subtotal (price * quantity)
      const subtotal = parseFloat((+quantity * +price)?.toFixed(4))

      // Calculate the discount based on the discount type
      let discountedSubtotal = subtotal
      if (discount_type === 'percentage') {
        const discountAmount = parseFloat(
          ((subtotal * +discount_type_amount) / 100)?.toFixed(4),
        )
        discountedSubtotal -= discountAmount
      } else if (discount_type === 'amount') {
        const discountAmount = parseFloat((+discount_type_amount)?.toFixed(4))
        discountedSubtotal -= discountAmount
      }

      // Ensure the discounted subtotal is not negative
      discountedSubtotal = Math.max(0, discountedSubtotal)

      // Calculate the tax on the discounted subtotal
      const taxAmount = parseFloat(
        (discountedSubtotal * (+tax_percentage / 100))?.toFixed(4),
      )

      // Add tax to the discounted subtotal to get the total
      const total = discountedSubtotal + taxAmount

      // Round to 4 decimal places and return the total
      return parseFloat(total?.toFixed(4))
    },

    computedSubTotal() {
      const grandTotal = this.invoiceItemsAttributes.reduce((total, item) => {
        const itemTotal = this.totalAmount(
          item.quantity,
          item.unit_price,
          item.tax_percentage,
          item.discount_type,
          item.discount_type_amount,
        )
        return total + itemTotal
      }, 0)

      return parseFloat(grandTotal.toFixed(4))
    },

    computedGrandTotal() {
      const subTotal = this.computedSubTotal()
      let discountedTotal: number

      if (this.form.discount_type === 'percentage') {
        discountedTotal =
          subTotal -
          parseFloat(
            ((subTotal * this.form.discount_type_amount) / 100).toFixed(4),
          )
      } else {
        discountedTotal =
          subTotal - parseFloat((+this.form.discount_type_amount).toFixed(4))
      }

      discountedTotal = Math.max(0, discountedTotal)

      const totalTax = this.form.invoice_tax_items_attributes.reduce(
        (total, taxItem) => {
          let taxAmount = 0
          if (taxItem.tax_type === 'percentage') {
            taxAmount = (discountedTotal * +taxItem.tax_type_amount) / 100
          } else if (taxItem.tax_type === 'amount') {
            taxAmount = +taxItem.tax_type_amount
          }
          return total + taxAmount
        },
        0,
      )

      const grandTotal = discountedTotal + totalTax
      return parseFloat(grandTotal.toFixed(4))
    },

    toggleItemSwitch(index: number): void {
      this.form.invoice_items_attributes[index].discount_type =
        this.form.invoice_items_attributes[index].discount_type === 'amount'
          ? 'percentage'
          : 'amount'
    },

    toggleInvoiceSwitch() {
      this.form.discount_type =
        this.form.discount_type === 'amount' ? 'percentage' : 'amount'
    },

    /////////////////////////////////////// Calendar Events ////////////////////////////////////////////////

    async handleGetEvents() {
      this.isCalendarItemsLoading = true

      const response = await getAllCalendarItems({
        per_page: 50,
        for_whole_company: true,
        sort_by_direction: 'desc',
        sort_by_field: 'start_time',
      })

      if (response.success) {
        this.calendarItems = response.data?.list.filter(
          (e: CalendarItemsPresenter) => e.billable == true,
        )
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isCalendarItemsLoading = false
    },

    addInvoiceCalendarEvent(data: CalendarItemsPresenter) {
      const newInvoiceItem = {
        description: data.description,
        quantity: parseFloat(
          (data.total_time_spent_seconds / 3600)?.toFixed(4),
        ),
        tax_percentage: 0,
        discount_type: 'percentage',
        discount_type_amount: 0,
        unit_price: Number(data.billable_hourly_rate_charging_amount),

        billable_type: 'CalendarItem',
        billable_id: data.id,
        billable: { class_name: data.class_name },
      } as invoiceItemAttributesInterface

      this.invoiceItemsAttributes = [
        ...this.invoiceItemsAttributes,
        newInvoiceItem,
      ]

      this.isItemAssignEvent = false
    },

    /////////////////////////////////////// Expenses ////////////////////////////////////////////////

    async handleGetExpenses() {
      this.isExpensesLoading = true

      const response = await getAllExpenses({
        per_page: 50,
      })
      if (response.success) {
        this.expenses = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isExpensesLoading = false
    },

    addInvoiceExpense(data: ExpensesPresenter) {
      const newInvoiceItem = {
        description: data.description,
        quantity: data.quantity,
        tax_percentage: 0,
        discount_type: 'percentage',
        discount_type_amount: 0,
        unit_price: data.unit_price,

        billable_type: 'Expense',
        billable_id: data.id,
        billable: { class_name: data.class_name },
      } as invoiceItemAttributesInterface

      this.invoiceItemsAttributes = [
        ...this.invoiceItemsAttributes,
        newInvoiceItem,
      ]

      this.isItemAssignExpense = false
    },
    ////////////////////////////// Icon ///////////////////////////////////

    getIconDetails(item?: string): IconDetails | undefined {
      return (
        this.iconMappings[item as ClassName] || {
          icon: 'radio_button_checked',
          tooltip: 'Item',
        }
      )
    },

    ////////////////////////////// Taxes ///////////////////////////////////

    addTaxItem() {
      this.form.invoice_tax_items_attributes.push({
        name: '',
        tax_type: 'percentage',
        tax_type_amount: 0,
      })
    },

    removeTaxItem(index: number) {
      if (this.form.invoice_tax_items_attributes.length > 1) {
        this.form.invoice_tax_items_attributes.splice(index, 1)
      }
    },

    ////////////////////////////// Currencies ///////////////////////////////////

    async handleGetCompanyCurrencies() {
      this.currencyLoading = true

      const response = await getAllCurrencies({})

      if (response.success) {
        this.currencies = response.data.list
        this.form.currency = this.currencies[0]
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.currencyLoading = false
    },

    ////////////////////////////////////////////////////////////////////////////////////

    handleDatePickerChange(date: Date) {
      this.paymentDueOptions.push({
        label: date.toLocaleDateString(),
        value: date.toLocaleDateString(),
      })
      this.form.payment_due_date = date.toLocaleDateString()
      this.isShowDueDate = false
    },
  },
})
