<template>
  <!-- <div class="main"> -->
  <SharedHeader title-width="30rem" title="Home">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left" />
      <div class="sub-SharedHeader-right" />
    </div>
  </SharedHeader>

  <div class="container">
    <ComingSoon />
  </div>
  <div>
    <!-- <VaButton @click="$stores.ui.showSideDrawer = !$stores.ui.showSideDrawer">
        Open Sidebar
      </VaButton>
      <SideDrawer /> -->
  </div>
  <!-- </div> -->
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
