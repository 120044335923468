import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SubTask',
  props: {
    text: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    dateRange: {
      type:
        (Object as () => { start: string | null; end: string | null }) || null,
      required: true,
    },
  },
  setup(props) {
    const formatDateTime = (dateString: string | null): string => {
      if (!dateString) {
        return ''
      } // If the date string is null, return an empty string

      const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
        hour12: false,
      }

      return new Date(dateString).toLocaleString(undefined, options)
    }

    // Check if start or end is null
    const formattedEnd = props.dateRange.end
      ? formatDateTime(props.dateRange.end)
      : null

    // Handle the case where both start and end are null
    const formattedDateRange = formattedEnd ? formattedEnd : 'No range date'

    return {
      formattedDateRange,
    }
  },
})
