import { defineComponent } from 'vue'
import draggable from 'vuedraggable'
import TaskCard from '@/components/Task/Card/index.vue'
import LegalMatterCard from '@/components/LegalMatter/legalMatterCard/index.vue'
import { StagesPresenter } from '@/presenters/StagesPresenter'
import ContactCard from '@/components/contacts/contactCard/index.vue'

export default defineComponent({
  name: 'DragBoard',
  components: {
    draggable,
    TaskCard,
    LegalMatterCard,
    ContactCard,
  },
  props: {
    stages: {
      type: Array as () => StagesPresenter[],
      required: true,
    },
    isCardsLoading: {
      type: Boolean,
      default: false,
    },
    isColumnsLoading: {
      type: Boolean,
      default: true,
    },
    isTasks: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLegalMatters: {
      type: Boolean,
      required: false,
      default: false,
    },
    isContacts: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['ticketMoved', 'handleAddStage'],
  data() {
    return {}
  },
  computed: {
    showNoStages(): boolean {
      return !this.isColumnsLoading && this.stages?.length === 0
    },
    showLoading(): boolean {
      return this.isColumnsLoading
    },
  },

  methods: {
    onDragEnd(evt: any) {
      const droppedOnStageId = evt.to.getAttribute('data-stage-id')
      const draggedTicketId = evt?.item?._underlying_vm_?.item.id
      const draggedTicketName = evt?.item?._underlying_vm_?.item.name
      const newIndex = evt.newIndex

      this.$emit('ticketMoved', {
        id: draggedTicketId,
        name: draggedTicketName,
        section_stage_id: droppedOnStageId,
        section_stage_order: newIndex,
      })
    },
    getTicketCount(stageIndex: number) {
      return this.stages[stageIndex]?.section_stage_items?.length || 0
    },

    handleAddStage() {
      this.$emit('handleAddStage')
    },
  },
})
