import { MatterPresenter } from '@/presenters/matterPresenter'
import { apiRequest } from './apiUtils'
import { TaskPresenter } from '@/presenters/taskPresenter'

// Get all for table
export const getAllTasks = async ({
  per_page,
  page,
  keyword,
}: {
  per_page: number
  page?: number
  keyword?: string
}) => {
  const response = await apiRequest('get', '/tasks', null, {
    per_page,
    page,
    keyword,
  })

  if (!response.success) {
    return response
  }

  const list = response.data?.list
  if (!Array.isArray(list)) {
    return response
  }

  return {
    ...response,
    data: {
      ...response.data,
      list: list.map((item: TaskPresenter) => new TaskPresenter(item)),
    },
  }
}

export const getTaskById = async (id: string) => {
  const response = await apiRequest('get', `/tasks/${id}`)
  if (response.success) {
    return {
      ...response,
      data: new TaskPresenter(response.data),
    }
  } else {
    return response
  }
}

export const addTask = (body: any) => {
  return apiRequest('post', '/tasks', body)
}

export const updateTask = (id: number, body: any) => {
  return apiRequest('put', `/tasks/${id}`, body)
}

export const deleteTask = (id: string) => {
  return apiRequest('delete', `/tasks/${id}`)
}

export const getAllTasksMatters = async ({
  per_page,
  page,
  task_id,
  related_to_type,
}: {
  per_page: number
  page?: number
  task_id: string
  related_to_type: string
}) => {
  const response = await apiRequest(
    'get',
    `/tasks/${task_id}/relationships`,
    null,
    {
      per_page,
      page,
      related_to_type,
    },
  )

  if (!response.success) {
    return response
  }

  const list = response.data?.list
  if (!Array.isArray(list)) {
    return response
  }

  return {
    ...response,
    data: {
      ...response.data,
      list: list.map((item) => {
        const relatedToData = item?.related_to

        if (relatedToData) {
          return new MatterPresenter(relatedToData)
        }
      }),
    },
  }
}

type linkMatterTaskBodyType = {
  related_to_type: string
  related_to_id: number
  task_id: number
}

export const linkMatterTask = (body: linkMatterTaskBodyType[]) => {
  return apiRequest('post', '/tasks/relationships', { items: body })
}

export const UnlinkMatterTask = (task_id: number, matter_id: number) => {
  return apiRequest('delete', `/tasks/${task_id}/relationships/${matter_id}`)
}
