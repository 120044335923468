<template>
  <TabsBar :tabs="tabs" :active-tab-id="activeTabId" @changeTab="changeTab" />
  <VaDivider />

  <div class="scroll-container">
    <InfoSecurity v-if="activeTab === 'INFO & SECURITY'" />

    <div v-else class="container">
      <ComingSoon />
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
