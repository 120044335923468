import { defineComponent } from 'vue'
import 'vue-cal/dist/vuecal.css'
import VueCal from 'vue-cal'
import SharedHeader from '@/components/sharedHeader/index.vue'
import AddEventModal from '@/components/Calendar/AddEventModal/index.vue'
import UpdateEventModal from '@/components/Calendar/UpdateEventModal/index.vue'
import {
  addCalendarItem,
  deleteCalendarItem,
  getAllCalendarItems,
  updateCalendarItem,
} from '@/services/calendarItem'
import { CalendarItemsPresenter } from '@/presenters/calendarItemsPresenter'
import {
  convertToLocalISO,
  getOptionFullName,
  getOptionName,
  getContrastingTextColor,
  formatTimeSeconds,
  updateDatePart,
} from '@/lib/utils'
import { addUpdateCalendarItemInterface } from '@/Interfaces/Services'
import { calendarItemsFormInterface } from '@/Interfaces/layout'
import { GlobalSearchPresenter } from '@/presenters/GlobalSearchPresenter'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'

type TimeRow = {
  [key: string]: string
}

export default defineComponent({
  name: '',

  components: {
    VueCal,
    SharedHeader,
    AddEventModal,
    UpdateEventModal,
  },
  props: {},
  emits: [],
  data() {
    function getWeekStartAndEndUTC(): {
      start_after: string
      end_before: string
    } {
      const today = new Date()
      const dayOfWeek = today.getUTCDay()

      const mondayOffset = dayOfWeek === 0 ? -6 : 1 - dayOfWeek

      // Get the start of the week (Monday)
      const startOfWeek = new Date(today)
      startOfWeek.setUTCDate(today.getUTCDate() + mondayOffset)
      startOfWeek.setUTCHours(0, 0, 0, 0)

      // Get the end of the week (Sunday)
      const endOfWeek = new Date(startOfWeek)
      endOfWeek.setUTCDate(startOfWeek.getUTCDate() + 6)
      endOfWeek.setUTCHours(23, 59, 59, 999)

      // Return the full ISO string in UTC format
      // console.log({ startOfWeek, endOfWeek })

      return {
        start_after: startOfWeek.toISOString(),
        end_before: endOfWeek.toISOString(),
      }
    }

    const typesOption = [
      { name: 'All Types', id: null, color: null },
      ...(this.$stores.generalData.companyConfigs.calendarTypesOption ?? []),
    ]

    const viewOptions = [
      { text: 'Month', value: 'month' },
      { text: 'Week', value: 'week' },
      { text: 'Day', value: 'day' },
    ]

    const userFiltrationOptions = [
      {
        id: this.$stores.auth.user.company_users[0].id,
        fullName: 'Me',
      },
      { id: 107070701, fullName: 'ALL Users' },
      ...(
        (this.$stores.usersData.teamMembers as CompanyUserPresenter[]) || []
      ).filter(
        (member) => member.id !== this.$stores.auth.user.company_users[0].id,
      ),
    ]

    const userFiltration = {
      id: this.$stores.auth.user.company_users[0].id,
      fullName: 'Me',
    } as CompanyUserPresenter

    return {
      getOptionFullName,
      getOptionName,
      getContrastingTextColor,
      formatTimeSeconds,

      timeRows: [] as TimeRow[],
      showTable: true,

      now: new Date(),
      timeCellHeight: 50,
      selectedDate: new Date() as string | Date,

      dateValue: new Date(),

      typesOption,
      activeType: { name: 'All Types', id: null, color: null },

      selectedView: { text: 'Week', value: 'week' },
      viewOptions,

      addCalendarLoading: false,
      isAddCalendarForm: false,

      updateCalendarLoading: false,
      isUpdateCalendarForm: false,

      isCalendarItemsLoading: false,

      itemsPerPage: 100,
      totalPages: 0,
      currentPage: 1,

      calendarItems: [] as CalendarItemsPresenter[],
      selectedEvent: {} as addUpdateCalendarItemInterface,
      newEventStart: '' as any,

      getWeekStartAndEndUTC,
      currentStartEndTime: getWeekStartAndEndUTC(),

      userFiltration,

      userFiltrationOptions,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,

      searchedEvent: '' as any,
      searchedEventOptions: [] as CalendarItemsPresenter[],
      isSearchedCalendarItemsLoading: false,

      isGlobalSearchLoading: false,
      globalSearchKeyword: '',
      globalSearchData: {} as GlobalSearchPresenter,

      isItemTypeSelect: false,
    }
  },

  watch: {
    '$stores.usersData.teamMembers': {
      handler(data) {
        this.userFiltrationOptions = [
          {
            id: this.$stores.auth.user.company_users[0].id,
            fullName: 'Me',
          },
          { id: 107070701, fullName: 'ALL Users' },
          ...data.filter(
            (member: CompanyUserPresenter) =>
              member.id !== this.$stores.auth.user.company_users[0].id,
          ),
        ]
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.handleGetEvents({
      ...this.getWeekStartAndEndUTC(),
    })

    this.handleGetMembers()
    this.handleGetSearchedEvents('')

    this.generateTimeRows()
  },

  methods: {
    handleToggleFilter() {
      this.$stores.ui.showCalendarSideDrawer =
        !this.$stores.ui.showCalendarSideDrawer
    },
    toggleAddCalendarForm() {
      this.isUpdateCalendarForm = false
      this.isAddCalendarForm = !this.isAddCalendarForm
    },
    toggleUpdateCalendarForm() {
      this.isAddCalendarForm = false
      this.isUpdateCalendarForm = !this.isUpdateCalendarForm
    },
    formatTime(time: string) {
      const date = new Date(time)
      const hours = date.getHours()
      const minutes = date.getMinutes()?.toString().padStart(2, '0')
      const period = hours >= 12 ? 'PM' : 'AM'
      const formattedHours = hours % 12 || 12 // Convert 0 to 12 for 12 AM
      return `${formattedHours}:${minutes} ${period}`
    },

    /////////////////////////////////////////////////////////

    onEventClick(event: addUpdateCalendarItemInterface) {
      console.log({ click: event })

      this.selectedEvent = event
      this.toggleUpdateCalendarForm()
    },

    onEventCreate(event: Date) {
      this.newEventStart = {
        start_time: event,
        end_time: new Date(event.getTime() + 30 * 60 * 1000),
      }

      this.toggleAddCalendarForm()
    },

    async onEventDragCreate(event: { start: Date; end: Date }) {
      const res = await this.handleCreateEvent({
        start_time: event.start.toISOString(),
        end_time: event.end.toISOString(),
        item_type_id:
          this.activeType?.id ||
          this.$stores.generalData.companyConfigs.calendarTypesOption?.[0].id,
        color:
          this.activeType?.color ||
          this.$stores.generalData.companyConfigs.calendarTypesOption?.[0]
            .color,
      })
      if (!res) {
        // revert()
      }
    },

    async onEventDragDrop(event: {
      event: CalendarItemsPresenter
      newDate: Date
    }) {
      let res

      const draggedEvent = this.calendarItems.find(
        (eventDragged) => eventDragged.id === event.event.id,
      )

      if (draggedEvent && draggedEvent.time_range) {
        const today = event.newDate

        const newDateOnly = convertToLocalISO(today).split('T')[0]

        draggedEvent.start = newDateOnly // Normalize start date
        draggedEvent.end = newDateOnly // Normalize end date

        res = await this.handleUpdateEvent({
          id: draggedEvent.id,
          start_time: updateDatePart(
            convertToLocalISO(draggedEvent.start_time),
            newDateOnly,
          ),
          end_time: updateDatePart(
            convertToLocalISO(draggedEvent.end_time),
            newDateOnly,
          ),
          total_time_spent_seconds: draggedEvent.total_time_spent_seconds,
        })
      } else {
        res = await this.handleUpdateEvent({
          id: event.event.id,
          start_time: event.event.start?.toString(),
          end_time: event.event.end?.toString(),
        })
      }

      if (!res) {
        // Handle the failure case (e.g., removing the event from vueCal)
        // vueCal.removeEvent(event);
      }
    },

    onEventDelete(event: { id: number }) {
      this.handleDeleteEvent(event.id)
    },

    onViewChange(event: { startDate: string; endDate: string; view: string }) {
      this.currentStartEndTime = {
        start_after: convertToLocalISO(event.startDate),
        end_before: convertToLocalISO(event.endDate),
      }

      this.handleGetEvents({
        start_after: convertToLocalISO(event.startDate),
        end_before: convertToLocalISO(event.endDate),
      })

      this.selectedView = this.viewOptions.find(
        (e) => e.value === event.view,
      ) || { text: '', value: '' }
      this.scrollToCurrentTime()
    },

    scrollToCurrentTime() {
      const calendar = document.querySelector('#vuecal .vuecal__bg') as any

      const hours = this.now.getHours() + this.now.getMinutes() / 60
      calendar.scrollTo({
        top: hours * this.timeCellHeight,
        behavior: 'smooth',
      })
    },

    /////////////////////////////////////////////////////////
    logEvents(eventType: string, event: any) {
      console.log(eventType, event)
    },

    handleOnCalendarReady() {
      this.scrollToCurrentTime()
    },

    //////////////////////////////////////////////////////////

    async handleGetEvents(body: { start_after: string; end_before: string }) {
      this.isCalendarItemsLoading = true

      const response = await getAllCalendarItems({
        skip_limit: true,
        ...(this.userFiltration.id === 107070701
          ? { for_whole_company: true }
          : { company_user_id: this.userFiltration.id }),
        item_type_id: this.activeType?.id || undefined,

        ...body,
      })

      if (response.success) {
        this.calendarItems = response.data?.list
        console.log({ calendarItems: this.calendarItems })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isCalendarItemsLoading = false
    },

    // calendarItemsFormInterface
    async handleCreateEvent(form: calendarItemsFormInterface) {
      this.addCalendarLoading = true

      const response = await addCalendarItem(form)

      if (response.success) {
        this.handleGetEvents({
          ...this.currentStartEndTime,
        })
        this.$vaToast.init({
          message: 'Event Created Successfully',
          color: 'success',
          position: 'bottom-right',
        })
        this.isAddCalendarForm = false
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
        this.addCalendarLoading = false
        return false
      }
      this.addCalendarLoading = false
      return true
    },

    async handleUpdateEvent(form: addUpdateCalendarItemInterface) {
      this.updateCalendarLoading = true
      const response = await updateCalendarItem(form.id, form)

      if (response.success) {
        this.handleGetEvents({
          ...this.currentStartEndTime,
        })
        this.isUpdateCalendarForm = false
        this.$vaToast.init({
          message: 'Event Updated Successfully',
          color: 'success',
          position: 'bottom-right',
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
        this.updateCalendarLoading = false
        return false
      }

      this.updateCalendarLoading = false
      return true
    },

    async handleDeleteEvent(id: number) {
      this.updateCalendarLoading = true
      const response = await deleteCalendarItem(id)

      if (response.success) {
        this.handleGetEvents({
          ...this.currentStartEndTime,
        })
        this.$vaToast.init({
          message: 'Event Removed Successfully',
          color: 'success',
          position: 'bottom-right',
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.updateCalendarLoading = false
    },

    /////////////////////////////////////////////////////////////////////////////////////////

    async handleGetMembers(search?: string) {
      this.$stores.usersData.handleGetMembers({
        keyword: search,
        as_company_user: true,
      })
    },

    handleSearchMembers(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMembers(search)
      }, 500)
    },

    handleSelectUserFilter() {
      this.handleGetEvents({
        ...this.getWeekStartAndEndUTC(),
      })
    },

    ///////////////////////////////////////////////////////////////////////////

    async handleGetSearchedEvents(keyword: string) {
      this.isSearchedCalendarItemsLoading = true

      const response = await getAllCalendarItems({
        per_page: keyword ? this.itemsPerPage : 10,
        page: this.currentPage,
        sort_by_direction: 'desc',
        sort_by_field: 'start_time',
        ...(this.userFiltration.id === 107070701
          ? { for_whole_company: true }
          : { company_user_id: this.userFiltration.id }),
        keyword,
      })

      if (response.success) {
        this.searchedEventOptions = response.data?.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isSearchedCalendarItemsLoading = false
    },

    handleSearchEvent(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      if (search.length > 0) {
        this.debounceTimeout = setTimeout(() => {
          this.handleGetSearchedEvents(search)
        }, 500)
      }
    },
    getEventTitle(option: CalendarItemsPresenter) {
      return option?.title
      // return `${option?.title} - ${option?.start?.split(' ')[0]}`
    },
    handleSelectSearchedEvent() {
      this.selectedDate = new Date(this.searchedEvent.start_time)
    },

    //////////////////////////////////////////////////////////////////

    handleSelectTypeFilter() {
      this.handleGetEvents({
        ...this.getWeekStartAndEndUTC(),
      })
    },

    /////////////////////////////// Time Zone /////////////////////////////

    toggleTable() {
      this.showTable = !this.showTable
    },

    generateTimeRows() {
      const startHour = 0 // Start at midnight (12:00 AM)
      const endHour = 23 // End at 11 PM
      const rows: TimeRow[] = []

      for (let hour = startHour; hour <= endHour; hour++) {
        const timeRow: TimeRow = {}
        this.$stores.auth.company.calendar_timezones_options.forEach((zone) => {
          timeRow[zone.abbreviation] = this.formatTime2(hour, zone.timezone)
        })
        rows.push(timeRow)
      }

      this.timeRows = rows
    },

    formatTime2(hour: number, timeZone: string): string {
      const now = new Date()
      now.setHours(hour, 0, 0, 0) // Set time to the specific hour
      return new Intl.DateTimeFormat('en-US', {
        timeZone,
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      }).format(now)
    },

    generateTimeSlots(start: string, end: string, interval: number): string[] {
      const slots: string[] = []
      const [startHour, startMinute] = start.split(':').map(Number)
      const [endHour, endMinute] = end.split(':').map(Number)

      const currentDate = new Date()
      currentDate.setHours(startHour, startMinute, 0, 0)

      const endDate = new Date()
      endDate.setHours(endHour, endMinute, 0, 0)

      while (currentDate <= endDate) {
        slots.push(
          currentDate.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          }),
        )
        currentDate.setHours(currentDate.getHours() + interval) // Increment by the interval
      }

      return slots
    },

    convertTime(time: string, timezone: string): string {
      const [hours, minutes] = time.split(':').map(Number)
      const date = new Date()
      date.setUTCHours(hours, minutes, 0, 0)

      try {
        return date.toLocaleTimeString('en-US', {
          timeZone: timezone,
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })
      } catch (error) {
        console.error(`Error converting time for timezone ${timezone}:`, error)
        return 'Invalid Time'
      }
    },
  },
})
