<template>
  <div class="search-and-views">
    <div class="filter-search">
      <div class="filter">
        <Button
          button-text="Filter"
          :text-color="'#66023c'"
          :icon-before="filterIcon"
          :custom-styles="{
            padding: '10px',
            borderRadius: '5px',
            fontSize: '12px',
            fontWeight: '500',
            backgroundColor: '#fff',
            border: '1px solid #66023c',
          }"
          :hover-styles="{
            scale: '1.02',
          }"
          @click="openFilterModal"
        />
      </div>
      <div class="search">
        <VaInput v-model="internalSearch" placeholder="Search" icon="search">
          <template #appendInner>
            <VaIcon name="search" color="secondary" />
          </template>
        </VaInput>
      </div>
    </div>

    <div v-if="showViews" class="views">
      <div
        class="view"
        :class="{ active: activeView === 'list' }"
        @click="changeView('list')"
      >
        <img
          :src="require('../../assets/icons/List-view-icon.svg')"
          alt="list"
        />
      </div>
      <div
        class="view"
        :class="{ active: activeView === 'board' }"
        @click="changeView('board')"
      >
        <img
          :src="require('../../assets/icons/kanban-view.svg')"
          alt="kanban"
        />
      </div>
    </div>

    <div v-if="showButton" class="button">
      <Button
        style="min-width: 14rem"
        :button-text="$t('actionsBar.addNew')"
        :icon-after="require('../../assets/icons/add-icon-white.svg')"
      />
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
