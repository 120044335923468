import { defineComponent } from 'vue'
import DatePickerModal from '@/components/DatePickerModal/index.vue'
import TimeLinkModal from '@/components/TimeRecordModal/TimeLinkModal/index.vue'
import { useForm } from 'vuestic-ui/web-components'
import { addUpdateCalendarItemInterface } from '@/Interfaces/Services'
import { camelCaseToNormal } from '@/lib/utils'
import { calendarGlobalSearchInterface } from '@/Interfaces/Store'
import { calendar_item_linked_items_attributesInterface } from '@/Interfaces/layout'

export default defineComponent({
  name: 'AddEventModal',
  components: {
    DatePickerModal,
    TimeLinkModal,
  },
  props: {
    addCalendarLoading: {
      type: Boolean,
      default: false,
    },
    isAddCalendarForm: {
      type: Boolean,
      default: false,
    },
    newEventStart: {
      type: Object,
    },
  },
  emits: ['toggleAddCalendarForm', 'handleCreateEvent'],
  data() {
    const { validate } = useForm('form')
    return {
      camelCaseToNormal,
      validate,
      isAddCalendarFormBool: this.isAddCalendarForm,

      activeType:
        this.$stores.generalData.companyConfigs.calendarTypesOption?.[0].id,

      reminderOptions: ['15 minutes', ' 30 minutes', '1 hour'],

      form: {
        item_type_id:
          this.$stores.generalData.companyConfigs.calendarTypesOption?.[0].id,
      } as addUpdateCalendarItemInterface,

      isItemAssignUpdate: false,
    }
  },
  watch: {
    isAddCalendarForm: {
      handler(data) {
        this.isAddCalendarFormBool = data
        this.form = {
          item_type_id:
            this.$stores.generalData.companyConfigs.calendarTypesOption?.[0].id,
        } as addUpdateCalendarItemInterface
      },
    },
    newEventStart: {
      handler(data) {
        if (data) {
          this.form = {
            start_time: data.start_time.toISOString(),
            end_time: data.end_time.toISOString(),
          }
        }
      },
    },
    form: {
      handler(newForm) {
        console.log('Form updated:', newForm)
      },
      deep: true,
    },
  },

  methods: {
    toggleAddCalendarForm() {
      this.isAddCalendarFormBool = !this.isAddCalendarForm
      this.$emit('toggleAddCalendarForm')
      this.form = {
        item_type_id:
          this.$stores.generalData.companyConfigs.calendarTypesOption?.[0].id,
      } as addUpdateCalendarItemInterface
    },
    selectType(id: number) {
      this.activeType = id
      this.form.item_type_id = id
    },

    handleUpdateStartDate(date: { date: string }) {
      console.log(date)

      this.form.start_time = date.date
    },
    handleUpdateEndDate(date: { date: string }) {
      this.form.end_time = date.date
    },

    handleCreateEvent() {
      this.$emit('handleCreateEvent', this.form)
    },

    handleLinkToUpdate(data: {
      selectedItems: calendarGlobalSearchInterface[]
      calendar_item_linked_items_attributes: calendar_item_linked_items_attributesInterface[]
    }) {
      this.form.calendar_item_linked_items = data.selectedItems
      this.form.calendar_item_linked_items_attributes =
        data.calendar_item_linked_items_attributes
    },
  },
})
