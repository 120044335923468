<template>
  <VaModal
    v-model="isAddNewOption"
    size="auto"
    hide-default-actions
    fixed-layout
  >
    <template #header>
      <div class="form-modal-header">
        <h1>Add {{ selectedField?.label }} Option</h1>
        <VaIcon
          class="header-icon press-button"
          name="close"
          size="30px"
          @click="handleToggleAddOption"
        />
      </div>
    </template>
    <VaForm
      v-if="selectedField.configuration_type"
      ref="optionForm"
      class="modal-container"
      @submit.prevent="handleToggleAddOption"
    >
      <VaInput
        v-model="optionForm.name"
        label="Option Name"
        placeholder="Enter option name"
        outlined
        background="#ECF0F1"
        class="input"
        required-mark
      />
    </VaForm>
    <VaForm
      v-else
      ref="contactForm"
      class="modal-container"
      @submit.prevent="handleToggleAddOption"
    >
      <VaInput
        v-model="optionForm.individual_first_name"
        label="First Name"
        placeholder="First Name"
        outlined
        background="#ECF0F1"
        class="input"
        required-mark
      />
      <VaInput
        v-model="optionForm.individual_last_name"
        label="Last Name"
        placeholder="Last Name"
        outlined
        background="#ECF0F1"
        class="input"
        required-mark
      />
    </VaForm>

    <template #footer>
      <div class="form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleToggleAddOption"
        >
          Cancel
        </VaButton>
        <VaButton
          :loading="
            $stores.contacts.addContactLoading ||
            $stores.generalData.isAddGlobalConfigLoading
          "
          @click="handleAddNewOption"
        >
          Save
        </VaButton>
      </div>
    </template>
  </VaModal>

  <VaInnerLoading v-if="loading" :loading="loading">
    <div class="loader" />
  </VaInnerLoading>

  <VaForm v-else ref="form" class="form-column" @submit.prevent="handleSubmit">
    <div
      v-for="(row, rowIndex) in fields"
      :key="`row-${rowIndex}`"
      class="form-row"
      :style="`--columns: ${Math.min(fields.length, 4)}`"
    >
      <div
        v-for="(field, index) in row"
        :key="index"
        class="form-field"
        :style="{ flex: field.flex || '1' }"
      >
        <!-- Scoped Slot for Custom Field Rendering -->
        <template v-if="$slots.field">
          <slot name="field" :field="field" :model="formData" />
        </template>

        <template v-else>
          <div v-if="field.type === 'text' || field.type === 'password'">
            <VaInput
              v-model="formData[field.name]"
              :label="field.label"
              :placeholder="field.placeholder"
              :required-mark="field.required"
              :rules="[() => validateField(field)]"
              background="#ECF0F1"
              outlined
              :loading="loading"
              class="input"
            />
          </div>

          <div v-else-if="field.type === 'select'">
            <VaSelect
              v-model="formData[field.name]"
              :label="field.label"
              :placeholder="field.placeholder"
              :options="handleOptions(field.options)"
              :text-by="(e: string) => handleTextBy(field.textBy, e)"
              :value-by="field.valueBy"
              :required-mark="field.required"
              background="#ECF0F1"
              outlined
              class="input"
              :multiple="field.multiple"
              :rules="[() => validateField(field)]"
              :searchable="field.searchable"
              :highlight-matched-text="false"
              :loading="getLoadingState(field.options)"
              allow-create
              :no-options-text="noOptionsText(field.options)"
              @update:search="(e) => handleSearch(field.options, e)"
              @create-new="(e) => handleNewOption(field, e)"
            />
          </div>

          <div v-else-if="field.type === 'textarea'" class="va-textarea">
            <VaTextarea
              v-model="formData[field.name]"
              :label="field.label"
              :placeholder="field.placeholder"
              :required-mark="field.required"
              autosize
              background="#ECF0F1"
              outlined
              max-rows="7"
              :loading="loading"
              class="input"
              style="height: 10rem"
              :rules="[() => validateField(field)]"
            />
          </div>

          <div v-else-if="field.type === 'radio'">
            <VaRadioGroup
              v-if="field.type === 'radio'"
              v-model="formData[field.name]"
              :label="field.label"
              outlined
              :loading="loading"
              class="input"
            >
              <VaRadio
                v-for="(option, idx) in Array.isArray(field.options)
                  ? field.options
                  : []"
                :key="idx"
                :label="option.label"
                :value="option.value"
              />
            </VaRadioGroup>
          </div>

          <div v-else-if="field.type === 'checklist'">
            <div :aria-labelledby="field.label">
              <VaCheckbox
                v-for="(option, idx) in Array.isArray(field.options)
                  ? field.options
                  : []"
                :key="idx"
                v-model="formData[field.name]"
                :label="option.label"
                :true-value="option.value"
                :false-value="null"
                :loading="loading"
                class="input"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </VaForm>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
