<template>
  <div>
    <SharedHeader
      :back-button="true"
      title-width="30rem"
      :title="`INVOICE NUMBER: #${form.invoice_number}`"
    >
      <div class="sub-SharedHeader">
        <PrintInvoice
          :form="form"
          :subtotal="computedSubTotal()"
          :total="computedGrandTotal()"
          :convert-to-secondary-currency="convertToSecondaryCurrency"
        />
      </div>
    </SharedHeader>
    <div class="scroll-container">
      <VaForm ref="form" class="create-invoice-form">
        <div class="scrollable-container">
          <div class="create-invoice-form-container">
            <div style="width: 76%">
              <VaSelect
                v-model="form.individual_contact"
                background="#ECF0F1"
                style="width: 70%; height: 1.5rem; margin-bottom: 1.8rem"
                label="Paid To"
                searchable
                :highlight-matched-text="false"
                :options="$stores.contacts.contacts"
                :text-by="getOptionFullName"
                :loading="$stores.contacts.contactsLoading"
                @update:search="handleSearchContacts"
                @update:modelValue="handleSelectContact"
              />
            </div>

            <div class="form-invoice-currency-container">
              <div class="form-invoice-currency">
                <VaSelect
                  v-model="form.currency"
                  label="main currency"
                  background="#ECF0F1"
                  :options="currencies"
                  :text-by="getOptionCurrency"
                  :loading="currencyLoading"
                />
                <VaSelect
                  v-model="form.secondary_currency"
                  label="Secondary currency"
                  background="#ECF0F1"
                  :options="currencies"
                  :text-by="getOptionCurrency"
                  :loading="currencyLoading"
                />
              </div>
            </div>

            <div style="width: 76%">
              <VaInput
                v-model="form.customer_name"
                background="#ECF0F1"
                style="width: 70%"
                label="Title"
                placeholder="Invoice Title"
              />
            </div>
            <div class="form-invoice-date">
              <VaDateInput
                v-model="form.invoice_date"
                placeholder="MM/DD/YYYY"
                label="Invoice Date"
                background="#ECF0F1"
                manual-input
              />
            </div>
            <div style="width: 76%">
              <VaInput
                v-model="form.customer_email"
                background="#ECF0F1"
                style="width: 70%"
                label="customer email"
                placeholder="Customer Email"
                :rules="[validateInputEmail]"
              />
            </div>
            <div class="form-payment-due">
              <VaSelect
                v-model="form.payment_due_date"
                label="payment due"
                placeholder="Payment Due"
                background="#ECF0F1"
                :options="paymentDueOptions"
                text-by="label"
                value-by="value"
              >
                <template #appendInner>
                  <v-menu
                    v-model="isShowDueDate"
                    :close-on-content-click="false"
                    location="bottom right"
                  >
                    <template #activator="{ props }">
                      <VaIcon
                        v-bind="props"
                        name="calendar_month"
                        color="primary"
                        @click.stop="console.log('calendar_month')"
                      />
                    </template>
                    <v-card class="date-picker-card">
                      <VaDatePicker
                        v-model="datePickerValue"
                        @update:modelValue="handleDatePickerChange"
                      />
                    </v-card>
                  </v-menu>
                </template>
              </VaSelect>
              <!-- <VaDateInput
              placeholder="MM/DD/YYYY"
              label="payment due"
              background="#ECF0F1"
              v-model="form.payment_due_date"
              manual-input
              :rules="[validateInput]"
            /> -->
            </div>
          </div>

          <VaDivider inset />

          <div class="items-container">
            <table class="invoice-table">
              <!-- Header Section -->

              <thead>
                <tr class="invoice-header">
                  <th class="invoice-icon-header" />
                  <th class="invoice-item-header">Items</th>
                  <th class="invoice-quantity-header">Quantity</th>
                  <th class="invoice-unit-header">Type</th>
                  <th class="invoice-price-header">Price</th>
                  <th class="invoice-amount-header">Discount Type</th>
                  <th class="invoice-amount-header">Discount</th>
                  <th class="invoice-amount-header">Tax</th>
                  <th class="invoice-amount-header">Amount</th>
                  <th class="invoice-icon-header" />
                </tr>
              </thead>

              <!-- Items Section -->
              <tbody>
                <tr
                  v-for="(item, index) in invoiceItems"
                  :key="index"
                  class="invoice-row"
                >
                  <td class="invoice-icon-cell">
                    <v-tooltip
                      :text="
                        getIconDetails(item?.billable?.class_name)?.tooltip ||
                        'Item'
                      "
                      location="top"
                    >
                      <template #activator="{ props }">
                        <VaIcon
                          v-bind="props"
                          :name="
                            getIconDetails(item?.billable?.class_name)?.icon ||
                            'radio_button_checked'
                          "
                          class="press-button-2"
                          color="#bbb"
                        />
                      </template>
                    </v-tooltip>
                  </td>
                  <td class="invoice-item-cell">
                    <VaInput
                      v-model="item.description"
                      background="#fff"
                      class="invoice-item-input"
                      placeholder="Enter item description"
                      :rules="[validateInput]"
                    />
                  </td>

                  <td class="invoice-quantity-cell">
                    <VaInput
                      v-model="item.quantity"
                      background="#fff"
                      type="number"
                      min="1"
                      class="invoice-quantity-input"
                    />
                  </td>

                  <td class="invoice-amount-cell">
                    <p>
                      {{
                        item?.billable?.class_name == 'CalendarItem'
                          ? 'HOURS'
                          : ''
                      }}
                    </p>
                  </td>

                  <td class="invoice-price-cell">
                    <VaInput
                      v-model="item.unit_price"
                      background="#fff"
                      type="number"
                      class="invoice-price-input"
                    />
                  </td>

                  <td class="invoice-quantity-cell">
                    <VaSelect
                      v-model="item.discount_type"
                      placeholder="Select Payment Due"
                      background="#fff"
                      :options="taxTypeOptions"
                      text-by="label"
                      value-by="value"
                    />
                  </td>

                  <td class="invoice-quantity-cell">
                    <VaInput
                      v-model="item.discount_type_amount"
                      background="#fff"
                      type="number"
                      min="0"
                      class="invoice-quantity-input"
                    >
                      <template
                        v-if="item.discount_type === 'percentage'"
                        #prependInner
                      >
                        <VaIcon name="percent" color="secondary" />
                      </template>
                    </VaInput>
                  </td>

                  <td class="invoice-quantity-cell">
                    <VaInput
                      v-model="item.tax_percentage"
                      background="#fff"
                      type="number"
                      min="0"
                      class="invoice-quantity-input"
                    >
                      <template #prependInner>
                        <VaIcon name="percent" color="secondary" />
                      </template>
                    </VaInput>
                  </td>

                  <td class="invoice-amount-cell">
                    {{
                      totalAmount(
                        item.quantity,
                        item.unit_price,
                        item.tax_percentage,
                        item.discount_type,
                        item.discount_type_amount,
                      )
                    }}
                  </td>
                  <td class="invoice-icon-cell">
                    <VaIcon
                      name="delete"
                      class="press-button-2"
                      color="#bbb"
                      @click="removeInvoiceItem(index)"
                    />
                  </td>
                </tr>
                <!-- Item Tax Percentage Section -->
                <tr class="invoice-tax-row" />
                <!-- Add Item Button -->
                <tr class="invoice-add-item-row">
                  <td colspan="5" class="invoice-add-item-cell">
                    <!-- <va-button
                    @click="addInvoiceItem"
                    class="add-item-button"
                    icon="add_circle"
                    color="primary"
                    preset="primary"
                  >
                    Add an item
                  </va-button> -->
                    <v-menu
                      v-model="isItemAssignEvent"
                      :close-on-content-click="false"
                      location="bottom right"
                    >
                      <template #activator="{ props }">
                        <va-button
                          v-bind="props"
                          class="add-item-button"
                          icon="add_circle"
                          color="primary"
                          preset="primary"
                        >
                          Add Calendar event
                        </va-button>
                      </template>
                      <LinkToCalendarEvent
                        :calendar-items="calendarItems"
                        @handleLinkTo="addInvoiceCalendarEvent"
                      />
                    </v-menu>

                    <v-menu
                      v-model="isItemAssignExpense"
                      :close-on-content-click="false"
                      location="bottom right"
                    >
                      <template #activator="{ props }">
                        <va-button
                          v-bind="props"
                          class="add-item-button"
                          icon="add_circle"
                          color="primary"
                          preset="primary"
                        >
                          Add an Expense
                        </va-button>
                      </template>
                      <LinkExpense
                        :expenses="expenses"
                        @handleLinkTo="addInvoiceExpense"
                      />
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <VaDivider />
        </div>
        <div class="text-area-section">
          <div class="payment-group">
            <VaSelect
              v-model="form.payment_details_note"
              style="width: 33%"
              label="payment due"
              placeholder="Select Payment Due"
              background="#fff"
              :options="['Due Today', 'Net 15', 'Net 30', 'Net 60']"
            />
            <VaSelect
              v-model="form.signature_note"
              style="width: 33%"
              label="payment address"
              placeholder="Select Payment Address"
              background="#fff"
              :options="['UAE BANK', 'US BANK']"
            />
            <VaSelect
              v-model="form.terms_and_conditions_note"
              style="width: 33%"
              label="terms"
              placeholder="Select Terms"
              background="#fff"
              :options="['Term 1', 'Term 2', 'Term 3']"
            />
          </div>
          <VaTextarea
            v-model="form.general_note"
            max-rows="7"
            label="general note"
            placeholder="Add your note here..."
            style="width: 100%; height: 10rem"
            autosize
          />
        </div>

        <div class="bottom-section">
          <div class="subtotal-price">
            <h2>Subtotal</h2>
            <span>{{ computedSubTotal() }} {{ form.currency?.code }}</span>
          </div>
          <div class="divider">
            <VaDivider />
          </div>

          <div class="discount-container">
            <p>Discount</p>
            <div class="discount-item">
              <VaSelect
                v-model="form.discount_type"
                placeholder="Select Payment Due"
                background="#fff"
                :options="taxTypeOptions"
                text-by="label"
                value-by="value"
              />

              <VaInput
                v-model="form.discount_type_amount"
                style="width: 6rem"
                background="#fff"
                type="number"
              >
                <template #prependInner>
                  <VaIcon
                    v-if="form.discount_type === 'percentage'"
                    name="percent"
                    color="secondary"
                  />
                </template>
              </VaInput>

              <VaIcon name="delete" class="press-button-2" color="#fff" />
            </div>
          </div>

          <div class="tax-container">
            <p>Taxes</p>

            <div
              v-for="(taxItem, index) in form.invoice_tax_items"
              :key="index"
              class="tax-item"
            >
              <VaInput
                v-model="taxItem.name"
                background="#fff"
                type="text"
                placeholder="Tax Name"
              />
              <VaSelect
                v-model="taxItem.tax_type"
                :options="taxTypeOptions"
                text-by="label"
                value-by="value"
                placeholder="Type"
              />
              <VaInput
                v-model.number="taxItem.tax_type_amount"
                background="#fff"
                type="number"
                min="0"
                placeholder="Amount"
              >
                <template #prependInner>
                  <VaIcon
                    v-if="taxItem.tax_type === 'percentage'"
                    name="percent"
                    color="secondary"
                  />
                </template>
              </VaInput>

              <VaIcon
                name="delete"
                class="press-button-2"
                color="#bbb"
                @click="removeTaxItem(index)"
              />
            </div>

            <VaButton
              preset="primary"
              icon="add_circle"
              class="add-tax-button"
              @click="addTaxItem"
            >
              Add Tax
            </VaButton>
          </div>

          <div class="divider">
            <VaDivider />
          </div>

          <div class="total-price">
            <h2>Total</h2>
            <div style="width: 5rem" />
            <span>{{ computedGrandTotal() }} {{ form.currency?.code }}</span>
          </div>

          <div v-if="convertToSecondaryCurrency != null" class="total-price">
            <div style="width: 5rem" />
            <span
              >{{ convertToSecondaryCurrency }}
              {{ form.secondary_currency?.code }}</span
            >
          </div>
        </div>

        <div class="add-contact-form-modal-buttons">
          <VaButton
            preset="secondary"
            border-color="primary"
            @click="handleGetInvoiceByID"
          >
            Reset
          </VaButton>
          <VaButton
            :loading="updateLoading"
            @click="validate() && handleUpdateInvoice()"
          >
            Save
          </VaButton>
        </div>
      </VaForm>
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
