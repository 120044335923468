import { App } from 'vue'
import { createPinia } from 'pinia'
import {
  auth,
  calendar,
  langs,
  ui,
  financial,
  mockTasks,
  matter,
  mockTimer,
  contacts,
  generalData,
  usersData,
  globalSearch,
} from './src/stores/index'

let piniaInstance: ReturnType<typeof createPinia> | null = null

export const setupPinia = (app: App<Element>) => {
  if (!piniaInstance) {
    piniaInstance = createPinia()
    app.use(piniaInstance)
  }

  app.mixin({
    beforeCreate() {
      this.$stores = {
        auth: auth(),
        langs: langs(),
        calendar: calendar(),
        ui: ui(),
        financial: financial(),
        mockTasks: mockTasks(),
        matter: matter(),
        mockTimer: mockTimer(),
        contacts: contacts(),
        generalData: generalData(),
        usersData: usersData(),
        globalSearch: globalSearch(),
      }
    },
  })
}
