import {
  getOptionCurrency,
  getOptionFullName,
  getOptionName,
  getOptionTruncateName50,
  validateSelect,
} from '@/lib/utils'
import { CurrenciesPresenter } from '@/presenters/CurrenciesPresenter'
import { StagesPresenter } from '@/presenters/StagesPresenter'
import { getAllCurrencies } from '@/services/currencies'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'

export default defineComponent({
  name: '',
  props: {
    addLeadLoading: {
      type: Boolean,
      default: false,
    },
    isAddLeadForm: {
      type: Boolean,
      default: false,
    },
    stages: {
      type: Array as () => StagesPresenter[],
      default: [] as StagesPresenter[],
    },
  },
  emits: ['toggleAddLeadForm', 'handleCreateLead'],
  data() {
    const { validate } = useForm('form')
    return {
      validate,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionTruncateName50,
      getOptionCurrency,

      isAddLeadFormBool: this.isAddLeadForm,

      form: {} as any,

      currencies: [] as CurrenciesPresenter[],
      currencyLoading: false,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,
    }
  },
  methods: {
    toggleAddLeadForm() {
      this.isAddLeadFormBool = !this.isAddLeadFormBool
      this.$emit('toggleAddLeadForm')
    },

    handleCreateLead() {
      this.$emit('handleCreateLead', this.form)
    },

    ////////////////////////////////// contacts ///////////////////////////////

    async handleGetContacts(search?: string) {
      this.$stores.contacts.handleGetContacts({
        keyword: search,
      })
    },
    handleSearchContacts(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts(search)
      }, 500)
    },

    ////////////////////////////////// Organizations ///////////////////////////////

    async handleGetOrganizations(search?: string) {
      this.$stores.contacts.handleGetOrganizations({
        keyword: search,
      })
    },
    handleSearchOrganizations(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetOrganizations(search)
      }, 500)
    },

    ////////////////////////////// Currencies ///////////////////////////////////

    async handleGetCompanyCurrencies() {
      this.currencyLoading = true

      const response = await getAllCurrencies({})

      if (response.success) {
        this.currencies = response.data.list
        this.form.currency = this.currencies[0]
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.currencyLoading = false
    },

    ////////////////////////////////// Members ///////////////////////////////

    async handleGetMembers(search?: string) {
      this.$stores.usersData.handleGetMembers({
        keyword: search,
      })
    },

    handleSearchMembers(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMembers(search)
      }, 500)
    },
  },
  watch: {
    isAddLeadForm: {
      handler(data) {
        this.isAddLeadFormBool = data
        if (data) {
          this.handleGetContacts()
          this.handleGetMembers()
          this.handleGetCompanyCurrencies()
          this.handleGetOrganizations()
        }
      },
    },
  },
})
