import { defineComponent } from 'vue'
import Button from '@/components/ui/button/index.vue'

const filterIcon = new URL('@/assets/icons/filter.svg', import.meta.url).href

export default defineComponent({
  name: 'SearchAndViews',
  components: {
    Button,
  },
  props: {
    activeView: {
      type: String,
      required: true,
    },
    withViews: {
      type: Boolean,
      required: true,
    },
    withAddButton: {
      type: Boolean,
      required: false,
    },
    search: {
      type: String,
      default: '', // Default to empty string if not provided
    },
  },
  data() {
    return {
      internalSearch: this.search, // Initialize with the prop value
      filterIcon,
    }
  },
  computed: {
    showViews(): boolean {
      return this.withViews
    },
    showButton(): boolean {
      return this.withAddButton
    },
  },
  watch: {
    // Watch for changes to the search prop and update internalSearch accordingly
    search(newValue) {
      this.internalSearch = newValue
    },
    // If internalSearch changes, emit the updated value to the parent
    internalSearch(newValue) {
      this.$emit('search', newValue)
    },
  },
  methods: {
    // Handle view change and emit event
    changeView(view: string) {
      this.$emit('changeView', view)
    },
    // Emit event to open the filter modal
    openFilterModal() {
      this.$emit('openFilterModal')
    },
  },
})
