import { CurrenciesPresenter } from '@/presenters/CurrenciesPresenter'
import { apiRequest } from './apiUtils'
import {
  addCurrencyFormInterface,
  getCurrenciesInterface,
} from '@/Interfaces/Services'

export const getAllCurrencies = async ({
  per_page,
  page,
}: getCurrenciesInterface) => {
  const response = await apiRequest('get', '/currencies', null, {
    per_page,
    page,
  })

  if (!response.success) {
    return response
  }

  const list = response.data?.list
  if (!Array.isArray(list)) {
    return response
  }

  return {
    ...response,
    data: {
      ...response.data,
      list: list.map(
        (item: CurrenciesPresenter) => new CurrenciesPresenter(item),
      ),
    },
  }
}

export const getCurrencyById = async (id: number) => {
  const response = await apiRequest('get', `/currencies/${id}`)
  if (response.success) {
    return {
      ...response,
      data: new CurrenciesPresenter(response.data),
    }
  } else {
    return response
  }
}

export const addCurrency = (body: addCurrencyFormInterface) => {
  return apiRequest('post', '/currencies', body)
}

export const updateCurrency = (id: number, body: addCurrencyFormInterface) => {
  return apiRequest('put', `/currencies/${id}`, body)
}

export const deleteCurrency = (id: number) => {
  return apiRequest('delete', `/currencies/${id}`)
}
