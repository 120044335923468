export const mockMatterFelids = [
  [{ name: 'name', required: true }],
  [{ name: 'representing_contact' }],
  // [
  //   { name: 'court', flex: 6 },
  //   { name: 'court_case_number', flex: '3' },
  // ],

  [{ name: 'parent_id' }],
  [{ name: 'status' }, { name: 'section_stage' }],
  [{ name: 'sector' }],
  [{ name: 'case_manager' }],
  [{ name: 'personnel', flex: 7 }],
  [{ name: 'specialization' }, { name: 'category' }],
  [{ name: 'jurisdiction' }],
  [{ name: 'region' }],
  [{ name: 'notes' }],
]
