export class CurrenciesPresenter {
  id!: number
  class_name!: string
  code!: string
  company_id!: number
  conversion_rate_against_us_dollars!: string
  created_at!: string
  name!: string
  updated_at!: string

  constructor(data: CurrenciesPresenter) {
    Object.assign(this, data)
  }
}
