import { defineComponent } from 'vue'
import TabsBar from '@/components/tabsBar/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import MilestoneGlobal from '@/views/Settings/MilestonesSetting/MilestoneGlobal/index.vue'

export default defineComponent({
  name: 'MilestonesSetting',
  components: {
    TabsBar,
    ComingSoon,
    MilestoneGlobal,
  },
  data() {
    const tabs = [{ id: 0, name: 'GLOBAL MILESTONE' }]

    return {
      tabs,
      activeTabId: 1,
      activeTab: 'GLOBAL MILESTONE',
    }
  },
  computed: {},
  methods: {
    /////////////////////////////////////////////////////////////////////////////
    changeTab(tab: { name: string; id: number }) {
      this.activeTabId = tab.id
      this.activeTab = tab.name
    },
  },
})
