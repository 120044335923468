import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f9752af"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "row total-row"
}
const _hoisted_2 = { class: "total-title" }
const _hoisted_3 = { class: "currency" }
const _hoisted_4 = {
  key: 1,
  class: "row"
}
const _hoisted_5 = { class: "title" }
const _hoisted_6 = {
  key: 0,
  class: "value"
}
const _hoisted_7 = { class: "currency" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isTotal)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", {
          class: _normalizeClass(["total-value", { 'red-text': _ctx.isRed, 'green-text': _ctx.isGreen }])
        }, [
          _createElementVNode("small", _hoisted_3, _toDisplayString(_ctx.$stores.financial.currency), 1),
          _createTextVNode(" " + _toDisplayString(_ctx.displayValue), 1)
        ], 2)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.title), 1),
        (_ctx.displayValue)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
              _createElementVNode("small", _hoisted_7, _toDisplayString(_ctx.$stores.financial.currency), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.displayValue), 1)
            ]))
          : _createCommentVNode("", true)
      ]))
}