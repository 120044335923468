import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d4f4bd2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-chip" }
const _hoisted_2 = { class: "chip-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_VaIcon = _resolveComponent("VaIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_va_icon, {
      name: "bookmark",
      size: "large",
      color: _ctx.color
    }, null, 8, ["color"]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createVNode(_component_VaIcon, {
      name: "close",
      size: "small",
      color: "primary",
      onClick: _ctx.handleClose
    }, null, 8, ["onClick"])
  ]))
}