<template>
  <VaModal
    v-model="isAddAnnouncementModalBool"
    size="small"
    hide-default-actions
    fixed-layout
    noOutsideDismiss
    :before-close="beforeAddAnnouncementModalClose"
  >
    <template #header>
      <div class="form-header">
        <h1>Create Announcement</h1>
        <VaIcon
          class="header-icon"
          name="close"
          size="30px"
          @click="toggleAddToDraftModal(false)"
        />
      </div>
    </template>
    <VaForm
      ref="form"
      class="create-announcement-form"
      @submit.prevent="validate"
    >
      <VaInput
        placeholder="Announcement Title"
        background="#ECF0F1"
        v-model="form.title"
        label="Title"
      />
      <VaInput
        placeholder="Enter here the body text of the announcement"
        background="#ECF0F1"
        v-model="form.description"
        label="description"
        :rules="[validateInput]"
        requiredMark
      />
      <div>
        <VaSelect
          placeholder="MM/DD/YYYY"
          label="Date & Time of Dispatching"
          background="#ECF0F1"
          v-model="form.dateAndTimeOfDispatching"
          :options="announcementDueOptions"
          text-by="label"
          value-by="value"
        >
          <template #appendInner>
            <v-menu
              v-model="isShowDueDate"
              :close-on-content-click="false"
              location="bottom right"
            >
              <template v-slot:activator="{ props }">
                <VaIcon
                  v-bind="props"
                  name="calendar_month"
                  color="primary"
                  @click.stop="console.log('calendar_month')"
                />
              </template>
              <v-card class="date-picker-card">
                <VaDatePicker
                  v-model="datePickerValue"
                  @update:modelValue="handleDatePickerChange"
                />
              </v-card>
            </v-menu>
          </template>
        </VaSelect>
      </div>

      <VaSelect
        placeholder="Choose the recipients"
        v-model="form.recipients"
        label="Recipients"
        searchable
        clearable
        :highlight-matched-text="false"
        :options="recipientsList"
        :text-by="getOptionFullName"
        multiple
      />

      <VaCheckbox
        label="Require confirmation on announcement receipt"
        v-model="form.requireConfirmation"
      />
    </VaForm>
    <template #footer>
      <div class="add-announcement-form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="toggleAddToDraftModal(false)"
          >Cancel</VaButton
        >
        <VaButton
          @click="validate() && handleAddAnnouncement(true)"
          :loading="addAnnouncementLoading"
          >Save</VaButton
        >
      </div>
    </template>
  </VaModal>

  <VaModal
    v-model="isAddToDraftModalOpen"
    size="small"
    hide-default-actions
    fixed-layout
    noOutsideDismiss
  >
    <template #header>
      <div class="form-header">
        <h1>Add To Draft</h1>
        <VaIcon
          class="header-icon"
          name="close"
          size="30px"
          @click="toggleAddToDraftModal(false)"
        />
      </div>
    </template>

    <template #footer>
      <div class="add-announcement-form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="toggleAddToDraftModal(true, false)"
          >Discard</VaButton
        >
        <VaButton
          @click="toggleAddToDraftModal(true, true)"
          :loading="addAnnouncementLoading"
          >Save</VaButton
        >
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
