import { getOptionName } from '@/lib/utils'
import { MatterPresenter } from '@/presenters/matterPresenter'
import {
  getAllTasksMatters,
  linkMatterTask,
  UnlinkMatterTask,
} from '@/services/tasks'
import { defineComponent } from 'vue'
import MatterTable from '@/components/LegalMatter/Table/index.vue'
import ActionsBar from '@/components/actionsBar/index.vue'
import SearchAndViews from '@/components/searchAndViews/index.vue'
import { getAllMatters } from '@/services/legalMatter'
import { paginationInterface } from '@/Interfaces/Services'
import AddLinkModal from '@/components/AddLinkModal/index.vue'

export default defineComponent({
  name: 'TaskLegalMattersSection',
  components: {
    ActionsBar,
    MatterTable,
    SearchAndViews,
    AddLinkModal,
  },
  data() {
    return {
      getOptionName,

      matters: [] as MatterPresenter[],
      mattersItemPerPage: 10,
      matterPage: 1,
      matterSortField: '' as any,
      matterSortDirection: '' as 'asc' | 'desc' | undefined,
      matterSearchKeyword: '',
      matterTotalItems: 0,
      selectedMatterRows: [] as MatterPresenter[],
      matterTableLoading: false,

      isAddMatter: false,
      link_to_matter: '' as any,
      mattersLoading: false,

      mattersModal: [] as MatterPresenter[],
      mattersItemPerPageModal: 10,
      matterPageModal: 1,
      matterSortFieldModal: '' as any,
      matterSortDirectionModal: '' as 'asc' | 'desc' | undefined,

      matterTotalItemsModal: 0,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null, // Holds the debounce timeout ID

      linkMatterLoading: false,
      UnlinkMatterLoading: false,
    }
  },
  watch: {
    isAddMatter: {
      handler(data) {
        if (data === true) {
          this.handleGetMattersModal()
          this.mattersModal = [] as MatterPresenter[]
        }
      },
    },
  },
  mounted() {
    this.handleGetMatters()
  },
  methods: {
    getSelectedMatterRows(rows: MatterPresenter[]) {
      this.selectedMatterRows = rows
    },
    async handleGetMatters() {
      this.matterTableLoading = true

      const response = await getAllTasksMatters({
        per_page: this.mattersItemPerPage,
        page: this.matterPage,
        task_id: this.$route.params.id as string,
        related_to_type: 'LegalMatter',
      })
      if (response.success) {
        this.matters = response.data?.list
        this.matterTotalItems =
          response.data.total_pages * this.mattersItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.matterTableLoading = false
    },
    async handlePaginationMatters({
      page,
      itemsPerPage,
      sortBy,
    }: paginationInterface) {
      this.mattersItemPerPage = itemsPerPage
      this.matterPage = page
      if (sortBy.length > 0) {
        this.matterSortField = sortBy[0].key.split('.')[1]
        this.matterSortDirection = sortBy[0].order
      } else {
        this.matterSortField = undefined
        this.matterSortDirection = undefined
      }

      await this.handleGetMatters()
    },
    //////////////////////////// Modal ///////////////////////////////////////////////////
    toggleAddMatter() {
      this.isAddMatter = !this.isAddMatter
    },

    async handleGetMattersModal(search?: string) {
      this.mattersLoading = true

      const response = await getAllMatters({
        per_page: this.mattersItemPerPageModal,
        page: this.matterPageModal,
        keyword: search,
      })
      if (response.success) {
        const matterIds = this.matters?.map((e: MatterPresenter) => e.id)

        this.mattersModal = response.data?.list.filter(
          (mat: MatterPresenter) => !matterIds?.includes(mat.id),
        )
        this.matterTotalItemsModal =
          response.data.total_pages * this.mattersItemPerPageModal
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.mattersLoading = false
    },
    async handlePaginationMattersModal({
      page,
      itemsPerPage,
      sortBy,
    }: paginationInterface) {
      this.mattersItemPerPageModal = itemsPerPage
      this.matterPageModal = page
      if (sortBy.length > 0) {
        this.matterSortFieldModal = sortBy[0].key.split('.')[1]
        this.matterSortDirectionModal = sortBy[0].order
      } else {
        this.matterSortFieldModal = undefined
        this.matterSortDirectionModal = undefined
      }

      await this.handleGetMattersModal()
    },

    searchMatterData(search?: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMattersModal(search)
      }, 500)
    },
    /////////////////////////////////////////////////////////////////////////////////

    async handleLinkMatter(selectedMatter: MatterPresenter) {
      this.linkMatterLoading = true
      const response = await linkMatterTask([
        {
          task_id: Number(this.$route.params.id),
          related_to_type: 'LegalMatter',
          related_to_id: Number(selectedMatter?.id),
        },
      ])
      if (response.success) {
        this.handleGetMatters()
        this.isAddMatter = false
        this.$vaToast.init({
          message: 'Matter linked successfully!',
          color: 'success',
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.linkMatterLoading = false
    },

    ///////////////////////////////////////////////////////////////////////////

    async handleUnlinkMatter(matter: MatterPresenter) {
      this.UnlinkMatterLoading = true
      const response = await UnlinkMatterTask(
        Number(this.$route.params.id),
        matter.id,
      )
      if (response.success) {
        this.handleGetMattersModal()
        this.$vaToast.init({
          message: 'Matter Unlinked successfully!',
          color: 'success',
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.UnlinkMatterLoading = false
    },
  },
})
