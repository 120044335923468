<template>
  <div class="notification-button">
    <v-menu
      v-model="showNotifications"
      :close-on-content-click="false"
      location="bottom center"
    >
      <template #activator="{ props }">
        <VaBadge
          v-if="notificationNumber > 0"
          overlap
          :text="notificationNumber"
        >
          <div class="notification-header-logo" v-bind="props">
            <VaIcon name="notifications" color="#ffffff" size="1.5rem" />
          </div>
        </VaBadge>
      </template>

      <v-card class="notification-container pa-4" width="25rem" height="90vh">
        <div class="notification-header">
          <p>
            Notification
            <VaButton size="small" round>
              {{ notificationNumber }}
            </VaButton>
          </p>
          <span>
            <VaButton
              preset="secondary"
              round
              icon="open_with"
              @click="handleCloseNotification"
            />
            <VaButton
              preset="secondary"
              round
              icon="close"
              @click="handleCloseNotification"
            />
          </span>
        </div>
        <div class="notification-sub-header">
          <span class="notification-sub-header-left">
            <VaButton size="small"> Unread </VaButton>
            <VaButton preset="primary" size="small"> Show all </VaButton>
          </span>
          <span>
            <VaButton preset="secondary" round icon="filter_list" />
            <VaButton preset="secondary" round icon="settings" />
          </span>
        </div>
        <VaDivider />
        <div class="notification-details">
          <div>
            <va-icon name="error" size="2rem" />
          </div>
          <div>
            <p class="detail">
              "Lawyers, listen up! Your firm's website needs your attention - an
              important alert notification has just been issued."
            </p>
          </div>
          <div>
            <p class="time">
              12 min ago
              <VaButton preset="secondary" round icon="close" />
            </p>
          </div>
        </div>
        <div class="notification-accordion">
          <VaAccordion v-model="value" class="max-w-sm" multiple>
            <VaCollapse
              v-for="(collapse, index) in collapses"
              :key="index"
              v-model="value[index]"
              :header="collapse.title"
              solid
            >
              <template #header="{ value, attrs, iconAttrs }">
                <div v-bind="attrs" class="collapse-header">
                  <h2 class="collapse-title">
                    {{ collapse.title }}
                    <VaButton size="small" round>
                      {{ collapse.data.length }}
                    </VaButton>
                  </h2>
                  <VaIcon
                    :name="!value ? 'va-arrow-down' : 'va-arrow-up'"
                    v-bind="iconAttrs"
                  />
                </div>
                <VaDivider class="divider" />
              </template>

              <template #body>
                <div
                  v-for="(data, index) in collapse.data"
                  :key="index"
                  :v-if="data"
                  class="collapse-body"
                >
                  <span><va-icon name="account_circle" size="2rem" /> </span>
                  <div class="collapse-body-content">
                    <p class="name">
                      {{ data.content }}
                    </p>
                    <p class="position">
                      {{ data.position }}
                    </p>
                    <div class="company">
                      <p class="company-name">
                        {{ data.companyName }}
                      </p>
                      <div class="company-team">
                        <VaIcon name="groups" color="primary" />
                        <div>
                          <p>Team member:</p>
                          <p>{{ data.team }}</p>
                        </div>
                      </div>
                    </div>
                    <p class="context">
                      Nam gravida augue scelerisque ornare tellus gravida nullam
                      sed. Dapibus feugiat massa pretium morbi eget.
                    </p>
                  </div>
                  <div class="menu-time">
                    <img
                      :src="MoreVertIcon"
                      alt="MoreVertIcon"
                      class="press-button-2"
                    />
                    <p>{{ data.time }}</p>
                  </div>
                </div>
              </template>
            </VaCollapse>
          </VaAccordion>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
