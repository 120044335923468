<template>
  <VaModal
    v-model="isTableConfigUpdateBool"
    size="small"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
    @update:modelValue="handleToggleTableConfig"
  >
    <template #header>
      <div class="modal-form-header">
        <h1>{{ selectedSection?.name }}'s Table Headers View</h1>
        <VaIcon
          class="press-button"
          name="close"
          size="30px"
          @click="handleToggleTableConfig"
        />
      </div>
    </template>

    <div class="select-all">
      <VaCheckbox
        v-model="isAllSelected"
        :label="isAllSelected ? 'Unselect All' : 'Select All'"
        class="mb-4 select-all"
      />
    </div>

    <div
      v-for="item in tableColumns.map((column) => column.title || '')"
      :key="item"
    >
      <VaCheckbox
        v-model="selectedHeaders"
        :array-value="item"
        :label="item"
        class="mb-4"
      />
    </div>

    <template #footer>
      <div class="form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleToggleTableConfig"
        >
          Cancel
        </VaButton>
        <VaButton
          :loading="isTableConfigUpdateLoading"
          @click="handleSubmitTableConfig"
        >
          Save
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
