import { timezoneOptions } from '@/constants/timezones'
import { TimezonesInterface } from '@/Interfaces/Constants'
import { ZoneType } from '@/Interfaces/Services'
import { defineComponent } from 'vue'

export default defineComponent({
  name: '',
  props: {
    isTimeZone: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['handleToggleTimeZone', 'handleSaveSelectedZones'],
  data() {
    return {
      isTimeZoneBool: this.isTimeZone,
      timezoneOptions,
      selectedTimeZone: [] as TimezonesInterface[],
      autoCompleteSearchValue: '',

      selectedZones: [] as ZoneType[],
    }
  },
  computed: {
    filteredTimezones(): ZoneType[] {
      return this.timezoneOptions.filter(
        (zone) =>
          !this.selectedZones.some(
            (selected) => selected.abbreviation === zone.abbreviation,
          ),
      )
    },
  },

  watch: {
    isTimeZone: {
      handler(data) {
        this.isTimeZoneBool = !!data

        if (data) {
          const newZones =
            this.$stores.auth?.company?.calendar_timezones_options || []
          this.selectedZones = Array.from(new Set([...newZones]))
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleToggleTimeZone() {
      this.isTimeZoneBool = !this.isTimeZoneBool
      this.$emit('handleToggleTimeZone')
    },

    getOptionTimeZone(zone: ZoneType) {
      return `${zone.country} - ${zone.abbreviation}`
    },

    handleAddTimeZone(zone: ZoneType) {
      if (
        !this.selectedZones.some(
          (selected) => selected.abbreviation === zone.abbreviation,
        )
      ) {
        this.selectedZones.push(zone)
      }
      this.selectedTimeZone = []
      this.autoCompleteSearchValue = ''
    },

    handleRemoveZone(zone: ZoneType) {
      this.selectedZones = this.selectedZones.filter(
        (selected) => selected.abbreviation !== zone.abbreviation,
      )
    },

    handleSaveSelectedZones() {
      console.log(this.selectedZones)

      this.$emit('handleSaveSelectedZones', this.selectedZones)
    },
  },
})
