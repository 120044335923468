<template>
  <v-card max-height="25rem" class="option-card">
    <div class="cal-link-body">
      <h1 class="title">Recent Expenses</h1>
      <div class="cal-list">
        <div
          v-for="(expense, index) in expenses"
          :key="index"
          class="expense-card"
          @click="handleLinkTo(expense)"
        >
          <div class="expense-details">
            <p class="expense-name">
              <VaIcon name="receipt_long" size="15px" />
              {{ expense?.name || 'Unnamed Expense' }}
            </p>
            <p class="expense-billed">
              <VaIcon name="account_circle" size="15px" />
              Billed To: {{ expense?.billed_to?.fullName || 'Unknown' }}
            </p>
            <p class="expense-due">
              <VaIcon name="calendar_today" size="15px" />
              Payment Date:
              {{ formatDate(expense?.due_date) || 'Not Specified' }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
