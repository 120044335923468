import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f82b82e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tasks-table-container" }
const _hoisted_2 = { class: "tasks-description-td" }
const _hoisted_3 = { class: "tasks-description-td" }
const _hoisted_4 = { class: "tasks-assignedTo-td" }
const _hoisted_5 = { class: "tasks-taskOwner-td" }
const _hoisted_6 = { class: "tasks-description-td" }
const _hoisted_7 = { class: "tasks-description-td" }
const _hoisted_8 = {
  key: 0,
  class: "tasks-priority-td"
}
const _hoisted_9 = { class: "table-action-td" }
const _hoisted_10 = ["src"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["onClick"]
const _hoisted_20 = ["onClick"]
const _hoisted_21 = ["onClick"]
const _hoisted_22 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_va_avatar = _resolveComponent("va-avatar")!
  const _component_VaButton = _resolveComponent("VaButton")!
  const _component_VaDropdownContent = _resolveComponent("VaDropdownContent")!
  const _component_va_dropdown = _resolveComponent("va-dropdown")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_data_table_server, {
      selection: _ctx.selectedRows,
      "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedRows) = $event)),
      loading: _ctx.loading,
      headers: _ctx.headers,
      items: _ctx.tasks,
      "show-select": "",
      class: "custom-table",
      "items-length": _ctx.localTotalItems,
      "items-per-page": _ctx.itemsPerPage,
      "items-per-page-options": [10, 25, 50],
      "onUpdate:options": _ctx.handleLoadMore
    }, {
      [`item.name`]: _withCtx(({ item }) => [
        _createVNode(_component_router_link, {
          class: "link",
          to: `/tasks/${item.id}`
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_2, _toDisplayString(item.name), 1)
          ]),
          _: 2
        }, 1032, ["to"])
      ]),
      [`item.description`]: _withCtx(({ item }) => [
        _createElementVNode("p", _hoisted_3, _toDisplayString(item.description), 1)
      ]),
      [`item.assigned_to`]: _withCtx(({ item }) => [
        _createElementVNode("td", _hoisted_4, [
          (item.assigned_to?.avatar_letter)
            ? (_openBlock(), _createBlock(_component_va_avatar, {
                key: 0,
                color: "#ECF0F1"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(item.assigned_to?.avatar_letter), 1)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(item.assigned_to?.fullName), 1)
        ])
      ]),
      [`item.owner`]: _withCtx(({ item }) => [
        _createElementVNode("td", _hoisted_5, [
          (item.owner?.avatar_letter)
            ? (_openBlock(), _createBlock(_component_va_avatar, {
                key: 0,
                color: "#ECF0F1"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(item.owner?.avatar_letter), 1)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(item.owner?.fullName), 1)
        ])
      ]),
      [`item.status`]: _withCtx(({ item }) => [
        _createElementVNode("td", {
          class: _normalizeClass(`tasks-status-td status-${item.status
            ?.toLowerCase()
            .replace(' ', '-')}`)
        }, _toDisplayString(item.status), 3)
      ]),
      [`item.start_date`]: _withCtx(({ item }) => [
        _createElementVNode("p", _hoisted_6, _toDisplayString(item.start_date ? _ctx.formattedDate(item.start_date) : '-'), 1)
      ]),
      [`item.end_date`]: _withCtx(({ item }) => [
        _createElementVNode("p", _hoisted_7, _toDisplayString(item.start_date ? _ctx.formattedDate(item.end_date) : '-'), 1)
      ]),
      [`item.priority`]: _withCtx(({ item }) => [
        (item.priority)
          ? (_openBlock(), _createElementBlock("td", _hoisted_8, [
              _createVNode(_component_VaButton, {
                round: "",
                icon: "flag",
                size: "small",
                color: item.priority?.color
              }, null, 8, ["color"]),
              _createTextVNode(" " + _toDisplayString(item.priority?.name), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      [`item.action`]: _withCtx(({ item }) => [
        _createElementVNode("td", _hoisted_9, [
          _createVNode(_component_va_dropdown, { offset: [0, -50] }, {
            anchor: _withCtx(() => [
              _createElementVNode("img", {
                src: _ctx.MoreVertIcon,
                alt: "MoreVertIcon",
                class: "press-button-2"
              }, null, 8, _hoisted_10)
            ]),
            default: _withCtx(() => [
              (!_ctx.matterTasksSection)
                ? (_openBlock(), _createBlock(_component_VaDropdownContent, { key: 0 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", {
                        class: "table-action-option",
                        onClick: ($event: any) => (_ctx.handleButtonClick(item, 'open-with'))
                      }, " Open with ", 8, _hoisted_11),
                      _createElementVNode("p", {
                        class: "table-action-option",
                        onClick: ($event: any) => (_ctx.handleButtonClick(item, 'request_signature'))
                      }, " Request Signature ", 8, _hoisted_12),
                      _createElementVNode("p", {
                        class: "table-action-option",
                        onClick: ($event: any) => (_ctx.handleButtonClick(item, 'preview'))
                      }, " Preview ", 8, _hoisted_13),
                      _createElementVNode("p", {
                        class: "table-action-option",
                        onClick: ($event: any) => (_ctx.handleButtonClick(item, 'rename'))
                      }, " Rename ", 8, _hoisted_14),
                      _createElementVNode("p", {
                        class: "table-action-option",
                        onClick: ($event: any) => (_ctx.handleButtonClick(item, 'file_info'))
                      }, " File Info ", 8, _hoisted_15),
                      _createElementVNode("p", {
                        class: "table-action-option",
                        onClick: ($event: any) => (_ctx.handleButtonClick(item, 'make_a_copy'))
                      }, " Make A Copy ", 8, _hoisted_16),
                      _createElementVNode("p", {
                        class: "table-action-option",
                        onClick: ($event: any) => (_ctx.handleButtonClick(item, 'access_permissions'))
                      }, " Access Permissions ", 8, _hoisted_17),
                      _createElementVNode("p", {
                        class: "table-action-option",
                        onClick: ($event: any) => (_ctx.handleButtonClick(item, 'share'))
                      }, " Share ", 8, _hoisted_18),
                      _createElementVNode("p", {
                        class: "table-action-option",
                        onClick: ($event: any) => (_ctx.handleButtonClick(item, 'move'))
                      }, " Move ", 8, _hoisted_19),
                      _createElementVNode("p", {
                        class: "table-action-option",
                        onClick: ($event: any) => (_ctx.handleButtonClick(item, 'make_available_online'))
                      }, " Make Available Offline ", 8, _hoisted_20),
                      _createElementVNode("p", {
                        class: "table-action-option",
                        onClick: ($event: any) => (_ctx.handleButtonClick(item, 'delete'))
                      }, " delete ", 8, _hoisted_21)
                    ]),
                    _: 2
                  }, 1024))
                : (_openBlock(), _createBlock(_component_VaDropdownContent, { key: 1 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", {
                        class: "table-action-option",
                        onClick: ($event: any) => (_ctx.handleButtonClick(item, 'remove'))
                      }, " Remove ", 8, _hoisted_22)
                    ]),
                    _: 2
                  }, 1024))
            ]),
            _: 2
          }, 1024)
        ])
      ]),
      _: 2
    }, 1032, ["selection", "loading", "headers", "items", "items-length", "items-per-page", "onUpdate:options"])
  ]))
}