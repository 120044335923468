import {
  MatterTemplateInterface,
  TableColumnInterface,
} from '@/Interfaces/Constants'

import { StagesPresenter } from '../StagesPresenter'
import { mockMatterFelids } from '@/constants/mockMaterTemplate'
import { formFields } from '@/constants/matterTemplates'

export class SectionsPresenter {
  id!: number
  allow_multiple_stages_per_item!: boolean
  class_name!: string
  company_id!: number
  created_at!: string
  name!: string
  section_stages!: StagesPresenter[]
  section_type!: 'contacts' | 'tasks' | 'legal_matters'
  sort_order!: number
  table_columns!: TableColumnInterface[]
  form_columns!: any[]
  updated_at!: string

  constructor(data: SectionsPresenter) {
    Object.assign(this, data)

    if (data.section_stages) {
      this.section_stages = data.section_stages.map(
        (stage) => new StagesPresenter(stage),
      )
    }

    if (data.form_columns) {
      const formColumns = data.form_columns.map((column) => column.columns)
      this.form_columns = formColumns.map((rowFields) => {
        return rowFields.map((field: { name: string | undefined }) => {
          const fieldData = formFields.find((f) => f.name === field.name)

          return {
            ...fieldData,
            ...field,
          }
        })
      })
    }
  }
}
