import { MilestonePresenter } from '@/presenters/MilestonePresenter'
import { apiRequest } from './apiUtils'
import {
  addPutMilestoneFormInterface,
  getMilestonesInterface,
} from '@/Interfaces/Services'

export const getAllMilestones = async ({
  per_page,
  page,
  related_to_type,
  related_to_id,
  status_id,
  keyword,
}: getMilestonesInterface) => {
  const response = await apiRequest('get', '/milestones', null, {
    per_page,
    page,
    related_to_type,
    related_to_id,
    status_id,
    keyword,
  })

  if (!response.success) {
    return response
  }

  const list = response.data?.list
  if (!Array.isArray(list)) {
    return response
  }

  return {
    ...response,
    data: {
      ...response.data,
      list: list.map(
        (item: MilestonePresenter) => new MilestonePresenter(item),
      ),
    },
  }
}

export const getMilestoneById = async (id: string) => {
  const response = await apiRequest('get', `/milestones/${id}`)
  if (response.success) {
    return {
      ...response,
      data: new MilestonePresenter(response.data),
    }
  } else {
    return response
  }
}

export const addMilestone = (body: addPutMilestoneFormInterface) => {
  return apiRequest('post', '/milestones', body)
}

export const updateMilestone = (
  id: number,
  body: addPutMilestoneFormInterface,
) => {
  return apiRequest('put', `/milestones/${id}`, body)
}

export const deleteMilestone = (id: number) => {
  return apiRequest('delete', `/milestones/${id}`)
}

export const generateMilestoneInvoice = (id: number) => {
  return apiRequest('post', `/milestones/${id}/generate_invoice`)
}
