import {
  getOptionFullName,
  getOptionName,
  getOptionTruncateName110,
  truncateText,
  validateInput,
  validateInputEmail,
  validateSelect,
} from '@/lib/utils'
import { CountryPresenter } from '@/presenters/AddressPresenter/CountryPresenter'
import { MatterPresenter } from '@/presenters/matterPresenter'
import { getAllCountries } from '@/services/address'
import { getAllMatters } from '@/services/legalMatter'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'

export default defineComponent({
  name: 'AddHearingForm',
  props: {
    addHearingLoading: {
      type: Boolean,
      default: false,
    },
    isAddHearing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { isValid, validate, reset, resetValidation } = useForm('form')

    return {
      validateInput,
      validateInputEmail,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionTruncateName110,

      isAddHearingBool: this.isAddHearing,
      isValid,
      validate,
      reset,
      resetValidation,
      form: {
        name: '',
        legal_matter_id: '' as number | string,
        assigned_to_id: '' as number | string,
        court_id: '' as number | string,
        court_case_number: '' as string | string,
        judge_id: '' as number | string,
        representing_contact_id: '' as number | string,
        opponent_contact_id: '' as number | string,
        status_id: '' as any,
        jurisdriction_id: '' as number | string,
        region: '' as string | string,
        notes: '' as string,
        date: new Date(),
        lawyer_user_ids: [] as any,
        participant_contact_ids: [] as any,
      },

      countriesLoading: false,
      statesLoading: false,
      contactsLoading: false,
      companyConfigLoading: false,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,

      countries: [] as CountryPresenter[],
      states: [] as CountryPresenter[],

      mattersLoading: false,
      matterPage: 1,
      mattersItemPerPage: 10,
      matterTotalItems: 0,
      matters: [] as MatterPresenter[],

      truncateText,
    }
  },
  watch: {
    isAddHearing: {
      handler(data) {
        this.isAddHearingBool = data
        if (data) {
          this.handleGetContacts()
          this.handleGetAllCountries()
          this.handleGetMembers()
          this.handleGetMatters()
        }
      },
    },
    form: {
      handler(data) {
        console.log({
          form: data,
        })
      },
      deep: true,
    },
  },
  methods: {
    handleToggleAddModal() {
      this.isAddHearingBool = !this.isAddHearingBool
      this.$emit('handleToggleAddModal')
    },
    handleCreateHearing() {
      this.$emit('handleCreateHearing', this.form)
    },
    ///////////////////////////////////////////////////////////////
    async handleGetContacts(search?: string) {
      this.$stores.contacts.handleGetContacts({
        keyword: search,
      })
    },
    handleSearchContacts(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts(search)
      }, 500)
    },
    ///////////////////////////////////////////////////////////////
    async handleGetAllCountries() {
      this.countriesLoading = true
      const response = await getAllCountries()

      if (response.success) {
        this.countries = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.countriesLoading = false
    },
    ///////////////////////////////////////////////////////////////
    async handleGetMembers(search?: string) {
      this.$stores.usersData.handleGetMembers({
        keyword: search,
      })
    },
    handleSearchMembers(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMembers(search)
      }, 500)
    },
    ///////////////////////////////////////////////////////////////
    async handleGetMatters(search?: string) {
      this.mattersLoading = true

      const response = await getAllMatters({
        per_page: this.mattersItemPerPage,
        page: this.matterPage,
        keyword: search,
      })

      if (response.success) {
        this.matters = response.data?.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.mattersLoading = false
    },

    handleSearchMatters(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetMatters(search)
      }, 500)
    },
  },
})
