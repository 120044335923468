import { CompanyUserPresenter } from '../CompanyUserPresenter';

export class AnnouncementPresenter {
  id!: number;
  title!: string;
  description!: string;
  dateAndTimeOfDispatching!: string;
  date!: string;
  requireConfirmation!: boolean;
  recipients!: CompanyUserPresenter[];

  constructor(data: AnnouncementPresenter) {
    Object.assign(this, data);

    if (data.recipients) {
      this.recipients = this.recipients.map(
        (recipient) => new CompanyUserPresenter(recipient)
      );
    }
  }
}
