import { CompanyConfigurationPresenter } from '../companyConfigPresenters'
import { SectionStageItem } from '../interface'
import { TeamMemberPresenter } from '../teamMember'

export class TaskPresenter {
  id!: number
  assigned_to!: TeamMemberPresenter | null
  assigned_to_id!: number | null
  billable!: boolean | null
  budget!: number | null
  class_name!: string
  company_id!: number
  created_at!: string
  creator!: TeamMemberPresenter
  creator_id!: number
  description!: string
  due_date!: string
  end_date!: string
  guid!: string
  hourly_rate!: number | null
  location!: string | null
  name!: string
  notes!: string | null
  owner!: TeamMemberPresenter
  owner_id!: number
  parent_id!: number | null
  priority!: CompanyConfigurationPresenter
  section_stage_items!: SectionStageItem[]
  start_date!: string
  sub_tasks!: TaskPresenter[]
  task_type!: string
  updated_at!: string
  prependIcon?: string
  path_to?: string

  constructor(data: TaskPresenter) {
    Object.assign(this, data)

    if (data.assigned_to) {
      this.assigned_to = new TeamMemberPresenter(data.assigned_to)
    }

    if (data.creator) {
      this.creator = new TeamMemberPresenter(data.creator)
    }

    if (data.owner) {
      this.owner = new TeamMemberPresenter(data.owner)
    }

    if (data.sub_tasks) {
      this.sub_tasks = data.sub_tasks.map(
        (item: TaskPresenter) => new TaskPresenter(item),
      )
    }

    this.path_to = `/tasks/${this.id}`
    this.prependIcon = 'assignment'
  }
}
