<template>
  <ActionsBar
    second-add-button="Assign to Matter"
    @secondButtonAction="toggleAddMatter"
  />

  <AddLinkModal
    :is-add-item="isAddMatter"
    :items-list-loading="mattersLoading"
    :items-list="mattersModal"
    :add-link-loading="linkMatterLoading"
    @handleToggleAddLink="toggleAddMatter"
    @handleSearch="searchMatterData"
    @handleLinkItem="handleLinkMatter"
  />

  <MatterTable
    :loading="matterTableLoading"
    :items-per-page="mattersItemPerPage"
    :total-items="matterTotalItems"
    :matters="matters"
    :un-link="true"
    @selectedRows="getSelectedMatterRows"
    @loadMore="handlePaginationMatters"
    @unLinkMatter="handleUnlinkMatter"
  />
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
