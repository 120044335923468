import { globalSearchParamsInterface } from '@/Interfaces/Services'
import { apiRequest } from './apiUtils'
import { GlobalSearchPresenter } from '@/presenters/GlobalSearchPresenter'

export const globalSearch = async ({
  keyword,
  number_of_records,

  hearings_enabled,
  hearings_order_by_column,
  hearings_order_by_direction,

  legal_matters_enabled,
  legal_matters_order_by_column,
  legal_matters_order_by_direction,

  individual_contacts_enabled,
  individual_contacts_order_by_column,
  individual_contacts_order_by_direction,

  organization_contacts_enabled,
  organization_contacts_order_by_column,
  organization_contacts_order_by_direction,

  tasks_enabled,
  tasks_order_by_column,
  tasks_order_by_direction,

  calendar_items_enabled,
  calendar_items_order_by_column,
  calendar_items_order_by_direction,
}: globalSearchParamsInterface) => {
  const response = await apiRequest('get', '/search', null, {
    keyword,
    number_of_records,

    hearings_enabled,
    hearings_order_by_column,
    hearings_order_by_direction,

    legal_matters_enabled,
    legal_matters_order_by_column,
    legal_matters_order_by_direction,

    individual_contacts_enabled,
    individual_contacts_order_by_column,
    individual_contacts_order_by_direction,

    organization_contacts_enabled,
    organization_contacts_order_by_column,
    organization_contacts_order_by_direction,

    tasks_enabled,
    tasks_order_by_column,
    tasks_order_by_direction,

    calendar_items_enabled,
    calendar_items_order_by_column,
    calendar_items_order_by_direction,
  })

  if (response.success) {
    return {
      ...response,
      data: new GlobalSearchPresenter(response.data),
    }
  } else {
    return response
  }
}
