export class BankAccountPresenter {
  id?: number | null
  account_number!: string | null
  bank_name!: string | null
  branch_address!: string | null
  branch_country!: string | null
  branch_name!: string | null
  bsb_number!: string | null
  clabe!: string | null
  extra_notes!: string | null
  iban!: string | null
  ifcs_code!: string | null
  routing_number!: string | null
  short_code!: string | null
  swift_or_bic!: string | null
  transit_number!: string | null

  constructor(data: BankAccountPresenter) {
    Object.assign(this, data)
  }
}
