<template>
  <div :class="{ 'dir-rtl': isRtl }">
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
  components: {},
  computed: {
    isRtl() {
      return this.$i18n.locale === 'ar'
    },
  },
})
</script>

<style lang="scss">
.dir-rtl {
  direction: rtl !important;
}
</style>
