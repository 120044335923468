<template>
  <VaModal
    v-model="isUpdateLayoutFormBool"
    size="large"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
    @update:modelValue="handleToggleUpdateLayoutModal"
  >
    <template #header>
      <h1 class="update-layout-modal-header">
        Customize
        <VaIcon
          name="close"
          size="30px"
          class="icon"
          @click="handleToggleUpdateLayoutModal"
        />
      </h1>
    </template>

    <div class="body">
      <draggable
        v-model="stages"
        group="columns"
        class="columns"
        item-key="id"
        @end="onDragEnd"
      >
        <template #item="{ element, index }">
          <div :key="element.id" class="body-column column-header-container">
            <div
              class="column-header"
              :style="{
                borderBottom: '0.5rem solid ' + (element?.color || 'red'),
              }"
            >
              <div class="delete-icon-container">
                <v-tooltip
                  v-if="!deleteStates[element.id]"
                  text="Delete Stage"
                  location="top"
                >
                  <template #activator="{ props }">
                    <div class="delete-icon" v-bind="props">
                      <VaIcon
                        class="press-button"
                        name="remove"
                        color="primary"
                        @click="toggleDeleteState(element.id)"
                      />
                    </div>
                  </template>
                </v-tooltip>

                <v-tooltip
                  v-if="deleteStates[element.id]"
                  text="Confirm Delete"
                  location="top"
                >
                  <template #activator="{ props }">
                    <div v-bind="props" class="delete-icon">
                      <VaIcon
                        :name="
                          isDeleteLayoutLoading ? 'loop' : 'delete_forever'
                        "
                        class="press-button"
                        :spin="isDeleteLayoutLoading"
                        @click="handleDeleteLayout(element.id, index)"
                      />
                    </div>
                  </template>
                </v-tooltip>
              </div>

              <VaIcon
                name="add"
                size="20px"
                class="icon-border press-button-2"
                @click="addNewStage(index, 'left')"
              />
              <VaIcon
                name="drag_indicator"
                size="30px"
                class="press-button drag-handle"
              />
              <p>
                {{ element.name }}
              </p>
              <VaIcon
                name="add"
                size="20px"
                class="icon-border press-button-2"
                @click="addNewStage(index, 'right')"
              />
            </div>
            <div class="column-body">
              <VaInput
                v-model="element.name"
                placeholder="Stage Name"
                label="Stage Name"
                background="#ECF0F1"
              />

              <h1>Color :</h1>
              <div class="color-palette">
                <div
                  v-for="(color, colorIndex) in palette"
                  :key="colorIndex"
                  class="color-circle"
                  :class="{ selected: element.color === color }"
                  :style="{
                    backgroundColor: color,
                    border:
                      element.color === color ? '2px solid black' : 'none',
                  }"
                  @click="selectColor(element, color)"
                />
              </div>

              <VaButton
                preset="primary"
                class="ok-button"
                :loading="
                  updateLayoutLoading && currentLoadingStageId === element.id
                "
                @click="handleUpdateStageData(element)"
              >
                Update
              </VaButton>
            </div>
          </div>
        </template>
      </draggable>
    </div>

    <template #footer>
      <div class="update-layout-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleReset"
        >
          Reset
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
