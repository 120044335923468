<template>
  <div class="global-config">
    <h1>Global Configurations</h1>

    <VaButton
      class="options"
      preset="secondary"
      border-color="primary"
      @click="toggleCurrency"
    >
      Currency Options
    </VaButton>

    <VaDivider inset />

    <CurrencySettingModal
      :is-currency="isCurrency"
      :currencies="currencies"
      :is-currency-loading="isCurrencyLoading"
      :is-add-currency-loading="isAddCurrencyLoading"
      :is-update-currency-loading="isUpdateCurrencyLoading"
      :new-currency="newCurrency"
      :show-confirm-currency-id="showConfirmCurrencyId"
      :loading-currency-id="loadingCurrencyId"
      @toggleCurrency="toggleCurrency"
      @handleAddCurrency="handleAddCurrency"
      @toggleConfirm="toggleConfirm"
      @confirmDelete="confirmDelete"
      @cancelDelete="cancelDelete"
      @handleUpdateCurrency="handleUpdateCurrency"
    />
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
