<template>
  <v-card max-height="25rem" class="option-card">
    <div class="cal-link-body">
      <h1 class="title">Recent Calendar Events</h1>
      <div class="cal-list">
        <div
          v-for="(record, index) in calendarItems"
          :key="index"
          class="event-card"
          @click="handleLinkTo(record)"
        >
          <div class="event-content">
            <p class="record-title">
              <VaIcon name="calendar_today" size="15px" />
              {{
                record?.calendar_item_linked_items[0]?.linked_item?.name ||
                record?.calendar_item_linked_items[0]?.linked_item?.fullName
              }}
            </p>
            <p v-if="record.description" class="record-details">
              <VaIcon name="subject" size="15px" />
              {{ record?.description }}
            </p>
            <p v-else class="record-details">
              <VaIcon name="info_outline" size="15px" />
              No additional details available
            </p>
            <p class="record-time">
              {{ formatDateRange(record?.start_time, record?.end_time) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
