import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d9a97f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "shared-header-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VaButton = _resolveComponent("VaButton")!
  const _component_VaIcon = _resolveComponent("VaIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.backButton)
      ? (_openBlock(), _createBlock(_component_VaButton, {
          key: 0,
          class: "back-button",
          icon: "arrow_back",
          preset: "secondary",
          "border-color": "primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Back ")
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "title",
      style: _normalizeStyle({ width: _ctx.titleWidth })
    }, [
      (_ctx.flag)
        ? (_openBlock(), _createBlock(_component_VaIcon, {
            key: 0,
            name: _ctx.flag,
            size: "large",
            color: "primary"
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1)
    ], 4),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}