<template>
  <div class="chat-panel" :class="{ 'chat-panel-collapsed': isCollapsed }">
    <div class="chat-panel-container" :class="{ hide: isCollapsed }">
      <div class="header">
        <div class="header-left">
          <VaIcon
            name="chat"
            class="press-button collapse-right-icon"
            @click="isCollapsed = !isCollapsed"
          />

          <h2>Chats</h2>
        </div>

        <VaButton preset="secondary" icon="add" border-color="primary" />
      </div>

      <div class="search-chats">
        <VaInput
          size="large"
          type="search"
          placeholder="Search..."
          preset="bordered"
        >
          <template #appendInner>
            <VaIcon name="search" color="primary" size="1.5rem" />
          </template>
        </VaInput>
      </div>

      <TabsBar
        :tabs="tabs"
        :full-tab="true"
        gap-size="1rem"
        :active-tab-id="activeTabId"
        class="groups-chat-taskbar"
        @changeTab="changeTab"
      />

      <div class="chat-list-container">
        <div v-for="n in 19" :key="n" class="chatting-person">
          <img
            :src="'https://i.pravatar.cc/300?img=' + n"
            alt="users"
            class="avatar"
          />
          <h2>Member Name {{ n }}</h2>
          <v-badge color="error" :content="n" inline class="person-badge" />
        </div>
      </div>
    </div>

    <div v-if="isCollapsed" class="chat-panel-container-collapsed">
      <VaIcon
        name="chat_outline"
        class="press-button collapse-left-icon"
        @click="isCollapsed = !isCollapsed"
      />

      <v-badge v-for="n in 9" :key="n" color="error" dot>
        <img
          :src="'https://i.pravatar.cc/300?img=' + n"
          alt="users"
          class="avatar"
        />
      </v-badge>
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>

<style>
.chat-panel {
  min-height: 88vh;
  /* border: solid red; */
}
.search-chats input {
  padding: 0rem 0.7rem !important;
}

.search-chats .va-input-wrapper {
  width: 100% !important;
  padding: 0rem 0.3rem !important;
}
</style>
