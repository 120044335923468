<template>
  <div class="task-page">
    <SharedHeader :back-button="true" :title="form?.name" flag="flag">
      <div class="sub-SharedHeader">
        <div class="sub-SharedHeader-left" />
        <div class="sub-SharedHeader-right">
          <TimeRange
            :date="{ start: form?.start_date, end: form?.end_date }"
            @update:date="handleSelectedDate"
          />

          <DatePickerModal
            :date="form.due_date"
            @update:date="handleSelectDueDate"
          />

          <ForItemTimeRecordModal
            :start-record-loading="startRecordLoading"
            :loading-states="loadingStates"
            :delete-record-loading="deleteRecordLoading"
            :update-record-loading="updateRecordLoading"
            :manual-form-loading="manualFormLoading"
            :calendar-items-range-form-loading="calendarItemsRangeFormLoading"
            @handleAddTimeRange="handleAddTimeRange"
            @handleStartEndTime="handleStartEndTime"
            @handleStartEndRecord="handleStartEndRecord"
            @handleDeleteRecord="handleDeleteRecord"
            @handleUpdateRecord="handleUpdateRecord"
            @handleCreateManual="handleCreateManual"
          />
        </div>
      </div>
    </SharedHeader>
    <div class="info-container">
      <h2 v-if="stages.length > 0" class="info-container-status">
        Status :
        <VaMenu>
          <template #anchor>
            <VaButton
              icon="arrow_drop_down"
              round
              size="small"
              :color="form?.section_stage_items[0]?.section_stage.color"
            >
              {{ form?.section_stage_items[0]?.section_stage.name }}
            </VaButton>
          </template>
          <VaMenuItem
            v-for="(stage, index) in stages"
            :key="index"
            @click="handleStageMenuItemClick(stage)"
          >
            {{ stage.name }}
          </VaMenuItem>
        </VaMenu>
      </h2>
      <h2 v-else-if="form.parent_id">
        <VaButton
          icon="arrow_back"
          round
          @click="handleBackToParent(form?.parent_id)"
        >
          Parent
        </VaButton>
      </h2>
      <h2 v-else />

      <h2 class="info-container-priority">
        Priority :
        <VaMenu>
          <template #anchor>
            <VaButton
              icon="arrow_drop_down"
              round
              size="small"
              :color="form?.priority?.color"
            >
              {{ form?.priority?.name }}
            </VaButton>
          </template>
          <VaMenuItem
            v-for="(flag, index) in $stores.generalData.companyConfigs
              .taskPriorityOptions"
            :key="index"
            @click="handleFlagMenuItemClick(flag)"
          >
            <VaIcon name="flag" :color="flag?.color" />
            {{ flag.name }}
          </VaMenuItem>
        </VaMenu>
      </h2>
    </div>

    <div class="taskbar-scrollable-container">
      <TabsBar
        :tabs="tabs"
        :active-tab-id="activeTabId"
        @changeTab="changeTab"
      />
    </div>

    <div class="content-container">
      <!-- everything inside here is scrollable; outside this box is sticky -->

      <!-- <div class="test" v-if="activeTab === 'TASK INFO'"> -->
      <div v-if="activeTab === 'TASK INFO'" class="task-info">
        <VaForm ref="form">
          <div class="OwnerContainer">
            <div class="owner">
              <h2>Owner</h2>
              <span class="owner-container">
                <va-avatar
                  color="#ECF0F1"
                  :src="form?.owner?.avatar?.thumbnail_url"
                  :alt="form?.owner?.fullName"
                  style="border-radius: 50%; width: 3rem; height: 3rem"
                >
                  <span
                    v-if="!form?.owner?.avatar?.thumbnail_url"
                    style="color: black"
                  >
                    {{ form.owner?.avatar_letter }}
                  </span>
                </va-avatar>

                <div>
                  <h2>
                    {{ form?.owner?.fullName || 'No Owner' }}
                    <VaIcon
                      v-if="form?.owner?.first_name"
                      name="cancel"
                      @click="handleRemoveOwner"
                    />
                  </h2>
                  <OwnerAssignTo
                    :list="$stores.usersData.teamMembers"
                    @handleAssign="handleChooseOwner"
                  />
                </div>
              </span>
            </div>
            <div class="owner">
              <h2>Assign to</h2>
              <span class="owner-container">
                <va-avatar
                  color="#ECF0F1"
                  :src="form?.assigned_to?.avatar?.thumbnail_url"
                  :alt="form?.assigned_to?.fullName"
                  style="border-radius: 50%; width: 3rem; height: 3rem"
                >
                  <span
                    v-if="!form?.assigned_to?.avatar?.thumbnail_url"
                    style="color: black"
                  >
                    {{ form.assigned_to?.avatar_letter }}
                  </span>
                </va-avatar>

                <div>
                  <h2>
                    {{ form?.assigned_to?.fullName || 'No Assigned' }}
                    <VaIcon
                      v-if="form?.assigned_to?.first_name"
                      name="cancel"
                      @click="handleRemoveAssign"
                    />
                  </h2>
                  <OwnerAssignTo
                    :list="$stores.usersData.teamMembers"
                    @handleAssign="handleChooseAssign"
                  />
                </div>
              </span>
            </div>
          </div>
          <div class="taskContainer">
            <div class="task-name-container">
              <h1>Task Title</h1>
              <VaInput
                v-model="form.name"
                placeholder="Title"
                class="task-name"
                preset="bordered"
                required-mark
                :rules="[validateInput]"
              />
            </div>
            <div class="add-label-container">
              <div class="chips-container">
                <span v-for="(label, index) in labels" :key="index">
                  <CustomChip
                    closeable
                    :label="label?.name"
                    :color="label?.color"
                    @close="handleDeleteLabel(label.id)"
                  />
                </span>
              </div>
              <v-menu
                v-model="isLabelModal"
                :close-on-content-click="false"
                location="right top"
              >
                <template #activator="{ props }">
                  <VaButton
                    icon-right="add"
                    preset="secondary"
                    border-color="primary"
                    icon-color="#812E9E"
                    v-bind="props"
                  >
                    Add Label
                  </VaButton>
                </template>

                <v-card width="300" class="pa-4">
                  <v-text-field
                    v-model="labelInputValue"
                    placeholder="Add Label"
                  />

                  <div class="color-palette">
                    <VaColorPalette
                      v-model="selectedLabelColor"
                      :palette="palette"
                    />
                  </div>

                  <VaButton
                    icon-right="add"
                    preset="secondary"
                    border-color="primary"
                    icon-color="#812E9E"
                    @click="handleAddLabel()"
                  >
                    Add
                  </VaButton>
                </v-card>
              </v-menu>
            </div>

            <div class="description-container">
              <h1>Description</h1>
              <VaTextarea
                v-model="form.description"
                placeholder="Add description..."
                counter
                class="description"
                autosize
                max-rows="6"
                background="#ECF0F1"
              />
            </div>
          </div>
        </VaForm>

        <VaForm ref="subTaskForm" class="sub-task-container">
          <h1>Subtask (Optional)</h1>
          <div v-for="(task, index) in subTasks" :key="index" class="sub-tasks">
            <SubTask
              :id="task.id"
              :text="task.name"
              :date-range="{ start: task.start_date, end: task.end_date }"
            />
          </div>
          <VaInput
            v-if="showAddSubtaskInput"
            v-model="subTaskForm.name"
            background="#ECF0F1"
            placeholder="Add Subtask"
            class="sub-task"
            label="Subtask"
            :rules="[validateInput]"
            @keydown.enter="handleKeydownAddTask"
          >
            <template #appendInner>
              <span class="sub-task-buttons-container">
                <TimeRange
                  :date="{
                    start: subTaskForm.start_date,
                    end: subTaskForm.end_date,
                  }"
                  shape="icon"
                  @update:date="handleSelectedSubDate"
                />
                <VaIcon
                  name="check"
                  size="small"
                  color="primary"
                  @click="handleKeydownAddTask"
                />
                <VaIcon
                  name="close"
                  size="small"
                  color="primary"
                  @click="showAddSubtaskInput = false"
                />
              </span>
            </template>
          </VaInput>
          <VaButton
            icon="add"
            preset="secondary"
            border-color="primary"
            icon-color="#812E9E"
            @click="showAddSubtaskInput = !showAddSubtaskInput"
          >
            Add a subtask
          </VaButton>
        </VaForm>

        <div class="action-buttons">
          <VaButton
            preset="secondary"
            border-color="primary"
            @click="handleBack"
          >
            Back
          </VaButton>

          <VaButton
            preset="primary"
            :loading="loadingUpdate"
            @click="validate() && handleUpdate()"
          >
            Update
          </VaButton>
        </div>
      </div>

      <div v-else-if="activeTab === 'LEGAL MATTERS'">
        <TaskLegalMattersSection />
      </div>

      <div v-else-if="activeTab === 'TIME LOG'">
        <DetailsTimeLogs
          :time-log="timeLogs"
          :loading="timeLogTableLoading"
          :items-per-page="timeLogsItemPerPage"
          :total-items="timeLogTotalItems"
          :update-time-log-loading="updateRecordLoading"
          :delete-time-log-loading="deleteRecordLoading"
          @loadMore="handlePaginationTimeLog"
          @handleUpdateTimeLog="handleUpdateRecord"
          @handleDeleteTimeLog="handleDeleteRecord"
        />
      </div>

      <div v-else class="coming-soon-container">
        <ComingSoon />
      </div>

      <!-- <div class="logs">
          <ChatActivity />

        </div> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style scoped lang="scss" src="./styles.scss"></style>
