<template>
  <div class="hearing-table-container">
    <v-data-table-server
      v-model:selection="selectedRows"
      :loading="loading"
      :headers="headers"
      :items="hearings"
      show-select
      class="custom-table"
      :items-length="localTotalItems"
      :items-per-page="itemsPerPage"
      :items-per-page-options="[10, 25, 50]"
      @update:options="handleLoadMore"
    >
      <template #[`item.courtDate`]="{ item }">
        <td>
          {{ formatDate(item.date) }}
        </td>
      </template>

      <template #[`item.name`]="{ item }">
        <router-link :to="`/hearings/${item?.id}`">
          <td class="matters-client-td">
            {{ item?.name }}
          </td>
        </router-link>
      </template>

      <template #[`item.assignedTo`]="{ item }">
        <td class="hearing-assignedTo-td">
          <va-avatar
            v-if="item.assigned_to?.client_avatar_letter"
            color="#ECF0F1"
          >
            <span>{{ item.assigned_to?.client_avatar_letter }}</span>
          </va-avatar>
          {{ item.assigned_to?.fullName }}
        </td>
      </template>

      <template #[`item.matter`]="{ item }">
        <router-link :to="`/legal-matters/${item?.legal_matter?.id}`">
          <td class="matters-client-td">
            {{ item?.legal_matter?.name }}
          </td>
        </router-link>
      </template>

      <template #[`item.internalMatterId`]="{ item }">
        <router-link :to="`/legal-matters/${item?.legal_matter?.id}`">
          <td class="matters-client-td">
            {{ item?.legal_matter?.id }}
          </td>
        </router-link>
      </template>

      <template #[`item.client`]="{ item }">
        <td class="hearing-client-td">
          <va-avatar
            v-if="item.representing_contact?.client_avatar_letter"
            color="#ECF0F1"
          >
            <span>{{ item.representing_contact?.client_avatar_letter }}</span>
          </va-avatar>
          {{ item.representing_contact?.fullName }}
        </td>
      </template>

      <template #[`item.opponentName`]="{ item }">
        <td class="hearing-client-td">
          <va-avatar
            v-if="item.opponent_contact?.client_avatar_letter"
            color="#ECF0F1"
          >
            <span>{{ item.opponent_contact?.client_avatar_letter }}</span>
          </va-avatar>
          {{ item.opponent_contact?.fullName }}
        </td>
      </template>

      <template #[`item.judgeName`]="{ item }">
        <td class="hearing-client-td">
          <va-avatar v-if="item.judge?.client_avatar_letter" color="#ECF0F1">
            <span>{{ item.judge?.client_avatar_letter }}</span>
          </va-avatar>
          {{ item.judge?.fullName }}
        </td>
      </template>

      <template #[`item.jurisdiction`]="{ item }">
        <td>
          {{ item.jurisdriction?.name }}
        </td>
      </template>

      <template #[`item.court`]="{ item }">
        <td>
          {{ item.court?.name }}
        </td>
      </template>

      <!-- <template #[`item.hearing_users`]="{ item }">
        <td
          v-for="user in item.hearing_users_details"
          :key="user?.id"
          class="hearing-attendance-td"
        >
          <va-avatar v-if="user?.avatar_letter" color="#ECF0F1">
            <span>{{ user?.avatar_letter }}</span>
          </va-avatar>
          {{ user?.full_name }}
        </td>
      </template> -->

      <template #[`item.hearing_users`]="{ item }">
        <td class="hearings-hearing_users-td">
          <va-avatar v-if="item.hearing_users_details[0]" color="#ECF0F1">
            <span>{{ item.hearing_users_details[0]?.avatar_letter }}</span>
          </va-avatar>
          {{ item.hearing_users_details[0]?.full_name }}

          <span v-if="item.hearing_users_details.length > 1">
            <VaPopover placement="top" color="#F5F0F3">
              <p>+{{ item.hearing_users_details.length - 1 }} others</p>
              <template #body>
                <span
                  v-for="(person, index) in item.hearing_users_details"
                  :key="index"
                  class="hearings-hearing_users-td"
                >
                  <v-avatar color="#7F325E" size="32">
                    <span class="white--text">{{ person.avatar_letter }}</span>
                  </v-avatar>

                  {{ person.full_name }}
                </span>
              </template>
            </VaPopover>
          </span>
        </td>
      </template>

      <template #[`item.status`]="{ item }">
        <td
          class="hearings-status-td"
          :style="{ backgroundColor: item?.status?.color }"
        >
          {{ item.status?.name }}
        </td>
      </template>

      <template #[`item.action`]="{ item }">
        <td class="table-action-td">
          <va-dropdown :offset="[0, -70]" close-on-content-click>
            <template #anchor>
              <img
                :src="MoreVertIcon"
                alt="MoreVertIcon"
                class="press-button-2"
              />
            </template>
            <va-dropdown-content>
              <p class="table-action-option" @click="viewProfile(item)">
                <va-icon name="person" /> View Hearing
              </p>
            </va-dropdown-content>
          </va-dropdown>
        </td>
      </template>
    </v-data-table-server>
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" />
