import { MatterPresenter } from '@/presenters/matterPresenter'
import { StagesPresenter } from '@/presenters/StagesPresenter'
import { defineStore } from 'pinia'

export const useMatterStore = defineStore({
  id: 'Matter',
  state: () => ({
    stages: [] as StagesPresenter[],
    matters: [] as MatterPresenter[],
    mattersLoading: false,
    sectionStages: [] as StagesPresenter[],
  }),
  actions: {},
})
