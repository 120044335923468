import { defineComponent } from 'vue'

const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'NotificationModal',
  data() {
    return {
      MoreVertIcon,

      showNotifications: false,
      notificationNumber: 12,

      value: [true, false, false, false, false],
      collapses: [
        {
          title: 'Client-related',
          data: [
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
          ],
        },

        {
          title: 'Internal',
          data: [
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
          ],
        },
        {
          title: 'Billing and Financial',
          data: [
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
          ],
        },
        {
          title: 'Calendar and Deadline',
          data: [
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
          ],
        },
        {
          title: 'General',
          data: [
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
          ],
        },
      ],
    }
  },
  methods: {
    handleCloseNotification() {
      this.showNotifications = !this.showNotifications
    },
  },
})
