import {
  truncateText,
  getOptionFullName,
  getOptionName,
  getOptionTruncateName50,
  validateInput,
  validateInputEmail,
  validateSelect,
} from '@/lib/utils'
import { CountryPresenter } from '@/presenters/AddressPresenter/CountryPresenter'
import { getAllCities, getAllCountries, getAllStates } from '@/services/address'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'

const UploadLogoIcon = new URL(
  '@/assets/icons/upload-logo-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'AddOrganizationForm',
  props: {
    addOrganizationLoading: {
      type: Boolean,
      default: false,
    },
    isAddOrganizationForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { isValid, validate, reset, resetValidation } = useForm('form')
    return {
      validateInput,
      validateInputEmail,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionTruncateName50,

      isAddOrganizationFormBool: this.isAddOrganizationForm,
      isValid,
      validate,
      reset,
      resetValidation,
      files: [],
      kyc_documents: [],
      UploadLogoIcon,
      form: {
        link_to_organization: '',
        organization_type: null,
        organization_name: '',
        relationship_owner_id: '',
        organization_owner: '',
        organization_industry: '',
        organization_tax_identification_number: '',
        organization_registration_number: '',
        organization_date_of_foundation: new Date(),
        designated_point_of_contact_id: '',
        website_url: '',
        organization_jurisdiction_of_incorporation: '',
        linkedin_url: '',
        assigned_to_id: '',
        referred_by: '',
        about: '',
        province_state: '',
        country: '',
        address_tag: '',

        phone_numbers: [{ label: 'Primary Phone', phone_number: '' }],
        email_addresses: [{ label: 'Primary Email', email_address: '' }],
        contact_addresses_attributes: [
          {
            city_id: '',
            floor: '',
            zip_code: '',
            street_name: '',
            building_name: '',
            phone_number: '',
            google_maps_url: '',
          },
        ],
        contact_bank_accounts_attributes: [
          {
            iban: '',
            swift_or_bic: '',
            account_number: '',
            bank_name: '',
            branch_name: '',
            branch_country: '',
            routing_number: '',
            short_code: '',
            bsb_number: '',
            fcs_code: '',
            transit_number: '',
            clabe: '',
            extra_notes: '',
          },
        ],
        kyc_completed: '',
        kyc_notes: '',
        notes: '',
        extra_notes: '',
      },
      countriesLoading: false,
      statesLoading: false,
      citiesLoading: false,
      addContactLoading: false,
      isAddressAccordion: false,
      isBankAccordion: false,
      countries: [] as CountryPresenter[],
      states: [] as CountryPresenter[],
      cities: [] as CountryPresenter[],

      organizationTypeOptions: [
        'Sole Proprietorship',
        'Individual Intrapreneur',
        'Freelancer',
        'Partnership',
        'General Partnership',
        'Limited Partnership',
      ],

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,
      truncateText,
    }
  },
  watch: {
    isAddOrganizationForm: {
      handler(data) {
        this.isAddOrganizationFormBool = data
        if (data) {
          this.handleGetMembers()
          this.handleGetAllCountries()
          this.handleGetOrganizations()
        }
      },
    },
  },
  methods: {
    toggleAddOrganizationForm() {
      this.isAddOrganizationFormBool = !this.isAddOrganizationFormBool
      this.$emit('toggleAddOrganizationForm')
    },
    async handleCreateOrganization() {
      this.$emit('handleCreateOrganization', this.form)
    },
    async handleGetMembers(search?: string) {
      this.$stores.usersData.handleGetMembers({
        keyword: search,
      })
    },
    async handleGetAllCountries() {
      this.countriesLoading = true
      const response = await getAllCountries()

      if (response.success) {
        this.countries = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.countriesLoading = false
    },
    async handleGetState(value: any) {
      this.statesLoading = true
      const response = await getAllStates(value)

      if (response.success) {
        this.states = response.data.list
      } else {
        // this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.statesLoading = false
    },
    async handleGetCities(value: any) {
      this.citiesLoading = true
      const response = await getAllCities(value)

      if (response.success) {
        this.cities = response.data.list
      } else {
        // this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.citiesLoading = false
    },
    /////////////////////////////////////////////////////////////////////
    async handleGetOrganizations(search?: string) {
      this.$stores.contacts.handleGetOrganizations({
        keyword: search,
      })
    },

    handleSearchOrganizations(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetOrganizations(search)
      }, 500)
    },

    handleSearchMembers(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMembers(search)
      }, 500)
    },
  },
})
