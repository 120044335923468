<template>
  <div class="global-config">
    <h1>Global Configurations</h1>

    <VaButton
      class="options"
      preset="secondary"
      border-color="primary"
      @click="
        toggleGlobalConfig({
          title: 'Types Options',
          option: 'calendarTypesOption',
          configuration_type: 'calendar_item_type',
        })
      "
    >
      Types Options
    </VaButton>

    <VaButton
      class="options"
      preset="secondary"
      border-color="primary"
      @click="handleToggleTimeZone"
    >
      Time Zones
    </VaButton>

    <VaDivider inset />

    <GlobalConfigModal
      v-model:isGlobalConfig="isGlobalConfig"
      :selected-option="selectedOption"
      :global-config="globalConfig"
      :show-confirm-config-id="showConfirmConfigId"
      :loading-config-id="loadingConfigId"
      :new-option-form="newOptionForm"
      :is-add-global-config-loading="isAddGlobalConfigLoading"
      :is-update-global-config-loading="isUpdateGlobalConfigLoading"
      @toggleGlobalConfig="toggleGlobalConfig"
      @handleSearchGlobalConfig="handleSearchGlobalConfig"
      @toggleConfirm="toggleConfirm"
      @confirmDelete="confirmDelete"
      @cancelDelete="cancelDelete"
      @handleAddGlobalConfig="handleAddGlobalConfig"
      @handleUpdateGlobalConfig="handleUpdateGlobalConfig"
    />

    <TimeZoneModal
      :is-time-zone="isTimeZone"
      @handleToggleTimeZone="handleToggleTimeZone"
      @handleSaveSelectedZones="handleSaveSelectedZones"
    />
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
