import { paginationInterface } from '@/Interfaces/Services'
import { formatDate } from '@/lib/utils'
import { defineComponent } from 'vue'
const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'HearingsTable',
  props: {
    hearings: {
      type: [] as any,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    totalItems: {
      type: Number,
      default: 10,
    },
  },
  emits: ['selectedRows', 'loadMore'],
  data() {
    console.log({ hearings: this.hearings })
    return {
      formatDate,
      localTotalItems: this.totalItems,
      selectedRows: [],
      MoreVertIcon,
      currentPage: 1,
      currentSortBy: [] as any,
      currentItemsPerPage: this.itemsPerPage,
      headers: [
        {
          title: 'COURT DATE',
          align: 'start',
          key: 'courtDate',
          sortable: false,
        },
        { title: 'NAME', align: 'starts', key: 'name', sortable: false },
        {
          title: 'ASSIGNED TO',
          align: 'starts',
          key: 'assignedTo',
          sortable: false,
        },
        { title: 'MATTER', align: 'starts', key: 'matter', sortable: false },
        {
          title: 'INTERNAL MATTER ID',
          align: 'starts',
          key: 'internalMatterId',
          sortable: false,
        },
        { title: 'CLIENT', align: 'starts', key: 'client', sortable: false },
        {
          title: 'OPPONENT NAME',
          align: 'starts',
          key: 'opponentName',
          sortable: false,
        },
        {
          title: 'COURT ID',
          align: 'starts',
          key: 'court_case_number',
          sortable: false,
        },
        {
          title: 'COURT',
          align: 'starts',
          key: 'court',
          sortable: false,
        },
        {
          title: 'JUDGE NAME',
          align: 'starts',
          key: 'judgeName',
          sortable: false,
        },
        {
          title: 'JURISDICTION',
          align: 'starts',
          key: 'jurisdiction',
          sortable: false,
        },
        { title: 'REGION', align: 'starts', key: 'region', sortable: false },
        {
          title: 'ATTENDANCE',
          align: 'starts',
          key: 'hearing_users',
          sortable: false,
        },
        { title: 'Note', align: 'starts', key: 'notes', sortable: false },
        { title: 'STATUS', align: 'starts', key: 'status', sortable: false },
        { title: '', align: 'center', key: 'action', sortable: false },
      ],
    }
  },
  watch: {
    selectedRows() {
      this.$emit('selectedRows', this.selectedRows)
    },
    totalItems(newValue) {
      this.localTotalItems = newValue
    },
  },
  methods: {
    handleButtonClick(item: any) {
      // Handle the button click for the specific item (row)
      console.log('Button clicked for:', item)
    },
    viewProfile(item: { id: any }) {
      this.$router.push(`/hearings/${item?.id}`)
    },
    //////////////////////////////////////////////////////////////
    handleLoadMore({ page, itemsPerPage, sortBy }: paginationInterface) {
      const { currentPage, currentItemsPerPage, currentSortBy } = this

      // Check if there are any changes in pagination or sorting
      const hasPaginationChanged =
        page !== currentPage || itemsPerPage !== currentItemsPerPage
      const hasSortingChanged =
        JSON.stringify(sortBy) !== JSON.stringify(currentSortBy) // Compare sorting objects

      // Only emit if there's a change in pagination or sorting
      if (hasPaginationChanged || hasSortingChanged) {
        this.$emit('loadMore', { page, itemsPerPage, sortBy })

        // Update state after the change
        this.currentPage = page
        this.currentItemsPerPage = itemsPerPage
        this.currentSortBy = sortBy
      }
    },
  },
})
