<template>
  <SharedHeader title-width="30rem" title="Tasks">
    <div class="sub-SharedHeader">
      <va-dropdown :offset="[0, -70]" close-on-content-click>
        <template #anchor>
          <img :src="MoreVertIcon" alt="MoreVertIcon" class="press-button-2" />
        </template>
        <va-dropdown-content>
          <p class="task-action-option" @click="handleToggleUpdateLayoutModal">
            Edit Page Layout
          </p>
        </va-dropdown-content>
      </va-dropdown>
    </div>
  </SharedHeader>

  <UpdateLayoutColumns
    :update-layout-loading="isUpdateLayoutLoading"
    :is-update-layout-form="isShowUpdateLayoutModal"
    :section_stages="stages"
    :is-delete-layout-loading="isDeleteLayoutLoading"
    @handleToggleUpdateLayoutModal="handleToggleUpdateLayoutModal"
    @handleUpdateStageData="handleUpdateLayout"
    @onDragEnd="handleUpdateLayout"
    @handleDeleteLayout="handleDeleteLayout"
  />

  <TabsBar
    :tabs="sections"
    :active-tab-id="activeTabId"
    :loading="isLoading"
    @changeTab="handleTabChange"
  />

  <div v-if="activeTabId === 1717171">
    <div class="scroll-container">
      <AllTasks />
    </div>
  </div>

  <div v-else>
    <ActionsBar
      :second-add-button="$t('actionsBar.addTask')"
      @secondButtonAction="handleToggleAddTaskModal"
    />

    <div>
      <AddTaskModal
        :add-task-loading="isAddLoading"
        :is-add-task-form="isAddTask"
        @handleToggleAddTaskModal="handleToggleAddTaskModal"
        @handleCreateTask="handleCreateTask"
      />
      <div class="contacts-kanban">
        <KanbanInfiniteLoad
          v-if="!isLoading"
          :is-tasks="true"
          :stages="stages"
          :is-cards-loading="isTasksLoading"
          :is-columns-loading="isStageLoading"
          @ticketMoved="handleTicketMoved"
          @handleAddStage="handleToggleUpdateLayoutModal"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" scoped src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss" />
