import { defineComponent } from 'vue'
import TabsBar from '@/components/tabsBar/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import SearchAndViews from '@/components/searchAndViews/index.vue'
import ActionsBar from '@/components/actionsBar/index.vue'
import { AnnouncementPresenter } from '@/presenters/AnnouncementPresenter'
import { addAnnouncement, getAllAnnouncements } from '@/services/announcements'
import AddAnnouncementModal from '@/components/AddAnnouncementModal/index.vue'

export default defineComponent({
  name: 'Announcements',
  data() {
    const tabs = [
      { id: 0, name: 'SENT', icon: 'send' },
      { id: 1, name: 'DRAFTS', icon: 'edit' },
    ]

    const sortByOptions = [
      { text: 'By Date', value: 'date' },
      { text: 'Chronological', value: 'chronological' },
      { text: 'Recipient', value: 'recipient' },
      { text: 'Number Of Read', value: 'number_of_read' },
    ]

    return {
      tabs,
      activeTabId: 0,
      activeTab: 'SENT',

      sortByOptions,
      selectedOption: 'date',

      searchKeyword: '',
      debounceTimeout: null as ReturnType<typeof setTimeout> | null,

      announcements: [] as AnnouncementPresenter[],

      isAddAnnouncementModalOpen: false,

      isAnnouncementsAccordion: [true],
    }
  },
  methods: {
    getAnnouncements() {
      const response = getAllAnnouncements({ activeTabId: this.activeTabId })
      if (response.success) {
        this.announcements = response.data?.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
    },

    changeTab(tab: { name: string; id: number }) {
      this.activeTabId = tab.id
      this.activeTab = tab.name
      this.getAnnouncements()
    },

    handleSearch(search: string) {
      this.searchKeyword = search

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        // Trigger any additional actions for search if needed
      }, 500)
    },

    toggleAddAnnouncementModal() {
      this.isAddAnnouncementModalOpen = !this.isAddAnnouncementModalOpen
    },

    handleAddAnnouncement({
      isSent,
      newAnnouncement,
    }: {
      isSent: boolean | undefined
      newAnnouncement: AnnouncementPresenter
    }) {
      addAnnouncement({ isSent, newAnnouncement })
      this.getAnnouncements()

      this.$vaToast.init({
        message: isSent
          ? 'Announcement added to Sent!'
          : 'Announcement saved as Draft!',
        color: 'success',
      })
    },
  },
  computed: {
    groupedAnnouncements(): {
      titleDate: string
      draftTitle: string
      data: AnnouncementPresenter[]
    }[] {
      const data: AnnouncementPresenter[] = this.announcements
      const grouped = data.reduce(
        (
          announcementsAccumulator: Map<
            string,
            { draftTitle: string; data: AnnouncementPresenter[] }
          >,
          announcement: AnnouncementPresenter,
        ) => {
          const date = new Date(announcement.date)
          const draftDate = new Date(announcement.dateAndTimeOfDispatching)

          const formatDate = (date: Date) => {
            return new Intl.DateTimeFormat('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            }).format(date)
          }

          const formattedDate = formatDate(date)
          const draftTitle = isNaN(draftDate.getTime())
            ? 'Draft'
            : 'Scheduled to ' + formatDate(draftDate)

          if (!announcementsAccumulator.has(formattedDate)) {
            announcementsAccumulator.set(formattedDate, {
              draftTitle,
              data: [],
            })
          }

          announcementsAccumulator.get(formattedDate)!.data.push(announcement)
          return announcementsAccumulator
        },
        new Map<
          string,
          { draftTitle: string; data: AnnouncementPresenter[] }
        >(),
      )
      return Array.from(grouped.entries())
        .sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime())
        .map(([titleDate, { draftTitle, data }]) => ({
          titleDate,
          draftTitle,
          data,
        }))
    },
  },
  mounted() {
    this.getAnnouncements()
  },
  components: {
    TabsBar,
    ComingSoon,
    SearchAndViews,
    ActionsBar,
    AddAnnouncementModal,
  },
})
