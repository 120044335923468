<template>
  <router-link
    class="contact-card link"
    :to="`/legal-matters/${matter.item.id}`"
  >
    <div class="contact-card-header">
      <h3>
        <input v-if="showCheckbox" v-model="isChecked" type="checkbox" />
        {{ matter.item.name }}
      </h3>
    </div>

    <div>
      <p class="title">
        {{ $t('matter.client') }}
      </p>
      <ul class="referal-list">
        <li class="referal">
          <va-avatar
            :src="matter.item.representing_contact?.avatar?.thumbnail"
            alt=""
            size="1.5rem"
            color="#ECF0F1"
          >
            <p
              v-if="!matter.item.representing_contact?.avatar?.thumbnail"
              class="avatar-p"
            >
              {{
                matter.item.representing_contact?.specific_data
                  ?.individual_first_name?.[0]
              }}
              {{
                matter.item.representing_contact?.specific_data
                  ?.individual_last_name?.[0]
              }}
            </p>
          </va-avatar>
          <p>
            {{
              matter.item.representing_contact?.specific_data
                ?.individual_first_name
            }}
            {{
              matter.item.representing_contact?.specific_data
                ?.individual_last_name
            }}
          </p>
        </li>
      </ul>
    </div>

    <div>
      <p class="title">Case Managers</p>
      <ul
        v-for="(caseM, index) in matter.item?.case_manager"
        :key="index"
        class="referal-list"
      >
        <li class="referal">
          <va-avatar size="1.5rem" color="#ECF0F1">
            <p class="avatar-p">
              {{ caseM.avatar_letter }}
            </p>
          </va-avatar>
          <p>
            {{ caseM.fullName }}
          </p>
        </li>
      </ul>
      <va-avatar
        v-if="!matter.item?.case_manager?.length"
        size="1.5rem"
        color="#ECF0F1"
      />
    </div>

    <div>
      <p class="title">Personnel</p>
      <ul
        v-for="(personnel, index) in matter.item?.personnel"
        :key="index"
        class="referal-list"
      >
        <li class="referal">
          <va-avatar size="1.5rem" color="#ECF0F1">
            <p class="avatar-p">
              {{ personnel.avatar_letter }}
            </p>
          </va-avatar>
          <p>
            {{ personnel.fullName }}
          </p>
        </li>
      </ul>
      <va-avatar
        v-if="!matter.item?.personnel?.length"
        size="1.5rem"
        color="#ECF0F1"
      />
    </div>
  </router-link>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
