<template>
  <div class="folder" @contextmenu.prevent="isOpenOptions = true">
    <div class="folder-header">
      <div class="info">
        <img src="../../assets/icons/file-icon.svg" alt="folder icon" />
        <div class="name-container">
          <h3 v-if="!isEditingName" class="name" @dblclick="enableEditing">
            {{ folder.name }}
          </h3>
          <input
            v-else
            ref="nameInput"
            v-model="editableName"
            type="text"
            class="name-input"
            autofocus
            @keydown.enter="saveName"
            @blur="cancelEditing"
          />
          <p class="size">{{ folder.total_size_cache }} GB</p>
        </div>
      </div>
      <div class="folder-options">
        <v-menu
          v-model="isOpenOptions"
          :close-on-content-click="true"
          location="bottom left"
        >
          <template #activator="{ props }">
            <img
              v-bind="props"
              :src="MoreVertIcon"
              alt="MoreVertIcon"
              class="press-button-2"
            />
          </template>
          <v-card class="menu-list">
            <ul class="menu">
              <li
                v-for="option in folderOptions"
                :key="option.id"
                :class="[
                  'menu-item',
                  { 'menu-item-disabled': option.deactivate },
                ]"
                @click="!option.deactivate && handleOptionClick(option)"
              >
                {{ option.option }}
              </li>
            </ul>
          </v-card>
        </v-menu>
        <!-- <div class="select-section">
              <VaButton preset="primary" size="small">Open</VaButton>
              <VaButton preset="primary" size="small">Edit</VaButton>
              <VaButton preset="primary" size="small">Delete</VaButton>
            </div>
            <VaDivider />
            <div class="description-section">
              <span>
                <p>Folder size:</p>
                <p>{{ folder.total_size_cache }} GB</p>
              </span>
              <span>
                <p>Number of folders:</p>
                <p>{{ folder.folders_count }}</p>
              </span>
              <span>
                <p>Number of files:</p>
                <p>{{ folder.files_count }}</p>
              </span>
              <p>Description: {{ folder.description }}</p>
            </div> -->
      </div>
    </div>
    <div class="labels">
      <div class="label">
        <p class="size">{{ folder.folders_count }} Folders</p>
      </div>
      <div class="label">
        <p class="size">{{ folder.files_count }} Files</p>
      </div>
      <!-- <p>{{ folder.description }}</p> -->
    </div>
    <!-- <div class="user">
      <img :src="folder.user.imageUrl" :alt="folder.user.name" />
      <h4>{{ folder.user.name }}</h4>
    </div> -->
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
