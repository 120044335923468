import { addExpenseFormInterface } from '@/Interfaces/Services'
import {
  deepCopy,
  getOptionCurrency,
  getOptionFullName,
  getOptionName,
  getOptionTruncateName50,
  validateInput,
  validateInputEmail,
  validateSelect,
} from '@/lib/utils'
import { CurrenciesPresenter } from '@/presenters/CurrenciesPresenter'
import { getAllCurrencies } from '@/services/currencies'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'

export default defineComponent({
  name: 'AddExpenseFormInterface',
  props: {
    addExpenseLoading: {
      type: Boolean,
      default: false,
    },
    isAddExpense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { isValid, validate, reset, resetValidation } = useForm('form')

    const baseForm = {
      category: 'Administrative Law',
      due_date: new Date(),
      status: 'not_started',
      expense_type: 'utilities',
      quantity: 1,
      total_tax: 0,
      unit_price: 0,
      declared_amount: 0,
      total_amount: 0,
    } as addExpenseFormInterface

    const currencies = [] as CurrenciesPresenter[]

    return {
      validateInput,
      validateInputEmail,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionCurrency,
      getOptionTruncateName50,
      deepCopy,

      isAddExpenseBool: this.isAddExpense,
      isValid,
      validate,
      reset,
      resetValidation,
      files: [],
      baseForm,
      form: deepCopy(baseForm) as addExpenseFormInterface,

      currencies,
      currencyLoading: false,
      secondaryCurrencyAmount: null as string | number | null,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null, // Holds the debounce timeout ID
    }
  },
  watch: {
    'form.unit_price': 'calculateDeclaredAmount',
    'form.quantity': 'calculateDeclaredAmount',
    'form.declared_amount': 'calculateTaxAndTotalAmount',
    'form.total_tax': 'calculateTaxAndTotalAmount',
    'form.currency': {
      handler() {
        this.calculateSecondaryCurrencyAmount()
      },
      deep: true,
    },
    'form.secondary_currency': {
      handler() {
        this.calculateSecondaryCurrencyAmount()
      },
      deep: true,
    },
    form: {
      handler(newForm) {
        console.log('Form updated:', newForm)
      },
      deep: true,
    },
    isAddExpense: {
      handler(data) {
        this.isAddExpenseBool = data
        if (data) {
          this.handleGetContacts()
          this.handleGetMembers()
          this.handleGetCompanyCurrencies()
        }
      },
    },
    addExpenseLoading: {
      handler(data) {
        !data && this.handleToggleAddModal()
      },
    },
  },
  methods: {
    handleToggleAddModal() {
      this.$emit('handleToggleAddModal')
      this.form = deepCopy(this.baseForm)
    },
    async handleCreateExpense() {
      const { currency, secondary_currency, ...form } = this.form

      console.log({
        currency_id: currency?.id,
        secondary_currency_id: secondary_currency?.id,
        ...form,
      })

      // this.$emit('handleCreateExpense', this.form);
    },
    ////////////////////////////////////////////////////////////////////////
    async handleGetMembers(search?: string) {
      this.$stores.usersData.handleGetMembers({
        keyword: search,
      })
    },

    handleSearchMembers(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMembers(search)
      }, 500)
    },
    ////////////////////////////////////////////////////////////////////////
    async handleGetContacts(search?: string) {
      this.$stores.contacts.handleGetContacts({
        keyword: search,
      })
    },
    handleSearchContacts(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts(search)
      }, 500)
    },
    ///////////////////////////////////////////////////////////////////////
    calculateDeclaredAmount() {
      const unitPrice = this.form.unit_price || 0
      const quantity = this.form.quantity || 0
      this.form.declared_amount = unitPrice * quantity
    },

    calculateTaxAndTotalAmount() {
      const declaredAmount = this.form.declared_amount || 0
      const taxPercentage = this.form.total_tax || 0
      const calculatedTax = (declaredAmount * taxPercentage) / 100
      this.form.total_amount = declaredAmount + calculatedTax

      // Recalculate secondary currency amount
      this.calculateSecondaryCurrencyAmount()
    },

    ////////////////////////////// Currencies ///////////////////////////////////

    async handleGetCompanyCurrencies() {
      this.currencyLoading = true

      const response = await getAllCurrencies({})

      if (response.success) {
        this.currencies = response.data.list
        this.form.currency = this.currencies[0]
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.currencyLoading = false
    },

    calculateSecondaryCurrencyAmount() {
      if (
        this.form.currency &&
        this.form.secondary_currency &&
        this.form.currency.conversion_rate_against_us_dollars &&
        this.form.secondary_currency.conversion_rate_against_us_dollars
      ) {
        const amountInUSD =
          this.form.total_amount /
          Number(this.form.currency.conversion_rate_against_us_dollars)

        this.secondaryCurrencyAmount = (
          amountInUSD *
          Number(
            this.form.secondary_currency.conversion_rate_against_us_dollars,
          )
        ).toFixed(4)
      } else {
        this.secondaryCurrencyAmount = null
      }
    },
  },
})
