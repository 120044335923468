import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createBlock(_component_v_navigation_drawer, {
    modelValue: _ctx.$stores.ui.showSideDrawer,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$stores.ui.showSideDrawer) = $event)),
    location: "right",
    temporary: "",
    persistent: false,
    style: {"width":"300px"}
  }, null, 8, ["modelValue"]))
}