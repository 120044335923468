<template>
  <VaModal
    v-model="isAddExpenseBool"
    size="auto"
    hide-default-actions
    no-outside-dismiss
    @update:modelValue="handleToggleAddModal"
  >
    <template #header>
      <div class="create-expense-form-header">
        <h1>Add General Expense</h1>
        <div style="width: 7rem" />
        <VaIcon
          class="header-icon press-button"
          name="close"
          size="30px"
          @click="handleToggleAddModal"
        />
      </div>
    </template>

    <VaForm ref="form" class="create-expense-form" @submit.prevent="validate()">
      <div class="body-expense-form">
        <VaInput
          v-model="form.name"
          background="#ECF0F1"
          label="Name"
          placeholder="Name"
          required-mark
          :rules="[validateInput]"
        />

        <!-- <VaSelect
          style="width: 100%"
          label="Category"
          background="#ECF0F1"
          v-model="form.category"
          :options="$stores.financial.categoryOption"
        /> -->
        <div class="ESE-section">
          <VaSelect
            v-model="form.billed_to_id"
            background="#ECF0F1"
            label="Paid To"
            placeholder="Paid To"
            searchable
            clearable
            :highlight-matched-text="false"
            :options="$stores.contacts.contacts"
            :text-by="getOptionFullName"
            :loading="$stores.contacts.contactsLoading"
            value-by="id"
            @update:search="handleSearchContacts"
          />
          <VaSelect
            v-model="form.from_wallet_id"
            background="#ECF0F1"
            label="Paid By"
            placeholder="Paid By"
            searchable
            clearable
            :options="$stores.usersData.teamMembers"
            :highlight-matched-text="false"
            :text-by="getOptionFullName"
            :loading="$stores.usersData.membersLoading"
            value-by="id"
            @update:search="handleSearchMembers"
          />
        </div>

        <div class="ESE-section">
          <VaDateInput
            v-model="form.due_date"
            placeholder="MM/DD/YYYY"
            manual-input
            label="Payment Date"
            clearable
          />
          <VaSelect
            v-model="form.status"
            label="Status"
            placeholder="Status"
            background="#ECF0F1"
            :options="$stores.financial.statusOptions"
            value-by="value"
          />
        </div>

        <div class="ESE-section">
          <VaSelect
            v-model="form.expense_type"
            label="expense type"
            placeholder="Expense Type"
            background="#ECF0F1"
            :options="$stores.financial.expenseTypeOptions"
            value-by="text"
          />
          <VaSelect
            v-model="form.currency"
            label="currency"
            background="#ECF0F1"
            :options="currencies"
            :text-by="getOptionCurrency"
            :loading="currencyLoading"
          />
        </div>

        <!-- this code is ready to take 2 currencies -->

        <!-- <div class="form-invoice-currency-container">
          <div class="form-invoice-currency">
            <VaSelect
              label="main currency"
              background="#ECF0F1"
              v-model="form.currency"
              :options="currencies"
              :text-by="getOptionCurrency"
              :loading="currencyLoading"
            />

            <VaSelect
              label="Secondary currency"
              background="#ECF0F1"
              v-model="form.secondary_currency"
              :options="currencies"
              :text-by="getOptionCurrency"
              :loading="currencyLoading"
            />
          </div>
        </div> -->

        <div class="upload-docs">
          <VaFileUpload v-model="files" disabled>
            <VaButton
              icon="add"
              preset="secondary"
              border-color="primary"
              size="small"
            />
          </VaFileUpload>
          Upload documents (.jpg,.jpeg,.pdf,.png)
        </div>

        <VaTextarea
          v-model="form.description"
          max-rows="11"
          label="description"
          placeholder="Add your description here..."
          style="width: 100%; height: 10rem"
          autosize
          background="#ECF0F1"
        />
        <div class="table-container">
          <table class="expense-table">
            <thead>
              <tr class="table-header">
                <th class="unit-header">Unit Price</th>
                <th class="quantity-header">Quantity</th>
                <th class="declared-header">Declared Value</th>
                <th class="tax-header">Tax</th>
                <th class="total-header">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr class="expense-row">
                <td class="expense-price-cell">
                  <VaInput
                    v-model="form.unit_price"
                    background="#fff"
                    type="number"
                    placeholder="Price"
                    class="expense-price-input"
                    min="0"
                  />
                </td>
                <td class="expense-quantity-cell">
                  <VaInput
                    v-model="form.quantity"
                    background="#fff"
                    type="number"
                    placeholder="Quantity"
                    class="expense-quantity-input"
                  />
                </td>
                <td class="expense-declared-cell">
                  {{ form.declared_amount }} {{ form.currency?.code || '' }}
                </td>
                <td class="expense-tax-cell">
                  <VaInput
                    v-model="form.total_tax"
                    background="#fff"
                    type="number"
                    placeholder="Tax"
                    class="expense-tax-input"
                    min="0"
                  >
                    <template #prependInner>
                      <VaIcon name="percent" color="secondary" />
                    </template>
                  </VaInput>
                </td>
                <td class="expense-total-cell">
                  {{ form.total_amount }} {{ form.currency?.code || '' }}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="secondaryCurrencyAmount"
            class="secondary-currency-display"
          >
            <p>
              Equivalent in Secondary Currency:
              <strong
                >{{ secondaryCurrencyAmount }}
                {{ form.secondary_currency?.code || '' }}</strong
              >
            </p>
          </div>
        </div>
      </div>
    </VaForm>
    <template #footer>
      <div class="add-expense-form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleToggleAddModal"
        >
          Cancel
        </VaButton>
        <VaButton
          :loading="addExpenseLoading"
          @click="validate() && handleCreateExpense()"
        >
          Save
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
