import SharedHeader from '@/components/sharedHeader/index.vue'
import TabsBar from '@/components/tabsBar/index.vue'
import TimeRange from '@/components/TimeRange/index.vue'
import DatePickerModal from '@/components/DatePickerModal/index.vue'
import CustomChip from '@/components/Chip/index.vue'
import OwnerAssignTo from '@/components/Task/OwnerAssignTo/index.vue'
import ChatActivity from '@/components/Task/ChatActivity/index.vue'
import SubTask from '@/components/Task/SubTask/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import DetailsTimeLogs from '@/components/DetailsTimeLogs/index.vue'
import TaskLegalMattersSection from './Sections/LegalMatter/index.vue'
import { defineComponent, ref } from 'vue'
import { addTask, getTaskById, updateTask } from '@/services/tasks'
import { useForm } from 'vuestic-ui/web-components'
import { getAllStages } from '@/services/stages'
import { TeamMemberPresenter } from '@/presenters/teamMember'
import MatterTable from '@/components/LegalMatter/Table/index.vue'
import ActionsBar from '@/components/actionsBar/index.vue'
import ForItemTimeRecordModal from '@/components/TimeRecordModal/ForItemTimeRecordModal/index.vue'
import {
  formatDateRange,
  getOptionFullName,
  getOptionName,
  validateInput,
  validateSelect,
} from '@/lib/utils'
import { useTabs } from '@/composables/useTabs'
import { TaskPresenter } from '@/presenters/taskPresenter'
import { calendarItemsFormInterface } from '@/Interfaces/layout'
import { CompanyConfigurationPresenter } from '@/presenters/companyConfigPresenters'
import { SectionStage } from '@/presenters/interface'
import { getAllCalendarItems } from '@/services/calendarItem'
import { paginationInterface } from '@/Interfaces/Services'
import { CalendarItemsPresenter } from '@/presenters/calendarItemsPresenter'

const stopImage = new URL(
  '../../../assets/icons/stop-icon.svg',
  import.meta.url,
).href
const playImage = new URL(
  '../../../assets/icons/play-icon.svg',
  import.meta.url,
).href
const FlagIcon = new URL('@/assets/icons/flag-icon.svg', import.meta.url).href

export default defineComponent({
  name: 'TaskDetails',
  components: {
    SharedHeader,
    TabsBar,
    TimeRange,
    DatePickerModal,
    OwnerAssignTo,
    CustomChip,
    SubTask,
    ChatActivity,
    MatterTable,
    ActionsBar,
    TaskLegalMattersSection,
    ForItemTimeRecordModal,
    DetailsTimeLogs,
    ComingSoon,
  },

  data() {
    const labels = ref<{ id: number; name: string; color: string }[]>([
      {
        id: 100,
        name: 'testy',
        color: '#ed6c02',
      },
    ])

    const secondaryColor = ref('$secondary-50')

    const palette = ref(['#fff', '#ef476f', '#ffd166', '#06d6a0', '#8338ec'])

    const selectedLabelColor = ref('')

    const taskBarTabs = [
      { id: 0, name: 'TASK INFO', icon: 'info' },
      { id: 1, name: 'LEGAL MATTERS', icon: 'inventory_2' },
      { id: 2, name: 'FILES', icon: 'file_copy' },
      { id: 3, name: 'EXPENSES', icon: 'bar_chart' },
      { id: 4, name: 'ENGAGEMENT LETTER', icon: 'article' },
      { id: 5, name: 'EMAILS', icon: 'mail' },
      { id: 6, name: 'TIME LOG', icon: 'schedule' },
    ]

    const { activeTabId, activeTab, changeTab } = useTabs(taskBarTabs)

    const { isValid, validate, reset, resetValidation } = useForm('form')
    const {
      isValid: subTaskIsValid,
      validate: subTaskValidate,
      reset: subTaskReset,
      resetValidation: subTaskResetValidation,
    } = useForm('subTaskForm')

    return {
      isValid,
      validate,
      reset,
      resetValidation,
      subTaskIsValid,
      subTaskValidate,
      subTaskReset,
      subTaskResetValidation,
      formatDateRange,

      validateInput,
      validateSelect,
      getOptionName,
      getOptionFullName,

      stages: [] as SectionStage[],
      FlagIcon,
      isAddLoading: false,
      selectedSubtaskDate: null,
      tabs: taskBarTabs,
      labels,
      labelId: 0,
      showAddLabelInput: false,
      showAddSubtaskInput: false,
      labelInputValue: '',

      activeTabId,
      activeTab,
      changeTab,

      secondaryColor,
      isLabelModal: false,
      palette,
      selectedLabelColor,
      stopImage,
      playImage,
      loading: false,
      isMenuOwner: false,
      isMenuAssign: false,
      loadingUpdate: false,
      flags: [
        { name: 'urgent', color: '#E53E3E' },
        { name: 'high', color: '#D69E2E' },
        { name: 'normal', color: '#296DAD' },
        { name: 'low', color: '#A0AEC0' },
      ],
      form: {} as TaskPresenter,

      subTaskForm: {
        name: '',
        start_date: '',
        end_date: '',
      },
      subTasks: [] as TaskPresenter[],

      timeLogs: [] as CalendarItemsPresenter[],
      timeLogsItemPerPage: 10,
      timeLogPage: 1,
      timeLogSortDirection: 'desc' as 'asc' | 'desc',
      timeLogTotalItems: 0,
      selectedTimeLogRows: [],
      timeLogTableLoading: false,

      isAddTimeLog: false,
      timeLogsLoading: false,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,

      startRecordLoading: false,
      deleteRecordLoading: false,
      updateRecordLoading: false,
      manualFormLoading: false,
      loadingStates: [] as boolean[],
      getTimeDataType: {
        linked_item_type: 'Task' as
          | 'Hearing'
          | 'Task'
          | 'Milestone'
          | 'LegalMatter',
        linked_item_id: Number(this.$route.params.id),
      },

      calendarItemsRangeFormLoading: false,
    }
  },
  watch: {
    form: {
      handler(data) {
        console.log({
          form: data,
        })
      },
      deep: true,
    },
    '$route.params.id'(newId) {
      // Logic to handle ID change
      this.handleGetTaskByID(newId)
      this.handleGetTimeRecords(newId)

      this.handleGetMembers()
    },
    activeTab: {
      handler(tabName) {
        if (tabName === 'TIME LOG') {
          this.handleGetTimeLog()
        }
      },
    },
  },
  mounted() {
    this.handleGetTaskByID(this.$route.params.id as string)
    this.handleGetMembers()
    this.handleGetTimeRecords(this.$route.params.id as string)
  },
  methods: {
    handleSelectedSubDate(date: { start: string; end: string }) {
      this.subTaskForm.start_date = date.start
      this.subTaskForm.end_date = date.end
    },
    handleSelectedDate(date: { start: string; end: string }) {
      this.form.start_date = date.start
      this.form.end_date = date.end
    },
    handleSelectDueDate(date: { date: string }) {
      this.form.due_date = date.date
    },
    /////////////////////////////////////////////////////////////////////
    handleDeleteLabel(id: number) {
      this.labels = this.labels.filter((label) => label.id !== id)
    },
    handleRemoveOwner() {
      this.form.owner = { id: null } as TeamMemberPresenter
    },
    handleRemoveAssign() {
      this.form.assigned_to = { id: null } as TeamMemberPresenter
    },
    handleAddLabel() {
      if (this.labelInputValue.length > 0) {
        this.labels.push({
          name: this.labelInputValue,
          color: this.selectedLabelColor,
          id: this.labelId,
        })
        this.labelInputValue = ''
        this.labelId = this.labelId + 1
        this.isLabelModal = false
      }
    },
    handleFlagMenuItemClick(flag: CompanyConfigurationPresenter) {
      this.form.priority = flag
    },
    handleStageMenuItemClick(stage: SectionStage) {
      if (
        this.form &&
        this.form.section_stage_items &&
        this.form.section_stage_items[0] &&
        this.form.section_stage_items[0]?.section_stage
      ) {
        this.form.section_stage_items[0].section_stage = {
          ...this.form.section_stage_items[0]?.section_stage,
          id: stage.id,
          name: stage.name,
          color: stage.color,
        }
      }
    },

    async handleGetStagesSection(section_id: number) {
      const response = await getAllStages(section_id)
      if (response.success) {
        this.stages = response.data?.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
    },
    ///////////////////////////////////////////////////////////////
    async handleGetTaskByID(id: string) {
      this.loading = true
      const response = await getTaskById(id)

      if (response.success) {
        this.form = response.data
        this.subTasks = this.form?.sub_tasks || []
        // this.populateForm()

        if (response.data.section_stage_items[0]?.section_stage.section_id) {
          this.handleGetStagesSection(
            response.data.section_stage_items[0]?.section_stage.section_id,
          )
        } else {
          this.stages = []
        }
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.loading = false
    },

    async handleUpdate() {
      this.loadingUpdate = true

      const {
        assigned_to,
        owner,
        section_stage_items,
        name,
        description,
        notes,
        priority,
        start_date,
        end_date,
        due_date,
        location,
        budget,
        billable,
        // hourly_rate,
      } = this.form

      const response = await updateTask(Number(this.$route.params.id), {
        assigned_to_id: assigned_to?.id,
        owner_id: owner?.id,
        section_stage_id: section_stage_items[0]?.section_stage.id,
        name,
        description,
        notes,
        priority_id: priority?.id,
        start_date,
        end_date,
        due_date,
        location,
        budget,
        billable,
        // hourly_rate,
      })

      if (response.success) {
        this.$vaToast.init({
          message: 'Task updates successfully!',
          color: 'success',
        })
        this.handleGetTaskByID(this.$route.params.id as string)
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.loadingUpdate = false
    },

    ///////////////////////////////////////////////////////////////
    handleChooseOwner(data: TeamMemberPresenter) {
      this.form.owner = data
    },
    handleChooseAssign(data: TeamMemberPresenter) {
      this.form.assigned_to = data
    },
    handleBack() {
      this.$router.back()
    },
    handleBackToParent(id: number) {
      this.$router.push({ path: `/tasks/${id}` })
    },
    async handleAddTask() {
      this.isAddLoading = true

      const response = await addTask({
        ...this.subTaskForm,
        parent_id: Number(this.$route.params.id as string),
        owner_id: this.form?.owner?.id,
        priority: this.form?.priority,
      })
      if (response.success) {
        this.$vaToast.init({ message: 'Sub Task Added!', color: 'success' })
        this.subTaskReset()
        this.handleGetTaskByID(this.$route.params.id as string)
        this.showAddSubtaskInput = false
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isAddLoading = false
    },
    handleKeydownAddTask(event: { preventDefault: () => void }) {
      event.preventDefault()
      this.subTaskValidate() && this.handleAddTask()
    },
    ///////////////////////////////////////////////////////////////
    async handleGetMembers(search?: string) {
      this.$stores.usersData.handleGetMembers({
        keyword: search,
      })
    },

    handleSearchMembers(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMembers(search)
      }, 500)
    },
    ///////////////////////////////////////////// Time Record /////////////////////////////////////////
    async handleGetTimeRecords(id: string) {
      await this.$stores.mockTimer.handleGetTimesForItem({
        linked_item_type: 'Task',
        linked_item_id: Number(id),
        with_user: true,
      })
    },

    async handleStartEndTime(start: boolean) {
      this.startRecordLoading = true

      if (!start) {
        await this.$stores.mockTimer.handleUpdateTimerForItem(
          {
            id: this.$stores.mockTimer?.specificCalendarItems[0].id,
            end_time: new Date(),
          },
          this.getTimeDataType,
        )
      } else {
        await this.$stores.mockTimer.handleStartTimerForItem(
          {
            start_time: new Date().toISOString(),
            calendar_item_linked_items_attributes: [this.getTimeDataType],
          },
          this.getTimeDataType,
        )
      }

      if (this.activeTab === 'TIME LOG') {
        this.handleGetTimeLog()
      }

      this.startRecordLoading = false
    },

    async handleStartEndRecord({
      record,
      index,
    }: {
      record: calendarItemsFormInterface
      index: number
    }) {
      const { id, end_time, description, billable } = record

      this.loadingStates[index] = true

      if (end_time === null) {
        await this.$stores.mockTimer.handleUpdateTimerForItem(
          {
            id,
            end_time: new Date(),
          },
          this.getTimeDataType,
        )
      } else {
        await this.$stores.mockTimer.handleStartTimerForItem(
          {
            description,
            start_time: new Date().toISOString(),
            billable,
            calendar_item_linked_items_attributes: [this.getTimeDataType],
            id,
          },
          this.getTimeDataType,
        )
      }
      if (this.activeTab === 'TIME LOG') {
        this.handleGetTimeLog()
      }

      this.loadingStates[index] = false
    },

    async handleUpdateRecord(record: calendarItemsFormInterface) {
      this.updateRecordLoading = true

      await this.$stores.mockTimer.handleUpdateTimerForItem(
        record,
        this.getTimeDataType,
      )
      if (this.activeTab === 'TIME LOG') {
        this.handleGetTimeLog()
      }
      this.updateRecordLoading = false
    },

    async handleDeleteRecord(id: number) {
      this.deleteRecordLoading = true

      await this.$stores.mockTimer.handleDeleteTimerForItem(
        id,
        this.getTimeDataType,
      )
      if (this.activeTab === 'TIME LOG') {
        this.handleGetTimeLog()
      }
      this.deleteRecordLoading = false
    },

    async handleCreateManual(manualForm: calendarItemsFormInterface) {
      this.manualFormLoading = true

      await this.$stores.mockTimer.handleStartTimerForItem(
        {
          ...manualForm,
          calendar_item_linked_items_attributes: [this.getTimeDataType],
        },
        this.getTimeDataType,
      )
      if (this.activeTab === 'TIME LOG') {
        this.handleGetTimeLog()
      }
      this.manualFormLoading = false
    },

    async handleAddTimeRange(
      calendarItemsRangeForm: calendarItemsFormInterface,
    ) {
      this.calendarItemsRangeFormLoading = true

      await this.$stores.mockTimer.handleStartTimerForItem(
        {
          ...calendarItemsRangeForm,
          calendar_item_linked_items_attributes: [this.getTimeDataType],
        },
        this.getTimeDataType,
      )

      if (this.activeTab === 'TIME LOG') {
        this.handleGetTimeLog()
      }
      this.calendarItemsRangeFormLoading = false
    },

    ///////////////////////////////////////////// Time Log /////////////////////////////////////////
    async handleGetTimeLog() {
      this.timeLogTableLoading = true

      const response = await getAllCalendarItems({
        per_page: this.timeLogsItemPerPage,
        page: this.timeLogPage,
        sort_by_direction: this.timeLogSortDirection,
        sort_by_field: 'start_time',
        linked_item_type: 'Task',
        linked_item_id: Number(this.$route.params.id),
        for_whole_company: true,
      })
      if (response.success) {
        this.timeLogs = response.data?.list

        this.timeLogTotalItems =
          response.data.total_pages * this.timeLogsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.timeLogTableLoading = false
    },

    async handlePaginationTimeLog({ page, itemsPerPage }: paginationInterface) {
      this.timeLogsItemPerPage = itemsPerPage
      this.timeLogPage = page

      await this.handleGetTimeLog()
    },
  },
})
