export class CompanyConfigurationPresenter {
  id!: number
  class_name!: string
  color!: string
  company_id!: number
  configuration_type!: string
  created_at!: string
  name!: string
  updated_at!: string
  icon_name!: string

  constructor(data: CompanyConfigurationPresenter) {
    Object.assign(this, data)
  }
}

export class CurrencyPresenter {
  id!: number
  class_name!: string | null
  created_at!: string
  name!: string | null
  updated_at!: string
  code!: string
  conversion_rate_against_us_dollars!: string

  constructor(data: CurrencyPresenter) {
    Object.assign(this, data)
  }
}
