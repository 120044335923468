<template>
  <div>
    <!-- <div id="svg-tree"></div> -->
    <div class="container">
      <ComingSoon />
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />

<!-- <script lang="ts">
import { defineComponent } from 'vue'
import ApexTree from 'apextree'

export default defineComponent({
  name: 'Tree',
  mounted() {
    const customData = {
      id: 0,
      name: 'First Name Last Name',
      imageURL: 'https://i.pravatar.cc/100?img=1',
      title: 'Manager',
      childrenSpacing: 50,
      expertise: 'International law',
      company: 'Eptalex global',
      status: 'active',
      lastActive: '2024-03-19, 4:12pm',
      location: 'Madagascar',
      team: 'Litigation Team',
      role: 'team member',
      children: [
        {
          id: 1,
          name: 'Test Name A',
          title: 'Manager',
          imageURL: 'https://i.pravatar.cc/100?img=2',
          expertise: 'International law',
          company: 'Eptalex global',
          status: 'active',
          lastActive: '2024-03-19, 4:12pm',
          location: 'Madagascar',
          team: 'Litigation Team',
          role: 'team member',
          children: [
            {
              id: 2,
              name: 'Test Name C',
              imageURL: 'https://i.pravatar.cc/100?img=3',
              title: 'Manager',
              expertise: 'International law',
              company: 'Eptalex global',
              status: 'not active',
              lastActive: '2024-03-19, 4:12pm',
              location: 'Madagascar',
              team: 'Litigation Team',
              role: 'team member',
            },
            {
              id: 3,
              name: 'Test Name D',
              imageURL: 'https://i.pravatar.cc/100?img=4',
              title: 'Manager',
              expertise: 'International law',
              status: 'active',
              company: 'Eptalex global',
              lastActive: '2024-03-19, 4:12pm',
              location: 'Madagascar',
              team: 'Litigation Team',
              role: 'team member',
            },
          ],
        },
        {
          id: 4,
          name: 'Test Name B',
          title: 'Manager',
          spacing: 100,
          imageURL: 'https://i.pravatar.cc/100?img=5',
          expertise: 'International law',
          status: 'not active',
          lastActive: '2024-03-19, 4:12pm',
          company: 'Eptalex global',
          location: 'Madagascar',
          team: 'Litigation Team',
          role: 'team member',
          children: [
            {
              id: 5,
              name: 'Test Name E',
              title: 'Manager',
              imageURL: 'https://i.pravatar.cc/100?img=6',
              expertise: 'International law',
              status: 'active',
              company: 'Eptalex global',
              lastActive: '2024-03-19, 4:12pm',
              location: 'Madagascar',
              team: 'Litigation Team',
              role: 'team member',
            },
          ],
        },
      ],
    }

    const options = {
      contentKey: 'data',
      width: '100%',
      height: 700,
      nodeWidth: 200,
      nodeHeight: 300,
      childrenSpacing: 100,
      siblingSpacing: 30,
      direction: 'top',
      borderColor: '#e0e0e0',
      borderColorHover: '#000',
      edgeColor: '#e0e0e0',
      edgeColorHover: '#000',
      canvasStyle: 'border: 1px solid black; background: #fff;',
      nodeTemplate: (content: any) => `
  <div class="graph-card-container">
    <div class="img-container">
      <img class='img' src='${content.imageURL}' alt='' />
      <div class="indicator" style='${content.status === 'active'
          ? 'background-color: green;'
          : 'background-color: red;'
        }'></div>
    </div>
    <div class="name">${content.name}</div>
    <div class="title">${content.title}</div>
    <div class="company">${content.company}</div>
    <div class="expertise">${content.expertise}</div>
    <button class="btn">View Profile</button>
    ${content.status === 'active'
          ? '<div class="status">Online</div>'
          : `<div class="status">Last Active: ${content.lastActive}</div>`
        }
    <div>
      <img src="../../assets/icons/location.svg"/>
      ${content.location}
      </div>
    <div>Team: ${content.team}</div>
    <div>Role: ${content.role}</div>
  </div>
`,
      enableToolbar: true,
    }

    const data = convertToApexTreeData(customData)
    const tree = new ApexTree(document.getElementById('svg-tree'), options)
    tree.render(data)
  },
})

function convertToApexTreeData(data: any) {
  const convertNode = (node: any) => ({
    id: node.id,
    data: {
      imageURL: node.imageURL,
      name: node.name,
      title: node.title,
      expertise: node.expertise,
      status: node.status,
      lastActive: node.lastActive,
      location: node.location,
      company: node.company,
      team: node.team,
      role: node.role,
      childrenSpacing: node.spacing || 50,
    },
    options: {
      nodeBGColor: '#fff', // Customize background color as needed
    },
    children: node.children ? node.children.map(convertNode) : [],
    ...(node.spacing ? { childrenSpacing: node.spacing } : {}),
  })

  return convertNode(data)
}
</script>

<style lang="scss">
.graph-card-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: auto;

  .img-container {
    position: relative;

    .img {
      max-width: 50px;
      max-height: 50px;
      border-radius: 50%;
    }

    .indicator {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      position: absolute;
      bottom: -10px;
      right: -5px;
      border: 5px solid white;
    }
  }

  .name {
    color: #000;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
  }

  .title {
    color: #000;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
  }

  .status {
    color: #718096;
    text-align: center;
    font-size: 8px;
    font-weight: 400;
  }

  .company {
    display: flex;
    padding: 4px 3px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 2px;
    background: rgba(0, 44, 108, 0.1);
    color: #000;
    text-align: center;
    font-size: 7px;
    font-weight: 400;
    text-transform: uppercase;
  }

  .expertise {
    display: flex;
    padding: 5px 10px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background-color: #d4b8c8;
    flex-shrink: 0;
    box-shadow: 0px 15px 30px 0px rgba(62, 19, 77, 0.09);
    color: $primary;
    font-size: 9px;
    font-weight: 600;
  }

  .btn {
    display: flex;
    min-width: 70px;
    min-height: 25px;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: rgba(0, 44, 108, 0.1);
    color: $primary;
    text-align: center;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
  }
}
</style> -->
