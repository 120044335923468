import { TableDataExporter } from '@/constants/Tables'
import { TableColumnInterface } from '@/Interfaces/Constants'
import { SectionsPresenter } from '@/presenters/SectionsPresenter'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'TablesConfigModal',
  props: {
    tableConfigLoading: {
      type: Boolean,
      default: false,
    },
    isTableConfigUpdate: {
      type: Boolean,
      default: false,
    },
    isTableConfigUpdateLoading: {
      type: Boolean,
      default: false,
    },
    selectedSection: {
      type: Object as PropType<SectionsPresenter>,
      default: () => ({} as SectionsPresenter),
    },
  },
  emits: ['handleToggleTableConfig', 'handleSubmitTableConfig'],
  data() {
    return {
      isTableConfigUpdateBool: false,
      selectedHeaders: [] as string[],
      // isAllSelected: false,
    }
  },
  computed: {
    tableColumns(): TableColumnInterface[] {
      return TableDataExporter.getTableColumns(this.selectedSection?.name)
    },
    isAllSelected: {
      get(): boolean {
        // Check if all table column titles are selected
        const allHeaders = this.tableColumns.map((column) => column.title || '')
        return (
          this.selectedHeaders.length === allHeaders.length &&
          allHeaders.every((header) => this.selectedHeaders.includes(header))
        )
      },
      set(value: boolean): void {
        if (value) {
          // If `isAllSelected` is true, select all headers
          this.selectedHeaders = this.tableColumns.map(
            (column) => column.title || '',
          )
        } else {
          // If `isAllSelected` is false, deselect all headers
          this.selectedHeaders = []
        }
      },
    },
  },
  watch: {
    isTableConfigUpdate: {
      handler(data) {
        this.isTableConfigUpdateBool = data
      },
    },
    isTableConfigUpdateLoading: {
      handler(data) {
        if (!data) {
          this.handleToggleTableConfig()
          this.selectedHeaders = []
        }
      },
    },
    selectedSection: {
      immediate: true,
      handler(newSection) {
        if (newSection && newSection.table_columns) {
          this.selectedHeaders = [
            ...newSection.table_columns.map(
              (column: TableColumnInterface) => column.title || '',
            ),
          ]
        }
      },
    },
  },
  methods: {
    handleToggleTableConfig() {
      this.isTableConfigUpdateBool = !this.isTableConfigUpdateBool
      this.$emit('handleToggleTableConfig')
    },

    handleSubmitTableConfig() {
      const headersTemplate = this.selectedHeaders
        ?.map((column: string) => {
          const foundColumn = this.tableColumns.find((f) => f.title === column)
          if (foundColumn) {
            return { key: foundColumn.key, title: foundColumn.title }
          }
          return null
        })
        .filter(Boolean)

      this.$emit('handleSubmitTableConfig', headersTemplate)
    },
  },
})
