import { CurrenciesPresenter } from '../CurrenciesPresenter'

export class MilestonePresenter {
  id!: number
  className!: string
  created_at!: string
  description!: string
  due_date!: string | Date
  expenses_amount!: string

  expenses_currency!: CurrenciesPresenter

  fees_amount!: string
  fees_currency!: { id: number }
  name!: string
  status!: { id: number }
  updated_at!: string

  constructor(data: MilestonePresenter) {
    Object.assign(this, data)

    if (data?.expenses_currency) {
      this.expenses_currency = new CurrenciesPresenter(data.expenses_currency)
    }
  }
}
