import { StorageCategoryPresenter } from '@/presenters/StorageCategoryPresenter'
import { apiRequest } from './apiUtils'
import { StorageCategoriesFormInterface } from '@/presenters/interface'

export const getAllStorageCategories = async () => {
  const response = await apiRequest('get', '/storage_categories')

  if (!response.success) {
    return response
  }

  const list = response.data?.list
  if (!Array.isArray(list)) {
    return response
  }

  return {
    ...response,
    data: {
      ...response.data,
      list: list.map(
        (item: StorageCategoryPresenter) => new StorageCategoryPresenter(item),
      ),
    },
  }
}

export const getStorageCategoryById = async (id: number) => {
  const response = await apiRequest('get', `/storage_categories/${id}`)
  if (response.success) {
    return {
      ...response,
      data: new StorageCategoryPresenter(response.data),
    }
  } else {
    return response
  }
}

export const addStorageCategory = (body: StorageCategoriesFormInterface) => {
  return apiRequest('post', '/storage_categories', body)
}

export const updateStorageCategory = (
  id: number,
  body: StorageCategoriesFormInterface,
) => {
  return apiRequest('put', `/storage_categories/${id}`, body)
}

export const deleteStorageCategory = (id: number) => {
  return apiRequest('delete', `/storage_categories/${id}`)
}
