<template>
  <div class="time-table-container">
    <VaModal
      v-model="isEditTimeLog"
      size="small"
      hide-default-actions
      fixed-layout
      no-outside-dismiss
    >
      <template #header>
        <h1 class="form-modal-header">
          Edit Time Log

          <VaIcon
            name="close"
            size="30px"
            class="icon press-button"
            @click="handleToggleEditTimeLog"
          />
        </h1>
      </template>
      <div class="option-card">
        <div class="option-header">
          <p>
            <va-avatar
              v-if="selectedRecord.company_user?.avatar_letter"
              class="option-header-avatar"
              color="#E5E9F0"
              size="small"
            >
              <span>
                {{ selectedRecord.company_user?.avatar_letter }}
              </span>
            </va-avatar>
            {{ selectedRecord.company_user?.fullName }}
          </p>
          <div class="option-header-buttons">
            <VaButton
              round
              :icon="deleteTimeLogLoading ? 'sync' : 'delete'"
              preset="secondary"
              hover-behavior="opacity"
              :hover-opacity="0.4"
              :class="[deleteTimeLogLoading && 'spin-animation']"
              @click="handleDeleteTimeLog"
            />
          </div>
        </div>

        <div class="option-spent-time">
          {{
            formatTimeSeconds(Number(selectedRecord.total_time_spent_seconds))
          }}
          <VaIcon name="timer" color="primary" size="16px" />
        </div>

        <VaDivider v-if="timeSheet" />

        <div v-if="timeSheet" class="option-assign">
          <VaIcon name="link" color="primary" size="16px" />
          <v-menu
            v-model="isItemAssignUpdate"
            :close-on-content-click="false"
            location="top left"
          >
            <template #activator="{ props }">
              <div v-bind="props" class="linked-items-container">
                <div
                  v-if="
                    selectedRecord.calendar_item_linked_items &&
                    selectedRecord.calendar_item_linked_items.length > 0
                  "
                  class="linked-items-container"
                >
                  <div
                    v-for="(
                      item, index
                    ) in selectedRecord.calendar_item_linked_items"
                    :key="index"
                    class="linked-item press-button"
                  >
                    <v-tooltip
                      :text="camelCaseToNormal(item.class_name || '')"
                      location="top"
                    >
                      <template #activator="{ props }">
                        <VaIcon
                          v-bind="props"
                          :name="item.prependIcon"
                          color="primary"
                          size="18px"
                        />
                      </template>
                    </v-tooltip>
                    <span class="linked-item-name">
                      {{ item.name || item.fullName }}
                    </span>
                  </div>
                </div>
                <p v-else class="press-button">Link to</p>
              </div>
            </template>
            <TimeLinkModal
              :linked-items="selectedRecord.calendar_item_linked_items"
              @handleLinkTo="handleLinkToUpdate"
            />
          </v-menu>
        </div>

        <VaDivider />
        <v-menu
          v-model="isItemTypeSelect"
          :close-on-content-click="false"
          location="bottom left"
        >
          <template #activator="{ props }">
            <div v-bind="props" class="press-button">
              <div
                v-if="selectedRecord.item_type_id"
                class="item-type-view"
                :style="{
                  color: selectedRecord.item_type?.color,
                }"
              >
                <font-awesome-icon
                  :icon="selectedRecord.item_type?.icon_name"
                />
                {{ selectedRecord?.item_type?.name }}
              </div>
              <div v-else class="item-type-no-view">
                <VaIcon name="arrow_right_alt" color="primary" size="16px" />
                <p>Select Type</p>
              </div>
            </div>
          </template>
          <TypeSelectModal @handleAssignType="handleAssignType" />
          <p>{{ selectedRecord.item_type_id }}</p>
        </v-menu>
        <VaDivider />

        <div class="option-update">
          <div v-if="!selectedRecord.time_range" class="option-update-time">
            <VaIcon name="manage_history" color="primary" size="16px" />
            <DatePickerModal
              class="Date-Picker-Modal-1"
              :date="selectedRecord.start_time"
              shape="time-record"
              @update:date="handleUpdateStartDate"
            />
            -
            <DatePickerModal
              :date="selectedRecord.end_time"
              shape="time-record"
              @update:date="handleUpdateEndDate"
            />
          </div>

          <div v-else class="option-range-container">
            <VaIcon name="manage_history" color="primary" size="16px" />
            <div class="option-range">
              <VaInput
                v-model="timeRangeUpdate.hours"
                type="number"
                min="0"
                step="1"
                @input="handleRangeTimeUpdateToSec"
              >
                <template #appendInner>
                  <span>hr</span>
                </template>
              </VaInput>
              <p>:</p>
              <VaInput
                v-model="timeRangeUpdate.minutes"
                type="number"
                min="0"
                step="1"
                @input="handleRangeTimeUpdateToSec"
              >
                <template #appendInner>
                  <span>min</span>
                </template>
              </VaInput>
            </div>
          </div>

          <div class="option-update-note">
            <VaIcon name="subject" color="primary" size="16px" />
            <VaInput
              v-model="selectedRecord.description"
              class="note-input"
              placeholder="Description..."
            />
          </div>
        </div>
      </div>
      <template #footer>
        <div class="option-footer-btns">
          <VaButton
            round
            icon="attach_money"
            class="dollar-button"
            :color="selectedRecord.billable ? '#38A169' : '#b8c4d6'"
            @click="selectedRecord.billable = !selectedRecord.billable"
          />
          <VaButton
            preset="primary"
            :loading="updateTimeLogLoading"
            @click="handleUpdateTimeLog"
          >
            Save
          </VaButton>
        </div>
      </template>
    </VaModal>

    <v-data-table-server
      v-model:selection="selectedRows"
      :loading="loading"
      :headers="headers"
      :items="timeLog"
      show-select
      class="custom-table"
      :items-length="totalItems"
      :items-per-page="itemsPerPage"
      :items-per-page-options="[10, 25, 50]"
      @update:options="handleLoadMore"
    >
      <template #[`item.company_user_id`]="{ item }">
        <!-- <p>{{ item?.firstName }}</p> -->
        <p class="company-user-name">
          <va-avatar
            v-if="item.company_user?.avatar_letter"
            class="option-header-avatar"
            color="#E5E9F0"
            size="small"
          >
            <span>
              {{ item.company_user?.avatar_letter }}
            </span> </va-avatar
          >{{ item.company_user?.fullName }}
        </p>
      </template>

      <template #[`item.description`]="{ item }">
        <v-tooltip
          v-if="isLongDescription(item?.description, 100)"
          :text="item?.description"
          location="top"
        >
          <template #activator="{ props }">
            <p v-bind="props" class="description-text">
              {{ truncateText(item?.description, 100) }}
            </p>
          </template>
        </v-tooltip>

        <p v-else class="description-text">
          {{ truncateText(item?.description, 100) }}
        </p>
      </template>

      <template #[`item.created_at`]="{ item }">
        <p>{{ formatDate(item?.created_at) }}</p>
      </template>

      <template #[`item.start_time`]="{ item }">
        <p>{{ formatTime(item?.start_time) }}</p>
      </template>

      <template #[`item.total_time_spent_seconds`]="{ item }">
        <p>{{ formatTimeSeconds(item?.total_time_spent_seconds) }}</p>
      </template>

      <template #[`item.total_billable_hourly_rate_charging_amount`]="{ item }">
        <p>{{ item?.total_billable_hourly_rate_charging_amount }} $</p>
      </template>

      <template v-if="timeSheet" #[`item.linked_to`]="{ item }">
        <td class="matters-leadLawyers-td">
          <v-tooltip
            :text="
              camelCaseToNormal(
                item.calendar_item_linked_items[0]?.class_name || '',
              )
            "
            location="top"
          >
            <template #activator="{ props }">
              <VaIcon
                v-bind="props"
                :name="item.calendar_item_linked_items[0]?.prependIcon"
                color="primary"
                size="26px"
              />
            </template>
          </v-tooltip>
          {{
            item.calendar_item_linked_items[0]?.full_name ||
            item.calendar_item_linked_items[0]?.name
          }}

          <span v-if="item.calendar_item_linked_items.length > 1">
            <VaPopover placement="top" color="#F5F0F3">
              <p>+{{ item.calendar_item_linked_items.length - 1 }} others</p>
              <template #body>
                <span
                  v-for="(
                    calendarItem, index
                  ) in item.calendar_item_linked_items"
                  :key="index"
                  class="matters-leadLawyers-td"
                >
                  <v-tooltip
                    :text="camelCaseToNormal(calendarItem.class_name || '')"
                    location="top"
                  >
                    <template #activator="{ props }">
                      <VaIcon
                        v-bind="props"
                        :name="calendarItem.prependIcon"
                        color="primary"
                        size="26px"
                      />
                    </template>
                  </v-tooltip>

                  {{ calendarItem.full_name || calendarItem.name }}
                </span>
              </template>
            </VaPopover>
          </span>
        </td>
      </template>

      <template #[`item.type`]="{ item }">
        <!-- <div
          v-if="item.item_type_id"
          class="item-type-view"
          :style="{
            color: item.item_type.color,
          }"
        >
          <font-awesome-icon :icon="item.item_type.icon_name" />
          {{ item.item_type.name }}
        </div> -->

        <v-tooltip :text="item.item_type?.name" location="top">
          <template #activator="{ props }">
            <font-awesome-icon
              v-bind="props"
              :color="item.item_type?.color"
              :icon="item.item_type?.icon_name"
            />
          </template>
        </v-tooltip>
      </template>

      <template #[`item.action`]="{ item }">
        <td class="table-action-td">
          <va-dropdown :offset="[0, 50]" close-on-content-click>
            <template #anchor>
              <img
                :src="moreVertIcon"
                alt="MoreVertIcon"
                class="press-button-2"
              />
            </template>

            <va-dropdown-content>
              <p
                class="table-action-option"
                @click="handleToggleEditTimeLog(item)"
              >
                <va-icon name="edit" /> Edit
              </p>
            </va-dropdown-content>
          </va-dropdown>
        </td>
      </template>
    </v-data-table-server>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
