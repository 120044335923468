<template>
  <div class="custom-chip">
    <va-icon name="bookmark" size="large" :color="color" />
    <span class="chip-content">{{ label }}</span>

    <VaIcon name="close" size="small" color="primary" @click="handleClose" />

    <!-- <span class="chip-close" @click="handleClose">✖</span> -->
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
