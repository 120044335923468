<template>
  <VaModal
    v-model="isGlobalConfigUpdate"
    size="auto"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
  >
    <template #header>
      <div class="update-header">
        <h1>Update Option</h1>
        <VaIcon
          class="header-icon press-button"
          name="close"
          size="30px"
          @click="toggleEdit"
        />
      </div>
    </template>
    <VaForm
      ref="updateForm"
      class="update-form"
      @submit.prevent="handleUpdateGlobalConfig"
    >
      <VaInput
        v-model="selectedConfig.name"
        placeholder="Option"
        :rules="[validateInput]"
        class="form-option-name"
      />
      <div class="update-color-icon">
        <VaColorInput
          v-if="isViewColorInput"
          v-model="selectedConfig.color"
          placeholder="Select a color"
          class="form-option-color"
          :rules="[validateColorInput]"
          :formats="['hex']"
          clearable-icon
        />
        <v-menu
          v-if="isViewIconSelect"
          v-model="isSelectIconUpdate"
          :close-on-content-click="false"
          location="bottom left"
        >
          <template #activator="{ props }">
            <VaPopover message="Select Icon" placement="top">
              <div v-bind="props" class="awesome-icon-button press-button-2">
                <font-awesome-icon
                  class="awesome-icon"
                  :icon="selectedConfig.icon_name"
                  color="#66023c"
                  bounce
                />
              </div>
            </VaPopover>
          </template>
          <v-card height="25rem" width="20rem" class="option-card">
            <div class="search">
              <VaInput
                v-model="iconSearchKeyword"
                placeholder="Search..."
                @input="handleIconSearch"
              >
                <template #prependInner>
                  <VaIcon name="search" color="secondary" />
                </template>
              </VaInput>
            </div>
            <p class="scroll-icons">scroll to see more...</p>
            <div class="icons-container">
              <div
                v-for="(icon, index) in filteredIcons"
                :key="index"
                class="icon-box"
                @click="handleSelectIcon(icon, true)"
              >
                <font-awesome-icon
                  class="font-icon"
                  :icon="icon"
                  color="#002c6c"
                />
                <p>{{ icon }}</p>
              </div>
            </div>
          </v-card>
        </v-menu>
      </div>
    </VaForm>
    <template #footer>
      <div class="update-buttons">
        <VaButton preset="secondary" border-color="primary" @click="toggleEdit">
          Cancel
        </VaButton>
        <VaButton
          :loading="isUpdateGlobalConfigLoading"
          @click="handleUpdateGlobalConfig"
        >
          Save
        </VaButton>
      </div>
    </template>
  </VaModal>

  <VaModal
    v-model="isGlobalConfigBool"
    size="small"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
    @update:modelValue="toggleGlobalConfig"
  >
    <template #header>
      <div class="global-config-header">
        <h1>{{ selectedOption.title }}</h1>
        <VaIcon
          class="header-icon press-button"
          name="close"
          size="30px"
          @click="toggleGlobalConfig"
        />
      </div>

      <div class="search">
        <VaInput
          v-model="globalConfigSearch"
          placeholder="Search..."
          @input="handleSearchGlobalConfig"
        >
          <template #prependInner>
            <VaIcon name="search" color="secondary" />
          </template>
        </VaInput>
      </div>
    </template>

    <div class="global-config-body">
      <div class="config-list">
        <ul v-for="(config, index) in filteredGlobalConfig" :key="index">
          <li>
            <VaPopover
              v-if="config.color"
              :message="config.color"
              :color="config.color"
              placement="top"
              class="color-popover"
            >
              <p v-if="isViewIconSelect">
                <font-awesome-icon
                  :icon="iconName || config?.icon_name"
                  :color="config.color"
                />
              </p>

              <VaIcon v-else :name="iconName" :color="config.color" />
            </VaPopover>

            <div v-else>
              <p v-if="isViewIconSelect">
                <font-awesome-icon
                  :icon="iconName || config?.icon_name"
                  color="#66023c"
                />
              </p>

              <VaIcon v-else :name="iconName" color="#66023c" />
            </div>

            <p>{{ config.name }}</p>

            <div
              v-show="showConfirmConfigId !== config.id"
              v-if="
                handleCheckDeletable(config) && loadingConfigId !== config.id
              "
              class="delete-icon"
            >
              <VaIcon
                name="delete"
                color="primary"
                @click="toggleConfirm(config.id)"
              />

              <VaIcon
                name="info"
                color="primary"
                class="header-icon press-button"
                @click="toggleEdit(config)"
              />
            </div>

            <VaIcon
              v-show="showConfirmConfigId !== config.id"
              v-if="
                handleCheckDeletable(config) && loadingConfigId === config.id
              "
              class="loading-icon"
              name="autorenew"
              color="primary"
            />

            <div v-if="showConfirmConfigId === config.id" class="confirm-box">
              <VaIcon name="check" @click="confirmDelete(config.id)" />
              <VaIcon name="close" @click="cancelDelete" />
            </div>
          </li>
        </ul>
      </div>
    </div>

    <VaForm
      ref="form"
      class="global-config-add"
      @submit.prevent="handleAddGlobalConfig"
    >
      <VaInput
        v-model="newOptionForm.name"
        placeholder="Add an option"
        :rules="[validateInput]"
        class="form-option-name"
      />
      <VaColorInput
        v-if="isViewColorInput"
        v-model="newOptionForm.color"
        placeholder="Select a color"
        class="form-option-color"
        :rules="[validateColorInput]"
        :formats="['hex']"
        clearable-icon
      />
      <v-menu
        v-if="isViewIconSelect"
        v-model="isSelectIcon"
        :close-on-content-click="false"
        location="top left"
      >
        <template #activator="{ props }">
          <VaPopover message="Select Icon" placement="top">
            <div v-bind="props" class="awesome-icon-button press-button-2">
              <font-awesome-icon
                class="awesome-icon"
                :icon="selectedIcon"
                color="#66023c"
                bounce
              />
            </div>
          </VaPopover>
        </template>
        <v-card height="25rem" width="20rem" class="option-card">
          <div class="search">
            <VaInput
              v-model="iconSearchKeyword"
              placeholder="Search..."
              @input="handleIconSearch"
            >
              <template #prependInner>
                <VaIcon name="search" color="secondary" />
              </template>
            </VaInput>
          </div>
          <p class="scroll-icons">scroll to see more...</p>
          <div class="icons-container">
            <div
              v-for="(icon, index) in filteredIcons"
              :key="index"
              class="icon-box"
              @click="handleSelectIcon(icon)"
            >
              <font-awesome-icon
                class="font-icon"
                :icon="icon"
                color="#002c6c"
              />
              <p>{{ icon }}</p>
            </div>
          </div>
        </v-card>
      </v-menu>
    </VaForm>

    <template #footer>
      <div class="global-config-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="toggleGlobalConfig"
        >
          Cancel
        </VaButton>
        <VaButton
          :loading="isAddGlobalConfigLoading"
          @click="handleAddGlobalConfig"
        >
          Add
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
