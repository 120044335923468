<template>
  <VaModal
    v-model="isAddContactFormBool"
    size="auto"
    hide-default-actions
    fixed-layout
    @update:modelValue="toggleAddContactForm"
  >
    <template #header>
      <div class="create-form-header">
        <h1>Add Contacts</h1>
        <VaIcon
          class="header-icon press-button"
          name="close"
          size="30px"
          @click="toggleAddContactForm"
        />
      </div>
    </template>
    <VaForm ref="form" class="add-contact-form-modal">
      <div class="add-contact-form-modal-body">
        <div class="left">
          <VaFileUpload v-model="files" file-types="csv,png" undo>
            <div class="custom-upload-file">
              <img :src="UploadAvatarIcon" alt="" />
              <div class="custom-upload-file-text">
                <h1>Upload Profile Picture</h1>
                <h2>RECOMMENDED 1000X1000 PX</h2>
                <h3>Max. 1.3Mb</h3>
                <div>
                  <VaButton
                    preset="secondary"
                    border-color="primary"
                    size="small"
                  >
                    Upload
                  </VaButton>
                </div>
              </div>
            </div>
          </VaFileUpload>

          <VaInput
            v-model="form.individual_title"
            placeholder="Title"
            background="#ECF0F1"
            style="width: 100%"
            label="title"
          />

          <VaInput
            v-model="form.individual_first_name"
            placeholder="First Name"
            background="#ECF0F1"
            style="width: 100%"
            :rules="[validateInput]"
            label="First Name"
            required-mark
          />

          <VaInput
            v-model="form.individual_middle_name"
            placeholder="Middle Name"
            background="#ECF0F1"
            style="width: 100%"
            label="Middle Name"
          />

          <VaInput
            v-model="form.individual_last_name"
            placeholder="Last Name"
            background="#ECF0F1"
            style="width: 100%"
            :rules="[validateInput]"
            label="Last Name"
            required-mark
          />

          <VaInput
            v-model="form.individual_suffix"
            placeholder="Suffix"
            background="#ECF0F1"
            style="width: 100%"
            label="Suffix"
          />

          <VaInput
            v-model="form.individual_job_title"
            placeholder="Job Title"
            background="#ECF0F1"
            style="width: 100%"
            label="job title"
          />

          <VaInput
            v-model="form.individual_tax_identification_number"
            placeholder="Tax Identification Number"
            background="#ECF0F1"
            style="width: 100%"
            label="Tax Identification Number"
          />

          <div class="form-sex">
            <p>Gender</p>
            <VaRadio v-model="form.individual_gender" :options="sexOptions" />
          </div>

          <VaDateInput
            v-model="form.individual_date_of_birth"
            placeholder="MM/DD/YYYY"
            label="Date Of Birth"
            background="#ECF0F1"
            manual-input
          />

          <VaInput
            v-model="form.email_addresses[0].email_address"
            placeholder="Primary Email"
            background="#ECF0F1"
            style="width: 100%"
            :rules="[validateInput, validateInputEmail]"
            label="Primary Email"
            required-mark
          />

          <VaInput
            v-model="form.website_url"
            placeholder="Personal Website"
            background="#ECF0F1"
            style="width: 100%"
            label="Personal Website"
          />

          <VaInput
            v-model="form.phone_numbers[0].phone_number"
            placeholder="Primary Phone Number"
            background="#ECF0F1"
            style="width: 100%"
            label="Primary Phone Number"
            required-mark
            :rules="[validateInput]"
          />

          <VaInput
            v-model="form.individual_nationality"
            placeholder="Nationality"
            background="#ECF0F1"
            style="width: 100%"
            label="Nationality"
          />

          <div>
            <VaSelect
              v-model="form.organization_contact_id"
              placeholder="Link To Organization"
              background="#ECF0F1"
              style="width: 100%; height: auto"
              label="Link To Organization"
              searchable
              clearable
              :highlight-matched-text="false"
              :options="$stores.contacts.organizations"
              :text-by="getOptionTruncateName50"
              :loading="$stores.contacts.organizationsLoading"
              @update:search="handleSearchOrganizations"
            />
          </div>

          <VaSelect
            v-model="form.relationship_owner_id"
            placeholder="Relationship Owner"
            background="#ECF0F1"
            style="width: 100%; height: auto"
            label="Relationship Owner"
            required-mark
            searchable
            :options="$stores.usersData.teamMembers"
            :text-by="getOptionFullName"
            :highlight-matched-text="false"
            value-by="id"
            :rules="[validateSelect]"
            :loading="$stores.usersData.membersLoading"
            @update:search="handleSearchMembers"
          />

          <VaSelect
            v-model="form.assigned_to_id"
            placeholder="Assigned To"
            background="#ECF0F1"
            style="width: 100%; height: auto"
            label="Assigned To"
            required-mark
            searchable
            :options="$stores.usersData.teamMembers"
            :highlight-matched-text="false"
            :text-by="getOptionFullName"
            value-by="id"
            :rules="[validateSelect]"
            :loading="$stores.usersData.membersLoading"
            @update:search="handleSearchMembers"
          />
          <div>
            <VaSelect
              v-model="form.referred_by_id"
              placeholder="Referred by"
              background="#ECF0F1"
              style="width: 100%; height: auto"
              label="Referred by"
              searchable
              clearable
              :highlight-matched-text="false"
              :options="$stores.contacts.contacts"
              :text-by="getOptionFullName"
              :loading="$stores.contacts.contactsLoading"
              @update:search="handleSearchContacts"
            />
          </div>

          <VaTextarea
            v-model="form.notes"
            max-rows="7"
            label="Notes"
            style="width: 100%"
            autosize
            background="#ECF0F1"
            placeholder="Add your note here..."
          />

          <VaAccordion v-model="isAddressAccordion">
            <VaCollapse header="Address" class="address-accordion">
              <div class="address-accordion">
                <div style="width: 65.5%">
                  <VaSelect
                    v-model="form.country"
                    placeholder="Country"
                    background="#ECF0F1"
                    :loading="countriesLoading"
                    style="width: 100%; height: auto"
                    label="Country"
                    required-mark
                    searchable
                    :options="countries"
                    :highlight-matched-text="false"
                    :text-by="getOptionName"
                    value-by="id"
                    :rules="[validateSelect]"
                    @update:modelValue="handleGetState"
                  />
                </div>
                <VaInput
                  v-model="form.contact_addresses_attributes[0].zip_code"
                  placeholder="Zip Code"
                  background="#ECF0F1"
                  style="width: 28.5%; padding-top: 0.3rem"
                  label="Zip Code"
                />

                <VaSelect
                  v-model="form.province_state"
                  placeholder="Province/State"
                  background="#ECF0F1"
                  :loading="statesLoading"
                  style="width: 100%; height: auto"
                  label="Province/State"
                  required-mark
                  searchable
                  :options="states"
                  :highlight-matched-text="false"
                  :text-by="getOptionName"
                  value-by="id"
                  :rules="[validateSelect]"
                  @update:modelValue="handleGetCities"
                />

                <VaSelect
                  v-model="form.contact_addresses_attributes[0].city_id"
                  placeholder="City"
                  background="#ECF0F1"
                  :loading="citiesLoading"
                  style="width: 100%; height: auto"
                  label="City"
                  required-mark
                  searchable
                  :options="cities"
                  :highlight-matched-text="false"
                  :text-by="getOptionName"
                  value-by="id"
                  :rules="[validateSelect]"
                />

                <VaInput
                  v-model="form.contact_addresses_attributes[0].street_name"
                  placeholder="Street"
                  background="#ECF0F1"
                  style="width: 100%"
                  label="Street"
                />

                <VaInput
                  v-model="form.contact_addresses_attributes[0].building_name"
                  placeholder="Building"
                  background="#ECF0F1"
                  style="width: 100%"
                  label="Building"
                />

                <VaInput
                  v-model="form.contact_addresses_attributes[0].phone_number"
                  placeholder="Office Number"
                  background="#ECF0F1"
                  style="width: 48.5%"
                  label="Office Number"
                />

                <VaInput
                  v-model="form.contact_addresses_attributes[0].floor"
                  placeholder="Floor"
                  background="#ECF0F1"
                  style="width: 48.5%"
                  label="Floor"
                />

                <VaInput
                  v-model="form.contact_addresses_attributes[0].google_maps_url"
                  placeholder="Google Maps Link"
                  background="#ECF0F1"
                  style="width: 100%"
                  label="Google Maps Link"
                />
              </div>
            </VaCollapse>
          </VaAccordion>
        </div>

        <VDivider vertical />
        <div class="right">
          <VaCheckbox
            v-model="form.kyc_completed"
            class="mb-6"
            label="KYC Complete"
          />

          <VaTextarea
            v-model="form.kyc_notes"
            max-rows="11"
            label="KYC Notes"
            style="width: 100%"
            autosize
            background="#ECF0F1"
            placeholder="Add your KYC note here..."
          />

          <div class="custom-upload-file-area-kyc-container">
            <h3>KYC DOCUMENTS</h3>
            <VaFileUpload v-model="kyc_documents">
              <div class="custom-upload-file-area-kyc">
                <p class="mb-2">Upload or drag and drop files here</p>
              </div>
            </VaFileUpload>
          </div>

          <VaTextarea
            v-model="form.extra_notes"
            max-rows="11"
            label="Office Notes"
            style="width: 100%"
            autosize
            background="#ECF0F1"
            placeholder="Add your Office note here..."
          />
        </div>
      </div>
    </VaForm>
    <template #footer>
      <div class="add-contact-form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="toggleAddContactForm"
        >
          Cancel
        </VaButton>
        <VaButton
          :loading="addContactLoading"
          @click="validate() && handleCreateContact()"
        >
          Save
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
