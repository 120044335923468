<template>
  <div class="task-card">
    <div class="task-card__title">
      <h1>{{ expense.title }}</h1>
    </div>
    <div class="task-card__info">
      <h4>{{ expense.title }}</h4>
      <p class="value"><span>Value</span> : {{ expense.amount }}$</p>
      <p class="companyName">
        {{ expense.account }}
      </p>
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" />
