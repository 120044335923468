import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75f341e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "member-container" }
const _hoisted_2 = { class: "member-details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionsBar = _resolveComponent("ActionsBar")!
  const _component_va_avatar = _resolveComponent("va-avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ActionsBar, { secondAddButton: "CHAT" }),
    _createVNode(_component_va_avatar, {
      color: "#ECF0F1",
      size: "96px",
      class: "avatar",
      "font-size": "1.75rem"
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.currentMember?.avatar_letter), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.currentMember?.fullName), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.currentMember?.user?.email), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.currentMember?.user?.title), 1)
    ])
  ]))
}