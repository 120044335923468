import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionsBar = _resolveComponent("ActionsBar")!
  const _component_AddLinkModal = _resolveComponent("AddLinkModal")!
  const _component_TasksTable = _resolveComponent("TasksTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ActionsBar, {
      "second-add-button": "Assign a Task",
      onSecondButtonAction: _ctx.toggleAddTask
    }, null, 8, ["onSecondButtonAction"]),
    _createVNode(_component_AddLinkModal, {
      "is-add-item": _ctx.isAddTask,
      "items-list-loading": _ctx.tasksLoading,
      "items-list": _ctx.tasksModal,
      "add-link-loading": _ctx.linkTaskLoading,
      onHandleToggleAddLink: _ctx.toggleAddTask,
      onHandleSearch: _ctx.searchTaskData,
      onHandleLinkItem: _ctx.handleLinkTask
    }, null, 8, ["is-add-item", "items-list-loading", "items-list", "add-link-loading", "onHandleToggleAddLink", "onHandleSearch", "onHandleLinkItem"]),
    _createVNode(_component_TasksTable, {
      loading: _ctx.taskTableLoading,
      "items-per-page": _ctx.tasksItemPerPage,
      "total-items": _ctx.taskTotalItems,
      tasks: _ctx.tasks,
      headers: _ctx.allTasksTemplateHeaders,
      "matter-tasks-section": true,
      onSelectedRows: _ctx.getSelectedRows,
      onHandleTableAction: _ctx.handleTableAction
    }, null, 8, ["loading", "items-per-page", "total-items", "tasks", "headers", "onSelectedRows", "onHandleTableAction"])
  ], 64))
}