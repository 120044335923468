import { MatterTemplateInterface } from '@/Interfaces/Constants'

export function getMonthAndYearFromDate(dateString: string) {
  const date = new Date(dateString)
  const month = date.toLocaleString('default', { month: 'long' })
  const year = date.getFullYear()
  return { month, year }
}

export function removeNullableFields(obj: any) {
  // Iterate through the object entries
  for (const key in obj) {
    // If the value is an object or array, recursively clean it
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      removeNullableFields(obj[key])

      // If it's an array, filter out empty objects or empty strings
      if (Array.isArray(obj[key])) {
        obj[key] = obj[key].filter(
          (item: any) =>
            (typeof item === 'object' && Object.keys(item).length > 0) ||
            item !== '',
        )
      }
      // If the object is now empty after cleaning, delete the property
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key]
      }
    }
    // Remove if the value is null, undefined, or an empty string
    if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
      delete obj[key]
    }
  }
  return obj
}

export function formatTime(isoDateString: string): string {
  const date = new Date(isoDateString)
  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }
  return new Intl.DateTimeFormat('en-US', options).format(date)
}

export function formatTimeSeconds(totalSeconds: number) {
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60

  if (totalSeconds === 0) {
    return 'still running'
  } else if (hours > 0) {
    return `${String(hours).padStart(2)}h ${String(minutes).padStart(
      2,
    )}m ${String(seconds).padStart(2)}s`
  } else if (minutes > 0) {
    return `${String(minutes)}m ${String(seconds).padStart(2)}s`
  } else {
    return `${String(seconds).padStart(2)}s`
  }
}

export function formatDate(
  date: string | Date | null,
  showDay = false
): string | null {
  if (date) {
    const dateObj = typeof date === 'string' ? new Date(date) : date

    if (dateObj instanceof Date && !isNaN(dateObj.getTime())) {
      const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        ...(showDay && { weekday: 'long' }), // Include weekday if showDay is true
      }
      return dateObj.toLocaleDateString('en-US', options)
    }
  }
  return null
}

export function formatDateRange(
  startString: Date | string,
  endString: Date | string
) {
  const start = new Date(startString)
  const end = new Date(endString)

  const isSameDay =
    start.getFullYear() === end.getFullYear() &&
    start.getMonth() === end.getMonth() &&
    start.getDate() === end.getDate()

  const startOptions: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }

  const endOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }

  // Adjust end options if on different days
  if (!isSameDay) {
    endOptions.weekday = 'short'
    endOptions.month = 'short'
    endOptions.day = 'numeric'
  }

  const startDateFormatted = new Intl.DateTimeFormat(
    'en-US',
    startOptions,
  ).format(start)
  const endDateFormatted = endString
    ? new Intl.DateTimeFormat('en-US', endOptions).format(end)
    : 'now'

  return `${startDateFormatted} - ${endDateFormatted}`
}

export function convertToLocalISO(dateString: string | Date) {
  const date = new Date(dateString)
  const newDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    '0'
  )}-${String(date.getDate()).padStart(2, '0')}T${String(
    date.getHours()
  ).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(
    date.getSeconds(),
  ).padStart(2, '0')}.000`
  return newDate
}

export function convertToCalendarFormat(dateString: string | Date) {
  const date = new Date(dateString)
  const newDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    '0'
  )}-${String(date.getDate()).padStart(2, '0')}T${String(
    date.getHours()
  ).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(
    date.getSeconds(),
  ).padStart(2, '0')}.000`
  return newDate.slice(0, 16).replace('T', ' ')
}

export function truncateText(text: string, charLimit: number) {
  if (!text) {
    return ''
  }
  return text.length > charLimit ? text.substring(0, charLimit) + '...' : text
}

export function isLongDescription(text: string, length: number): boolean {
  if (!text) {
    return false
  }
  return text?.length > length
}

export function validateInput(value: string) {
  return value !== null && value !== undefined && value !== ''
}

export function validateInputEmail(value: string) {
  if (!value) {
    return true
  }
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
}

export function validateSelect(value: any) {
  return !!value
}

export function validateColorInput(value: string) {
  return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)
}

export function getOptionName(option: any) {
  return option?.name
}

export function getOptionFullName(option: any) {
  return option?.fullName
}

export function getOptionCurrency(option: any) {
  return option?.code
}

export function getOptionTruncateName50(option: any) {
  return truncateText(option?.name, 50)
}

export function getOptionTruncateName110(option: any) {
  return truncateText(option?.name, 110)
}

export const capitalizeFirst = (str: string) =>
  str?.charAt(0).toUpperCase() + str?.slice(1)

export const toSnakeCase = (str: string) =>
  str.toLowerCase().replace(/\s+/g, '_')

export const camelCaseToNormal = (str: string) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before uppercase letters
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Handle consecutive uppercase letters
    .replace(/^./, (match) => match.toUpperCase()) // Capitalize the first letter
}

export const deepCopy = (obj: any) => {
  return JSON.parse(JSON.stringify(obj)) // Perform a deep copy
}

export const getContrastingTextColor = (hexColor: string) => {
  if (!hexColor) {
    return '#000000'
  }

  const color = hexColor.charAt(0) === '#' ? hexColor.slice(1) : hexColor

  // Convert RGB to decimal
  const r = parseInt(color.substr(0, 2), 16)
  const g = parseInt(color.substr(2, 2), 16)
  const b = parseInt(color.substr(4, 2), 16)

  // Calculate relative luminance
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b

  // Return white for dark backgrounds, black for light backgrounds
  return luminance > 128 ? '#000000' : '#FFFFFF'
}

export const addSecondsToISOTime = (
  isoTime: string,
  secondsToAdd: number
): string => {
  // Parse the ISO string into a Date object
  const date = new Date(isoTime)

  // Add seconds by converting to milliseconds (1 second = 1000 ms)
  date.setSeconds(date.getSeconds() + secondsToAdd)

  // Convert the updated Date back to an ISO string
  return date.toISOString()
}

export const updateDatePart = (
  dateStr: string,
  newDatePart: string
): string => {
  const [datePart, timePart] = dateStr.split('T')
  console.log(datePart)
  return `${newDatePart}T${timePart}`
}

export const formatTemplateFields = (
  selectedFields: MatterTemplateInterface[][],
) => {
  return selectedFields.map((columns, index: number) => ({
    index,
    columns: columns.map((field) => ({
      name: field?.name,
      required: false,
    })),
  }))
}

export const getDueDateByDays = (days = 0) => {
  const currentDate = new Date();
  const dueDate = new Date(currentDate.setDate(currentDate.getDate() + days));
  return dueDate.toLocaleDateString();
};
