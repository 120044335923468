<template>
  <VaModal
    v-model="isAddLeadFormBool"
    size="auto"
    hide-default-actions
    fixed-layout
    @update:modelValue="toggleAddLeadForm"
  >
    <template #header>
      <div class="create-form-header">
        <h1>Add Leads</h1>
        <VaIcon
          class="header-icon press-button"
          name="close"
          size="30px"
          @click="toggleAddLeadForm"
        />
      </div>
    </template>
    <VaForm ref="form" class="form-modal">
      <div class="form-modal-row">
        <VaSelect
          v-model="form.referred_by_id"
          placeholder="Contact"
          background="#ECF0F1"
          label="Contact"
          searchable
          clearable
          :highlight-matched-text="false"
          :options="$stores.contacts.contacts"
          :text-by="getOptionFullName"
          :loading="$stores.contacts.contactsLoading"
          @update:search="handleSearchContacts"
          value-by="id"
        />
      </div>

      <div class="form-modal-row">
        <VaSelect
          v-model="form.organization_contact_id"
          placeholder="Organization"
          background="#ECF0F1"
          label="Organization"
          searchable
          clearable
          :highlight-matched-text="false"
          :options="$stores.contacts.organizations"
          :text-by="getOptionTruncateName50"
          :loading="$stores.contacts.organizationsLoading"
          @update:search="handleSearchOrganizations"
        />
      </div>
      <div class="form-modal-row">
        <VaInput
          v-model="form.title"
          placeholder="Title"
          background="#ECF0F1"
          label="Title"
        />
      </div>

      <div class="form-modal-row">
        <VaInput
          v-model="form.value"
          placeholder="Value"
          background="#ECF0F1"
          label="Value"
        />
        <VaSelect
          v-model="form.currency"
          label="main currency"
          background="#ECF0F1"
          :options="currencies"
          :text-by="getOptionCurrency"
          :loading="currencyLoading"
        />
      </div>
      <div class="form-modal-row">
        <VaSelect
          v-model="form.section_stage_id"
          placeholder="Stage"
          background="#ECF0F1"
          label="Stage"
          :options="stages"
          :highlight-matched-text="false"
          :text-by="getOptionName"
          value-by="id"
        />
      </div>

      <div class="form-modal-row">
        <VaSelect
          v-model="form.label"
          label="Label"
          background="#ECF0F1"
          :options="['Label 1', 'Label 2']"
        />
      </div>

      <div class="form-modal-row">
        <VaInput
          v-model="form.percentage"
          placeholder="Probability"
          background="#ECF0F1"
          label="Probability"
        />
      </div>

      <div class="form-modal-row">
        <VaDateInput
          v-model="form.expected_close_date"
          placeholder="MM/DD/YYYY"
          label="expected close date"
          background="#ECF0F1"
          manual-input
        />
      </div>

      <div class="form-modal-row">
        <VaSelect
          v-model="form.relationship_owner_id"
          placeholder="Relationship Owner"
          background="#ECF0F1"
          label="Relationship Owner"
          searchable
          :options="$stores.usersData.teamMembers"
          :text-by="getOptionFullName"
          :highlight-matched-text="false"
          value-by="id"
          :loading="$stores.usersData.membersLoading"
          @update:search="handleSearchMembers"
        />
      </div>

      <div class="form-modal-row">
        <VaDateInput
          v-model="form.renewal_date"
          placeholder="MM/DD/YYYY"
          label="renewal date"
          background="#ECF0F1"
          manual-input
        />
      </div>
    </VaForm>
    <template #footer>
      <div class="form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="toggleAddLeadForm"
        >
          Cancel
        </VaButton>
        <VaButton
          :loading="addLeadLoading"
          @click="validate() && handleCreateLead()"
        >
          Save
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
