import { HearingsPresenter } from '@/presenters/hearingsPresenter'
import { apiRequest } from './apiUtils'

export const getAllHearings = async ({
  per_page,
  page,
  keyword,
  legal_matter_id,
}: {
  per_page: number
  page?: number
  keyword?: string
  legal_matter_id?: string
}) => {
  const response = await apiRequest('get', '/hearings', null, {
    per_page,
    page,
    keyword,
    legal_matter_id,
  })

  if (!response.success) {
    return response
  }

  const list = response.data?.list
  if (!Array.isArray(list)) {
    return response
  }

  return {
    ...response,
    data: {
      ...response.data,
      list: list.map((item: HearingsPresenter) => new HearingsPresenter(item)),
    },
  }
}

export const getHearingById = async (id: string) => {
  const response = await apiRequest('get', `/hearings/${id}`)
  if (response.success) {
    return {
      ...response,
      data: new HearingsPresenter(response.data),
    }
  } else {
    return response
  }
}

export const addHearing = (body: any) => {
  return apiRequest('post', '/hearings', body)
}

export const updateHearing = (id: string, body: any) => {
  return apiRequest('put', `/hearings/${id}`, body)
}

export const deleteHearing = (id: string) => {
  return apiRequest('delete', `/hearings/${id}`)
}
