import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/approved-icon.svg'


const _withScopeId = n => (_pushScopeId("data-v-507279df"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table" }
const _hoisted_2 = { class: "col-8" }
const _hoisted_3 = { class: "table" }
const _hoisted_4 = { class: "table-header" }
const _hoisted_5 = { class: "header-row" }
const _hoisted_6 = {
  key: 0,
  class: "icon",
  src: _imports_0
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 2,
  class: "link"
}
const _hoisted_9 = {
  key: 3,
  class: "actions"
}
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 4,
  class: "cell"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_rawDisplayer = _resolveComponent("rawDisplayer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("table", _hoisted_3, [
        _createElementVNode("thead", _hoisted_4, [
          _createElementVNode("tr", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
              return (_openBlock(), _createElementBlock("th", {
                key: header,
                scope: "col"
              }, _toDisplayString(header.name), 1))
            }), 128))
          ])
        ]),
        _createVNode(_component_draggable, {
          modelValue: _ctx.list,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
          tag: "tbody",
          "item-key": "name"
        }, {
          item: _withCtx(({ element }) => [
            _createElementVNode("tr", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
                return (_openBlock(), _createElementBlock("td", {
                  key: header.feild
                }, [
                  (header.feild === 'approved' && element[header.feild])
                    ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                    : (
                    header.feild === 'approved' && !element[header.feild]
                  )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7))
                      : (header.feild === 'linkTo')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createVNode(_component_router_link, {
                              to: `/legal-matter/${element.id}`
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(element[header.feild]), 1)
                              ]),
                              _: 2
                            }, 1032, ["to"])
                          ]))
                        : (header.feild === 'actions')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                              _createElementVNode("img", {
                                src: _ctx.MoreVertIcon,
                                alt: "MoreVertIcon",
                                class: "press-button-2"
                              }, null, 8, _hoisted_10)
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(element[header.feild]), 1))
                ]))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ]),
    _createVNode(_component_rawDisplayer, {
      class: "col-3",
      value: _ctx.list,
      title: "List"
    }, null, 8, ["value"])
  ]))
}