<template>
  <div v-if="stages?.length > 0" class="kanban-row">
    <div
      v-for="(stage, stageIndex) in stages"
      :key="stage?.id"
      :data-stage-index="stageIndex"
      class="kanban-column"
    >
      <div
        class="kanban-column-header"
        :style="{
          color: stage?.color || 'black',
          borderBottom: '0.5rem solid ' + (stage?.color || 'red'),
        }"
      >
        <h3>{{ stage?.name }}</h3>
        <!-- {{ stage.section_stage_items_count }} -->
        {{ getTicketCount(stageIndex) }}
      </div>
      <div v-if="isCardsLoading" class="kanban-list-group">
        <VaSkeleton
          style="border-radius: 8px"
          inline
          width="269px"
          height="153px"
        />
        <VaSkeleton
          style="border-radius: 8px"
          inline
          width="269px"
          height="153px"
        />
      </div>
      <draggable
        v-model="stages[stageIndex].section_stage_items"
        group="section_stage_items"
        class="kanban-list-group draggable-area"
        :data-stage-id="stage.id"
        @end="onDragEnd"
      >
        <template v-if="isTasks" #item="{ element }">
          <TaskCard :task="element" />
        </template>

        <template v-else-if="isLegalMatters" #item="{ element }">
          <LegalMatterCard :matter="element" />
        </template>

        <template v-else-if="isContacts" #item="{ element }">
          <ContactCard :matter="element" />
        </template>
      </draggable>
    </div>
  </div>

  <div v-else-if="showLoading" class="kanban-row">
    <VaSkeleton
      v-for="index in 5"
      :key="index"
      width="300px"
      height="33rem"
      delay="1"
    />
  </div>

  <div v-else-if="showNoStages" class="kanban-column">
    <div
      class="kanban-column-header"
      :style="{
        color: 'black',
        borderBottom: '0.5rem solid red',
      }"
    >
      <h3>No Stages yet...</h3>
    </div>
    <div class="empty-stage">
      <VaButton
        preset="secondary"
        border-color="primary"
        icon="add"
        @click="handleAddStage"
      >
        Add stage
      </VaButton>
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss"></style>
