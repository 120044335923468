import { defineComponent } from 'vue'
import TabsBar from '@/components/tabsBar/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import { useForm } from 'vuestic-ui'
import {
  deleteContact,
  getContactById,
  updateOrganizationContact,
} from '@/services/contacts'
import ComingSoon from '@/components/ComingSoon/index.vue'
import { getAllCities, getAllCountries, getAllStates } from '@/services/address'
import {
  getOptionFullName,
  getOptionName,
  getOptionTruncateName50,
  truncateText,
  validateInput,
  validateInputEmail,
  validateSelect,
} from '@/lib/utils'
import { useTabs } from '@/composables/useTabs'
import { OrganizationSpecificData } from '@/presenters/interface'
import { OrganizationContactPresenter } from '@/presenters/organizationPresenter'
import { CountryPresenter } from '@/presenters/AddressPresenter/CountryPresenter'

const verifiedImage = new URL(
  '@/assets/icons/verified-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'OrganizationDetailsPage',
  components: {
    TabsBar,
    SharedHeader,
    ComingSoon,
  },

  setup() {
    const { isValid, validate, reset, resetValidation } = useForm(
      'organizationDetailsFormRef',
    )
    return { isValid, validate, reset, resetValidation }
  },
  data() {
    const tabs = [
      { id: 0, name: 'DASHBOARD', icon: 'dashboard' },
      { id: 1, name: 'ORGANIZATION INFO', icon: 'info' },
      { id: 2, name: 'LEADS', icon: 'my_location' },
      { id: 12, name: 'LEGAL MATTERS', icon: 'inventory_2' },
      { id: 3, name: 'TASKS', icon: 'assignment' },
      { id: 4, name: 'CALENDAR', icon: 'calendar_month' },
      { id: 5, name: 'MILESTONES', icon: 'flag' },
      { id: 6, name: 'FILES', icon: 'file_copy' },
      { id: 7, name: 'FINANCIAL', icon: 'attach_money' },
      { id: 8, name: 'HEARINGS', icon: 'gavel' },
      { id: 9, name: 'ENGAGEMENT LETTER', icon: 'article' },
      { id: 10, name: 'EMAILS', icon: 'mail' },
      { id: 11, name: 'KYC DOCUMENTS', icon: 'article' },
    ]

    const { activeTabId, activeTab, changeTab } = useTabs(tabs, 1)
    return {
      validateInput,
      validateInputEmail,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionTruncateName50,

      verifiedImage,
      loading: false,
      tabs,
      activeTabId,
      activeTab,
      changeTab,
      countries: [] as CountryPresenter[],
      states: [] as CountryPresenter[],
      cities: [] as CountryPresenter[],
      countriesLoading: false,
      statesLoading: false,
      citiesLoading: false,
      loadingUpdate: false,

      organizationTypeOptions: [
        'Sole Proprietorship',
        'Individual Intrapreneur',
        'Freelancer',
        'Partnership',
        'General Partnership',
        'Limited Partnership',
      ],

      contactTypeOptions: [
        { id: 0, title: 'Person' },
        { id: 1, title: 'Organization' },
      ],
      isAddressAccordion: [false],
      isBankAccountAccordion: [false],
      isDeleteModal: false,

      form: {
        specific_data: {
          organization_about: '',
          organization_date_of_foundation: '',
          organization_industry: '',
          organization_jurisdiction_of_incorporation: '',
          organization_logo: {},
          organization_name: '',
          organization_owner_email: '',
          organization_registration_number: '',
          organization_tax_identification_number: '',
          organization_type: '',
        } as OrganizationSpecificData,
      } as OrganizationContactPresenter,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,
      truncateText,
    }
  },
  mounted() {
    this.handleGetContactByID()
    this.handleGetMembers()
    this.handleGetAllCountries()
    this.handleGetOrganizations()
    this.handleGetContacts()
  },

  methods: {
    async handleGetContactByID() {
      this.loading = true
      const response = await getContactById(this.$route.params.id as string)
      if (response.success) {
        this.form = response.data
        console.log({ data: response.data })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.loading = false
    },
    ////////////////////////////////////////////////////////////////////////////////////////////
    async handleGetMembers(search?: string) {
      this.$stores.usersData.handleGetMembers({
        keyword: search,
      })
    },

    handleSearchMembers(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMembers(search)
      }, 500)
    },
    ////////////////////////////////////////////////////////////////////////////////////////////
    async handleGetAllCountries() {
      this.countriesLoading = true
      const response = await getAllCountries()

      if (response.success) {
        this.countries = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.countriesLoading = false
    },
    async handleGetState(id: string) {
      this.statesLoading = true
      const response = await getAllStates(id)

      if (response.success) {
        this.states = response.data.list
      } else {
        // this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.statesLoading = false
    },
    async handleGetCities(id: string) {
      this.citiesLoading = true
      const response = await getAllCities(id)

      if (response.success) {
        this.cities = response.data.list
      } else {
        // this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.citiesLoading = false
    },
    ////////////////////////////////////////////////////////////////////////////////////////////

    async handleDelete() {
      const response = await deleteContact(this.$route.params.id as string)
      if (response.success) {
        this.$router.push({ name: 'ContactsView' })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
    },
    handleBack() {
      this.$router.back()
    },
    async handleUpdate() {
      this.loadingUpdate = true
      const updatedEmailArr = this.form.email_addresses?.map(
        ({ email, ...rest }: any) => ({
          email_address: email,
          ...rest,
        }),
      )

      const {
        assigned_to,
        contact_addresses,
        contact_bank_accounts,
        designated_point_of_contact,
        specific_data,
        relationship_owner,
        referred_by,
        id,
        organization_contact,
        phone_numbers,
        kyc_completed,
        kyc_notes,
        kyc_document,
        notes,
        extra_notes,
        facebook_url,
        twitter_url,
        google_plus_url,
        linkedin_url,
        instagram_url,
        tiktok_url,
        youtube_url,
        website_url,
      } = this.form

      const { organization_logo, ...UpdatedSpecific_data } = specific_data

      console.log({ id, kyc_document, organization_logo })

      const response = await updateOrganizationContact({
        id: this.$route.params.id as string,
        body: {
          assigned_to_id: assigned_to?.id,
          relationship_owner_id: relationship_owner?.id,
          designated_point_of_contact_id: designated_point_of_contact?.id,
          referred_by_id: referred_by?.id,
          organization_contact_id: organization_contact?.id,

          ...UpdatedSpecific_data,
          email_addresses: updatedEmailArr,
          phone_numbers,
          ...(this.isAddressAccordion[0] && {
            contact_addresses_attributes: contact_addresses
              .slice(0, 1)
              .map(
                ({
                  id,
                  city,
                  phone_number,
                  floor,
                  building_name,
                  street_name,
                  zip_code,
                  google_maps_url,
                }: any) => ({
                  id,
                  city_id: city.id,
                  phone_number,
                  floor,
                  building_name,
                  street_name,
                  zip_code,
                  google_maps_url,
                }),
              ),
          }),
          ...(this.isBankAccountAccordion[0] && {
            contact_bank_accounts_attributes: [
              (({
                iban,
                swift_or_bic,
                account_number,
                bank_name,
                branch_name,
                branch_country,
                routing_number,
                short_code,
                bsb_number,
                ifcs_code,
                transit_number,
                clabe,
                extra_notes,
              }) => ({
                iban,
                swift_or_bic,
                account_number,
                bank_name,
                branch_name,
                branch_country,
                routing_number,
                short_code,
                bsb_number,
                ifcs_code,
                transit_number,
                clabe,
                extra_notes,
              }))(contact_bank_accounts[0]),
            ],
          }),
          kyc_completed,
          kyc_notes,
          notes,
          extra_notes,
          facebook_url,
          twitter_url,
          google_plus_url,
          linkedin_url,
          instagram_url,
          tiktok_url,
          youtube_url,
          website_url,
        },
      })
      if (response.success) {
        this.$vaToast.init({
          message: 'Contact updates successfully!',
          color: 'success',
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.loadingUpdate = false
    }, ////////////////////////////////////////////////////////////////
    async handleGetContacts(search?: string) {
      this.$stores.contacts.handleGetContacts({
        keyword: search,
      })
    },
    handleSearchContacts(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts(search)
      }, 500)
    },
    /////////////////////////////////////////////////////////////////////
    async handleGetOrganizations(search?: string) {
      this.$stores.contacts.handleGetOrganizations({
        keyword: search,
      })
    },
    handleSearchOrganizations(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetOrganizations(search)
      }, 500)
    },
  },
})
