import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ChatActivity',
  data() {
    return {
      enabled: false,
    }
  },
})
