import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OwnerAssignToComponent',
  props: {
    shape: {
      type: String,
      default: 'button',
      validator: (value: string) => {
        return ['button', 'icon'].includes(value)
      },
    },
    list: {
      type: Array,
      required: true,
    },
  },
  emits: ['handleAssign'],
  data() {
    return {
      value: '',
      menuOwner: false,
    }
  },
  computed: {
    filteredAssignees(): any {
      return this.list.filter(
        (person: any) =>
          person.class_name === 'User' &&
          (person.first_name.toLowerCase().includes(this.value.toLowerCase()) ||
            person.last_name.toLowerCase().includes(this.value.toLowerCase())),
      )
    },
    filteredOrganizations(): any {
      return this.list.filter(
        (person: any) =>
          person.role === 'Organization' &&
          (person.first_name.toLowerCase().includes(this.value.toLowerCase()) ||
            person.last_name.toLowerCase().includes(this.value.toLowerCase())),
      )
    },
  },
  methods: {
    handleChooseOwner(data: any) {
      this.$emit('handleAssign', data)
      this.value = ''
      this.menuOwner = false
    },
  },
})
