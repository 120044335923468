import { defineComponent } from 'vue'
import DatePickerModal from '@/components/DatePickerModal/index.vue'
import { useForm } from 'vuestic-ui/web-components'
import { addUpdateCalendarItemInterface } from '@/Interfaces/Services'
import { addSecondsToISOTime, camelCaseToNormal, deepCopy } from '@/lib/utils'
import { calendarGlobalSearchInterface } from '@/Interfaces/Store'
import TimeLinkModal from '@/components/TimeRecordModal/TimeLinkModal/index.vue'
import { calendar_item_linked_items_attributesInterface } from '@/Interfaces/layout'

type timeRangeInterface = {
  hours: string | number
  minutes: string | number
}

export default defineComponent({
  name: 'UpdateEventModal',
  components: {
    DatePickerModal,
    TimeLinkModal,
  },
  props: {
    selectedEvent: {
      type: Object,
      required: true,
    },
    updateCalendarLoading: {
      type: Boolean,
      default: false,
    },
    isUpdateCalendarForm: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggleUpdateCalendarForm', 'handleUpdateEvent'],
  data() {
    const { validate } = useForm('form')

    return {
      camelCaseToNormal,
      validate,
      deepCopy,
      isUpdateCalendarFormBool: this.isUpdateCalendarForm,

      activeType: 0,

      reminderOptions: ['15 minutes', ' 30 minutes', '1 hour'],

      form: {} as addUpdateCalendarItemInterface,

      isCalendarAssign: false,

      isItemAssignUpdate: false,

      timeRangeUpdate: {
        hours: 0,
        minutes: 0,
      } as timeRangeInterface,
    }
  },
  watch: {
    isUpdateCalendarForm: {
      handler(data) {
        this.isUpdateCalendarFormBool = data

        this.form = deepCopy(this.selectedEvent)

        if (this.form.time_range) {
          const seconds = this.form.total_time_spent_seconds
          if (seconds) {
            const hours = Math.floor(seconds / 3600)
            const minutes = Math.floor((seconds % 3600) / 60)

            this.timeRangeUpdate.hours = hours
            this.timeRangeUpdate.minutes = minutes
          }
        }

        this.activeType = JSON.parse(
          JSON.stringify(this.selectedEvent),
        )?.item_type?.id
      },
    },
    form: {
      handler(newForm) {
        console.log('Form updated:', newForm)
      },
      deep: true,
    },
  },
  methods: {
    toggleUpdateCalendarForm() {
      this.isUpdateCalendarFormBool = !this.isUpdateCalendarForm
      this.$emit('toggleUpdateCalendarForm')
    },

    selectType(id: number) {
      this.activeType = id
      this.form.item_type_id = id
    },

    handleUpdateStartDate(date: { date: string }) {
      this.form.start_time = date.date
    },
    handleUpdateEndDate(date: { date: string }) {
      this.form.end_time = date.date
    },

    handleUpdateEvent() {
      this.$emit('handleUpdateEvent', this.form)
      console.log({ form: this.form })
    },

    toggleLinkToEvent() {
      this.isCalendarAssign = !this.isCalendarAssign
    },

    handleLinkToUpdate(data: {
      selectedItems: calendarGlobalSearchInterface[]
      calendar_item_linked_items_attributes: calendar_item_linked_items_attributesInterface[]
    }) {
      this.form.calendar_item_linked_items = data.selectedItems
      this.form.calendar_item_linked_items_attributes =
        data.calendar_item_linked_items_attributes
    },

    handleRangeTimeUpdateToSec() {
      let { hours, minutes } = this.timeRangeUpdate

      hours = Number(hours) || 0
      minutes = Number(minutes) || 0

      // both = 0 => minutes = 1
      const adjustedHours = hours >= 0 ? hours : 0
      const adjustedMinutes = minutes > 0 || hours > 0 ? minutes : 1

      //  time in seconds
      if (adjustedHours >= 0 && adjustedMinutes >= 0) {
        const totalSeconds = adjustedHours * 3600 + adjustedMinutes * 60

        this.form.total_time_spent_seconds = totalSeconds
        this.form.time_range = true

        // Set start_time to midnight UTC

        this.form.start_time =
          this.form.start_time && new Date(this.form.start_time).toISOString()

        this.form.end_time =
          this.form.start_time &&
          addSecondsToISOTime(this.form.start_time, totalSeconds)
      }
    },
  },
})
