import { defineComponent } from 'vue'
import Table from '@/components/foldersTable/index.vue'
import TabsBar from '@/components/tabsBar/index.vue'
import SearchAndViews from '@/components/searchAndViews/index.vue'
import ActionsBar from '@/components/actionsBar/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import FilesGrid from '@/components/filesGrid/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import draggable from 'vuedraggable'
import { useTabs } from '@/composables/useTabs'
import {
  addStorageCategory,
  getAllStorageCategories,
  updateStorageCategory,
} from '@/services/storageCategories'
import { StorageCategoryPresenter } from '@/presenters/StorageCategoryPresenter'
import { StorageFoldersPresenter } from '@/presenters/StorageFoldersPresenter'
import {
  StorageCategoriesFormInterface,
  StorageFoldersFormInterface,
} from '@/presenters/interface'
import FileCard from '@/components/fileCard/index.vue'
import FolderCard from '@/components/folderCard/index.vue'
import { LocationQueryValue } from 'vue-router'
import {
  addStorageFolder,
  getStorageFolderById,
  updateStorageFolder,
} from '@/services/storageFolders'
import { formatDate, validateInput } from '@/lib/utils'
import { useForm } from 'vuestic-ui/web-components'

export default defineComponent({
  name: 'ContactsPage',
  components: {
    Table,
    TabsBar,
    SearchAndViews,
    ActionsBar,
    SharedHeader,
    ComingSoon,
    FilesGrid,
    draggable,
    FileCard,
    FolderCard,
  },
  data() {
    const tabs = [] as StorageCategoryPresenter[]

    const { activeTabId, activeTab, changeTab } = useTabs(tabs)

    const { validate: folderCategory } = useForm('folderForm')
    const { validate: categoryValidate } = useForm('categoryForm')

    return {
      tabs,
      activeTabId,
      activeTab,
      changeTab,

      validateInput,
      folderCategory,
      categoryValidate,
      formatDate,

      activeView: 'board',

      categoriesLoading: false,
      FoldersLoading: false,
      isEditCategories: false,
      categoriesUpdateLoading: false,
      categoriesFoldersLoading: false,

      storageFolders: [] as StorageFoldersPresenter[],
      storageFiles: [] as StorageFoldersPresenter[],

      editedCatIndex: null as number | null,
      editedName: '',

      breadCrumbItems: [{ id: 0, name: 'Home' }],

      isAddCategory: false,
      addCategoryLoading: false,
      categoryForm: {} as StorageCategoriesFormInterface,

      isAddFolder: false,
      addFolderLoading: false,
      folderForm: {} as StorageFoldersFormInterface,

      isFolderInfo: false,
      targetedFolder: {} as StorageFoldersPresenter,
      isEditing: false, // Tracks if we are in edit mode
      descriptionDraft: '',
    }
  },
  computed: {
    editedNameError(): boolean {
      return this.editedName.trim().length === 0
    },
    breadCrumbItemsText(): string {
      return this.breadCrumbItems.map((item) => item.name).join(' > ')
    },
  },
  watch: {
    activeTabId: {
      handler() {
        console.log({ activeTabId: this.activeTabId })
      },
    },
    isFolderInfo: {
      handler(data) {
        if (!data) {
          this.targetedFolder = {} as StorageFoldersPresenter
        }
      },
    },
    '$route.query.tab': async function (newTab) {
      const theFoundTab = this.tabs.find((tab) => tab.name === newTab)
      this.breadCrumbItems = [{ id: 0, name: 'Home' }]

      if (theFoundTab) {
        this.activeTabId = theFoundTab.id as number
        this.activeTab = theFoundTab.name
      }
    },
  },
  mounted() {
    this.handleGetStorageCategories()
  },
  methods: {
    changeView(view: string) {
      this.activeView = view
    },

    searchData(search: string) {
      console.log(search)
    },

    //////////////////////////////////////////////////////////

    handleToggleAddFolder() {
      this.isAddFolder = !this.isAddFolder
      this.folderForm = {} as StorageFoldersFormInterface
    },

    handleToggleAddCategory() {
      this.isAddCategory = !this.isAddCategory
      this.categoryForm = {} as StorageCategoriesFormInterface
    },

    ///////////////////////// category /////////////////////////////////

    async handleGetStorageCategories(loading = true) {
      if (loading) {
        this.categoriesLoading = true
      }
      const response = await getAllStorageCategories()
      if (response.success) {
        if (response.data?.list.length > 0) {
          this.tabs = response.data?.list

          // it should be in this function cuz its on mount
          const queryTab = this.$route.query.tab
          const foundTab = queryTab
            ? this.tabs.find(
                (tab: { name: string | LocationQueryValue[] }) =>
                  tab.name === queryTab,
              )
            : null

          if (foundTab) {
            this.activeTabId = foundTab.id as number
            this.activeTab = foundTab.name
            this.storageFolders = foundTab.storage_folders || []
            this.storageFiles = foundTab.storage_files || []
          } else {
            // Default to the first tab
            const firstTab = this.tabs[0]
            this.activeTabId = firstTab.id as number
            this.activeTab = firstTab.name
            this.storageFolders = firstTab.storage_folders || []
            this.storageFiles = firstTab.storage_files || []
          }
        }
        this.breadCrumbItems = [{ id: 0, name: 'Home' }]
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.categoriesLoading = false
    },

    async handleAddCategory() {
      this.addCategoryLoading = true

      const response = await addStorageCategory(this.categoryForm)

      if (response.success) {
        this.handleGetStorageCategories()

        this.handleToggleAddCategory()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.addCategoryLoading = false
    },

    async handleUpdateStorageCategory(
      id: number,
      body: StorageCategoriesFormInterface,
    ) {
      this.categoriesUpdateLoading = true

      const response = await updateStorageCategory(id, body)

      if (response.success) {
        this.handleGetStorageCategories(false)
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.categoriesUpdateLoading = false
    },

    ///////////////////////////////////// folder ////////////////////////////////////////////

    async handleGetStorageFolders(id: number) {
      this.categoriesFoldersLoading = true

      const response = await getStorageFolderById(id)

      if (response.success) {
        this.storageFolders = response.data?.storage_folders
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.categoriesFoldersLoading = false
    },

    async handleAddFolder() {
      this.addFolderLoading = true

      let parent_data: {
        parent_type: 'StorageFolder' | 'StorageCategory'
        parent_id: number
      }

      if (this.breadCrumbItems.length > 1) {
        parent_data = {
          parent_type: 'StorageFolder',
          parent_id: this.breadCrumbItems[this.breadCrumbItems.length - 1].id,
        }
      } else {
        parent_data = {
          parent_type: 'StorageCategory',
          parent_id: this.activeTabId,
        }
      }

      const response = await addStorageFolder({
        ...this.folderForm,
        ...parent_data,
      })

      if (response.success) {
        parent_data.parent_type === 'StorageCategory'
          ? this.handleGetStorageCategories(false)
          : this.handleGetStorageFolders(
              this.breadCrumbItems[this.breadCrumbItems.length - 1].id,
            )

        this.handleToggleAddFolder()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.addFolderLoading = false
    },

    async handleUpdateStorageFolder(folder?: StorageFoldersPresenter) {
      this.categoriesUpdateLoading = true

      let response
      if (folder) {
        // more scalable if there was large amount of folders
        const folderIndex = this.storageFolders.findIndex(
          (storageFolder) => storageFolder.id === folder.id,
        )
        if (folderIndex !== -1) {
          this.storageFolders[folderIndex].name = folder.name
        }

        response = await updateStorageFolder(folder.id, {
          name: folder.name,
        })
      } else {
        response = await updateStorageFolder(this.targetedFolder.id, {
          description: this.descriptionDraft,
        })
      }

      if (response.success) {
        this.isEditing = false
        this.targetedFolder.description = this.descriptionDraft

        this.breadCrumbItems.length > 1
          ? this.handleGetStorageFolders(
              this.breadCrumbItems[this.breadCrumbItems.length - 1].id,
            )
          : this.handleGetStorageCategories(false)
      } else {
        this.breadCrumbItems.length > 1
          ? this.handleGetStorageFolders(
              this.breadCrumbItems[this.breadCrumbItems.length - 1].id,
            )
          : this.handleGetStorageCategories(false)
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.categoriesUpdateLoading = false
    },

    /////////////////////////////////////////////////////////////////////////////////

    onDragEnd(event: {
      newIndex: number
      oldIndex: number
      item: { _underlying_vm_: StorageFoldersPresenter }
    }) {
      const {
        newIndex,
        oldIndex,
        item: { _underlying_vm_ },
      } = event

      const sort_order = newIndex >= oldIndex ? newIndex + 1 : newIndex

      this.handleUpdateStorageCategory(_underlying_vm_.id as number, {
        name: _underlying_vm_.name,
        sort_order,
      })
    },

    editName(index: null | number, currentName: string) {
      this.editedCatIndex = index
      this.editedName = currentName
    },
    submitEdit(catId: number) {
      if (this.editedCatIndex !== null && this.editedName.trim() !== '') {
        this.tabs[this.editedCatIndex].name = this.editedName.trim()
        this.handleUpdateStorageCategory(catId, {
          name: this.editedName,
        })
      }
      this.cancelEdit()
    },

    cancelEdit() {
      this.editedCatIndex = null
      this.editedName = ''
    },
    ///////////////////////////////////////////////////////////////////////////////
    async handleTabChange(tab: StorageFoldersPresenter) {
      // Update active tab data
      this.activeTabId = tab.id as number
      this.activeTab = tab.name

      // Update storage folders and files based on the selected tab
      this.storageFolders = tab.storage_folders || []
      this.storageFiles = tab.storage_files || []

      // Update the query parameter in the route
      this.$router.push({ query: { tab: tab.name } })
    },
    ////////////////////////////////////////////////////////////////////////////////
    handleOpenFolder(folder: StorageFoldersPresenter) {
      this.breadCrumbItems.push({
        id: folder.id as number,
        name: folder.name,
      })
      this.handleGetStorageFolders(folder.id as number)
    },

    handleBackButton() {
      // If we're already at Home or have only one item, do nothing
      if (this.breadCrumbItems.length <= 1) {
        return
      }

      // Remove the last item
      this.breadCrumbItems = this.breadCrumbItems.slice(0, -1)

      // Get the ID of the previous folder (last item in the updated breadcrumbs)
      const previousFolderId =
        this.breadCrumbItems[this.breadCrumbItems.length - 1].id

      if (previousFolderId === 0) {
        this.breadCrumbItems = [{ id: 0, name: 'Home' }]
        const queryTab = this.$route.query.tab
        const foundTab = queryTab
          ? this.tabs.find(
              (tab: { name: string | LocationQueryValue[] }) =>
                tab.name === queryTab,
            )
          : null
        console.log({ foundTab })

        if (foundTab) {
          this.storageFolders = foundTab.storage_folders || []
          this.storageFiles = foundTab.storage_files || []
        }
        return
      } else {
        // Call handleGetStorageFolders with the previous folder's ID
        this.handleGetStorageFolders(previousFolderId)
      }
    },

    handleBreadcrumbClick(item: { id: number; name: string }, index: number) {
      // If it's the "Home" item (first item with id 0)
      if (item.id === 0) {
        this.breadCrumbItems = [{ id: 0, name: 'Home' }]
        const queryTab = this.$route.query.tab
        const foundTab = queryTab
          ? this.tabs.find(
              (tab: { name: string | LocationQueryValue[] }) =>
                tab.name === queryTab,
            )
          : null

        if (foundTab) {
          this.storageFolders = foundTab.storage_folders || []
          this.storageFiles = foundTab.storage_files || []
        }
        return
      }

      // If it's the last item in the breadcrumbs, do nothing
      if (index === this.breadCrumbItems.length - 1) {
        return
      }

      // For any other item in between, slice the array up to and including that item
      this.breadCrumbItems = this.breadCrumbItems.slice(0, index + 1)
      this.handleGetStorageFolders(item.id)
    },
    ////////////////////////////////////////////////////////////////////////////////
    handleFileInfo(file: StorageFoldersPresenter) {
      console.log({ file })

      this.targetedFolder = file
      this.isFolderInfo = true
    },
    // saveDescription() {
    //   this.isEditing = false

    //   this.targetedFolder.description = this.descriptionDraft
    // },
    enterEditMode() {
      this.isEditing = true
      this.descriptionDraft = this.targetedFolder.description || ''
    },
    cancelEditDescription() {
      this.isEditing = false
    },
  },
})
