import { defineComponent } from 'vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import SearchAndViews from '@/components/searchAndViews/index.vue'
import ActionsBar from '@/components/actionsBar/index.vue'
import HearingsTable from '@/components/Hearing/ListView/index.vue'
import HearingCards from '@/components/Hearing/CardView/index.vue'
import AddHearingModal from '@/components/Hearing/AddHearingModal/index.vue'
import { addHearing, getAllHearings } from '@/services/hearings'
import { paginationInterface } from '@/Interfaces/Services'

export type Card = {
  id: number
  title: string
  date: string
  assignedTo: string
  status: string
  client: string
  lawyer: string
}

export default defineComponent({
  name: 'HearingsPage',
  components: {
    SharedHeader,
    SearchAndViews,
    ActionsBar,
    HearingsTable,
    HearingCards,
    AddHearingModal,
  },
  data() {
    const cardsList: Card[] = [
      {
        id: 1,
        title: 'Intra Case',
        date: '2-jan-2024',
        assignedTo: 'Tarek',
        status: 'In Progress',
        client: 'Julien Sfeir',
        lawyer: 'Antoine Kanaan',
      },
      {
        id: 12,
        title: 'Patent Dispute',
        date: '3-sep-2024',
        assignedTo: 'Sara',
        status: 'Pending',
        client: 'Nadine Khoury',
        lawyer: 'Georges Abi Saad',
      },
      {
        id: 13,
        title: 'Property Litigation',
        date: '4-oct-2024',
        assignedTo: 'Rami',
        status: 'Completed',
        client: 'Marc Jaber',
        lawyer: 'Maya Saliba',
      },
      {
        id: 14,
        title: 'Employment Dispute',
        date: '5-jul-2024',
        assignedTo: 'Lara',
        status: 'In Progress',
        client: 'Hassan Daher',
        lawyer: 'Carla Younes',
      },
      {
        id: 15,
        title: 'Property Litigation',
        date: '4-oct-2024',
        assignedTo: 'Rami',
        status: 'Completed',
        client: 'Marc Jaber',
        lawyer: 'Maya Saliba',
      },
      {
        id: 16,
        title: 'Employment Dispute',
        date: '5-sep-2024',
        assignedTo: 'Lara',
        status: 'In Progress',
        client: 'Hassan Daher',
        lawyer: 'Carla Younes',
      },
    ]

    return {
      activeView: 'list',
      selectedRows: [],
      isSelect: false,
      cardsList,

      isAddHearing: false,
      addHearingLoading: false,
      hearingTableLoading: false,

      hearings: [] as any,
      hearingsItemPerPage: 10,
      hearingPage: 1,
      hearingSortField: '' as any,
      hearingSortDirection: '' as 'asc' | 'desc' | undefined,
      hearingSearchKeyword: '',
      hearingTotalItems: 0,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,
    }
  },
  mounted() {
    this.handleGetHearings()
  },
  methods: {
    changeView(view: string) {
      this.activeView = view
    },
    searchData(search: string) {
      console.log(search)
    },
    getSelectedRows(rows: any) {
      this.selectedRows = rows
    },
    toggleAddHearing() {
      this.isAddHearing = !this.isAddHearing
    },
    searchHearingData(search: string) {
      this.hearingSearchKeyword = search
      console.log(this.hearingSearchKeyword)

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetHearings()
      }, 500)
    },
    ///////////////////////////////////////////////////////////////////////////

    async handleGetHearings() {
      this.hearingTableLoading = true

      const response = await getAllHearings({
        per_page: this.hearingsItemPerPage,
        page: this.hearingPage,
        keyword: this.hearingSearchKeyword,
      })
      if (response.success) {
        this.hearings = response.data?.list

        this.hearingTotalItems =
          response.data.total_pages * this.hearingsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.hearingTableLoading = false
    },
    async handlePaginationHearings({
      page,
      itemsPerPage,
      sortBy,
    }: paginationInterface) {
      this.hearingsItemPerPage = itemsPerPage
      this.hearingPage = page
      if (sortBy.length > 0) {
        this.hearingSortField = sortBy[0].key.split('.')[1]
        this.hearingSortDirection = sortBy[0].order
      } else {
        this.hearingSortField = undefined
        this.hearingSortDirection = undefined
      }

      await this.handleGetHearings()
    },
    ///////////////////////////////////////////////////////////////////////////
    async handleCreateHearing(form: any) {
      this.addHearingLoading = true
      console.log({ form })

      const response = await addHearing(form)
      if (response.success) {
        this.$vaToast.init({
          message: 'Hearing created successfully!',
          color: 'success',
        })
        this.handleGetHearings()
        this.toggleAddHearing()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.addHearingLoading = false
    },
  },
})
