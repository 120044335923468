<template>
  <SharedHeader :back-button="true" title-width="30rem" :title="form?.name">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left" />
      <div class="sub-SharedHeader-right" />
    </div>
  </SharedHeader>

  <TabsBar :tabs="tabs" :active-tab-id="activeTabId" @changeTab="changeTab" />

  <div class="scroll-container">
    <VaForm
      v-if="activeTabId == 1"
      ref="organizationDetailsFormRef"
      class="contact-info-container"
    >
      <div class="contact-image">
        <!-- <img src="https://i.pravatar.cc/302" class="contact-profile"
        :alt="`${contactDetails.first_name || ''} ${contactDetails.middle_name || ''} ${contactDetails.last_name || ''}`"> -->
        <div class="avatar-container">
          <!-- <img v-if="form?.specific_data" :src="form.specific_data?.individual_profile_picture?.url" alt="Avatar"
          class="avatar" /> -->
          <div class="avatar-fallback">
            {{ form?.name?.[0] }}
          </div>
        </div>
        <p>
          <img
            :src="verifiedImage"
            alt="VERIFIED"
            class="contact-profile-icon"
          />Verified <br />
          HAQQ User
        </p>
        <!-- <div class="delete-button">
        <VaButton @click="isDeleteModal = !isDeleteModal"> Delete </VaButton>
        <VaModal
          v-model="isDeleteModal"
          message="Would you like to Delete?"
          ok-text="Delete"
          size="auto"
          @ok="handleDelete"
        />
      </div> -->
      </div>

      <VaInput
        v-model="form.specific_data.organization_name"
        placeholder="Organization Name"
        background="#ECF0F1"
        style="width: 49%"
        label="organization name"
        required-mark
        :rules="[validateInput]"
      />

      <!-- <VaInput
      placeholder="Email"
      background="#ECF0F1"
      v-model="form.organization_owner"
      style="width: 49%"
      label="organization Owner"
    /> -->

      <div style="width: 49%">
        <VaSelect
          v-model="form.specific_data.organization_type"
          background="#ECF0F1"
          style="width: 100%"
          label="Organization Type"
          required-mark
          :options="organizationTypeOptions"
          :highlight-matched-text="false"
          :text-by="getOptionName"
        />
      </div>

      <div style="width: 49%">
        <VaSelect
          v-model="form.specific_data.organization_industry"
          placeholder="Organization Industry"
          background="#ECF0F1"
          style="width: 100%"
          label="industry"
          selected-top-shown
          :options="['TYPE 1', 'TYPE 2']"
          :highlight-matched-text="false"
        />
      </div>

      <VaInput
        v-model="form.specific_data.organization_tax_identification_number"
        placeholder="Tax Identification Number"
        background="#ECF0F1"
        style="width: 49%"
        label="tax identification number"
      />

      <VaInput
        v-model="form.specific_data.organization_registration_number"
        placeholder="Registration Incorporation Number"
        background="#ECF0F1"
        style="width: 49%"
        label="registration incorporation Number"
      />
      <div style="width: 49%">
        <VaDateInput
          v-model="form.specific_data.organization_date_of_foundation"
          placeholder="MM/DD/YYYY"
          label="Date Of Foundation"
          background="#ECF0F1"
          style="width: 100%"
          manual-input
        />
      </div>
      <div style="width: 49%">
        <VaSelect
          v-model="form.designated_point_of_contact"
          placeholder="Designed Point Of Contact"
          background="#ECF0F1"
          required-mark
          style="width: 100%"
          label="Designated point of contact"
          searchable
          :options="$stores.usersData.teamMembers"
          :highlight-matched-text="false"
          :text-by="getOptionFullName"
          :rules="[validateSelect]"
          :loading="$stores.usersData.membersLoading"
          @update:search="handleSearchMembers"
        />
      </div>
      <div style="width: 49%">
        <VaSelect
          v-model="
            form.specific_data.organization_jurisdiction_of_incorporation
          "
          placeholder="Jurisdiction Of Incorporation"
          background="#ECF0F1"
          :loading="countriesLoading"
          style="width: 100%"
          label="jurisdiction of incorporation"
          searchable
          required-mark
          :options="countries"
          :highlight-matched-text="false"
          :text-by="getOptionName"
          value-by="name"
          :rules="[validateSelect]"
        />
      </div>

      <VaInput
        v-model="form.website_url"
        placeholder="Organizational Website"
        background="#ECF0F1"
        style="width: 49%"
        label="Organization website"
      />
      <div style="width: 49%">
        <VaSelect
          v-model="form.organization_contact"
          placeholder="Link To Organization"
          background="#ECF0F1"
          style="width: 100%; height: auto"
          label="Link To Organization"
          searchable
          clearable
          :highlight-matched-text="false"
          :options="$stores.contacts.organizations"
          :text-by="getOptionTruncateName50"
          :loading="$stores.contacts.organizationsLoading"
          @update:search="handleSearchOrganizations"
        />
      </div>

      <div style="width: 49%">
        <VaSelect
          v-model="form.relationship_owner"
          placeholder="Relationship Owner"
          background="#ECF0F1"
          style="width: 100%; height: 2rem"
          label="Relationship Owner"
          required-mark
          searchable
          :options="$stores.usersData.teamMembers"
          :highlight-matched-text="false"
          :text-by="getOptionFullName"
          :loading="$stores.usersData.membersLoading"
          @update:search="handleSearchMembers"
        />
      </div>

      <div style="width: 49%">
        <VaSelect
          v-model="form.assigned_to"
          placeholder="Assigned To"
          background="#ECF0F1"
          style="width: 100%"
          label="Assigned To"
          required-mark
          searchable
          :options="$stores.usersData.teamMembers"
          :highlight-matched-text="false"
          :text-by="getOptionFullName"
          :rules="[validateSelect]"
          :loading="$stores.usersData.membersLoading"
          @update:search="handleSearchMembers"
        />
      </div>
      <div style="width: 100%">
        <VaSelect
          v-model="form.referred_by"
          placeholder="Referred by"
          background="#ECF0F1"
          style="width: 49%; height: auto"
          label="Referred by"
          searchable
          clearable
          :highlight-matched-text="false"
          :options="$stores.contacts.contacts"
          :text-by="getOptionFullName"
          :loading="$stores.contacts.contactsLoading"
          @update:search="handleSearchContacts"
        />
      </div>

      <div style="width: 49%">
        <div v-for="(phoneNB, index) in form.phone_numbers" :key="index">
          <VaInput
            v-model="phoneNB.phone_number"
            placeholder="Phone Number"
            background="#ECF0F1"
            style="width: 100%"
            :label="`${phoneNB.label}`"
            required-mark
            :rules="[validateInput]"
          />
        </div>
        <!-- Button to add more email input fields -->
        <!-- <button @click="addEmailAddress">Add Another Email</button> -->
      </div>

      <div style="width: 49%">
        <div v-for="(emailAddress, index) in form.email_addresses" :key="index">
          <VaInput
            v-model="emailAddress.email_address"
            placeholder="Email"
            background="#ECF0F1"
            style="width: 100%"
            :rules="[validateInput, validateInputEmail]"
            :label="`${emailAddress.label}`"
            required-mark
          />
        </div>
        <!-- Button to add more email input fields -->
        <!-- <button @click="addEmailAddress">Add Another Email</button> -->
      </div>

      <VaTextarea
        v-model="form.specific_data.organization_about"
        max-rows="7"
        label="about "
        style="width: 99%"
        autosize
        placeholder="Add your note here..."
        background="#ECF0F1"
      />

      <VaAccordion v-model="isAddressAccordion">
        <VaCollapse header="Address">
          <template #content>
            <div style="width: 79%">
              <VaSelect
                v-model="form.contact_addresses[0].country.name"
                background="#ECF0F1"
                :loading="countriesLoading"
                style="width: 100%; height: 2rem"
                label="Country"
                required-mark
                searchable
                :options="countries"
                :highlight-matched-text="false"
                :text-by="getOptionName"
                value-by="id"
                :rules="[validateSelect]"
                @update:modelValue="handleGetState"
              />
            </div>
            <VaInput
              v-model="form.contact_addresses[0].zip_code"
              background="#ECF0F1"
              style="width: 19%"
              label="Postal/Zip Code"
            />
            <div style="width: 49%">
              <VaSelect
                v-model="form.contact_addresses[0].state.name"
                background="#ECF0F1"
                :loading="statesLoading"
                style="width: 100%"
                label="Province/State"
                searchable
                required-mark
                :options="states"
                :highlight-matched-text="false"
                :text-by="getOptionName"
                value-by="id"
                :rules="[validateSelect]"
                @update:modelValue="handleGetCities"
              />
            </div>

            <div style="width: 49%">
              <VaSelect
                v-model="form.contact_addresses[0].city.id"
                background="#ECF0F1"
                :loading="citiesLoading"
                style="width: 100%"
                label="City"
                required-mark
                searchable
                :options="cities"
                :highlight-matched-text="false"
                :text-by="getOptionName"
                value-by="id"
                :rules="[validateSelect]"
              />
            </div>
            <VaInput
              v-model="form.contact_addresses[0].street_name"
              background="#ECF0F1"
              style="width: 49%"
              label="Street"
            />
            <VaInput
              v-model="form.contact_addresses[0].building_name"
              background="#ECF0F1"
              style="width: 49%"
              label="Building"
            />

            <VaInput
              v-model="form.contact_addresses[0].phone_number"
              background="#ECF0F1"
              style="width: 49%"
              label="Office number"
            />

            <VaInput
              v-model="form.contact_addresses[0].floor"
              background="#ECF0F1"
              style="width: 49%"
              label="Floor"
            />

            <VaInput
              v-model="form.contact_addresses[0].google_maps_url"
              background="#ECF0F1"
              style="width: 99%"
              label="Google Maps Link"
            />
          </template>
        </VaCollapse>
      </VaAccordion>

      <VaAccordion v-model="isBankAccountAccordion">
        <VaCollapse header="Bank Account">
          <template #content>
            <VaInput
              v-model="form.contact_bank_accounts[0].iban"
              background="#ECF0F1"
              style="width: 49%"
              label="iban"
              required-mark
              :rules="[validateInput]"
            />
            <VaInput
              v-model="form.contact_bank_accounts[0].swift_or_bic"
              background="#ECF0F1"
              style="width: 49%"
              label="swift/bic code"
              required-mark
              :rules="[validateInput]"
            />
            <VaInput
              v-model="form.contact_bank_accounts[0].account_number"
              background="#ECF0F1"
              style="width: 49%"
              label="account number"
              required-mark
              :rules="[validateInput]"
            />
            <VaInput
              v-model="form.contact_bank_accounts[0].bank_name"
              background="#ECF0F1"
              style="width: 49%"
              label="bank name"
              required-mark
              :rules="[validateInput]"
            />
            <VaInput
              v-model="form.contact_bank_accounts[0].branch_name"
              background="#ECF0F1"
              style="width: 49%"
              label="branch name"
            />
            <div style="width: 49%">
              <VaSelect
                v-model="form.contact_bank_accounts[0].branch_country"
                background="#ECF0F1"
                :loading="countriesLoading"
                style="width: 100%; height: 2rem"
                label="Branch country"
                required-mark
                :rules="[validateSelect]"
                searchable
                :options="countries"
                :highlight-matched-text="false"
                :text-by="getOptionName"
                value-by="name"
              />
            </div>

            <VaInput
              v-model="form.contact_bank_accounts[0].routing_number"
              background="#ECF0F1"
              style="width: 49%"
              label="routing number"
            />

            <VaInput
              v-model="form.contact_bank_accounts[0].short_code"
              background="#ECF0F1"
              style="width: 49%"
              label="short code"
            />

            <VaInput
              v-model="form.contact_bank_accounts[0].bsb_number"
              background="#ECF0F1"
              style="width: 49%"
              label="BSB number"
            />

            <VaInput
              v-model="form.contact_bank_accounts[0].ifcs_code"
              background="#ECF0F1"
              style="width: 49%"
              label="ifcs code"
            />
            <VaInput
              v-model="form.contact_bank_accounts[0].transit_number"
              background="#ECF0F1"
              style="width: 49%"
              label="transit number"
            />
            <VaInput
              v-model="form.contact_bank_accounts[0].clabe"
              background="#ECF0F1"
              style="width: 49%"
              label="clabe"
            />

            <VaTextarea
              v-model="form.contact_bank_accounts[0].extra_notes"
              max-rows="11"
              label="other "
              style="width: 99%"
              autosize
              placeholder="Add Notes Here"
              background="#ECF0F1"
            />
          </template>
        </VaCollapse>
      </VaAccordion>
      <div class="action-buttons">
        <VaButton preset="secondary" border-color="primary" @click="handleBack">
          Back
        </VaButton>

        <VaButton
          preset="primary"
          :loading="loadingUpdate"
          @click="validate() && handleUpdate()"
        >
          Update
        </VaButton>
      </div>
    </VaForm>
    <div v-else class="container">
      <ComingSoon />
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
