import { defineComponent } from 'vue'
import Button from '@/components/ui/button/index.vue'

const Calendar = new URL(
  '../../../assets/icons/calendar-icon.svg',
  import.meta.url,
).href
const Person = new URL('../../../assets/icons/person-icon.svg', import.meta.url)
  .href
const Money = new URL('../../../assets/icons/money-icon.svg', import.meta.url)
  .href

export default defineComponent({
  name: 'TabsBar',
  props: {
    tabs: {
      type: Array as () => { id: number; title: string }[],
      required: true,
    },
    activeTabId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      Calendar,
      Person,
      Money,
    }
  },
  components: {
    Button,
  },
  methods: {
    changeTab(tabId: number) {
      const tab = this.tabs.find((tabb) => tabb.id === tabId)
      this.$emit('changeTab', tab)
    },
    addTab() {
      console.log('add tab')
    },
  },
})
