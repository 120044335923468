import {
  addInvoiceFormInterface,
  getInvoicesInterface,
} from '@/Interfaces/Services'
import { apiRequest } from './apiUtils'
import { InvoicePresenter } from '@/presenters/InvoicesPresenter'

export const getAllInvoices = async ({
  per_page,
  page,
  status,
  keyword,
}: getInvoicesInterface) => {
  const response = await apiRequest('get', '/invoices', null, {
    per_page,
    page,
    status,
    keyword,
  })

  if (!response.success) {
    return response
  }

  const list = response.data?.list
  if (!Array.isArray(list)) {
    return response
  }

  return {
    ...response,
    data: {
      ...response.data,
      list: list.map((item: InvoicePresenter) => new InvoicePresenter(item)),
    },
  }
}
export const getNextInvoiceNumber = () => {
  return apiRequest('get', '/invoices/next_invoice_number')
}

export const getInvoiceById = async (id: string) => {
  const response = await apiRequest('get', `/invoices/${id}`)
  if (response.success) {
    return {
      ...response,
      data: new InvoicePresenter(response.data),
    }
  } else {
    return response
  }
}

export const addInvoice = (body: addInvoiceFormInterface) => {
  return apiRequest('post', '/invoices', body)
}

export const updateInvoice = (id: string, body: any) => {
  return apiRequest('put', `/invoices/${id}`, body)
}

export const deleteInvoice = (id: string) => {
  return apiRequest('delete', `/invoices/${id}`)
}

export const approveInvoice = (id: number) => {
  return apiRequest('post', `/invoices/${id}/approve`)
}
