<template>
  <div class="contact-card">
    <div class="contact-card-header">
      <h3>{{ contact.fullName }}</h3>
      <div class="line" />
      <p>{{ $t('contacts.referralName') }}</p>
    </div>
    <div>
      <ul class="referral-list">
        <li
          v-for="(referral, index) in contact.referred_by"
          :key="index"
          class="referral"
        >
          <!-- <img :src="referral.imageUrl" alt="referral.name" /> -->
          <p>{{ referral }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
