import { calendarItemsFormInterface } from '@/Interfaces/layout'
import {
  getAllCalendarItemsInterface,
  getTimesForItemInterface,
} from '@/Interfaces/Services'
import { CalendarItemsPresenter } from '@/presenters/calendarItemsPresenter'
import {
  addCalendarItem,
  deleteCalendarItem,
  getAllCalendarItems,
  updateCalendarItem,
} from '@/services/calendarItem'
import { defineStore } from 'pinia'
import { useToast } from 'vuestic-ui'

const prioritizeItemsWithoutEndTime = (
  items: CalendarItemsPresenter[],
): CalendarItemsPresenter[] => {
  // Separate items without end_time and with end_time
  const itemsWithoutEndTime = items.filter((item) => !item.end_time)
  const itemsWithEndTime = items.filter((item) => item.end_time)

  // Concatenate items without end_time at the beginning
  return [...itemsWithoutEndTime, ...itemsWithEndTime]
}

export const useTimerStore = defineStore({
  id: 'Timer',
  state: () => ({
    vaToast: useToast(),
    timer: null as number | null,
    elapsedTime: Number(localStorage.getItem('elapsedTime')) || 0, // Retrieve elapsedTime from local storage

    timeRecords: [] as { startTime: string; duration: string }[],
    isPlaying: false,
    startTime: null as string | null,

    isCalendarItemsActionLoading: false,
    isCalendarItemsLoading: false,
    calendarItems: [] as CalendarItemsPresenter[],

    specificCalendarItems: [] as CalendarItemsPresenter[],
  }),
  getters: {
    formattedTime(state) {
      const hours = Math.floor(state.elapsedTime / 3600)
      const minutes = Math.floor((state.elapsedTime % 3600) / 60)
      const seconds = state.elapsedTime % 60
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0',
      )}:${String(seconds).padStart(2, '0')}`
    },
  },
  actions: {
    async handleGetTimes(data: getAllCalendarItemsInterface) {
      this.isCalendarItemsLoading = true

      const response = await getAllCalendarItems(data)
      if (response.success) {
        this.calendarItems = prioritizeItemsWithoutEndTime(response.data?.list)
        this.isCalendarItemsLoading = false
        return response.data
      } else {
        this.vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isCalendarItemsLoading = false
    },

    async handleToggleTimer(form: calendarItemsFormInterface) {
      this.isCalendarItemsActionLoading = true
      if (this.isPlaying) {
        if (this.timer !== null) {
          clearInterval(this.timer)
        }

        this.elapsedTime++
        this.timer = setInterval(() => {
          this.elapsedTime++
          localStorage.setItem('elapsedTime', this.elapsedTime?.toString()) // Update local storage
        }, 1000)

        const calendarItemId =
          this.calendarItems?.[this.calendarItems.length - 1]?.id

        const response = await updateCalendarItem(calendarItemId, form)
        if (response.success) {
          this.elapsedTime = 0
          localStorage.removeItem('elapsedTime') // Clear from local storage after saving
        } else {
          this.isPlaying = false
          this.vaToast.init({
            message: `${response.message}`,
            color: 'danger',
          })
        }
      } else {
        this.isPlaying = true
        this.startTime = new Date().toLocaleString() // Save the current date and time
        this.elapsedTime++ // Immediately increment the time
        this.timer = setInterval(() => {
          this.elapsedTime++
          localStorage.setItem('elapsedTime', this.elapsedTime?.toString()) // Update local storage
        }, 1000)

        const response = await addCalendarItem(form)
        if (response.success) {
          console.log('Timer started')
        } else {
          this.isPlaying = true
          this.vaToast.init({
            message: `${response.message}`,
            color: 'danger',
          })
        }
      }
      this.isCalendarItemsActionLoading = false
    },

    async handleUpdateTimer(
      form: calendarItemsFormInterface,
      data: getAllCalendarItemsInterface,
    ) {
      const response = await updateCalendarItem(form.id, form)
      if (response.success) {
        this.handleGetTimes(data)
      } else {
        this.vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
    },

    async handleStartTimer(
      form: calendarItemsFormInterface,
      data: getAllCalendarItemsInterface,
    ) {
      const response = await addCalendarItem(form)
      if (response.success) {
        this.handleGetTimes(data)
      } else {
        this.vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
    },

    async handleDeleteTimer(id: number, data: getAllCalendarItemsInterface) {
      const response = await deleteCalendarItem(id)
      if (response.success) {
        this.handleGetTimes(data)
        return true
      } else {
        this.vaToast.init({ message: `${response.message}`, color: 'danger' })
        return false
      }
    },

    ///////////////////////////////////// for details page /////////////////////////////////////////////

    async handleGetTimesForItem(
      data: getTimesForItemInterface,
      resetTimer = true,
    ): Promise<void> {
      this.isCalendarItemsLoading = true
      resetTimer &&
        (this.specificCalendarItems = [] as CalendarItemsPresenter[])

      const response = await getAllCalendarItems({
        sort_by_direction: 'desc',
        sort_by_field: 'start_time',
        per_page: 20,
        with_user: true,
        ...data,
      })
      if (response.success) {
        this.specificCalendarItems = prioritizeItemsWithoutEndTime(
          response.data?.list,
        )
      } else {
        this.vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isCalendarItemsLoading = false
    },

    async handleUpdateTimerForItem(
      form: calendarItemsFormInterface,
      data: getTimesForItemInterface,
    ) {
      const response = await updateCalendarItem(form.id, form)
      if (response.success) {
        this.handleGetTimesForItem(data, false)
      } else {
        this.vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
    },

    async handleStartTimerForItem(
      form: calendarItemsFormInterface,
      data: getTimesForItemInterface,
    ) {
      const response = await addCalendarItem(form)
      if (response.success) {
        this.handleGetTimesForItem(data, false)
      } else {
        this.vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
    },

    async handleDeleteTimerForItem(id: number, data: getTimesForItemInterface) {
      const response = await deleteCalendarItem(id)
      if (response.success) {
        this.handleGetTimesForItem(data, false)
        return true
      } else {
        this.vaToast.init({ message: `${response.message}`, color: 'danger' })
        return false
      }
    },
  },
})
