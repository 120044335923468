import { TimezonesInterface } from '@/Interfaces/Constants'

export const timezoneOptions: TimezonesInterface[] = [
  { country: 'Bahrain', abbreviation: 'BH', timezone: 'Asia/Bahrain' },
  { country: 'Egypt', abbreviation: 'EG', timezone: 'Africa/Cairo' },
  { country: 'Iran', abbreviation: 'IR', timezone: 'Asia/Tehran' },
  { country: 'Iraq', abbreviation: 'IQ', timezone: 'Asia/Baghdad' },
  { country: 'Jordan', abbreviation: 'JO', timezone: 'Asia/Amman' },
  { country: 'Kuwait', abbreviation: 'KW', timezone: 'Asia/Kuwait' },
  { country: 'Lebanon', abbreviation: 'LB', timezone: 'Asia/Beirut' },
  { country: 'Oman', abbreviation: 'OM', timezone: 'Asia/Muscat' },
  { country: 'Palestine', abbreviation: 'PS', timezone: 'Asia/Gaza' },
  { country: 'Qatar', abbreviation: 'QA', timezone: 'Asia/Qatar' },
  { country: 'Saudi Arabia', abbreviation: 'SA', timezone: 'Asia/Riyadh' },
  { country: 'Syria', abbreviation: 'SY', timezone: 'Asia/Damascus' },
  { country: 'Turkey', abbreviation: 'TR', timezone: 'Europe/Istanbul' },
  {
    country: 'United Arab Emirates',
    abbreviation: 'AE',
    timezone: 'Asia/Dubai',
  },
  { country: 'Yemen', abbreviation: 'YE', timezone: 'Asia/Aden' },

  // North Africa
  { country: 'Algeria', abbreviation: 'DZ', timezone: 'Africa/Algiers' },
  { country: 'Libya', abbreviation: 'LY', timezone: 'Africa/Tripoli' },
  { country: 'Morocco', abbreviation: 'MA', timezone: 'Africa/Casablanca' },
  { country: 'Sudan', abbreviation: 'SD', timezone: 'Africa/Khartoum' },
  { country: 'Tunisia', abbreviation: 'TN', timezone: 'Africa/Tunis' },
  {
    country: 'Mauritania',
    abbreviation: 'MR',
    timezone: 'Africa/Nouakchott',
  },

  // Others sometimes considered part of MENA
  { country: 'Somalia', abbreviation: 'SO', timezone: 'Africa/Mogadishu' },
  { country: 'Djibouti', abbreviation: 'DJ', timezone: 'Africa/Djibouti' },

  // Africa
  {
    country: 'South Africa',
    abbreviation: 'ZA',
    timezone: 'Africa/Johannesburg',
  },
  { country: 'Nigeria', abbreviation: 'NG', timezone: 'Africa/Lagos' },

  // Asia
  { country: 'India', abbreviation: 'IN', timezone: 'Asia/Kolkata' },
  { country: 'China', abbreviation: 'CN', timezone: 'Asia/Shanghai' },
  { country: 'Japan', abbreviation: 'JP', timezone: 'Asia/Tokyo' },
  { country: 'South Korea', abbreviation: 'KR', timezone: 'Asia/Seoul' },

  // Europe
  {
    country: 'United Kingdom',
    abbreviation: 'GB',
    timezone: 'Europe/London',
  },
  { country: 'Germany', abbreviation: 'DE', timezone: 'Europe/Berlin' },
  { country: 'France', abbreviation: 'FR', timezone: 'Europe/Paris' },
  { country: 'Spain', abbreviation: 'ES', timezone: 'Europe/Madrid' },
  { country: 'Italy', abbreviation: 'IT', timezone: 'Europe/Rome' },
  { country: 'Russia', abbreviation: 'RU', timezone: 'Europe/Moscow' },

  // North America
  {
    country: 'United States (Eastern)',
    abbreviation: 'US-E',
    timezone: 'America/New_York',
  },
  {
    country: 'United States (Central)',
    abbreviation: 'US-C',
    timezone: 'America/Chicago',
  },
  {
    country: 'United States (Mountain)',
    abbreviation: 'US-M',
    timezone: 'America/Denver',
  },
  {
    country: 'United States (Pacific)',
    abbreviation: 'US-P',
    timezone: 'America/Los_Angeles',
  },
  { country: 'Canada', abbreviation: 'CA', timezone: 'America/Toronto' },
  {
    country: 'Mexico',
    abbreviation: 'MX',
    timezone: 'America/Mexico_City',
  },

  // South America
  { country: 'Brazil', abbreviation: 'BR', timezone: 'America/Sao_Paulo' },
  {
    country: 'Argentina',
    abbreviation: 'AR',
    timezone: 'America/Argentina/Buenos_Aires',
  },
  { country: 'Chile', abbreviation: 'CL', timezone: 'America/Santiago' },

  // Oceania
  {
    country: 'Australia (Sydney)',
    abbreviation: 'AU-S',
    timezone: 'Australia/Sydney',
  },
  {
    country: 'Australia (Perth)',
    abbreviation: 'AU-P',
    timezone: 'Australia/Perth',
  },
  {
    country: 'New Zealand',
    abbreviation: 'NZ',
    timezone: 'Pacific/Auckland',
  },

  {
    country: 'South Africa',
    abbreviation: 'ZA',
    timezone: 'Africa/Johannesburg',
  },
  { country: 'Indonesia', abbreviation: 'ID', timezone: 'Asia/Jakarta' },
]
