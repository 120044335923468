import { defineComponent } from 'vue'
import TasksTable from '@/components/Task/Table/index.vue'
import ActionsBar from '@/components/actionsBar/index.vue'
import SearchAndViews from '@/components/searchAndViews/index.vue'
import AddLinkModal from '@/components/AddLinkModal/index.vue'
import { TaskPresenter } from '@/presenters/taskPresenter'
import { getAllTasks, linkMatterTask, UnlinkMatterTask } from '@/services/tasks'
import { allTasksTemplateHeaders } from '@/constants/Tables/allTasksTemplate'
import { paginationInterface } from '@/Interfaces/Services'

export default defineComponent({
  name: 'TaskTasksSection',
  props: {
    tasks: {
      type: Array as () => TaskPresenter[],
      required: true,
    },
  },
  data() {
    return {
      selectedRows: [] as TaskPresenter[],
      taskTableLoading: false,
      tasksItemPerPage: 50,
      taskTotalItems: 0,

      isAddTask: false,
      link_to_task: '' as any,
      tasksLoading: false,

      tasksModal: [] as TaskPresenter[],
      tasksItemPerPageModal: 10,
      taskPageModal: 1,
      taskSortFieldModal: '' as any,
      taskSortDirectionModal: '' as 'asc' | 'desc' | undefined,

      taskTotalItemsModal: 0,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null, // Holds the debounce timeout ID

      linkTaskLoading: false,
      UnlinkTaskLoading: false,

      allTasksTemplateHeaders,
    }
  },
  watch: {
    isAddTask: {
      handler(data) {
        if (data === true) {
          this.handleGetTasksModal()
          this.tasksModal = [] as TaskPresenter[]
        }
      },
    },
  },
  methods: {
    getSelectedRows(rows: TaskPresenter[]) {
      this.selectedRows = rows
    },
    handleTableAction({
      item,
      actionType,
    }: {
      item: TaskPresenter
      actionType: string
    }) {
      console.log('Button clicked for:', { item, actionType })

      if (actionType === 'remove') {
        this.handleUnLinkTask(item.id)
      }
    },
    /////////////////////////////////////// Modal ////////////////////////////////////////
    toggleAddTask() {
      this.isAddTask = !this.isAddTask
    },

    async handleGetTasksModal(search?: string) {
      this.tasksLoading = true

      const response = await getAllTasks({
        per_page: this.tasksItemPerPageModal,
        page: this.taskPageModal,
        keyword: search,
      })
      if (response.success) {
        const taskIds = this.tasks?.map((e: TaskPresenter) => e.id)

        this.tasksModal = response.data?.list.filter(
          (task: TaskPresenter) => !taskIds?.includes(task.id),
        )
        this.taskTotalItemsModal =
          response.data.total_pages * this.tasksItemPerPageModal
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.tasksLoading = false
    },

    async handlePaginationTasksModal({
      page,
      itemsPerPage,
      sortBy,
    }: paginationInterface) {
      this.tasksItemPerPageModal = itemsPerPage
      this.taskPageModal = page
      if (sortBy.length > 0) {
        this.taskSortFieldModal = sortBy[0].key.split('.')[1]
        this.taskSortDirectionModal = sortBy[0].order
      } else {
        this.taskSortFieldModal = undefined
        this.taskSortDirectionModal = undefined
      }

      await this.handleGetTasksModal()
    },

    searchTaskData(search?: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetTasksModal(search)
      }, 500)
    },

    /////////////////////////////////////////////////////////////////////////////////

    async handleLinkTask(selectedTask: TaskPresenter) {
      this.linkTaskLoading = true
      const response = await linkMatterTask([
        {
          task_id: Number(selectedTask?.id),
          related_to_type: 'LegalMatter',
          related_to_id: Number(this.$route.params.id),
        },
      ])
      if (response.success) {
        this.$emit('handleGetMattersByID', Number(this.$route.params.id))
        this.isAddTask = false
        this.$vaToast.init({
          message: 'Task linked successfully!',
          color: 'success',
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.linkTaskLoading = false
    },

    ///////////////////////////////////////////////////////////////////////////

    async handleUnLinkTask(taskId: number) {
      this.linkTaskLoading = true
      const response = await UnlinkMatterTask(
        taskId,
        Number(this.$route.params.id),
      )
      if (response.success) {
        this.$emit('handleGetMattersByID', Number(this.$route.params.id))
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.linkTaskLoading = false
    },
  },
  components: {
    TasksTable,
    ActionsBar,
    SearchAndViews,
    AddLinkModal,
  },
})
