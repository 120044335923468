import { AddressPresenter } from '../AddressPresenter'
import { CityPresenter } from '../AddressPresenter/CityPresenter'
import { CountryPresenter } from '../AddressPresenter/CountryPresenter'
import { StatePresenter } from '../AddressPresenter/StatePresenter'
import {
  EmailAddress,
  IndividualSpecificData,
  KycDocument,
  PhoneNumber,
} from '../interface'
import { OrganizationContactPresenter } from '../organizationPresenter'
import { TeamMemberPresenter } from '../teamMember'

export class IndividualContactPresenter {
  id!: number
  assigned_to!: TeamMemberPresenter
  class_name!: string
  company_id!: number
  contact_type!: string
  created_at!: string
  designated_point_of_contact!: TeamMemberPresenter
  email_addresses!: EmailAddress[]
  extra_notes!: string
  facebook_url!: string
  google_plus_url!: string
  instagram_url!: string
  kyc_completed!: boolean
  kyc_document!: KycDocument
  kyc_notes!: string
  linkedin_url!: string
  notes!: string
  organization_contact!: OrganizationContactPresenter
  phone_numbers!: PhoneNumber[]
  referred_by!: IndividualContactPresenter
  relationship_owner!: TeamMemberPresenter
  specific_data!: IndividualSpecificData
  tiktok_url!: string | null
  twitter_url!: string | null
  updated_at!: string
  website_url!: string
  youtube_url!: string | null
  contact_addresses!: AddressPresenter[]
  client!: boolean

  fullName!: string
  client_avatar_letter!: string
  firstName!: string
  middleName!: string
  lastName!: string
  title!: string
  nationality!: string
  taxIdentificationNumber!: string

  prependIcon?: string
  path_to?: string

  constructor(data: IndividualContactPresenter) {
    Object.assign(this, data)

    if (data.assigned_to) {
      this.assigned_to = new TeamMemberPresenter(data.assigned_to)
    }

    if (data.organization_contact) {
      this.organization_contact = new OrganizationContactPresenter(
        data.organization_contact,
      )
    }

    if (data.referred_by) {
      this.referred_by = new IndividualContactPresenter(data.referred_by)
    }

    if (data.relationship_owner) {
      this.relationship_owner = new TeamMemberPresenter(data.relationship_owner)
    }

    if (data.designated_point_of_contact) {
      this.designated_point_of_contact = new TeamMemberPresenter(
        data.designated_point_of_contact,
      )
    }

    if (this.contact_addresses?.length === 0) {
      this.contact_addresses.push({
        building_name: '',
        city: {} as CityPresenter,
        country: {} as CountryPresenter,
        floor: '',
        google_maps_url: '',
        phone_number: '',
        state: {} as StatePresenter,
        street_name: '',
        updated_at: '',
        zip_code: '',
        class_name: '',
        created_at: '',
      })
    } else if (this.contact_addresses) {
      this.contact_addresses = this.contact_addresses.map(
        (item: AddressPresenter) => new AddressPresenter(item),
      )
    }

    this.fullName = [
      this.specific_data?.individual_first_name,
      this.specific_data?.individual_last_name,
    ]
      .filter(Boolean)
      .join(' ')

    this.client_avatar_letter = [
      this.specific_data?.individual_first_name?.charAt(0),
      this.specific_data?.individual_last_name?.charAt(0),
    ]
      .filter(Boolean)
      .join(' ')

    this.firstName = this.specific_data?.individual_first_name || ''
    this.middleName = this.specific_data?.individual_middle_name || ''
    this.lastName = this.specific_data?.individual_last_name || ''
    this.title = this.specific_data?.individual_title || ''
    this.nationality = this.specific_data?.individual_nationality || ''
    this.taxIdentificationNumber =
      this.specific_data?.individual_tax_identification_number || ''

    this.path_to = `/contacts/people/${this.id}`
    this.prependIcon = 'contacts'
  }
}
