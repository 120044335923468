import { defineComponent } from 'vue'
import TabsBar from '@/components/tabsBar/index.vue'

export default defineComponent({
  name: 'ChatPage',
  components: {
    TabsBar,
  },
  data() {
    return {
      isCollapsed: true,
      tabs: [
        { id: 0, name: 'CONTACTS' },
        { id: 1, name: 'MEMBERS' },
        { id: 2, name: 'GROUPS' },
      ],
      activeTabId: 0,
      activeTab: 'CONTACTS',
    }
  },
  methods: {
    changeTab(tab: { name: string; id: number }) {
      this.activeTabId = tab.id
      this.activeTab = tab.name
    },
  },
})
