import SideBar from '@/components/SideBar/index.vue'
import ChatComponent from '@/components/chat/index.vue'
import NotificationModal from '@/components/NotificationModal/index.vue'
import TimeRecordModal from '@/components/TimeRecordModal/index.vue'
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { generalDataStore } from '@/stores/generalData'
import { calendarGlobalSearchInterface } from '@/Interfaces/Store'
import { camelCaseToNormal } from '@/lib/utils'
import { useGlobalSearch } from '@/stores/globalSearch'
import { useAuthStore } from '@/stores/auth'

interface SearchOption {
  title?: string

  prependIcon?: string
  path_to: string
}

const tlaImage = new URL('@/assets/images/tla.png', import.meta.url).href
const tlaImageJamal = new URL('@/assets/images/JAMAL.png', import.meta.url).href
const SamiImage = new URL('@/assets/images/SamiH.png', import.meta.url).href

const arabicImage = new URL('../../src/assets/icons/saudi.png', import.meta.url)
  .href
const englishImage = new URL('../../src/assets/icons/usa.png', import.meta.url)
  .href
const logoImage = new URL('../../src/assets/icons/logo.svg', import.meta.url)
  .href
const teamsImage = new URL(
  '../../src/assets/icons/team-icon.svg',
  import.meta.url,
).href
const verifiedImage = new URL(
  '../../src/assets/icons/verified-icon.svg',
  import.meta.url,
).href
const zoomImage = new URL(
  '../../src/assets/icons/zoom-icon.svg',
  import.meta.url,
).href
const frenchImage = new URL(
  '../../src/assets/icons/france.png',
  import.meta.url,
).href
const expandedLeftImage = new URL(
  '../../src/assets/icons/collapse-left-icon.svg',
  import.meta.url,
).href
const description2Image = new URL(
  '../../src/assets/icons/description2-icon.svg',
  import.meta.url,
).href

const JYPic = new URL('@/assets/images/Jad_Younes.png', import.meta.url).href

export default defineComponent({
  name: 'DefaultLayout',
  data() {
    const router = useRouter()

    const { user } = useAuthStore()

    const generalDataStores = generalDataStore()
    const useGlobalSearches = useGlobalSearch()

    const isTLAFirm =
      user?.company_users?.[0]?.company?.name.toUpperCase() ===
      'TRIBONIAN LAW ADVISORS'

    const roleId = localStorage.getItem('RoleId') as string

    return {
      user,
      generalDataStores,
      useGlobalSearches,
      camelCaseToNormal,
      roleId,

      isTLAFirm,
      JYPic,

      lang: localStorage.getItem('locale') || 'en',
      router,
      arabicImage,
      tlaImage,
      tlaImageJamal,
      SamiImage,
      englishImage,
      frenchImage,
      logoImage,
      teamsImage,
      zoomImage,
      verifiedImage,
      description2Image,
      expandedLeftImage,
      changeLanguage: false,
      showNotifications: false,
      notificationNumber: 12,
      value: [true, false, false, false, false],
      collapses: [
        {
          title: 'Client-related',
          data: [
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
          ],
        },

        {
          title: 'Internal',
          data: [
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
          ],
        },
        {
          title: 'Billing and Financial',
          data: [
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
          ],
        },
        {
          title: 'Calendar and Deadline',
          data: [
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
          ],
        },
        {
          title: 'General',
          data: [
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
          ],
        },
      ],
      items: [
        { title: 'Dashboard', icon: 'dashboard' },
        { title: 'Sidebar demo', icon: 'room', active: true },
        { title: 'Loop', icon: 'loop' },
      ],

      minimized: false,

      searchQuery: null as string | null,
      globalSearchOptions: [] as calendarGlobalSearchInterface[],
      debounceTimeout: null as ReturnType<typeof setTimeout> | null,
    }
  },
  methods: {
    selectLanguage(lang: string) {
      if (lang === this.lang) return
      localStorage.setItem('locale', lang)
      window.location.reload()
    },
    handleCloseNotification() {
      this.showNotifications = !this.showNotifications
    },
    handleSideBarToggle(toggle: boolean) {
      this.minimized = toggle
    },
    ///////////////////////////////// global variables /////////////////////////////////
    async handleGetCompanyConfigurations() {
      this.generalDataStores.handleGetCompanyConfigurations()
    },
    async handleGetGeneralData() {
      this.generalDataStores.handleGetGeneralData()
    },
    ////////////////////////////////////////////////
    handleSearchGlobal(search: string) {
      // if (!search) {
      // this.globalSearchOptions = []
      //   return
      // }

      // Clear previous timeout to prevent multiple concurrent searches
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      // Use a more robust debounce
      this.debounceTimeout = setTimeout(() => {
        try {
          this.useGlobalSearches.handleCalendarGlobalSearch(search)
        } catch (error) {
          console.error('Search error:', error)
          // Optionally reset search options
          this.globalSearchOptions = []
        }
      }, 500)
    },
    /////////////////////////////////////////////////////////////////////
    onSearchFocus() {
      // this.$stores.globalSearch.handleCalendarGlobalSearch()
    },
    handleChooseOption(option: SearchOption) {
      console.log('Selected option:', option)
      if (option) {
        this.$router.push(option?.path_to)
      }
      this.searchQuery = null
    },
  },
  computed: {
    currentLanguage(): string {
      if (this.lang === 'ar') return 'العربية'
      if (this.lang === 'en') return 'English'
      if (this.lang === 'fr') return 'Français'
      return 'English'
    },
    currentFlag(): string {
      if (this.lang === 'ar') return this.arabicImage
      if (this.lang === 'en') return this.englishImage
      if (this.lang === 'fr') return this.frenchImage
      return this.englishImage
    },

    itemProps() {
      return (item: SearchOption) => ({
        title: item.title,
        icon: item.prependIcon,
      })
    },
  },
  mounted() {
    this.handleGetCompanyConfigurations()
    // }
    // this.handleGetGeneralData();
    //   this.handleGetGeneralData();
    this.$stores.globalSearch.handleCalendarGlobalSearch()
  },
  components: {
    ChatComponent,
    SideBar,
    NotificationModal,
    TimeRecordModal,
  },

  watch: {
    '$stores.auth.user': {
      handler(data) {
        this.user = data
      },
      deep: true,
      immediate: true,
    },
    '$stores.globalSearch.calendarGlobalSearch': {
      handler(data) {
        this.globalSearchOptions = Array.isArray(data)
          ? data.map((item) => ({
              ...item,
              // Add any necessary transformations
              title: item.name || item.fullName || 'Unnamed Item',
              value: {
                ...item,
                prependIcon: item.prependIcon,
              },
            }))
          : []
      },
      deep: true,
      immediate: true,
    },
  },
})
