import { SectionsPresenter } from '@/presenters/SectionsPresenter'
import { getAllSections, updateSection } from '@/services/sections'
import { defineComponent } from 'vue'
import draggable from 'vuedraggable'
import TablesConfigModal from '@/components/TablesConfigModal/index.vue'
import { TableColumnInterface } from '@/Interfaces/Constants'

export default defineComponent({
  name: 'TasksTables',
  components: {
    draggable,
    TablesConfigModal,
  },
  props: {},
  emits: [],
  data() {
    return {
      isSectionsLoading: false,
      sections: [] as SectionsPresenter[],

      isTableConfigUpdate: false,
      selectedSection: {} as SectionsPresenter,

      isTableConfigUpdateLoading: false,
    }
  },

  mounted() {
    this.handleGetAllSections()
  },
  methods: {
    handleToggleTableConfig(section?: SectionsPresenter) {
      if (section) {
        this.selectedSection = section
      } else {
        this.selectedSection = {} as SectionsPresenter
      }

      this.isTableConfigUpdate = !this.isTableConfigUpdate
    },

    //////////////////////////////////////////////////////////////////////////////

    async handleGetAllSections() {
      this.isSectionsLoading = true
      const response = await getAllSections('tasks')
      if (response.success) {
        this.sections = response.data?.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isSectionsLoading = false
    },

    async handleSubmitTableConfig(headersTemplate: TableColumnInterface[]) {
      this.isTableConfigUpdateLoading = true

      const response = await updateSection(this.selectedSection.id, {
        table_columns: headersTemplate,
      })
      if (response.success) {
        this.$vaToast.init({
          message: 'Table configuration updated successfully',
          color: 'success',
        })
        this.handleGetAllSections()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isTableConfigUpdateLoading = false
    },
  },
})
