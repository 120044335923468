import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    user: {
      type: Object as () => {
        id: number
        name: string
        title: string
        company: string
        avatar: string
      },
      required: true,
    },
    users: {
      type: Array as () => {
        id: number
        name: string
        title: string
        company: string
        avatar: string
      }[],
      required: true,
    },
  },
  data() {
    return {
      panel: [],
    }
  },
  components: {},
  methods: {},
})
