import { StagesPresenter } from '@/presenters/StagesPresenter'
import { apiRequest } from './apiUtils'
import { TableDataExporter } from '@/constants/Tables'
import { TableColumnInterface } from '@/Interfaces/Constants'
import { addUpdateSectionType } from '@/Interfaces/Services'
import { SectionsPresenter } from '@/presenters/SectionsPresenter'

export const getAllSections = async (section_type: string) => {
  const response = await apiRequest('get', '/sections', null, { section_type })

  if (!response.success) {
    return response
  }

  const list = response.data?.list
  if (!Array.isArray(list)) {
    return response
  }

  return {
    ...response,
    data: {
      ...response.data,
      list: list.map((item: SectionsPresenter) => new SectionsPresenter(item)),
    },
  }
}

export const updateSection = async (id: number, body: addUpdateSectionType) => {
  return await apiRequest('put', `/sections/${id}`, body)
}

export const addSection = async (body: addUpdateSectionType) => {
  return await apiRequest('post', '/sections', body)
}

export const deleteSection = async (id: number) => {
  return await apiRequest('delete', `/sections/${id}`)
}
