export class StatePresenter {
  id!: number | string
  class_name!: string
  created_at!: string // ISO date string
  name!: string
  updated_at!: string

  constructor(data: StatePresenter) {
    Object.assign(this, data)
  }
}
