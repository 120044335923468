import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SharedHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    flag: {
      type: String,
      required: true,
    },
    titleWidth: {
      type: String,
      default: '30rem',
    },
    backButton: {
      type: Boolean,
      default: false,
    },
  },
})
