<template>
  <SharedHeader title-width="30rem" title="Contacts">
    <div v-if="activeTab === 'Leads'" class="sub-SharedHeader">
      <va-dropdown :offset="[0, -70]" close-on-content-click>
        <template #anchor>
          <img :src="MoreVertIcon" alt="MoreVertIcon" class="press-button-2" />
        </template>
        <va-dropdown-content>
          <p class="action-option" @click="handleToggleUpdateLayoutModal">
            Edit Page Layout
          </p>
        </va-dropdown-content>
      </va-dropdown>
    </div>
  </SharedHeader>

  <TabsBar
    :tabs="sections"
    :active-tab-id="activeTabId"
    :loading="isSectionsLoading"
    @changeTab="handleTabChange"
    @addTab="handleAddTab"
  />

  <VaModal v-model="isImportContacts" ok-text="Upload">
    <VaFileUpload
      v-model="uploadFilesList"
      file-types="csv,png"
      undo
      file-incorrect-message="Only CSV files are accepted. (eg: file)"
    >
      <div class="fileUpload-container">
        <div class="custom-upload-file-area">
          <p class="upload-text">
            <i class="fas fa-cloud-upload-alt" />
            <span class="highlight">Click</span> or
            <span class="highlight">drag & drop</span> to upload your file
          </p>
        </div>
      </div>
    </VaFileUpload>
  </VaModal>

  <!-- <div class="leads-container" v-if="activeTab === 'Leads'">
    <SearchAndViews
      :activeView="activeView"
      :withViews="true"
      @changeView="changeView"
    />

    <ActionsBar
      :showSelectButton="true"
      :showDownloadIcon="true"
      :showArchiveIcon="true"
      :showLabelIcon="true"
      :showShareIcon="true"
      :addButton="$t('actionsBar.addContact')"
      secondAddButton="Import Contacts"
      :editColumns="true"
      :isFilterColumns="true"
      @SecondButtonAction="handleToggleImportContacts"
      @addButtonAction="isAddContact = !isAddContact"
    />

    <div class="contacts-kanban" v-if="activeView === 'board'">
      <KanbanColumn :contactsList="list1" :isContacts="true" title="Raw" />
      <KanbanColumn :contactsList="list2" :isContacts="true" title="Booked Meeting" />
      <KanbanColumn :contactsList="list3" :isContacts="true" title="Proposal Sent" />
    </div>


  </div> -->

  <div v-if="activeTab === 'People'" class="all-contacts">
    <SearchAndViews
      :search="contactSearchKeyword"
      @search="searchContactData"
    />

    <ActionsBar
      :show-select-button="true"
      :show-download-icon="true"
      :show-archive-icon="true"
      :show-label-icon="true"
      :show-share-icon="true"
      :add-button="$t('actionsBar.addContact')"
      second-add-button="Import Contacts"
      :edit-columns="true"
      :is-filter-columns="true"
      @SecondButtonAction="handleToggleImportContacts"
      @addButtonAction="isAddContact = !isAddContact"
    />

    <VaModal v-model="isAddContact" size="auto" hide-default-actions>
      <template #header>
        <div class="create-form-header">
          <h1>Contact Creation</h1>
          <VaIcon
            class="header-icon press-button"
            name="close"
            size="30px"
            @click="toggleAddContactForm"
          />
        </div>
      </template>
      <div class="add-contact-modal-container">
        <h2>Send a platform invite link to the contact.</h2>
        <VaInput background="#ECF0F1" placeholder="Type email" />
        <VaButton> Send Invite </VaButton>

        <h2>
          Or, create the contact <b @click="toggleAddContactForm">here</b>.
        </h2>
      </div>
    </VaModal>

    <AddContactModal
      :add-contact-loading="addContactLoading"
      :is-add-contact-form="isAddContactForm"
      @toggleAddContactForm="cancelForm"
      @handleCreateContact="handleCreateContact"
    />

    <div class="scroll-container">
      <AllContactsTable
        :loading="tableLoading"
        :items-per-page="contactsItemPerPage"
        :total-items="contactTotalItems"
        :items="$stores.contacts.allContacts"
        :headers="getHeaders()"
        @selectedRows="getSelectedRows"
        @loadMore="handlePaginationContacts"
      />
    </div>
  </div>

  <div
    v-else-if="activeTab === 'Organizations'"
    class="Organizations-container"
  >
    <SearchAndViews
      :search="organizationSearchKeyword"
      @search="searchOrganizationData"
    />

    <ActionsBar
      :show-select-button="true"
      :show-download-icon="true"
      :show-archive-icon="true"
      :show-label-icon="true"
      :show-share-icon="true"
      :add-button="$t('actionsBar.addOrganization')"
      :edit-columns="true"
      :is-filter-columns="true"
      @addButtonAction="isAddOrganizationForm = !isAddOrganizationForm"
    />

    <AddOrganizationModal
      :add-organization-loading="addContactLoading"
      :is-add-organization-form="isAddOrganizationForm"
      @toggleAddOrganizationForm="cancelForm"
      @handleCreateOrganization="handleCreateOrganization"
    />

    <div class="scroll-container">
      <OrganizationTable
        :loading="tableLoading"
        :items-per-page="organizationsItemPerPage"
        :total-items="organizationTotalItems"
        :headers="getHeaders('Organizations')"
        @selectedRows="getSelectedRows"
        @loadMore="handlePaginationOrganizations"
      />
    </div>
  </div>

  <div v-else-if="activeTab === 'Clients'" class="Clients-container">
    <SearchAndViews :search="clientSearchKeyword" @search="searchClientData" />

    <ActionsBar
      :show-select-button="true"
      :show-download-icon="true"
      :show-archive-icon="true"
      :show-label-icon="true"
      :show-share-icon="true"
      :edit-columns="true"
      :is-filter-columns="true"
    />

    <div class="scroll-container">
      <AllContactsTable
        :loading="tableLoading"
        :items-per-page="clientsItemPerPage"
        :total-items="clientTotalItems"
        :items="$stores.contacts.clients"
        :headers="getHeaders()"
        @selectedRows="getSelectedRows"
        @loadMore="handlePaginationClients"
      />
    </div>
  </div>

  <div v-else-if="activeTab === 'Current Matters'" class="Clients-container">
    <SearchAndViews
      :search="contactsActiveMatterSearchKeyword"
      @search="searchContactsActiveMatterData"
    />

    <ActionsBar
      :show-select-button="true"
      :show-download-icon="true"
      :show-archive-icon="true"
      :show-label-icon="true"
      :show-share-icon="true"
      :edit-columns="true"
      :is-filter-columns="true"
    />
    <div class="scroll-container">
      <AllContactsTable
        :loading="tableLoading"
        :items-per-page="contactsActiveMattersItemPerPage"
        :total-items="contactsActiveMatterTotalItems"
        :items="$stores.contacts.contactsActiveMatters"
        :headers="getHeaders()"
        @selectedRows="getSelectedRows"
        @loadMore="handlePaginationContactsActiveMatters"
      />
    </div>
  </div>

  <SectionLeads
    v-else-if="activeTab === 'Leads'"
    :leadsId="activeTabId"
    @handleToggleUpdateLayoutModal="handleToggleUpdateLayoutModal"
    :isShowUpdateLayoutModal="isShowUpdateLayoutModal"
    :headers="headers"
  />

  <div v-else class="container">
    <ComingSoon />
  </div>
  <router-view />
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss"></style>
