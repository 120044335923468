<template>
  <div class="table-container">
    <h3 class="table-header">
      {{ header }}
    </h3>
    <div v-for="item in items" :key="item.id" class="table-item">
      <p class="table-item-title">
        {{ item.title }}
      </p>
      <p class="table-item-value">
        {{ item.value }}
      </p>
    </div>
    <div class="table-total" :class="{ 'without-border': isLast }">
      <p class="table-total-title">total {{ header }}</p>
      <p
        class="table-total-value"
        :class="{ 'red-text': isRed, 'green-text': isGreen }"
      >
        <span>{{ $stores.financial.currency }}</span>
        {{ totalDisplayValue }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style scoped lang="scss" src="./styles.scss"></style>
