<template>
  <v-card height="25rem" class="option-card">
    <div class="search">
      <VaInput
        v-model="globalSearchKeyword"
        placeholder="Search to link..."
        :loading="$stores.globalSearch.calendarGlobalSearchLoading"
        @input="handleSearchGlobally"
      >
        <template #prependInner>
          <VaIcon name="search" color="secondary" />
        </template>
      </VaInput>
    </div>

    <div v-if="selectedItems?.length > 0" class="selected-body">
      <h1>Selected</h1>
      <ul v-for="(data, index) in selectedItems" :key="index">
        <li class="selected-links">
          <div>
            <v-tooltip
              :text="camelCaseToNormal(data.class_name || '')"
              location="top"
            >
              <template #activator="{ props }">
                <VaIcon
                  v-bind="props"
                  :name="data.prependIcon"
                  color="primary"
                  size="22px"
                />
              </template>
            </v-tooltip>

            {{ data?.name || data?.fullName }}
          </div>

          <div class="selected-links">
            <v-tooltip text="Go to" location="top" size="12px">
              <template #activator="{ props }">
                <router-link class="remove-linked-item" :to="data?.path_to">
                  <VaButton
                    v-bind="props"
                    icon="north_west"
                    class="remove-linked-item press-button"
                    size="17px"
                    preset="primary"
                  />
                </router-link>
              </template>
            </v-tooltip>

            <v-tooltip text="Remove" location="top" size="5px">
              <template #activator="{ props }">
                <VaIcon
                  v-bind="props"
                  name="remove"
                  color="#E53E3E"
                  size="22px"
                  class="press-button-2"
                  @click="handleRemoveLinked(data.id)"
                />
              </template>
            </v-tooltip>
          </div>
        </li>
      </ul>
    </div>

    <div class="cal-link-body">
      <h1>Recent</h1>
      <div class="cal-list">
        <ul
          v-for="(data, index) in $stores.globalSearch.calendarGlobalSearch"
          :key="index"
        >
          <li class="press-button" @click="handleLinkTo(data)">
            <v-tooltip
              :text="camelCaseToNormal(data.class_name || '')"
              location="top"
            >
              <template #activator="{ props }">
                <VaIcon
                  v-bind="props"
                  :name="data.prependIcon"
                  color="primary"
                  size="22px"
                />
              </template>
            </v-tooltip>
            {{ data?.name || data?.fullName }}
          </li>
        </ul>
      </div>
    </div>
  </v-card>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
