export const organizationsTemplateHeaders = [
  { title: '#', align: 'start', key: 'avatar', sortable: false },
  {
    title: 'Organization Name',
    align: 'start',
    key: 'name',
    sortable: true,
  },
  { title: 'Owner', align: 'start', key: 'relationship_owner' },
  {
    title: 'Type',
    align: 'start',
    key: 'type',
    sortable: false,
  },
  {
    title: 'Industry',
    align: 'start',
    key: 'industry',
    sortable: false,
  },
  {
    title: 'Tax Identification Number',
    align: 'start',
    key: 'taxIdentificationNumber',
    sortable: false,
  },
  {
    title: 'Registration Incorporation Number',
    align: 'start',
    key: 'registrationNumber',
    sortable: false,
  },
  {
    title: 'Date of Foundation',
    align: 'start',
    key: 'dateOfFoundation',
    sortable: false,
  },
  {
    title: 'Designed Point Of Contact',
    align: 'start',
    key: 'designated_point_of_contact',
    sortable: false,
  },
  {
    title: 'Website',
    align: 'start',
    key: 'website_url',
    sortable: false,
  },
  {
    title: 'Social Media Links',
    align: 'start',
    key: 'social_media_links',
    sortable: false,
  },
  {
    title: 'Link to Organization',
    align: 'start',
    key: 'organization_contact',
    sortable: false,
  },
  {
    title: 'Relationship Owner',
    align: 'start',
    key: 'relationship_owner',
    sortable: false,
  },
  {
    title: 'Assigned To',
    align: 'start',
    key: 'assigned_to',
    sortable: false,
  },
  {
    title: 'Referred By',
    align: 'start',
    key: 'referred_by',
    sortable: false,
  },
  {
    title: 'About',
    align: 'start',
    key: 'about',
    sortable: false,
  },
  {
    title: 'opt',
    align: 'center',
    key: 'action',
    sortable: false,
  },
]
