import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LegalMatterCard',
  props: {
    matter: {
      type: Object,
      required: true,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    selectedCards: {
      type: Array as () => number[],
      default: () => [],
    },
  },
  data() {
    return {
      isChecked: this.selectedCards.includes(this.matter.id),
    }
  },
  watch: {
    // Watch for changes in selectedCards to update isChecked
    selectedCards(newVal) {
      this.isChecked = newVal.includes(this.matter.id)
    },
    isChecked(value) {
      this.toggleChecked(value)
    },
  },
  methods: {
    toggleChecked(value: any) {
      let updatedMatters = [...this.selectedCards]
      if (value) {
        if (!updatedMatters.includes(this.matter.id)) {
          updatedMatters.push(this.matter.id)
        }
      } else {
        updatedMatters = updatedMatters.filter((id) => id !== this.matter.id)
      }
      console.log('Checked items:', updatedMatters) // Log the selected items
      this.$emit('update:selectedCards', updatedMatters)
    },
  },
})
