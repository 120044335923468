export const allTasksTemplateHeaders = [
  { title: '#', align: 'start', key: 'id', sortable: false },
  { title: 'NAME', align: 'start', key: 'name', sortable: false },
  {
    title: 'DESCRIPTION',
    align: 'start',
    key: 'description',
    sortable: false,
  },
  {
    title: 'ASSIGNED TO',
    align: 'start',
    key: 'assigned_to',
    sortable: false,
  },
  { title: 'TASK OWNER', align: 'start', key: 'owner', sortable: false },
  { title: 'STATUS', align: 'start', key: 'status', sortable: false },
  {
    title: 'START DATE',
    align: 'start',
    key: 'start_date',
    sortable: false,
  },
  { title: 'END DATE', align: 'start', key: 'end_date', sortable: false },
  { title: 'DUE DATE', align: 'start', key: 'dueDate', sortable: false },
  { title: 'TAG', align: 'start', key: 'tag', sortable: false },
  {
    title: 'PRIORITY',
    align: 'center',
    key: 'priority',
    sortable: false,
  },
  { title: '# FILES', align: 'start', key: 'files', sortable: false },
  {
    title: 'WORKING HOURS',
    align: 'start',
    key: 'workingHours',
    sortable: false,
  },
  {
    title: 'opt',
    align: 'center',
    key: 'action',
    sortable: false,
  },
]
