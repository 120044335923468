import { CompanyInterface } from '@/Interfaces/Services'
import { CurrenciesPresenter } from '../CurrenciesPresenter'
import { TeamMemberPresenter } from '../teamMember'
import { TimezonesInterface } from '@/Interfaces/Constants'
import { timezoneOptions } from '@/constants/timezones'

export class CompanyUserPresenter {
  id!: number
  basic_salary_amount!: string | number
  basic_salary_currency!: CurrenciesPresenter
  basic_salary_frequency!:
    | 'daily'
    | 'weekly'
    | 'biweekly'
    | 'monthly'
    | 'quarterly'
    | 'annually'
  billable_hourly_rate_charging_amount!: string | number
  billable_hourly_rate_charging_currency!: CurrenciesPresenter
  billable_hourly_rate_earning_amount!: string | number
  billable_hourly_rate_earning_currency!: CurrenciesPresenter

  calendar_timezones!: string[]
  calendar_timezones_options!: TimezonesInterface[]

  class_name!: string
  created_at!: string
  role!: string
  company_id!: number

  company!: CompanyInterface

  roleable_type!: string
  updated_at!: string
  user_id!: number
  fullName!: string
  avatar_letter!: string

  user!: TeamMemberPresenter

  constructor(data: CompanyUserPresenter) {
    Object.assign(this, data)

    if (data.calendar_timezones) {
      this.calendar_timezones_options = data.calendar_timezones.map((tz) =>
        timezoneOptions.find((option) => option.timezone === tz),
      ) as TimezonesInterface[]
    }

    this.user = new TeamMemberPresenter(data.user)
    this.fullName = this.user.fullName

    this.avatar_letter = [
      this.user.first_name?.charAt(0),
      this.user.last_name?.charAt(0),
    ]
      .filter(Boolean)
      .join(' ')
  }
}
