import { defineComponent } from 'vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'

export default defineComponent({
  name: 'RecycleBinPage',
  components: {
    ComingSoon,
    SharedHeader,
  },
})
