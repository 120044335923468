import { paginationInterface } from '@/Interfaces/Services'
import { formatDate, getMonthAndYearFromDate } from '@/lib/utils'
import { MatterPresenter } from '@/presenters/matterPresenter'
import { defineComponent } from 'vue'

const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'MatterTable',
  props: {
    matters: {
      type: Array as () => MatterPresenter[],
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    totalItems: {
      type: Number,
      default: 10,
    },
    unLink: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectedRows', 'loadMore', 'unLinkMatter'],
  data() {
    console.log({ matters: this.matters })

    return {
      formatDate,
      getMonthAndYearFromDate,
      localTotalItems: this.totalItems,
      selectedRows: [],
      MoreVertIcon,
      deleteDialog: false,
      deleteItem: null,
      currentPage: 1,
      currentSortBy: [] as any,
      currentItemsPerPage: this.itemsPerPage,
      headers: [
        { title: '#', align: 'start', key: 'id', sortable: false },
        // {
        //   title: 'Internal Matter ID',
        //   align: 'start',
        //   key: 'internalMatterId',
        //   sortable: false,
        // },
        {
          title: 'MATTER NAME',
          align: 'start',
          key: 'name',
          sortable: false,
        },
        { title: 'STATUS', align: 'start', key: 'status', sortable: false },
        { title: 'CLIENT', align: 'start', key: 'client', sortable: false },
        {
          title: 'LEAD LAWYERS',
          align: 'start',
          key: 'leadLawyers',
          sortable: false,
        },
        { title: 'LAWYERS', align: 'start', key: 'lawyers', sortable: false },
        {
          title: 'JURISDICTION',
          align: 'starts',
          key: 'jurisdiction',
          sortable: false,
        },
        {
          title: 'SPECIALIZATION',
          align: 'starts',
          key: 'specialization',
          sortable: false,
        },
        {
          title: 'CATEGORY',
          align: 'starts',
          key: 'category',
          sortable: false,
        },
        // { title: 'TYPE', align: 'starts', key: 'type', sortable: false },
        { title: 'STAGE', align: 'starts', key: 'stage', sortable: false },
        {
          title: 'CREATED DATE',
          align: 'starts',
          key: 'created_at',
          sortable: false,
        },
        {
          title: 'MODIFIED DATE',
          align: 'starts',
          key: 'modifiedDate',
          sortable: false,
        },
        { title: 'CITY', align: 'starts', key: 'region', sortable: false },
        {
          title: 'LATEST NOTES',
          align: 'starts',
          key: 'latestNotes',
          sortable: false,
        },
        { title: '', align: 'center', key: 'action', sortable: false },
      ],
    }
  },
  watch: {
    selectedRows() {
      this.$emit('selectedRows', this.selectedRows)
    },
    totalItems(newValue) {
      this.localTotalItems = newValue
    },
  },
  methods: {
    handleButtonClick(item: any) {
      // Handle the button click for the specific item (row)
      console.log('Button clicked for:', item)
    },
    viewProfile(item: { id: any }) {
      this.$router.push(`/legal-matters/${item?.id}`)
    },
    handleUnLink(item: any) {
      this.$emit('unLinkMatter', item)
    },
    ////////////////////////////////////////////////////////////
    handleLoadMore({ page, itemsPerPage, sortBy }: paginationInterface) {
      const { currentPage, currentItemsPerPage, currentSortBy } = this

      // Check if there are any changes in pagination or sorting
      const hasPaginationChanged =
        page !== currentPage || itemsPerPage !== currentItemsPerPage
      const hasSortingChanged =
        JSON.stringify(sortBy) !== JSON.stringify(currentSortBy) // Compare sorting objects

      // Only emit if there's a change in pagination or sorting
      if (hasPaginationChanged || hasSortingChanged) {
        this.$emit('loadMore', { page, itemsPerPage, sortBy })

        // Update state after the change
        this.currentPage = page
        this.currentItemsPerPage = itemsPerPage
        this.currentSortBy = sortBy
      }
    },
    ////////////////////////////////////////////////////////////////////////
  },
})
