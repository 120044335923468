<template>
  <ActionsBar
    second-add-button="Assign a Task"
    @secondButtonAction="toggleAddTask"
  />

  <AddLinkModal
    :is-add-item="isAddTask"
    :items-list-loading="tasksLoading"
    :items-list="tasksModal"
    :add-link-loading="linkTaskLoading"
    @handleToggleAddLink="toggleAddTask"
    @handleSearch="searchTaskData"
    @handleLinkItem="handleLinkTask"
  />

  <TasksTable
    :loading="taskTableLoading"
    :items-per-page="tasksItemPerPage"
    :total-items="taskTotalItems"
    :tasks="tasks"
    :headers="allTasksTemplateHeaders"
    :matter-tasks-section="true"
    @selectedRows="getSelectedRows"
    @handleTableAction="handleTableAction"
  />
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
