import { StorageFoldersPresenter } from '@/presenters/StorageFoldersPresenter'
import { PropType, defineComponent } from 'vue'
const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url,
).href

type FolderOption = {
  id: number
  option: string
  deactivate: boolean
}

export default defineComponent({
  name: 'FilesGrid',
  props: {
    folder: {
      type: {} as PropType<StorageFoldersPresenter>,
      required: false,
      default: {},
    },
  },
  data() {
    return {
      MoreVertIcon,
      isOpenOptions: false,

      isEditingName: false,
      editableName: '',

      folderOptions: [
        { id: 0, option: 'Open With', deactivate: true },
        { id: 1, option: 'Request Signature', deactivate: true },
        { id: 2, option: 'Preview', deactivate: true },
        { id: 3, option: 'Rename', deactivate: false },
        { id: 4, option: 'File Info', deactivate: false },
        { id: 5, option: 'Make A Copy', deactivate: true },
        { id: 6, option: 'Access Permissions', deactivate: true },
        { id: 7, option: 'Share', deactivate: true },
        { id: 8, option: 'Move', deactivate: true },
        { id: 9, option: 'Make Available Offline', deactivate: true },
        { id: 10, option: 'Delete', deactivate: true },
      ],
    }
  },
  mounted() {
    console.log(this.folder)
  },
  methods: {
    handleOptionClick(option: FolderOption) {
      if (option.deactivate) {
        console.log('Option is deactivated:', option.option)
        return // Prevent further action for deactivated options
      }

      switch (option.id) {
        case 0:
          this.$emit('open-with', this.folder)
          console.log('Open With selected')
          break
        case 1:
          this.$emit('request-signature', this.folder)
          console.log('Request Signature selected')
          break
        case 2:
          this.$emit('preview', this.folder)
          console.log('Preview selected')
          break
        case 3:
          this.enableEditing()
          break
        case 4:
          this.$emit('file-info', this.folder)
          console.log('File Info selected', this.folder)
          break
        case 5:
          this.$emit('make-copy', this.folder)
          console.log('Make A Copy selected')
          break
        case 6:
          this.$emit('access-permissions', this.folder)
          console.log('Access Permissions selected')
          break
        case 7:
          this.$emit('share', this.folder)
          console.log('Share selected')
          break
        case 8:
          this.$emit('move', this.folder)
          console.log('Move selected')
          break
        case 9:
          this.$emit('make-offline', this.folder)
          console.log('Make Available Offline selected')
          break
        case 10:
          this.$emit('delete', this.folder)
          console.log('Delete selected')
          break
        default:
          console.warn('Unhandled option:', option.option)
      }
    },
    /////////////////////////////////////////////////////////////
    enableEditing() {
      this.isEditingName = true
      this.editableName = this.folder.name // Set initial value
    },
    saveName() {
      this.isEditingName = false
      // this.folder.name = this.editableName // Update folder name
      this.$emit('rename', { ...this.folder, name: this.editableName })
    },
    cancelEditing() {
      this.isEditingName = false
      this.editableName = '' // Reset temporary name
    },
    ////////////////////////////////////////////////////////////////
  },
})
