<template>
  <v-card height="15rem" class="option-card">
    <div class="search">
      <VaInput v-model="typeSearchKeyword" placeholder="Search to link...">
        <template #prependInner>
          <VaIcon name="search" color="secondary" />
        </template>
      </VaInput>
    </div>

    <div class="cal-link-body">
      <div class="cal-list">
        <ul v-for="(type, index) in filteredCalendarTypes" :key="index">
          <li class="press-button" @click="handleAssignType(type)">
            <font-awesome-icon
              :icon="type.icon_name"
              size="22px"
              :color="type.color"
            />
            {{ type.name }}
          </li>
        </ul>
      </div>
    </div>
  </v-card>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
