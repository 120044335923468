import { defineStore } from 'pinia'

export const useFinancialStore = defineStore({
  id: 'financial',
  state: () => ({
    categoryOption: [
      'Administrative Law',
      'Alternative Dispute Resolution',
      'Anti-Corruption Law',
      'Arbitration',
      'Aviation Law',
      'Banking Law',
      'Bankruptcy Law',
      'Civil Litigation',
    ],
    statusOptions: [
      { value: 'not_started', text: 'Not Started' },
      { value: 'transferred', text: 'Transferred' },
      { value: 'in_progress', text: 'In Progress' },
      { value: 'not_retained', text: 'Not Retained' },
    ],
    expenseTypeOptions: [
      { value: 'office_rent', text: 'Office Rent/Lease' },
      {
        value: 'utilities',
        text: 'Utilities (Electricity, Water, Internet)',
      },
      { value: 'salaries_wages', text: 'Salaries and Wages' },
      { value: 'office_supplies', text: 'Office Supplies' },
      {
        value: 'software_licenses',
        text: 'Software Licenses (e.g., case management tools)',
      },
      {
        value: 'marketing_expenses',
        text: 'Marketing and Advertising Expenses',
      },
      {
        value: 'professional_liability_insurance',
        text: 'Professional Liability Insurance',
      },
      { value: 'travel_expenses', text: 'Travel Expenses' },
      {
        value: 'continuing_education',
        text: 'Continuing Education and Training',
      },
      {
        value: 'client_entertainment',
        text: 'Client Entertainment and Meals',
      },
    ],
  }),
})
