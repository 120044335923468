import { camelCaseToNormal, formatDate } from '@/lib/utils'
import { ExpensesPresenter } from '@/presenters/ExpensesPresenter'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LinkExpense',
  props: {
    expenses: {
      type: Array as () => ExpensesPresenter[],
      required: true,
    },
  },
  emits: ['handleLinkTo'],
  data() {
    return {
      camelCaseToNormal,
      formatDate,

      globalSearchKeyword: '',
      debounceTimeout: null as ReturnType<typeof setTimeout> | null,
    }
  },
  watch: {},
  methods: {
    handleLinkTo(data: ExpensesPresenter) {
      this.$emit('handleLinkTo', data)
    },
  },
})
