import CurrencySettingModal from '@/components/financial/CurrencySettingModal/index.vue'
import { addCurrencyFormInterface } from '@/Interfaces/Services'
import { validateInput } from '@/lib/utils'
import { CurrenciesPresenter } from '@/presenters/CurrenciesPresenter'
import {
  addCurrency,
  deleteCurrency,
  getAllCurrencies,
  updateCurrency,
} from '@/services/currencies'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FinancialGlobal',
  components: { CurrencySettingModal },
  data() {
    return {
      validateInput,

      currencies: [] as CurrenciesPresenter[],

      newCurrency: {} as CurrenciesPresenter,

      isCurrency: false,
      isCurrencyLoading: false,
      isAddCurrencyLoading: false,
      isUpdateCurrencyLoading: false,

      showConfirmCurrencyId: null as number | null,
      loadingCurrencyId: null as number | null,
    }
  },
  computed: {},
  watch: {
    isCurrency: {
      handler(data) {
        data && this.handleGetCurrencies()
      },
    },
  },
  methods: {
    toggleCurrency() {
      this.isCurrency = !this.isCurrency
    },

    /////////////////////////////////////////////////////////////////////////////////////////

    async handleGetCurrencies() {
      this.isCurrencyLoading = true

      const response = await getAllCurrencies({ per_page: 100, page: 1 })

      if (response.success) {
        this.currencies = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isCurrencyLoading = false
    },

    async handleAddCurrency(body: addCurrencyFormInterface) {
      this.isAddCurrencyLoading = true

      const response = await addCurrency(body)

      if (response.success) {
        this.handleGetCurrencies()
        this.newCurrency = {} as CurrenciesPresenter
        this.$vaToast.init({
          message: 'Currency created successfully!',
          color: 'success',
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isAddCurrencyLoading = false
    },

    async handleUpdateCurrency(body: addCurrencyFormInterface) {
      this.isUpdateCurrencyLoading = true

      const response = await updateCurrency(Number(body.id), body)

      if (response.success) {
        this.handleGetCurrencies()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isUpdateCurrencyLoading = false
    },

    async handleDeleteCurrency(id: number) {
      this.isUpdateCurrencyLoading = true

      const response = await deleteCurrency(id)

      if (response.success) {
        this.handleGetCurrencies()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isUpdateCurrencyLoading = false
    },

    /////////////////////////////////////////////////////////////////////////////////////////

    toggleConfirmDelete(configId: number) {
      this.showConfirmCurrencyId =
        this.showConfirmCurrencyId === configId ? null : configId
    },

    toggleConfirm(configId: number) {
      this.showConfirmCurrencyId =
        this.showConfirmCurrencyId === configId ? null : configId
    },

    confirmDelete(configId: number) {
      this.loadingCurrencyId = configId

      this.handleDeleteCurrency(configId)
      this.showConfirmCurrencyId = null
    },

    cancelDelete() {
      this.showConfirmCurrencyId = null
    },

    /////////////////////////////////////////////////////////////////////////////////////////
  },
})
