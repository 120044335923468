import { CompanyConfigurationPresenter } from '../companyConfigPresenters'
import { IndividualContactPresenter } from '../contactPresenter'
import { hearing_contacts, hearing_users } from '../interface'
import { MatterPresenter } from '../matterPresenter'
import { TeamMemberPresenter } from '../teamMember'

export class HearingsPresenter {
  id!: number
  assigned_to!: TeamMemberPresenter
  assigned_to_id!: number
  class_name!: string
  company_id!: number
  court_case_number!: string
  court_id!: number
  court!: CompanyConfigurationPresenter
  created_at!: string
  creator_id!: number
  end_date!: string
  judge_id!: number
  jurisdriction_id!: number
  jurisdriction!: CompanyConfigurationPresenter
  legal_matter!: MatterPresenter | null
  legal_matter_id!: number
  name!: string
  notes!: string
  opponent_contact_id!: number
  opponent_contact!: IndividualContactPresenter
  region!: string
  representing_contact!: IndividualContactPresenter
  representing_contact_id!: number
  start_date!: string
  status!: CompanyConfigurationPresenter
  status_id!: number
  updated_at!: string
  judge!: IndividualContactPresenter
  hearing_users!: hearing_users[]
  hearing_contacts!: hearing_contacts[]
  prependIcon?: string
  path_to?: string

  hearing_users_details!: {
    full_name: string
    avatar_letter: string
  }[]
  hearing_users_users!: TeamMemberPresenter[]
  hearing_contacts_users!: IndividualContactPresenter[]

  constructor(data: HearingsPresenter) {
    Object.assign(this, data)

    if (data.representing_contact) {
      this.representing_contact = new IndividualContactPresenter(
        data.representing_contact,
      )
    }
    if (data.judge) {
      this.judge = new IndividualContactPresenter(data.judge)
    }
    if (data.assigned_to) {
      this.assigned_to = new TeamMemberPresenter(data.assigned_to)
    }
    if (data.legal_matter) {
      this.legal_matter = new MatterPresenter(data.legal_matter)
    }

    if (data.opponent_contact) {
      this.opponent_contact = new IndividualContactPresenter(
        data.opponent_contact,
      )
    }

    this.hearing_users_details =
      this.hearing_users?.map((user: hearing_users) => {
        const teamMember = new TeamMemberPresenter(user?.user)
        return {
          full_name: teamMember?.fullName || '',
          avatar_letter: teamMember?.avatar_letter || '',
        }
      }) || []

    this.hearing_users_users =
      this.hearing_users?.map(
        (user: hearing_users) => new TeamMemberPresenter(user?.user),
      ) || []

    this.hearing_contacts_users =
      this.hearing_contacts?.map(
        (user: hearing_contacts) =>
          new IndividualContactPresenter(user?.contact),
      ) || []

    this.path_to = `/hearings/${this.id}`
    this.prependIcon = 'gavel'
  }
}
