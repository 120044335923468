import { defineComponent } from 'vue'
import ComingSoon from '@/components/ComingSoon/index.vue'

export default defineComponent({
  name: 'Tree',
  components: {
    ComingSoon,
  },
  data() {
    return {}
  },
})
