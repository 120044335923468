import { IndividualContactPresenter } from '@/presenters/contactPresenter'
import { apiRequest } from './apiUtils'
import { OrganizationContactPresenter } from '@/presenters/organizationPresenter'
import { getContactsInterface } from '@/Interfaces/Services'

export const getContacts = async ({
  contact_type,
  per_page,
  page,
  sort_by_field,
  sort_by_direction,
  keyword,
  client,
  has_active_legal_matters,
}: getContactsInterface) => {
  const response: any = await apiRequest('get', '/contacts', null, {
    contact_type,
    per_page,
    page,
    sort_by_field,
    sort_by_direction,
    keyword,
    client,
    has_active_legal_matters,
  })
  if (response?.success && Array.isArray(response.data?.list)) {
    // Map the data to an array of TeamMemberPresenter instances with nested data
    return {
      ...response,
      data: {
        ...response.data,
        list: response.data?.list?.map((item: any) => {
          if (contact_type === 'IndividualContact') {
            return new IndividualContactPresenter(item)
          } else {
            return new OrganizationContactPresenter(item)
          }
        }),
      },
    }
  } else {
    return response
  }
}

export const getContactById = async (id: string) => {
  const response = await apiRequest('get', `/contacts/${id}`)
  if (response.success) {
    return {
      ...response,
      data:
        response.data.class_name === 'OrganizationContact'
          ? new OrganizationContactPresenter(response.data)
          : new IndividualContactPresenter(response.data),
    }
  } else {
    return response
  }
}

export const addIndividualContact = (body: any) => {
  return apiRequest('post', '/contacts/individuals', body)
}

export const addOrganizationContact = (body: any) => {
  return apiRequest('post', '/contacts/organizations', body)
}

export const updateIndividualContact = ({ id, body }: any) => {
  return apiRequest('put', `/contacts/individuals/${id}`, body)
}

export const updateOrganizationContact = ({ id, body }: any) => {
  return apiRequest('put', `/contacts/organizations/${id}`, body)
}

export const deleteContact = (id: string) => {
  return apiRequest('delete', `/contacts/${id}`)
}
