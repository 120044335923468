import { MatterTemplateInterface } from '@/Interfaces/Constants'

export const formFields: MatterTemplateInterface[] = [
  {
    type: 'text',
    icon: 't',
    rules: 'validateInput',
    name: 'name',
    label: 'Name',
    placeholder: 'Name',
    details: 'Text Field',
  },
  {
    type: 'text',
    icon: 't',
    rules: 'validateInput',
    name: 'court_case_number',
    label: 'Court ID',
    placeholder: 'Court ID',
    details: 'Text Field',
  },

  {
    type: 'select',
    icon: 'square-caret-down',
    rules: 'validateSelect',
    name: 'representing_contact',
    label: 'Client',
    placeholder: 'Select Client',
    options: 'contacts',
    textBy: 'getOptionFullName',
    searchable: true,
    details: 'Dropdown : Select Client and possibility to add new one',
  },
  {
    type: 'select',
    icon: 'square-caret-down',
    rules: 'validateSelect',
    name: 'court',
    label: 'Court',
    placeholder: 'Court Type',
    options: 'courtOptions',
    textBy: 'getOptionTruncateName110',
    searchable: true,
    details: 'Dropdown : Select Court Type controlled by admin',
  },

  {
    type: 'select',
    icon: 'square-caret-down',
    rules: 'validateSelect',
    name: 'representation_type',
    label: 'representing',
    placeholder: 'Representing',
    options: 'presentationTypeOption',
    searchable: true,
    details: 'Dropdown : Select Representing Type',
  },
  {
    type: 'select',
    icon: 'square-caret-down',
    rules: 'validateSelect',
    name: 'judge',
    label: 'judge name',
    placeholder: 'Representing',
    options: 'contacts',
    textBy: 'getOptionFullName',
    searchable: true,
    details: 'Dropdown : Select Judge Name and possibility to add new one',
  },
  {
    type: 'select',
    icon: 'square-caret-down',
    rules: 'validateSelect',
    name: 'opponent_contact',
    label: 'opponent name',
    placeholder: 'Select The Opponent',
    options: 'contacts',
    textBy: 'getOptionFullName',
    searchable: true,
    details: 'Dropdown : Select Opponent Name and possibility to add new one',
  },

  {
    type: 'select',
    icon: 'square-caret-down',
    rules: 'validateSelect',
    name: 'parent_id',
    label: 'Link to Matter',
    placeholder: 'Link to Matter',
    options: 'matters',
    textBy: 'getOptionName',
    clearable: true,
    searchable: true,
    details: 'Link to another matter',
  },

  {
    type: 'select',
    icon: 'square-caret-down',
    rules: 'validateSelect',
    name: 'status',
    label: 'Status',
    placeholder: 'Select Status',
    options: 'matterStatusOption',
    textBy: 'getOptionName',
    searchable: true,
    details: 'Dropdown : Select Matter Status',
  },

  {
    type: 'select',
    icon: 'square-caret-down',
    rules: 'validateSelect',
    name: 'section_stage',
    label: 'Stage',
    placeholder: 'Select Stage',
    options: 'sectionStages',
    textBy: 'getOptionName',
    searchable: true,
    details: 'Dropdown : Select Section Stage',
  },

  {
    type: 'select',
    icon: 'square-caret-down',
    rules: 'validateSelect',
    name: 'sector',
    label: 'Sectors',
    placeholder: 'Select A Sector',
    options: 'matterSectorsOption',
    textBy: 'getOptionName',
    searchable: true,
    details: 'Dropdown : Select Matter Sector',
  },

  {
    type: 'select',
    icon: 'square-caret-down',
    rules: 'validateSelect',
    name: 'case_manager',
    label: 'Case Manager',
    placeholder: 'Select Case Manager',
    options: 'teamMembers',
    textBy: 'getOptionFullName',
    multiple: true,
    searchable: true,
    details: 'Dropdown : Select Case Manager',
  },

  {
    type: 'select',
    icon: 'square-caret-down',
    rules: 'validateSelect',
    name: 'personnel',
    label: 'Personnel',
    placeholder: 'Select A Personnel',
    options: 'teamMembers',
    textBy: 'getOptionFullName',
    multiple: true,
    searchable: true,
    details: 'Dropdown : Select Personnel',
  },

  {
    type: 'select',
    icon: 'square-caret-down',
    rules: 'validateSelect',
    name: 'specialization',
    label: 'Specialization',
    placeholder: 'Specialization',
    options: 'matterSpecializationOptions',
    textBy: 'getOptionName',
    searchable: true,
    details: 'Dropdown : Select Matter Specialization',
  },

  {
    type: 'select',
    icon: 'square-caret-down',
    rules: 'validateSelect',
    name: 'category',
    label: 'Category',
    placeholder: 'Category',
    options: 'matterCategoryOptions',
    textBy: 'getOptionName',
    searchable: true,
    details: 'Dropdown : Select Matter Category',
  },

  {
    type: 'select',
    icon: 'square-caret-down',
    rules: 'validateSelect',
    name: 'jurisdriction',
    label: 'Country',
    placeholder: 'Country',
    options: 'countries',
    textBy: 'getOptionName',
    searchable: true,
    details: 'Dropdown : Select Country',
  },

  {
    type: 'text',
    icon: 't',
    rules: 'validateInput',
    name: 'region',
    label: 'City',
    placeholder: 'City',
    clearable: true,
    details: 'Text Field',
  },
  {
    type: 'textarea',
    icon: 't',
    rules: 'validateInput',
    name: 'notes',
    label: 'Notes',
    placeholder: 'Enter your notes here...',
    details: 'Text Area',
  },
]

export const formFieldsMostUsed: MatterTemplateInterface[] = [
  {
    type: 'text',
    icon: 't',
    rules: 'validateInput',
    name: 'name',
    label: 'Name',
    placeholder: 'Name',
    details: 'Text Field',
  },
  {
    type: 'select',
    icon: 'square-caret-down',
    rules: 'validateSelect',
    name: 'jurisdriction',
    label: 'Country',
    placeholder: 'Country',
    options: 'countries',
    textBy: 'getOptionName',
    searchable: true,
    details: 'Dropdown : Select Country',
  },
  {
    type: 'textarea',
    icon: 't',
    rules: 'validateInput',
    name: 'notes',
    label: 'Notes',
    placeholder: 'Enter your notes here...',
    details: 'Text Area',
  },
]
