<template>
  <div class="shared-header-container">
    <VaButton
      v-if="backButton"
      class="back-button"
      icon="arrow_back"
      preset="secondary"
      border-color="primary"
      @click="$router.back()"
    >
      Back
    </VaButton>
    <div class="title" :style="{ width: titleWidth }">
      <VaIcon v-if="flag" :name="flag" size="large" color="primary" />
      <h1>{{ title }}</h1>
    </div>
    <slot />
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
