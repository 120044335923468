<template>
  <!-- <div class="container">
    <ComingSoon />

  </div> -->
  <div>
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel title="Legislation Team" value="Legislation Team">
        <v-expansion-panel-text>
          <div class="details">
            <p class="description">
              Lorem ipsum dolor sit amet consectetur. Donec a nunc blandit
              congue leo dictum sed est. Enim mattis blandit diam malesuada et
              pellentesque gravida ipsum velit.
            </p>
            <div class="team">
              <span>Legislation Team</span>
            </div>
            <small>*This tag is autimatically created for every team.</small>
            <h5>Jurisdictions</h5>
            <router-link to="">
              <span>Chat with the team here</span>
            </router-link>
            <p>Team Manager</p>
            <UserCard :user="user" />
            <p>Team Members</p>
            <UserCard v-for="user in users" :key="user.id" :user="user" />
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel title="Accounting Team" value="Accounting Team" />
      <v-expansion-panel
        title="International Law Team"
        value="International Law Team"
      />
    </v-expansion-panels>
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />

<!-- <script lang="ts">

</script>

<style lang="scss" scoped>

</style> -->
