import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f422b3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-container" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-btn" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = {
  key: 0,
  class: "tag"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "tag-value" }
const _hoisted_9 = { class: "tag-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("button", _hoisted_4, _toDisplayString(_ctx.$t('financialDashboard.card.viewDetails')), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.hasTag)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("img", {
              src: _ctx.tag.icon,
              alt: "tag",
              class: "tag-icon"
            }, null, 8, _hoisted_7),
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.tag.value), 1),
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.tag.title), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}