<template>
  <div>
    <AddLeadModal
      :addLeadLoading="addLeadLoading"
      :isAddLeadForm="isAddLeadForm"
      :stages="stages"
      @toggleAddLeadForm="toggleAddLeadForm"
      @handleCreateLead="handleCreateLead"
    />

    <SearchAndViews
      :search="searchKeyword"
      @search="handleSearchData"
      :activeView="activeView"
      :withViews="true"
      @changeView="handleChangeView"
    />
    <ActionsBar
      :show-select-button="true"
      :show-download-icon="true"
      :show-archive-icon="true"
      :show-label-icon="true"
      :show-share-icon="true"
      :add-button="'Add Lead'"
      :edit-columns="true"
      :is-filter-columns="true"
      @addButtonAction="toggleAddLeadForm"
    />

    <div v-if="activeView === 'list'">
      <div class="scroll-container">
        <AllContactsTable
          :loading="tableLoading"
          :items-per-page="contactsActiveMattersItemPerPage"
          :total-items="contactsActiveMatterTotalItems"
          :items="$stores.contacts.contactsActiveMatters"
          :headers="headers"
          @selectedRows="getSelectedRows"
          @loadMore="handlePaginationContactsActiveMatters"
        />
      </div>
    </div>

    <div v-else-if="activeView === 'board'">
      <UpdateLayoutColumns
        :update-layout-loading="isUpdateLayoutLoading"
        :is-update-layout-form="isShowUpdateLayoutModal"
        :section_stages="stages"
        :is-delete-layout-loading="isDeleteLayoutLoading"
        @handleToggleUpdateLayoutModal="handleToggleUpdateLayoutModal"
        @handleUpdateStageData="handleUpdateLayout"
        @onDragEnd="handleUpdateLayout"
        @handleDeleteLayout="handleDeleteLayout"
      />

      <KanbanInfiniteLoad
        :isLegalMatters="true"
        :stages="stages"
        :is-cards-loading="isLeadsLoading"
        :is-columns-loading="isStagesLoading"
        @ticketMoved="handleTicketMoved"
        @handleAddStage="handleToggleUpdateLayoutModal"
      />
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
