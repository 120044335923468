<template>
  <SharedHeader title-width="30rem" title="Calendar">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left" />
      <div class="sub-SharedHeader-right">
        <VaButton
          class="filter-button"
          preset="secondary"
          icon="filter_list"
          border-color="#DFE5F3"
          disabled
          @click="handleToggleFilter"
        >
          Filter
        </VaButton>

        <VaSelect
          v-model="activeType"
          class="types-view"
          :options="typesOption"
          :text-by="getOptionName"
          searchable
          :highlight-matched-text="false"
          track-by="id"
          @update:modelValue="handleSelectTypeFilter"
        />

        <VaSelect
          v-model="selectedView"
          class="calendar-view"
          :options="viewOptions"
          track-by="value"
        />

        <VaSelect
          v-model="userFiltration"
          class="user-filter"
          :options="userFiltrationOptions"
          :text-by="getOptionFullName"
          :loading="$stores.usersData.membersLoading"
          searchable
          :highlight-matched-text="false"
          track-by="id"
          @update:search="handleSearchMembers"
          @update:modelValue="handleSelectUserFilter"
        />

        <div class="search">
          <VaSelect
            v-model="searchedEvent"
            class="search"
            placeholder="Search"
            :options="searchedEventOptions"
            autocomplete
            :highlight-matched-text="false"
            :loading="isSearchedCalendarItemsLoading"
            :text-by="getEventTitle"
            @update:search="handleSearchEvent"
            @update:modelValue="handleSelectSearchedEvent"
          >
            <template #prependInner>
              <VaIcon name="search" color="secondary" />
            </template>
          </VaSelect>
        </div>

        <VaButton
          preset="secondary"
          border-color="primary"
          @click="selectedDate = new Date()"
        >
          Today
        </VaButton>
      </div>
    </div>
  </SharedHeader>

  <AddEventModal
    :add-calendar-loading="addCalendarLoading"
    :is-add-calendar-form="isAddCalendarForm"
    :new-event-start="newEventStart"
    @toggleAddCalendarForm="toggleAddCalendarForm"
    @handleCreateEvent="handleCreateEvent"
  />

  <UpdateEventModal
    :selected-event="selectedEvent"
    :update-calendar-loading="updateCalendarLoading"
    :is-update-calendar-form="isUpdateCalendarForm"
    @toggleUpdateCalendarForm="toggleUpdateCalendarForm"
    @handleUpdateEvent="handleUpdateEvent"
  />
  <div class="vue-cal">
    <div class="time-zones-container">
      <VaButton preset="primary" class="toggle-button" @click="toggleTable">
        {{ showTable ? 'Hide Time Zones' : 'Show Time Zones' }}
      </VaButton>
      <transition name="slide">
        <div v-if="showTable" class="time-zones-grid">
          <table>
            <thead>
              <tr>
                <th
                  v-for="(zone, index) in $stores.auth.company
                    .calendar_timezones_options"
                  :key="index"
                >
                  {{ zone.abbreviation }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in timeRows" :key="index">
                <td
                  v-for="(zone, zoneIndex) in $stores.auth.company
                    .calendar_timezones_options"
                  :key="zoneIndex"
                >
                  {{ row[zone.abbreviation] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </transition>
    </div>

    <vue-cal
      id="vuecal"
      ref="vueCal"
      hide-view-selector
      :active-view="selectedView?.value"
      today-button
      :disable-views="['years']"
      editable-events
      :events="calendarItems"
      cell-contextmenu
      :drag-to-create-event="true"
      :dblclick-to-navigate="false"
      :time-step="60"
      :on-event-click="onEventClick"
      :selected-date="selectedDate"
      :show-all-day-events="['short', true, false][0]"
      @view-change="onViewChange"
      @ready="handleOnCalendarReady"
      @cell-dblclick="onEventCreate"
      @event-drag-create="onEventDragCreate"
      @event-drop="onEventDragDrop"
      @event-duration-change="onEventDragDrop"
      @event-delete="onEventDelete"
    >
      <!-- @event-mouse-enter="logEvents('event-mouse-enter for tooltip', $event)" -->
      <!-- customize today's button -->
      <template #today-button>
        <!-- <VaIcon name="my_location" v-on="on" class="today-button" /> -->

        <!-- to add an external today button use this : :selected-date="selectedDate"; and a button that gets the now date and make it equal to selectedDate -->

        <v-tooltip text="Add Event" location="top">
          <template #activator="{ props }">
            <VaButton
              v-bind="props"
              icon="add"
              preset="secondary"
              border-color="primary"
              class="add-event-button"
              @click="toggleAddCalendarForm"
            />
          </template>
        </v-tooltip>
      </template>

      <template #arrow-next>
        <!-- <i class="icon material-icons arrow-next">arrow_forward</i> -->
        <!-- <VaButton
          icon="add"
          preset="secondary"
          border-color="primary"
          class="add-event-button"
          @click="toggleAddCalendarForm"
        /> -->
      </template>

      <template #weekday-heading>
        <!-- here we can update the heading of the week -->
      </template>

      <template #title="{ view }">
        <span v-if="view.id === 'years'">Years</span>
        <!-- Using Vue Cal injected Date prototypes -->
        <span v-else-if="view.id === 'year'"
          >{{ view.startDate.format('YYYY') }}
        </span>
        <span v-else-if="view.id === 'month'"
          >{{ view.startDate.format('MMMM YYYY') }}
        </span>
        <span v-else-if="view.id === 'week'">
          <!-- w{{ view.startDate.getWeek() }} -->
          {{ view.startDate.format('MMM YYYY') }}
        </span>
        <span v-else-if="view.id === 'day'"
          >{{ view.startDate.format('dddd D MMMM (YYYY)') }}
        </span>
      </template>

      <template #cell-content="{ view, goNarrower }">
        <span
          v-if="view.id === 'day'"
          class="vuecal__cell-date"
          :class="view?.id"
          @click="goNarrower"
        >
          <!-- {{ onEventCreate(cell) }} -->
          <!-- {{ cell.date.getDate() }} -->
        </span>
        <!-- <span
         count number inside the cell in month view
          class="vuecal__cell-events-count"
          v-if="view.id === 'month' && events.length"
          >{{ events.length }}</span
        > -->
        <!-- <span
          class="vuecal__no-event"
          v-if="['week', 'day'].includes(view.id) && !events.length"
          >Nothing here 👌</span
        > -->
      </template>

      <!-- custom time slot -->
      <template #event="{ event, view }">
        <!-- Short version for month view -->
        <div v-if="view === 'month'" class="vuecal__event-title--short">
          <!-- <span v-html="event?.description"></span> -->
        </div>

        <!-- Full version for other views -->

        <div
          v-else
          class="vuecal__event-title--full"
          :style="{
            backgroundColor: event?.item_type?.color,
            color: getContrastingTextColor(event?.item_type?.color),
          }"
        >
          <small class="vuecal__event-time">
            <p class="event-title">
              <font-awesome-icon
                class="font-icon"
                :icon="event?.item_type?.icon_name"
              />
              {{ event?.title }}
            </p>
            <span v-if="!event.time_range">
              {{ event?.start.formatTime('h:mm ') }} -
              {{ event?.end.formatTime('h:mm ') }}
            </span>
            <span v-else>
              {{ formatTimeSeconds(event.total_time_spent_seconds) }}
            </span>
          </small>
        </div>
      </template>
    </vue-cal>
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
