import { useTabs } from '@/composables/useTabs'
import { defineComponent } from 'vue'
import TabsBar from '@/components/tabsBar/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'
import { getMemberById } from '@/services/company'
import ProfileSettings from '@/views/firm/MemberProfile/ProfileSettings/index.vue'
import ProfilePreview from '@/views/firm/MemberProfile/ProfilePreview/index.vue'

const tabs = [
  { id: 0, name: 'Settings' },
  { id: 1, name: 'Profile' },
  { id: 2, name: 'Permissions' },
  { id: 3, name: 'Cases' },
  { id: 4, name: 'Tasks' },
  { id: 5, name: 'Hearings' },
  { id: 6, name: 'Charts' },
  { id: 7, name: 'Contacts' },
  { id: 8, name: 'Stats' },
  { id: 9, name: 'Payroll' },
]

export default defineComponent({
  name: 'MemberProfilePage',

  data() {
    const { activeTabId, activeTab, changeTab } = useTabs(tabs, 1)

    return {
      tabs,
      activeTabId,
      activeTab,
      changeTab,

      memberLoading: false,

      currentMember: {} as CompanyUserPresenter,
    }
  },
  methods: {
    async handleGetMember(company_user_id: number) {
      this.memberLoading = true

      const responseMember = await getMemberById({
        company_id: this.$stores.auth.company.company_id,
        company_user_id: company_user_id,
      })

      if (responseMember.success) {
        this.currentMember = responseMember.data
      } else {
        this.$vaToast.init({
          message: `${responseMember.message}`,
          color: 'danger',
        })
      }
      this.memberLoading = false
    },
  },
  computed: {},
  mounted() {
    this.handleGetMember(Number(this.$route.params.id))
  },
  components: {
    TabsBar,
    ComingSoon,
    SharedHeader,
    ProfileSettings,
    ProfilePreview,
  },
})
