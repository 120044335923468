import { AddressPresenter } from '../AddressPresenter'
import { CityPresenter } from '../AddressPresenter/CityPresenter'
import { CountryPresenter } from '../AddressPresenter/CountryPresenter'
import { StatePresenter } from '../AddressPresenter/StatePresenter'
import { BankAccountPresenter } from '../BankAccountPresenter'
import { IndividualContactPresenter } from '../contactPresenter'
import {
  EmailAddress,
  KycDocument,
  OrganizationSpecificData,
  PhoneNumber,
} from '../interface'
import { TeamMemberPresenter } from '../teamMember'

export class OrganizationContactPresenter {
  id!: number
  assigned_to!: TeamMemberPresenter | null
  class_name!: string
  company_id!: number
  contact_addresses!: AddressPresenter[]
  contact_bank_accounts!: BankAccountPresenter[]
  contact_type!: string
  created_at!: string
  designated_point_of_contact!: TeamMemberPresenter
  email_addresses!: EmailAddress[] | null
  extra_notes!: string | null
  facebook_url!: string | null
  google_plus_url!: string | null
  instagram_url!: string | null
  kyc_completed!: boolean
  kyc_document!: KycDocument
  kyc_notes!: string | null
  linkedin_url!: string | null
  notes!: string | null
  organization_contact!: OrganizationContactPresenter | null
  phone_numbers!: PhoneNumber[] | null
  referred_by!: IndividualContactPresenter | null
  relationship_owner!: TeamMemberPresenter | null
  specific_data!: OrganizationSpecificData
  tiktok_url!: string | null
  twitter_url!: string | null
  updated_at!: string
  website_url!: string | null
  youtube_url!: string | null
  name!: string
  type!: string
  industry!: string
  taxIdentificationNumber!: string
  registrationNumber!: string
  dateOfFoundation!: string
  about!: string

  prependIcon?: string
  path_to?: string

  constructor(data: OrganizationContactPresenter) {
    Object.assign(this, data)

    if (data.organization_contact) {
      this.organization_contact = new OrganizationContactPresenter(
        data.organization_contact,
      )
    }

    if (data.relationship_owner) {
      this.relationship_owner = new TeamMemberPresenter(data.relationship_owner)
    }

    if (data.referred_by) {
      this.referred_by = new IndividualContactPresenter(data.referred_by)
    }

    if (data.designated_point_of_contact) {
      this.designated_point_of_contact = new TeamMemberPresenter(
        data.designated_point_of_contact,
      )
    }

    if (data.assigned_to) {
      this.assigned_to = new TeamMemberPresenter(data.assigned_to)
    }

    if (this.contact_addresses?.length === 0) {
      this.contact_addresses.push({
        building_name: '',
        city: {} as CityPresenter,
        country: {} as CountryPresenter,
        floor: '',
        google_maps_url: '',
        phone_number: '',
        state: {} as StatePresenter,
        street_name: '',
        updated_at: '',
        zip_code: '',
        class_name: '',
        created_at: '',
      })
    } else if (this.contact_addresses) {
      this.contact_addresses = this.contact_addresses?.map(
        (item: AddressPresenter) => new AddressPresenter(item),
      )
    }

    if (this.contact_bank_accounts?.length === 0) {
      this.contact_bank_accounts.push({
        iban: '',
        swift_or_bic: '',
        account_number: '',
        bank_name: '',
        branch_name: '',
        branch_country: '',
        branch_address: '',
        routing_number: '',
        short_code: '',
        transit_number: '',
        clabe: '',
        extra_notes: '',
        bsb_number: '',
        ifcs_code: '',
      } as BankAccountPresenter)
    } else if (this.contact_bank_accounts) {
      this.contact_bank_accounts = this.contact_bank_accounts?.map(
        (item: BankAccountPresenter) => new BankAccountPresenter(item),
      )
    }

    this.name = this.specific_data.organization_name || ''
    this.path_to = `/contacts/organization/${this.id}`
    this.prependIcon = 'corporate_fare'

    this.name = this.specific_data.organization_name || ''
    this.type = this.specific_data.organization_type || ''
    this.industry = this.specific_data.organization_industry || ''
    this.taxIdentificationNumber =
      this.specific_data.organization_tax_identification_number || ''
    this.registrationNumber =
      this.specific_data.organization_registration_number || ''
    this.dateOfFoundation =
      this.specific_data.organization_date_of_foundation || ''
    this.about = this.specific_data.organization_about || ''
  }
}
