<template>
  <div class="global-config">
    <h1>Global Configurations</h1>

    <VaButton
      class="options"
      preset="secondary"
      border-color="primary"
      @click="
        toggleGlobalConfig({
          title: 'Court Options',
          option: 'courtOptions',
          configuration_type: 'court',
        })
      "
    >
      Court Options
    </VaButton>
    <VaButton
      class="options"
      preset="secondary"
      border-color="primary"
      @click="
        toggleGlobalConfig({
          title: 'Status Options',
          option: 'matterStatusOption',
          configuration_type: 'legal_matter_status',
        })
      "
    >
      Status Options
    </VaButton>
    <VaButton
      class="options"
      preset="secondary"
      border-color="primary"
      @click="
        toggleGlobalConfig({
          title: 'Specialization Options',
          option: 'matterSpecializationOptions',
          configuration_type: 'legal_matter_specialization',
        })
      "
    >
      Specialization Options
    </VaButton>
    <VaButton
      class="options"
      preset="secondary"
      border-color="primary"
      @click="
        toggleGlobalConfig({
          title: 'Category Options',
          option: 'matterCategoryOptions',
          configuration_type: 'legal_matter_category',
        })
      "
    >
      Category Options
    </VaButton>
    <VaButton
      class="options"
      preset="secondary"
      border-color="primary"
      @click="
        toggleGlobalConfig({
          title: 'Sectors Options',
          option: 'matterSectorsOption',
          configuration_type: 'legal_matter_sector',
        })
      "
    >
      Sectors Options
    </VaButton>
    <VaDivider inset />

    <GlobalConfigModal
      v-model:isGlobalConfig="isGlobalConfig"
      :selected-option="selectedOption"
      :global-config="globalConfig"
      :icon-name="iconName"
      :show-confirm-config-id="showConfirmConfigId"
      :loading-config-id="loadingConfigId"
      :new-option-form="newOptionForm"
      :is-add-global-config-loading="isAddGlobalConfigLoading"
      :is-update-global-config-loading="isUpdateGlobalConfigLoading"
      @toggleGlobalConfig="toggleGlobalConfig"
      @toggleConfirm="toggleConfirm"
      @confirmDelete="confirmDelete"
      @cancelDelete="cancelDelete"
      @handleAddGlobalConfig="handleAddGlobalConfig"
      @handleUpdateGlobalConfig="handleUpdateGlobalConfig"
    />
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
