import { getInvoiceById, updateInvoice } from '@/services/invoices'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'
import SharedHeader from '@/components/sharedHeader/index.vue'
import LinkToCalendarEvent from '@/components/financial/LinkToCalendarEvent/index.vue'
import LinkExpense from '@/components/financial/LinkExpenses/index.vue'
import PrintInvoice from '@/components/financial/PrintInvoice/index.vue'

import {
  getOptionCurrency,
  getOptionFullName,
  getOptionName,
  getOptionTruncateName50,
  validateInput,
  validateInputEmail,
  validateSelect,
} from '@/lib/utils'
import { invoiceItemAttributesInterface } from '@/Interfaces/Services'
import { InvoicePresenter } from '@/presenters/InvoicesPresenter'
import { CalendarItemsPresenter } from '@/presenters/calendarItemsPresenter'
import { ExpensesPresenter } from '@/presenters/ExpensesPresenter'
import { getAllCalendarItems } from '@/services/calendarItem'
import { getAllExpenses } from '@/services/expenses'
import { CurrenciesPresenter } from '@/presenters/CurrenciesPresenter'
import { getAllCurrencies } from '@/services/currencies'

interface IconDetails {
  icon: string
  tooltip: string
}
type ClassName = 'CalendarItem' | 'Expense' | 'Other'

const tlaLogoImage = new URL(
  '@/assets/images/tribonian_law_advisors_logo.png',
  import.meta.url,
).href

export default defineComponent({
  name: 'InvoiceDetailPage',

  components: {
    SharedHeader,
    LinkToCalendarEvent,
    LinkExpense,
    PrintInvoice,
  },
  data() {
    const { validate } = useForm('form')

    const paymentDueOptions = [
      {
        label: 'Due Today',
        value: new Date().toISOString(),
      },
      {
        label: 'Net 15',
        value: new Date(
          new Date().setDate(new Date().getDate() + 15),
        ).toISOString(),
      },
      {
        label: 'Net 30',
        value: new Date(
          new Date().setDate(new Date().getDate() + 30),
        ).toISOString(),
      },
      {
        label: 'Net 60',
        value: new Date(
          new Date().setDate(new Date().getDate() + 60),
        ).toISOString(),
      },
    ]

    const iconMappings = {
      CalendarItem: { icon: 'calendar_month', tooltip: 'Calendar Item' },
      Expense: { icon: 'paid', tooltip: 'Expense' },
    } as Record<ClassName, IconDetails>

    return {
      validateInput,
      validateInputEmail,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionTruncateName50,
      getOptionCurrency,

      validate,
      tlaLogoImage,
      loading: false,
      updateLoading: false,
      form: {} as InvoicePresenter,
      showPreviewModal: false,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null, // Holds the debounce timeout ID

      isItemAssignEvent: false,
      isCalendarItemsLoading: false,
      calendarItems: [] as CalendarItemsPresenter[],

      isItemAssignExpense: false,
      isExpensesLoading: false,
      expenses: [] as ExpensesPresenter[],

      iconMappings,

      taxTypeOptions: [
        { label: 'Percent', value: 'percentage' },
        { label: 'Amount', value: 'amount' },
      ],

      currencyLoading: false,
      currencies: [] as CurrenciesPresenter[],

      isShowDueDate: false,
      datePickerValue: new Date(),
      paymentDueOptions,

      invoiceItems: [] as invoiceItemAttributesInterface[], // New array for items
    }
  },
  computed: {
    convertToSecondaryCurrency(): string | number | null {
      if (!this.form?.secondary_currency_conversion_rate_against_us_dollars) {
        return null
      }

      if (
        this.form?.currency_conversion_rate_against_us_dollars ===
        this.form?.secondary_currency_conversion_rate_against_us_dollars
      ) {
        return null
      }

      const amountInUSD =
        this.computedGrandTotal() /
        Number(this.form?.currency_conversion_rate_against_us_dollars)

      const amountInSecondaryCurrency =
        amountInUSD *
        Number(this.form?.secondary_currency_conversion_rate_against_us_dollars)

      return amountInSecondaryCurrency.toFixed(4)
    },
  },
  watch: {
    invoiceItems: {
      handler(data) {
        console.log({
          invoiceItems: data,
        })
      },
      deep: true,
    },
    'form.currency': {
      handler(data) {
        this.form.currency_conversion_rate_against_us_dollars =
          data?.conversion_rate_against_us_dollars
      },
    },
    'form.secondary_currency': {
      handler(data) {
        this.form.secondary_currency_conversion_rate_against_us_dollars =
          data?.conversion_rate_against_us_dollars
      },
    },
  },
  mounted() {
    this.handleGetInvoiceByID()
    this.handleGetContacts()

    this.handleGetEvents()
    this.handleGetExpenses()

    this.handleGetCompanyCurrencies()
  },
  methods: {
    async handleGetInvoiceByID() {
      this.loading = true
      const response = await getInvoiceById(this.$route.params.id as string)
      if (response.success) {
        this.form = response.data
        this.invoiceItems = [...response.data.invoice_items]
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.loading = false
    },

    async handleGetContacts(search?: string) {
      this.$stores.contacts.handleGetContacts({
        keyword: search,
      })
    },

    handleSearchContacts(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts(search)
      }, 500)
    },

    handleSelectContact(contact: any) {
      if (
        contact?.email_addresses &&
        contact?.email_addresses[0]?.email_address
      ) {
        this.form.customer_email = contact?.email_addresses[0]?.email_address
      }
    },
    ///////////////////////////////////////////////////////////////////////////////////

    totalAmount(
      quantity: string | number,
      price: string | number,
      tax_percentage: string | number,
      discount_type: string,
      discount_type_amount: string | number,
    ) {
      // Calculate the subtotal (price * quantity)
      const subtotal = parseFloat((+quantity * +price)?.toFixed(4))

      // Calculate the discount based on the discount type
      let discountedSubtotal = subtotal
      if (discount_type === 'percentage') {
        const discountAmount = parseFloat(
          ((subtotal * +discount_type_amount) / 100)?.toFixed(4),
        )
        discountedSubtotal -= discountAmount
      } else if (discount_type === 'amount') {
        const discountAmount = parseFloat((+discount_type_amount)?.toFixed(4))
        discountedSubtotal -= discountAmount
      }

      // Ensure the discounted subtotal is not negative
      discountedSubtotal = Math.max(0, discountedSubtotal)

      // Calculate the tax on the discounted subtotal
      const taxAmount = parseFloat(
        (discountedSubtotal * (+tax_percentage / 100))?.toFixed(4),
      )

      // Add tax to the discounted subtotal to get the total
      const total = discountedSubtotal + taxAmount

      // Round to 4 decimal places and return the total
      return parseFloat(total?.toFixed(4))
    },

    computedSubTotal() {
      const grandTotal = this.invoiceItems.reduce((total, item) => {
        const itemTotal = this.totalAmount(
          item.quantity,
          item.unit_price,
          item.tax_percentage,
          item.discount_type,
          item.discount_type_amount,
        )
        return total + itemTotal
      }, 0)

      return parseFloat(grandTotal?.toFixed(4))
    },

    computedGrandTotal() {
      const subTotal = this.computedSubTotal()
      let discountedTotal: number

      if (this.form.discount_type === 'percentage') {
        discountedTotal =
          subTotal -
          parseFloat(
            ((subTotal * Number(this.form.discount_type_amount)) / 100).toFixed(
              4,
            ),
          )
      } else {
        discountedTotal =
          subTotal - parseFloat((+this.form.discount_type_amount).toFixed(4))
      }

      discountedTotal = Math.max(0, discountedTotal)

      const totalTax = this.form.invoice_tax_items?.reduce((total, taxItem) => {
        let taxAmount = 0
        if (taxItem.tax_type === 'percentage') {
          taxAmount = (discountedTotal * +taxItem.tax_type_amount) / 100
        } else if (taxItem.tax_type === 'amount') {
          taxAmount = +taxItem.tax_type_amount
        }
        return total + taxAmount
      }, 0)

      const grandTotal = discountedTotal + totalTax

      return parseFloat(grandTotal.toFixed(4))
    },

    ///////////////////////////////////////////////////////////////////////////////////

    addInvoiceItem() {
      this.invoiceItems.push({
        quantity: 1,
        tax_percentage: 0,
        discount_type: 'percentage',
        discount_type_amount: 0,
        unit_price: 0,
      } as invoiceItemAttributesInterface)
    },

    removeInvoiceItem(index: number) {
      if (this.invoiceItems.length > 1) {
        this.invoiceItems.splice(index, 1)
      }
    },

    async handleUpdateInvoice() {
      const {
        currency,
        secondary_currency,
        organization_contact,
        individual_contact,
        invoice_items,
        invoice_tax_items,
        ...form
      } = this.form

      this.updateLoading = true
      const response = await updateInvoice(this.$route.params.id as string, {
        organization_contact_id: organization_contact?.organization_contact,
        individual_contact_id: individual_contact?.id,
        currency_id: currency?.id,
        secondary_currency_id: secondary_currency?.id,
        invoice_items_attributes: [...this.invoiceItems],
        ...(invoice_tax_items[0]?.name && {
          invoice_tax_items_attributes: [...invoice_tax_items],
        }),
        ...form,
      })
      if (response.success) {
        this.$vaToast.init({
          message: 'Invoice updated successfully!',
          color: 'success',
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.updateLoading = false
    },

    toggleInvoiceSwitch() {
      this.form.discount_type =
        this.form.discount_type === 'amount' ? 'percentage' : 'amount'
    },
    /////////////////////////////////////// Calendar Events ////////////////////////////////////////////////

    async handleGetEvents() {
      this.isCalendarItemsLoading = true

      const response = await getAllCalendarItems({
        per_page: 50,
        for_whole_company: true,
        sort_by_direction: 'desc',
        sort_by_field: 'start_time',
      })

      if (response.success) {
        this.calendarItems = response.data?.list.filter(
          (e: CalendarItemsPresenter) => e.billable === true,
        )
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isCalendarItemsLoading = false
    },

    addInvoiceCalendarEvent(data: CalendarItemsPresenter) {
      this.invoiceItems.push({
        description: data.description,
        quantity: parseFloat(
          (data.total_time_spent_seconds / 3600)?.toFixed(4),
        ),
        tax_percentage: 0,
        discount_type: 'percentage',
        discount_type_amount: 0,
        unit_price: Number(data.billable_hourly_rate_charging_amount),

        billable_type: 'CalendarItem',
        billable_id: data.id,
        billable: { class_name: data.class_name },
      } as invoiceItemAttributesInterface)
      this.isItemAssignEvent = false
    },

    /////////////////////////////////////// Expenses ////////////////////////////////////////////////

    async handleGetExpenses() {
      this.isExpensesLoading = true

      const response = await getAllExpenses({
        per_page: 50,
      })
      if (response.success) {
        this.expenses = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isExpensesLoading = false
    },

    addInvoiceExpense(data: ExpensesPresenter) {
      this.invoiceItems.push({
        description: data.description,
        quantity: data.quantity,
        tax_percentage: 0,
        discount_type: 'percentage',
        discount_type_amount: 0,
        unit_price: data.unit_price,

        billable_type: 'Expense',
        billable_id: data.id,
        billable: { class_name: data.class_name },
      } as invoiceItemAttributesInterface)
      this.isItemAssignExpense = false
    },

    ////////////////////////////// Icon ///////////////////////////////////

    getIconDetails(item?: string): IconDetails | undefined {
      return (
        this.iconMappings[item as ClassName] || {
          icon: 'radio_button_checked',
          tooltip: 'Item',
        }
      )
    },

    ////////////////////////////// Taxes ///////////////////////////////////

    addTaxItem() {
      this.form.invoice_tax_items.push({
        name: '',
        tax_type: 'percentage',
        tax_type_amount: 0,
      })
    },
    removeTaxItem(index: number) {
      if (this.form.invoice_tax_items.length > 1) {
        this.form.invoice_tax_items.splice(index, 1)
      }
    },

    ////////////////////////////// Currencies ///////////////////////////////////

    async handleGetCompanyCurrencies() {
      this.currencyLoading = true

      const response = await getAllCurrencies({})

      if (response.success) {
        this.currencies = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.currencyLoading = false
    },

    ////////////////////////////////////////////////////////////////////////////////////

    handleDatePickerChange(date: Date) {
      this.paymentDueOptions.push({
        label: date.toISOString().split('T')[0],
        value: date.toISOString(),
      })
      this.form.payment_due_date = date.toISOString()
      this.isShowDueDate = false
    },
  },
})
