<template>
  <VaModal
    v-model="isAddMatterTempBool"
    size="auto"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
    @update:modelValue="handleToggleAddModalTemp"
  >
    <template #header>
      <div class="modal-form-header">
        <h1>{{ modalTitle }}</h1>
        <VaIcon
          class="press-button"
          name="close"
          size="30px"
          @click="handleToggleAddModalTemp"
        />
      </div>
    </template>

    <div v-if="isSelectTemplate" class="select-template">
      <div class="search">
        <VaInput
          placeholder="Search"
          icon="search"
          @update:modelValue="searchMatterData"
        >
          <template #appendInner>
            <VaIcon name="search" color="secondary" />
            <VaIcon name="search" color="secondary" />
          </template>
        </VaInput>
      </div>

      <div
        v-for="temps in templatesFound"
        :key="temps.id"
        class="template-container"
      >
        <p>{{ temps?.name }}</p>
        <div class="view-templates">
          <div
            v-for="temp in temps.temps"
            :key="temp.id"
            class="template-card-container"
          >
            <div class="template-card press-button">
              <div class="content">
                <div class="line" />
                <div class="line" />
                <div class="line" />
                <div class="subline" />
              </div>
            </div>
            <p>{{ temp?.name }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="create-template" v-else>
      <VaInput
        background="#ECF0F1"
        v-model="templateForm.name"
        label="Template Name"
        placeholder="Name"
        requiredMark
        :rules="[validateInput]"
      />
      <VaDivider />
      <!-- <h1 class="create-template-title">Info</h1> -->
      <div class="all-fields">
        <draggable
          v-model="selectedFields"
          item-key="id"
          group="sections"
          class="draggable-sections"
          @end="onFieldDragEnd"
        >
          <template #item="{ element, index }">
            <div class="AF-row">
              <div class="AF-row">
                <VaIcon name="drag_handle" size="1.5rem" class="press-button" />

                <div
                  v-for="(field, subIndex) in element"
                  :key="subIndex"
                  class="AF-map"
                >
                  <font-awesome-icon
                    :icon="field?.icon"
                    color="#52709b"
                    width="0.5rem"
                  />
                  <div>
                    <p class="AF-name">
                      {{ field?.label }}
                    </p>
                  </div>
                  <font-awesome-icon
                    icon="trash-can"
                    size="sm"
                    class="press-button"
                    color="#66023c"
                    @click="handleRemoveField(index, subIndex)"
                  />
                </div>
              </div>
              <font-awesome-icon
                icon="circle-plus"
                size="lg"
                class="press-button"
                color="#66023c"
                @click="handleToggleSelectFields(index)"
              />
              <font-awesome-icon
                icon="trash-can"
                size="lg"
                class="press-button"
                color="#66023c"
                @click="handleRemoveRowField(index)"
              />
            </div>
          </template>
        </draggable>
      </div>
      <div class="form-modal-buttons">
        <VaButton
          color="#ECF0F1"
          size="small"
          icon-right="add"
          @click="handleToggleSelectFields()"
        >
          Add Field
        </VaButton>
        <VaButton
          preset="secondary"
          border-color="primary"
          size="small"
          @click="handleToggleShowSelectFields"
        >
          Show Form
        </VaButton>
      </div>
    </div>

    <template #footer>
      <div class="form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleToggleSelectTemplate"
          disabled
        >
          {{ firstButton }}
        </VaButton>
        <VaButton
          @click="handleSubmitTemplate"
          :loading="addMatterTempLoading"
          :disabled="!templateForm?.name"
        >
          Start Matter
        </VaButton>
      </div>
    </template>
  </VaModal>

  <!-- Nested modals using vuestic should be inside the same file -->
  <VaModal
    v-model="isSelectFields"
    size="30rem"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
    @update:modelValue="handleToggleSelectFields()"
  >
    <template #header>
      <div class="modal-form-header">
        <h1>Add Field</h1>
        <VaIcon
          class="press-button"
          name="close"
          size="30px"
          @click="handleToggleSelectFields()"
        />
      </div>
      <div class="search">
        <VaInput
          placeholder="Search"
          icon="search"
          @update:modelValue="searchMatterData"
        >
          <template #appendInner>
            <VaIcon name="search" color="secondary" />
            <VaIcon name="search" color="secondary" />
          </template>
        </VaInput>
      </div>
    </template>

    <div class="add-field-container">
      <h1 class="title">Most Used</h1>
      <h1 class="title">Most Used</h1>
      <div class="most-used-fields">
        <div
          v-for="(field, index) in formFieldsMostUsed"
          :key="index"
          class="MUF-map"
          @click="isFieldSelected(field) && handleAddField(field)"
        >
          <font-awesome-icon
            :icon="field.icon"
            color="#52709b"
            width="0.5rem"
          />
          <div>
            <p class="MUF-name">
              {{ field.label }} <span>{{ isFieldSelectedText(field) }}</span>
            </p>
            <p class="MUF-details">
              {{ field.details }}
            </p>
          </div>
        </div>
      </div>
      <h1 class="title">All Fields</h1>
      <h1 class="title">All Fields</h1>
      <div class="all-fields">
        <div
          v-for="(field, index) in filteredFormFields"
          :key="index"
          class="AF-map"
          @click="handleAddField(field)"
        >
          <font-awesome-icon
            :icon="field.icon"
            color="#52709b"
            width="0.5rem"
          />
          <div>
            <p class="AF-name">
              {{ field.label }}
            </p>
            <p class="AF-details">
              {{ field.details }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </VaModal>

  <VaModal
    v-model="isShowSelectFields"
    size="medium"
    hide-default-actions
    fixed-layout
  >
    <template #header>
      <div class="modal-form-header">
        <h1>Form View</h1>
        <VaIcon
          class="press-button"
          name="close"
          size="30px"
          @click="handleToggleShowSelectFields"
        />
      </div>
    </template>
    <div>
      <DynamicForm :fields="selectedFields" :initial-values="form" />
    </div>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
