import { addUpdateSectionType } from '@/Interfaces/Services'
import { SectionsPresenter } from '@/presenters/SectionsPresenter'
import { StorageFoldersPresenter } from '@/presenters/StorageFoldersPresenter'
import {
  addSection,
  deleteSection,
  getAllSections,
  updateSection,
} from '@/services/sections'
import { defineComponent } from 'vue'
import draggable from 'vuedraggable'

export default defineComponent({
  name: 'TaskToolbar',
  components: {
    draggable,
  },
  props: {},
  emits: [],
  data() {
    return {
      isSectionsLoading: false,

      isSectionAddLoading: false,

      deleteDialog: false,
      deleteSectionId: null as number | null,
      isSectionLoading: {} as Record<
        number,
        { updating: boolean; deleting: boolean }
      >,

      sections: [] as SectionsPresenter[],
    }
  },
  mounted() {
    this.handleGetAllSections()
  },
  methods: {
    handleToggleDeleteDialog(id: number) {
      this.deleteDialog = !this.deleteDialog
      this.deleteSectionId = id
    },
    ////////////////////////////////////////////////////////////////////

    async handleGetAllSections() {
      this.isSectionsLoading = true
      const response = await getAllSections('tasks')
      if (response.success) {
        this.sections = response.data?.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isSectionsLoading = false
    },

    async handleUpdateSection(id: number, body: addUpdateSectionType) {
      if (!this.isSectionLoading[id]) {
        this.isSectionLoading[id] = { updating: false, deleting: false }
      }
      this.isSectionLoading[id].updating = true

      const response = await updateSection(id, body)

      if (!response.success) {
        this.handleGetAllSections()
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isSectionLoading[id].updating = false
    },

    async handleAddSection(section: SectionsPresenter) {
      this.isSectionAddLoading = true

      const { name, section_type, allow_multiple_stages_per_item } = section

      const response = await addSection({
        name,
        section_type,
        allow_multiple_stages_per_item,
      })

      if (response.success) {
        this.handleGetAllSections()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isSectionAddLoading = false
    },

    async handleDeleteSection() {
      if (this.deleteSectionId !== null) {
        if (!this.isSectionLoading[this.deleteSectionId]) {
          this.isSectionLoading[this.deleteSectionId] = {
            updating: false,
            deleting: false,
          }
        }
        this.isSectionLoading[this.deleteSectionId].deleting = true

        const response = await deleteSection(this.deleteSectionId)

        if (response.success) {
          this.deleteSectionId = null
          this.handleGetAllSections()
        } else {
          this.$vaToast.init({
            message: `${response.message}`,
            color: 'danger',
          })
        }

        if (this.deleteSectionId !== null) {
          this.isSectionLoading[this.deleteSectionId].deleting = false
        }
      }
    },

    ////////////////////////////////////////////////////////////////////

    onSectionDragEnd(event: {
      newIndex: number
      oldIndex: number
      item: { _underlying_vm_: StorageFoldersPresenter }
    }) {
      const {
        newIndex,
        oldIndex,
        item: { _underlying_vm_ },
      } = event

      const sort_order = newIndex >= oldIndex ? newIndex + 1 : newIndex

      this.handleUpdateSection(_underlying_vm_.id as number, {
        sort_order,
      })
    },
  },
})
