import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'
import TimeRange from '@/components/TimeRange/index.vue'
import DatePickerModal from '@/components/DatePickerModal/index.vue'
import { getAllMatters } from '@/services/legalMatter'
import { MatterPresenter } from '@/presenters/matterPresenter'
import {
  getOptionFullName,
  getOptionName,
  validateInput,
  validateSelect,
} from '@/lib/utils'
import { CompanyConfigurationPresenter } from '@/presenters/companyConfigPresenters'

const DatePickerImage = new URL(
  '@/assets/icons/date-picker-icon.svg',
  import.meta.url,
).href
const StartDateImage = new URL(
  '@/assets/icons/start-date-icon.svg',
  import.meta.url,
).href
const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url,
).href
const AttachFileIcon = new URL(
  '@/assets/icons/attach-file-icon.svg',
  import.meta.url,
).href
const FlagIcon = new URL('@/assets/icons/flag-icon.svg', import.meta.url).href

export default defineComponent({
  name: 'AddTaskForm',
  components: {
    TimeRange,
    DatePickerModal,
  },
  props: {
    addTaskLoading: {
      type: Boolean,
      default: false,
    },
    isAddTaskForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const alert = (...args: any) => console.log(args)
    const { isValid, validate, reset, resetValidation } = useForm('form')
    return {
      validateInput,
      validateSelect,
      getOptionName,
      getOptionFullName,
      alert,
      isAddTaskFormBool: this.isAddTaskForm,
      isValid,
      validate,
      reset,
      resetValidation,
      DatePickerImage,
      StartDateImage,
      MoreVertIcon,
      AttachFileIcon,
      FlagIcon,
      flagMenuOpen: false,
      loadingUpdate: false,

      form: {
        name: '',
        tag: [],
        start_date: '',
        end_date: '',
        due_date: '',
        priority: {} as CompanyConfigurationPresenter,
        section_stage_id: '',
        isOwner_counsel: false,
        owner_id: '',
        isAssigned_counsel: false,
        assigned_to_id: '',
        description: '',
        location: '',
        notes: '',
        budget: '',
        billable: '',
        // pre_charged: false,
        // value: '',
        // currency: 'USD',
        task_relationships_attributes: [] as any,
      },

      link_to: [] as any,
      file: [],
      time_range: {},
      due_time: '',

      mattersLoading: false,
      matterPage: 1,

      mattersItemPerPage: 10,

      matters: [] as MatterPresenter[],

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,
      noOptionsText: 'press enter to add',
    }
  },
  watch: {
    form: {
      handler(data) {
        console.log({ form: data })
      },
      deep: true, // Enable deep watching to detect changes within the object
    },
    handleChooseFlag(flag) {
      this.form.priority = flag.name
    },
    isAddTaskForm: {
      handler(data) {
        this.isAddTaskFormBool = data
        if (data) {
          this.handleGetMembers()
          this.handleGetMatters()
        }
      },
    },
  },
  methods: {
    addNewOption(newOption: string) {
      console.log({ newOption })
    },
    ////////////////////////////////////////////////////////////////////////
    handleToggleAddTaskModal() {
      this.isAddTaskFormBool = !this.isAddTaskFormBool
      this.$emit('handleToggleAddTaskModal')
    },
    handleCreateTask() {
      const { billable, priority, ...form } = this.form

      this.$emit('handleCreateTask', {
        ...form,
        priority_id: priority?.id,
        billable: billable.toString(),
      })
    },
    handleSelectedRangeDate(date: { start: string; end: string }) {
      this.form.start_date = date.start
      this.form.end_date = date.end
    },
    handleSelectedDate(date: { date: string }) {
      this.form.due_date = date.date
    },
    handleFlagMenuItemClick(flag: any) {
      this.form.priority = flag
    },
    handleLinkMatters(data: number[]) {
      if (data.length > 0) {
        this.form.task_relationships_attributes = data.map((id: number) => ({
          related_to_type: 'LegalMatter',
          related_to_id: id,
        }))
      } else {
        this.form.task_relationships_attributes = []
      }
    },
    /////////////////////////////////////////////////////////////////////
    async handleGetMembers(search?: string) {
      this.$stores.usersData.handleGetMembers({
        keyword: search,
      })
    },
    handleSearchMembers(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMembers(search)
      }, 500)
    },
    ////////////////////////////////////////////////////////////////////
    async handleGetMatters(search?: string) {
      this.mattersLoading = true

      const response = await getAllMatters({
        per_page: this.mattersItemPerPage,
        page: this.matterPage,
        keyword: search,
      })

      if (response.success) {
        this.matters = response.data?.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.mattersLoading = false
    },
    handleSearchMatters(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetMatters(search)
      }, 500)
    },
    ////////////////////////////////////////////////////////////////////
  },
})
