<template>
  <SharedHeader :back-button="true" title-width="30rem" :title="form.fullName">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left" />
      <div class="sub-SharedHeader-right" />
    </div>
  </SharedHeader>

  <TabsBar :tabs="tabs" :active-tab-id="activeTabId" @changeTab="changeTab" />
  <div class="scroll-container">
    <VaForm
      v-if="activeTabId == 1"
      ref="ContactDetailsFormRef"
      class="contact-info-container"
    >
      <div class="contact-image">
        <!-- <img src="https://i.pravatar.cc/302" class="contact-profile"
        :alt="`${contactDetails.first_name || ''} ${contactDetails.middle_name || ''} ${contactDetails.last_name || ''}`"> -->
        <div class="avatar-container">
          <!-- <img v-if="form?.specific_data" :src="form.specific_data?.individual_profile_picture?.url" alt="Avatar"
          class="avatar" /> -->
          <div class="avatar-fallback">
            {{ form.client_avatar_letter }}
          </div>
        </div>
        <p>
          <img
            :src="verifiedImage"
            alt="VERIFIED"
            class="contact-profile-icon"
          />Verified <br />
          HAQQ User
        </p>
        <!-- <div class="delete-button">
        <VaButton @click="isDeleteModal = !isDeleteModal"> Delete </VaButton>
        <VaModal
          v-model="isDeleteModal"
          message="Would you like to Delete?"
          ok-text="Delete"
          size="auto"
          @ok="handleDelete"
        />
      </div> -->
      </div>

      <VaInput
        v-model="form.specific_data.individual_title"
        placeholder="Title"
        background="#ECF0F1"
        style="width: 49%"
        label="Title"
      />

      <VaInput
        v-model="form.specific_data.individual_first_name"
        placeholder="First Name"
        background="#ECF0F1"
        style="width: 49%"
        :rules="[validateInput]"
        label="First Name"
        required-mark
      />

      <VaInput
        v-model="form.specific_data.individual_middle_name"
        placeholder="Middle Name"
        background="#ECF0F1"
        style="width: 49%"
        label="Middle Name"
      />

      <VaInput
        v-model="form.specific_data.individual_last_name"
        placeholder="Last Name"
        background="#ECF0F1"
        style="width: 49%"
        :rules="[validateInput]"
        label="Last Name"
        required-mark
      />

      <VaInput
        v-model="form.specific_data.individual_suffix"
        placeholder="Suffix"
        background="#ECF0F1"
        style="width: 49%"
        label="Suffix"
      />

      <VaInput
        v-model="form.specific_data.individual_job_title"
        placeholder="Job Title"
        background="#ECF0F1"
        style="width: 49%"
        label="job title"
      />

      <VaInput
        v-model="form.specific_data.individual_tax_identification_number"
        placeholder="Tax Identification Number"
        background="#ECF0F1"
        style="width: 49%"
        label="Tax Identification Number"
      />

      <VaDateInput
        v-model="form.specific_data.individual_date_of_birth"
        placeholder="Date Of Birth"
        label="Date Of Birth"
        background="#ECF0F1"
        style="width: 49%"
        manual-input
      />
      <VaInput
        v-model="form.specific_data.individual_nationality"
        placeholder="Nationality"
        background="#ECF0F1"
        style="width: 49%"
        label="Nationality"
      />

      <div class="form-sex">
        <p>Gender</p>
        <VaRadio
          v-model="form.specific_data.individual_gender"
          :options="['male', 'female']"
        />
      </div>

      <div style="width: 100%">
        <div v-for="(emailAddress, index) in form.email_addresses" :key="index">
          <VaInput
            v-model="emailAddress.email_address"
            background="#ECF0F1"
            style="width: 99%"
            :rules="[validateInput, validateInputEmail]"
            :label="`${emailAddress.label}`"
            required-mark
          />
        </div>
        <!-- Button to add more email input fields -->
        <!-- <button @click="addEmailAddress">Add Another Email</button> -->
      </div>

      <div style="width: 100%">
        <div v-for="(phoneNB, index) in form.phone_numbers" :key="index">
          <VaInput
            v-model="phoneNB.phone_number"
            background="#ECF0F1"
            style="width: 99%"
            :label="`${phoneNB.label}`"
            required-mark
            :rules="[validateInput]"
          />
        </div>
        <!-- Button to add more email input fields -->
        <!-- <button @click="addEmailAddress">Add Another Email</button> -->
      </div>

      <div style="width: 49%">
        <VaSelect
          v-model="form.relationship_owner"
          background="#ECF0F1"
          style="width: 100%; height: 2rem"
          label="Relationship Owner"
          required-mark
          searchable
          :options="$stores.usersData.teamMembers"
          :highlight-matched-text="false"
          :text-by="getOptionFullName"
          :rules="[validateSelect]"
          :loading="$stores.usersData.membersLoading"
          @update:search="handleSearchMembers"
        />
      </div>

      <div style="width: 49%">
        <VaSelect
          v-model="form.assigned_to"
          background="#ECF0F1"
          style="width: 100%"
          label="Assigned To"
          required-mark
          clearable
          searchable
          :options="$stores.usersData.teamMembers"
          :highlight-matched-text="false"
          :text-by="getOptionFullName"
          :rules="[validateSelect]"
          :loading="$stores.usersData.membersLoading"
          @update:search="handleSearchMembers"
        />
      </div>
      <div style="width: 49%">
        <VaSelect
          v-model="form.referred_by"
          placeholder="Referred by"
          background="#ECF0F1"
          style="width: 100%; height: auto"
          label="Referred by"
          searchable
          clearable
          :highlight-matched-text="false"
          :options="$stores.contacts.contacts"
          :text-by="getOptionFullName"
          :loading="$stores.contacts.contactsLoading"
          @update:search="handleSearchContacts"
        />
      </div>
      <div style="width: 49%">
        <VaSelect
          v-model="form.organization_contact"
          placeholder="Link To Organization"
          background="#ECF0F1"
          style="width: 100%; height: auto"
          label="Link To Organization"
          searchable
          clearable
          :highlight-matched-text="false"
          :options="$stores.contacts.organizations"
          :text-by="getOptionTruncateName50"
          :loading="$stores.contacts.organizationsLoading"
          @update:search="handleSearchOrganizations"
        />
      </div>
      <!-- <div style="width: 49%">
      <VaSelect
        background="#ECF0F1"
        v-model="form.designated_point_of_contact"
        style="width: 100%; "
        label="Designated point of contact"
        searchable
        :options="teamMembers"
        :highlight-matched-text="false"
            :text-by="getOptionFullName"
        value-by="id"
      />
    </div> -->
      <VaTextarea
        v-model="form.notes"
        max-rows="11"
        label="Note "
        style="width: 99%"
        autosize
        placeholder="Add your note here..."
        background="#ECF0F1"
      />

      <VaAccordion v-model="isAddressAccordion">
        <VaCollapse header="Address">
          <template #content>
            <div style="width: 79%">
              <VaSelect
                v-model="form.contact_addresses[0].country"
                placeholder="Country"
                background="#ECF0F1"
                :loading="countriesLoading"
                style="width: 100%; height: 2rem"
                label="Country"
                required-mark
                searchable
                :options="countries"
                :highlight-matched-text="false"
                :text-by="getOptionName"
                :rules="[validateSelect]"
                @update:modelValue="handleGetState"
              />
            </div>
            <VaInput
              v-model="form.contact_addresses[0].zip_code"
              placeholder="Zip Code"
              background="#ECF0F1"
              style="width: 19%"
              label="Postal/Zip Code"
            />
            <div style="width: 49%">
              <VaSelect
                v-model="form.contact_addresses[0].state"
                placeholder="Province/State"
                background="#ECF0F1"
                :loading="statesLoading"
                style="width: 100%"
                label="Province/State"
                searchable
                :options="states"
                :highlight-matched-text="false"
                :text-by="getOptionName"
                :rules="[validateSelect]"
                required-mark
                @update:modelValue="handleGetCities"
              />
            </div>

            <div style="width: 49%">
              <VaSelect
                v-model="form.contact_addresses[0].city"
                placeholder="City"
                background="#ECF0F1"
                :loading="citiesLoading"
                style="width: 100%"
                label="City"
                searchable
                :options="cities"
                :highlight-matched-text="false"
                :text-by="getOptionName"
                :rules="[validateSelect]"
                required-mark
              />
            </div>
            <VaInput
              v-model="form.contact_addresses[0].street_name"
              placeholder="Street"
              background="#ECF0F1"
              style="width: 49%"
              label="Street"
            />
            <VaInput
              v-model="form.contact_addresses[0].building_name"
              placeholder="Building"
              background="#ECF0F1"
              style="width: 49%"
              label="Building"
            />

            <VaInput
              v-model="form.contact_addresses[0].phone_number"
              placeholder="Office Number"
              background="#ECF0F1"
              style="width: 49%"
              label="Office number"
            />

            <VaInput
              v-model="form.contact_addresses[0].floor"
              placeholder="Floor"
              background="#ECF0F1"
              style="width: 49%"
              label="Floor"
            />

            <VaInput
              v-model="form.contact_addresses[0].google_maps_url"
              placeholder="Google Maps Link"
              background="#ECF0F1"
              style="width: 99%"
              label="Google Maps Link"
            />
          </template>
        </VaCollapse>
      </VaAccordion>
      <div class="action-buttons">
        <VaButton preset="secondary" border-color="primary" @click="handleBack">
          Back
        </VaButton>

        <VaButton
          :loading="loadingUpdate"
          preset="primary"
          @click="validate() && handleUpdate()"
        >
          Update
        </VaButton>
      </div>
    </VaForm>
    <div v-else class="container">
      <ComingSoon />
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
