import { defineComponent } from 'vue'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'
import { CurrenciesPresenter } from '@/presenters/CurrenciesPresenter'
import { deleteMember, updateMember } from '@/services/company'
import { useForm } from 'vuestic-ui/web-components'
import { deepCopy, getOptionCurrency } from '@/lib/utils'
import { getAllCurrencies } from '@/services/currencies'

const salaryFrequencyOptions = [
  {
    text: 'Daily',
    value: 'daily',
  },
  {
    text: 'Weekly',
    value: 'weekly',
  },
  {
    text: 'Bi Weekly',
    value: 'biweekly',
  },
  {
    text: 'Monthly',
    value: 'monthly',
  },
  {
    text: 'Quarterly',
    value: 'quarterly',
  },
  {
    text: 'Annually',
    value: 'annually',
  },
]

export default defineComponent({
  name: 'MemberProfilePage',
  props: {
    currentMember: {
      type: CompanyUserPresenter,
      required: true,
    },
  },
  data() {
    const { validate, reset } = useForm('update-salary-form')

    return {
      validate,
      reset,
      getOptionCurrency,

      salaryFrequencyOptions,

      currencies: [] as CurrenciesPresenter[],
      currencyLoading: false,

      member: deepCopy(this.currentMember) as CompanyUserPresenter,

      isDeleteMember: false,
      deleteMemberLoading: false,

      updateMemberLoading: false,
    }
  },
  emits: ['handleGetMember'],
  methods: {
    ///////////////////////////////////////// Update /////////////////////////////////////////

    async handleUpdateMember() {
      this.updateMemberLoading = true
      const { company_id } = this.$stores.auth.company
      const {
        id,
        basic_salary_amount,
        basic_salary_frequency,
        billable_hourly_rate_earning_amount,
        billable_hourly_rate_charging_amount,
        ...member
      } = this.member

      const response = await updateMember(company_id, id, {
        basic_salary_amount,
        basic_salary_currency_id: member.basic_salary_currency?.id,
        basic_salary_frequency,
        billable_hourly_rate_earning_amount,
        billable_hourly_rate_earning_currency_id:
          member.billable_hourly_rate_earning_currency?.id,
        billable_hourly_rate_charging_amount,
        billable_hourly_rate_charging_currency_id:
          member.billable_hourly_rate_charging_currency?.id,
      })

      if (response.success) {
        this.$vaToast.init({
          message: 'Member Updated successfully!',
          color: 'success',
        })
        this.$emit('handleGetMember', id)
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.updateMemberLoading = false
    },

    ////////////////////////////// Currencies ///////////////////////////////////

    async handleGetCompanyCurrencies() {
      this.currencyLoading = true

      const responseCurrencies = await getAllCurrencies({})

      if (responseCurrencies.success) {
        this.currencies = responseCurrencies.data.list
      } else {
        this.$vaToast.init({
          message: `${responseCurrencies.message}`,
          color: 'danger',
        })
      }

      this.currencyLoading = false
    },

    ///////////////////////////////////////// delete /////////////////////////////////////////

    handleToggleDeleteMember() {
      this.isDeleteMember = !this.isDeleteMember
    },

    async handleDeleteMember() {
      this.deleteMemberLoading = true

      const response = await deleteMember(
        this.$stores.auth.company.company_id,
        this.member.id,
      )
      if (response.success) {
        this.$vaToast.init({
          message: 'Member Removed successfully!',
          color: 'success',
        })

        this.handleToggleDeleteMember()
        this.$router.replace('/firm?tab=Workspaces,+Teams+%26+Members')
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.deleteMemberLoading = false
    },
  },
  computed: {},
  mounted() {
    this.handleGetCompanyCurrencies()
  },
  watch: {
    currentMember: {
      handler(data) {
        this.member = deepCopy(data)
      },
    },
  },
})
