import { SectionStageItemInterface } from '@/Interfaces/Services'
import { SectionsPresenter } from '../SectionsPresenter'

export class StagesPresenter {
  id!: number
  class_ame!: string
  color!: string
  created_at!: string
  name!: string
  section_id!: number
  section_stage_items!: SectionsPresenter[]
  section_stage_items_count!: number
  sort_order!: number
  updated_at!: string

  section_stages!: any[]
  section!: SectionsPresenter
  section_stage!: SectionsPresenter

  order?: number

  constructor(data: StagesPresenter) {
    Object.assign(this, data)

    if (data.section_stage_items) {
      this.section_stage_items = data.section_stage_items.map(
        (item) => new SectionsPresenter(item),
      )
    }

    if (data.section) {
      this.section = new SectionsPresenter(data.section)
    }
  }
}
