<template>
  <div class="member-container">
    <ActionsBar secondAddButton="CHAT" />

    <va-avatar color="#ECF0F1" size="96px" class="avatar" font-size="1.75rem">
      <span>{{ currentMember?.avatar_letter }}</span>
    </va-avatar>
    <div class="member-details">
      <h2>
        {{ currentMember?.fullName }}
      </h2>
      <p>
        {{ currentMember?.user?.email }}
      </p>
      <p>
        {{ currentMember?.user?.title }}
      </p>
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
