import { StorageFoldersPresenter } from '../StorageFoldersPresenter'

export class StorageCategoryPresenter {
  id?: number
  class_name!: string
  company_id!: number
  created_at!: string
  created_by_user_id!: number
  description!: string
  name!: string
  sort_order!: number
  storage_files!: any
  storage_folders!: StorageFoldersPresenter[]
  updated_at!: string

  constructor(data: StorageCategoryPresenter) {
    Object.assign(this, data)

    if (data.storage_folders) {
      this.storage_folders = data.storage_folders.map(
        (item: StorageFoldersPresenter) => new StorageFoldersPresenter(item),
      )
    }
  }
}
