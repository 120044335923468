<template>
  <v-menu
    v-model="isStartTimer"
    :close-on-content-click="false"
    location="bottom left"
  >
    <template #activator="{ props }">
      <div class="timer-container" v-bind="props">
        <div class="circular-background-stop-image">
          <div v-if="startRecordLoading" class="play-image">
            <VaIcon class="loading-icon" name="autorenew" color="primary" />
          </div>
          <img
            v-else
            :src="isTimeRecord ? playImage : stopImage"
            alt="LOGO"
            class="press-button"
            @click.stop="handleStartEndTime(isTimeRecord)"
          />
        </div>

        <p>{{ isTimeRecord ? 'Add time' : 'Running tasks...' }}</p>
      </div>
    </template>
    <v-card class="option-card">
      <VaTabs
        v-model="timerTab"
        stateful
        grow
        color="primary"
        class="time-log-header-tabs"
      >
        <template #tabs>
          <VaTab
            v-for="title in timerTabs"
            :key="title"
            :name="title"
            color="primary"
          >
            {{ title }}
          </VaTab>
        </template>
      </VaTabs>

      <div v-if="timerTab === 'Manual'" class="time-log-Manual">
        <div class="option-update">
          <div class="option-update-time">
            <VaIcon name="manage_history" color="primary" size="16px" />
            <DatePickerModal
              class="Date-Picker-Modal-1"
              :date="manualForm.start_time"
              shape="time-record-small"
              @update:date="handleUpdateStartDateManual"
            />
            -
            <DatePickerModal
              :date="manualForm.end_time"
              shape="time-record-small"
              @update:date="handleUpdateEndDateManual"
            />
          </div>
          <VaDivider />

          <div class="option-update-note">
            <VaIcon name="subject" color="primary" size="16px" />
            <VaInput
              v-model="manualForm.description"
              placeholder="Description..."
              background="#fff"
              class="note-input"
            />
          </div>
        </div>
        <div class="time-log-Manual-footer">
          <div class="option-footer-btn">
            <VaButton
              round
              icon="attach_money"
              class="dollar-button"
              :color="manualForm.billable ? '#38A169' : '#b8c4d6'"
              size="small"
              @click="manualForm.billable = !manualForm.billable"
            />
            <VaButton
              preset="primary"
              :loading="manualFormLoading"
              size="small"
              @click="handleCreateManual"
            >
              Add
            </VaButton>
          </div>
        </div>
      </div>

      <div v-if="timerTab === 'Range'" class="time-log-Manual">
        <div class="option-update">
          <div class="option-range-container">
            <VaIcon name="manage_history" color="primary" size="16px" />
            <div class="option-range">
              <VaInput
                v-model="timeRange.hours"
                background="#fff"
                type="number"
                min="0"
                step="1"
                @input="handleRangeTimeToSec"
              >
                <template #appendInner>
                  <span>hr</span>
                </template>
              </VaInput>
              <p>:</p>
              <VaInput
                v-model="timeRange.minutes"
                background="#fff"
                type="number"
                min="0"
                step="1"
                @input="handleRangeTimeToSec"
              >
                <template #appendInner>
                  <span>min</span>
                </template>
              </VaInput>
            </div>
          </div>
          <VaDivider />

          <div class="option-update-note">
            <VaIcon name="subject" color="primary" size="16px" />
            <VaInput
              v-model="calendarItemsRangeForm.description"
              placeholder="Description..."
              background="#fff"
              class="note-input"
            />
          </div>
        </div>
        <div class="time-log-Manual-footer">
          <div class="option-footer-btn">
            <VaButton
              round
              icon="attach_money"
              class="dollar-button"
              :color="calendarItemsRangeForm.billable ? '#38A169' : '#b8c4d6'"
              size="small"
              @click="
                calendarItemsRangeForm.billable =
                  !calendarItemsRangeForm.billable
              "
            />
            <VaButton
              preset="primary"
              :loading="calendarItemsRangeFormLoading"
              size="small"
              :disabled="timeRange.hours === '' || timeRange.minutes === ''"
              @click="handleAddTimeRange"
            >
              Add
            </VaButton>
          </div>
        </div>
      </div>

      <div class="time-log-section-details-container">
        <div
          v-for="(record, index) in $stores.mockTimer.specificCalendarItems"
          :key="index"
          class="time-log-section-details"
        >
          <div v-if="!record.time_range" class="circular-background-stop-image">
            <div v-if="loadingStates[index]" class="play-image">
              <VaIcon class="loading-icon" name="autorenew" color="primary" />
            </div>

            <img
              v-else
              :src="record.end_time ? playImage : stopImage"
              alt="LOGO"
              class="play-image"
              @click="handleStartEndRecord(record, index)"
            />
          </div>

          <div
            v-else-if="record.time_range"
            class="circular-background-stop-image"
          >
            <div class="play-image">
              <font-awesome-icon
                icon="stopwatch"
                style="width: 1rem; height: 1rem"
              />
            </div>
          </div>

          <div class="time-log-section-details-container">
            <p v-if="record.description" class="record-details">
              <VaIcon name="subject" size="15px" />
              {{ record?.description }}
            </p>

            <p v-if="!record.time_range" class="record-time">
              {{ formatDateRange(record.start_time, record.end_time) }}
            </p>

            <p v-if="record.time_range" class="record-time">
              {{
                new Date(record.start_time).toLocaleDateString('en-US', {
                  weekday: 'short',
                  month: 'short',
                  day: 'numeric',
                })
              }}
              ,
              {{ formatTimeSeconds(record.total_time_spent_seconds) }}
            </p>
          </div>
          <v-menu
            v-model="showRecordOptionAsObject[index]"
            :close-on-content-click="false"
            location="end"
            @click:outside.stop="closeAllMenus"
          >
            <template #activator="{ props }">
              <VaIcon
                name="more_vert"
                v-bind="props"
                class="vert-icon press-button-2"
                @click="toggleMenu(index, record)"
              />
            </template>

            <v-card class="option-card">
              <div class="option-header">
                <p>
                  <va-avatar
                    v-if="user.avatar_first_letter"
                    class="option-header-avatar"
                    color="#B7C3D5"
                    size="18px"
                  >
                    <span>
                      {{ user.avatar_first_letter }}
                    </span> </va-avatar
                  >{{ user.fullName }}
                </p>
                <div class="option-header-buttons">
                  <VaButton
                    round
                    :icon="deleteRecordLoading ? 'sync' : 'delete'"
                    size="small"
                    preset="secondary"
                    hover-behavior="opacity"
                    :hover-opacity="0.4"
                    :class="[deleteRecordLoading && 'spin-animation']"
                    @click="handleDeleteRecord"
                  />

                  <VaButton
                    round
                    icon="close"
                    size="small"
                    preset="secondary"
                    hover-behavior="opacity"
                    :hover-opacity="0.1"
                    @click="closeAllMenus"
                  />
                </div>
              </div>

              <div class="option-spent-time">
                {{
                  formatTimeSeconds(
                    Number(selectedRecord.total_time_spent_seconds),
                  )
                }}
                <VaIcon name="timer" color="primary" size="16px" />
              </div>

              <VaDivider />

              <div>
                <v-menu
                  v-model="isItemTypeSelect"
                  :close-on-content-click="false"
                  location="bottom left"
                >
                  <template #activator="{ props }">
                    <div v-bind="props" class="press-button">
                      <div
                        v-if="selectedRecord.item_type_id"
                        class="item-type-view"
                        :style="{
                          color: selectedRecord.item_type?.color,
                        }"
                      >
                        <font-awesome-icon
                          :icon="selectedRecord.item_type?.icon_name"
                        />
                        {{ selectedRecord?.item_type?.name }}
                      </div>
                      <div v-else class="item-type-no-view">
                        <VaIcon
                          name="arrow_right_alt"
                          color="primary"
                          size="16px"
                        />
                        <p>Select Type</p>
                      </div>
                    </div>
                  </template>
                  <TypeSelectModal @handleAssignType="handleAssignType" />
                  <p>{{ selectedRecord.item_type_id }}</p>
                </v-menu>
              </div>

              <VaDivider />

              <div class="option-update">
                <div v-if="!record.time_range" class="option-update-time">
                  <VaIcon name="manage_history" color="primary" size="16px" />
                  <DatePickerModal
                    class="Date-Picker-Modal-1"
                    :date="selectedRecord.start_time"
                    shape="time-record-small"
                    @update:date="handleUpdateStartDate"
                  />
                  -
                  <DatePickerModal
                    :date="selectedRecord.end_time"
                    shape="time-record-small"
                    @update:date="handleUpdateEndDate"
                  />
                </div>

                <div v-else class="option-range-container">
                  <VaIcon name="manage_history" color="primary" size="16px" />
                  <div class="option-range">
                    <VaInput
                      v-model="timeRangeUpdate.hours"
                      type="number"
                      min="0"
                      step="1"
                      @input="handleRangeTimeUpdateToSec"
                    >
                      <template #appendInner>
                        <span>hr</span>
                      </template>
                    </VaInput>
                    <p>:</p>
                    <VaInput
                      v-model="timeRangeUpdate.minutes"
                      type="number"
                      min="0"
                      step="1"
                      @input="handleRangeTimeUpdateToSec"
                    >
                      <template #appendInner>
                        <span>min</span>
                      </template>
                    </VaInput>
                  </div>
                </div>

                <div class="option-update-note">
                  <VaIcon name="subject" color="primary" size="16px" />
                  <VaInput
                    v-model="selectedRecord.description"
                    class="note-input"
                    placeholder="Description..."
                  />
                </div>
              </div>

              <VaCardActions class="option-footer">
                <div class="option-footer-btns">
                  <VaButton
                    round
                    icon="attach_money"
                    class="dollar-button"
                    size="small"
                    :color="selectedRecord.billable ? '#38A169' : '#b8c4d6'"
                    @click="selectedRecord.billable = !selectedRecord.billable"
                  />
                  <VaButton
                    preset="primary"
                    size="small"
                    :loading="updateRecordLoading"
                    @click="handleUpdateRecord"
                  >
                    Save
                  </VaButton>
                </div>
              </VaCardActions>
            </v-card>
          </v-menu>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
