import { paginationInterface } from '@/Interfaces/Services'
import { TaskPresenter } from '@/presenters/taskPresenter'
import { defineComponent } from 'vue'
const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'TasksTable',
  props: {
    tasks: {
      type: Array as () => TaskPresenter[],
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    totalItems: {
      type: Number,
      default: 10,
    },
    headers: {
      type: Array,
      required: true,
    },
    matterTasksSection: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectedRows', 'loadMore', 'handleTableAction'],
  data() {
    return {
      localTotalItems: this.totalItems,
      selectedRows: [],
      MoreVertIcon,
      deleteDialog: false,
      deleteItem: null,
      currentPage: 1,
      currentSortBy: [] as any,
      currentItemsPerPage: this.itemsPerPage,
    }
  },
  watch: {
    selectedRows() {
      this.$emit('selectedRows', this.selectedRows)
    },
    totalItems(newValue) {
      this.localTotalItems = newValue
    },
  },
  methods: {
    handleButtonClick(item: TaskPresenter, actionType: string) {
      this.$emit('handleTableAction', { item, actionType })
    },
    formattedDate(newDate: string) {
      const date = new Date(newDate)

      const formattedDate = date.toLocaleDateString('en-US', {
        month: 'short', // "Sep" for September
        day: 'numeric',
        year: 'numeric',
      })

      const formattedTime = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false, // Use 24-hour format (change to true for 12-hour format)
      })

      return `${formattedDate} ${formattedTime}`
    },
    viewProfile(item: { id: any }) {
      this.$router.push(`/tasks/${item?.id}`)
    },
    handleLoadMore({ page, itemsPerPage, sortBy }: paginationInterface) {
      const { currentPage, currentItemsPerPage, currentSortBy } = this

      // Check if there are any changes in pagination or sorting
      const hasPaginationChanged =
        page !== currentPage || itemsPerPage !== currentItemsPerPage
      const hasSortingChanged =
        JSON.stringify(sortBy) !== JSON.stringify(currentSortBy) // Compare sorting objects

      // Only emit if there's a change in pagination or sorting
      if (hasPaginationChanged || hasSortingChanged) {
        this.$emit('loadMore', { page, itemsPerPage, sortBy })

        // Update state after the change
        this.currentPage = page
        this.currentItemsPerPage = itemsPerPage
        this.currentSortBy = sortBy
      }
    },
  },
})
