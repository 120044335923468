<template>
  <div>
    <SharedHeader backButton="true">
      <div class="sub-SharedHeader">
        <div class="sub-SharedHeader-left"></div>
        <div class="sub-SharedHeader-right"></div>
      </div>
    </SharedHeader>
    <TabsBar :tabs="tabs" :activeTabId="activeTabId" @changeTab="changeTab" />

    <!-- SETTINGS -->

    <ProfileSettings
      v-if="activeTabId === 0"
      :currentMember="currentMember"
      @handleGetMember="handleGetMember"
    />

    <!-- PROFILE -->
    <ProfilePreview
      v-else-if="activeTabId === 1"
      :currentMember="currentMember"
    />

    <!--  ELSE  -->
    <div v-else class="container">
      <ComingSoon />
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
