<template>
  <div class="container">
    <p><img src="../../assets/icons/logo.svg" /></p>
    <h1>Coming Soon</h1>
    <p>Page is being cooked</p>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
