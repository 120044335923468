import {
  invoiceItemAttributesInterface,
  invoiceTaxItemsAttributesInterface,
} from '@/Interfaces/Services'
import { CurrencyPresenter } from '../companyConfigPresenters'
import { IndividualContactPresenter } from '../contactPresenter'

export class InvoicePresenter {
  id!: number

  currency!: CurrencyPresenter
  secondary_currency!: CurrencyPresenter
  currency_conversion_rate_against_us_dollars!: string
  secondary_currency_conversion_rate_against_us_dollars!: string

  customer_email!: string
  customer_name!: string
  customer_amount!: string
  discount_type!: 'percentage' | 'amount'
  discount_type_amount!: string
  general_note!: string
  guid!: string
  individual_contact!: IndividualContactPresenter
  invoice_date!: string
  invoice_items!: invoiceItemAttributesInterface[]
  invoice_number!: string
  invoice_tax_items!: invoiceTaxItemsAttributesInterface[]
  organization_contact!: IndividualContactPresenter
  payment_details_note!: string
  payment_due_date!: string
  signature_note!: string
  status!: string
  tax_after_discount!: string
  tax_before_discount!: string
  terms_and_conditions_note!: string
  total_after_discount!: string
  total_before_discount!: string

  constructor(data: InvoicePresenter) {
    Object.assign(this, data)

    if (data.individual_contact) {
      this.individual_contact = new IndividualContactPresenter(
        data.individual_contact,
      )
    }

    if (data.organization_contact) {
      this.organization_contact = new IndividualContactPresenter(
        data.organization_contact,
      )
    }

    if (data.currency) {
      this.currency = new CurrencyPresenter(data.currency)
    }

    data.invoice_items?.forEach((e: invoiceItemAttributesInterface) => {
      e.quantity = Number(e.quantity)
    })
  }
}
