import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33d0ea26"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "actions-bar" }
const _hoisted_2 = { class: "select-actions" }
const _hoisted_3 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_va_icon = _resolveComponent("va-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showSelectButton && !_ctx.select)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            "button-text": "Select",
            "text-color": '#66023c',
            "custom-styles": {
          padding: '10px 15px',
          borderRadius: '5px',
          fontSize: '10px',
          fontWeight: '700',
          backgroundColor: '#e5eaf0',
          border: 'none',
        },
            "hover-styles": {
          backgroundColor: '#66023C',
          color: '#e5eaf0',
        }
          }))
        : (_ctx.showSelectButton && _ctx.select)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              "button-text": "Deselect",
              "text-color": '#e5eaf0',
              "custom-styles": {
          padding: '10px 15px',
          borderRadius: '5px',
          fontSize: '10px',
          fontWeight: '700',
          backgroundColor: '#002c6c',
          border: 'none',
        },
              "hover-styles": {
          backgroundColor: '#e5eaf0',
          color: '#002c6c',
        }
            }))
          : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass({ actions: true, disabled: !_ctx.select })
      }, [
        (_ctx.showDownloadIcon)
          ? (_openBlock(), _createBlock(_component_va_icon, {
              key: 0,
              name: "download",
              color: "primary",
              class: _normalizeClass(["icon", { 'disabled-icon': !_ctx.select }])
            }, null, 8, ["class"]))
          : _createCommentVNode("", true),
        (_ctx.showArchiveIcon)
          ? (_openBlock(), _createBlock(_component_va_icon, {
              key: 1,
              name: "inventory_2",
              color: "primary",
              class: _normalizeClass(["icon", { 'disabled-icon': !_ctx.select }])
            }, null, 8, ["class"]))
          : _createCommentVNode("", true),
        (_ctx.showLabelIcon)
          ? (_openBlock(), _createBlock(_component_va_icon, {
              key: 2,
              name: "label",
              color: "primary",
              class: _normalizeClass(["icon", { 'disabled-icon': !_ctx.select }])
            }, null, 8, ["class"]))
          : _createCommentVNode("", true),
        (_ctx.showShareIcon)
          ? (_openBlock(), _createBlock(_component_va_icon, {
              key: 3,
              name: "share",
              color: "primary",
              class: _normalizeClass(["icon", { 'disabled-icon': !_ctx.select }])
            }, null, 8, ["class"]))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.addButton)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            "button-text": _ctx.addButton,
            "text-color": '#fff',
            "icon-after": _ctx.addIcon,
            "custom-styles": {
          padding: '10px 15px',
          borderRadius: '5px',
          fontSize: '12px',
          fontWeight: '500',
          color: '#66023c',
          backgroundColor: '#fff',
          border: '0.5px solid #E2E8F0',
          maxHeight: '37px',
        },
            "hover-styles": {
          border: '0.5px solid #66023c',
        },
            onClick: _ctx.addButtonAction
          }, null, 8, ["button-text", "icon-after", "onClick"]))
        : _createCommentVNode("", true),
      (_ctx.deleteButton && _ctx.select)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 1,
            "button-text": _ctx.deleteButton,
            "text-color": '#fff',
            "icon-after": _ctx.deleteIcon,
            "custom-styles": {
          padding: '10px 15px',
          borderRadius: '5px',
          fontSize: '12px',
          fontWeight: '500',
          backgroundColor: '#66023C',
          border: '1px solid #66023C',
          maxHeight: '37px',
        },
            "hover-styles": {
          border: '1px solid #fff',
        },
            onClick: _ctx.addContact
          }, null, 8, ["button-text", "icon-after", "onClick"]))
        : _createCommentVNode("", true),
      (_ctx.secondAddButton && !_ctx.select)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 2,
            "button-text": _ctx.secondAddButton,
            "text-color": '#fff',
            "icon-after": _ctx.whiteAddIcon,
            "custom-styles": {
          padding: '10px 15px',
          borderRadius: '5px',
          fontSize: '12px',
          fontWeight: '500',
          backgroundColor: '#66023C',
          border: '1px solid #66023C',
          maxHeight: '37px',
        },
            "hover-styles": {
          border: '1px solid #fff',
        },
            onClick: _ctx.secondButtonAction
          }, null, 8, ["button-text", "icon-after", "onClick"]))
        : _createCommentVNode("", true),
      (_ctx.updateButton && _ctx.select)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 3,
            "button-text": _ctx.updateButton,
            "text-color": '#fff',
            "custom-styles": {
          padding: '10px 15px',
          borderRadius: '5px',
          fontSize: '12px',
          fontWeight: '500',
          color: '#66023c',
          backgroundColor: '#fff',
          border: '0.5px solid #E2E8F0',
          maxHeight: '37px',
        },
            "hover-styles": {
          border: '0.5px solid #66023c',
        },
            onClick: _ctx.addLead
          }, null, 8, ["button-text", "onClick"]))
        : _createCommentVNode("", true),
      (_ctx.editColumns)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 4,
            "button-text": "",
            "text-color": '#fff',
            "icon-before": _ctx.editColumnsIcon,
            "custom-styles": {
          padding: '10px',
          borderRadius: '5px',
          fontSize: '12px',
          fontWeight: '500',
          backgroundColor: '#fff',
          width: 'fit-content',
          border: '0.5px solid #E2E8F0',
        },
            "hover-styles": {
          border: '0.5px solid #66023c',
        },
            "only-icon": true
          }, null, 8, ["icon-before"]))
        : _createCommentVNode("", true)
    ])
  ]))
}