import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomChip',
  props: {
    label: {
      type: String,
    },
    color: {
      type: String,
    },
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
  },
})
