import { defineComponent } from 'vue'
import TabsBar from '@/components/tabsBar/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import Members from '@/views/firm/WorkspaceTeamsMembers/Members/index.vue'

export default defineComponent({
  name: '',
  components: {
    TabsBar,
    Members,
    ComingSoon,
  },
  data() {
    const tabs = [
      { id: 0, name: 'WORKSPACE' },
      { id: 1, name: 'TEAMS' },
      { id: 2, name: 'MEMBERS' },
      { id: 3, name: 'KPI & TArgets' },
    ]
    return {
      tabs,
      activeTabId: 2,
      activeTab: 'MEMBERS',
    }
  },
  methods: {
    changeTab(tab: { name: string; id: number }) {
      this.activeTabId = tab.id
      this.activeTab = tab.name
    },
  },
})
