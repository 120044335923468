import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2695fd62"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VaIcon = _resolveComponent("VaIcon")!
  const _component_VaCheckbox = _resolveComponent("VaCheckbox")!
  const _component_VaSidebar = _resolveComponent("VaSidebar")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.enabled)
      ? (_openBlock(), _createBlock(_component_VaIcon, {
          key: 0,
          name: "chat",
          size: "large",
          color: "primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.enabled = !_ctx.enabled))
        }))
      : _createCommentVNode("", true),
    (!_ctx.enabled)
      ? (_openBlock(), _createBlock(_component_VaIcon, {
          key: 1,
          name: "article",
          size: "large",
          color: "primary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.enabled = !_ctx.enabled))
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_VaSidebar, {
      modelValue: _ctx.enabled,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.enabled) = $event)),
      width: "20rem",
      class: "absolute right-0 left-auto",
      animated: "right"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_VaCheckbox, {
            modelValue: _ctx.enabled,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.enabled) = $event)),
            label: "Show2"
          }, null, 8, ["modelValue"]),
          _createTextVNode(" aliiiiiiiiii ")
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}