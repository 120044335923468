import {
  getOptionFullName,
  getOptionName,
  getOptionTruncateName50,
  validateInput,
  validateInputEmail,
  validateSelect,
  truncateText,
  getOptionTruncateName110,
} from '@/lib/utils'
import { MatterPresenter } from '@/presenters/matterPresenter'
import { StagesPresenter } from '@/presenters/StagesPresenter'
import { getAllCountries } from '@/services/address'
import { getAllMatters, getTemplate } from '@/services/legalMatter'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'
import DynamicForm from '@/components/DynamicForm/index.vue'
import { MatterTemplatePresenter } from '@/presenters/TemplatePresenter/Matter'
import { SectionsPresenter } from '@/presenters/SectionsPresenter'

export default defineComponent({
  name: 'AddMatterForm',
  props: {
    addMatterLoading: {
      type: Boolean,
      default: false,
    },
    isAddMatter: {
      type: Boolean,
      default: false,
    },
    subSections: {
      type: Array as () => SectionsPresenter[],
      required: true,
    },
  },
  data() {
    const { isValid, validate, reset, resetValidation } = useForm('form')

    const subSection = {} as SectionsPresenter

    return {
      validateInput,
      validateInputEmail,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionTruncateName50,
      getOptionTruncateName110,

      formFields: [] as MatterTemplatePresenter[][],

      isAddMatterBool: this.isAddMatter,
      isValid,
      validate,
      reset,
      resetValidation,
      case_personal_of_counsel: false as boolean,
      form: {} as MatterPresenter,

      countriesLoading: false,
      contactsLoading: false,

      presentationTypeOption: ['Plaintiff', 'Defendant'],

      truncateText,

      mattersLoading: false,
      matterPage: 1,

      mattersItemPerPage: 10,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,

      subSection,
      toggleSubmit: false,
    }
  },
  methods: {
    handleToggleAddModal() {
      this.isAddMatterBool = !this.isAddMatterBool
      this.$emit('handleToggleAddModal')
    },
    async handleToggleSubmit() {
      this.toggleSubmit = !this.toggleSubmit
    },
    handleCreateMatter(form: MatterPresenter) {
      const {
        representation_type,
        parent,
        court,
        judge,
        representing_contact,
        opponent_contact,
        status,
        specialization,
        category,
        sector,
        jurisdriction,
        case_manager,
        personnel,
        section_stage,
        ...updatedForm
      } = form

      this.$emit('handleCreateMatter', {
        representation_type: representation_type?.toLocaleLowerCase(),
        parent_id: parent?.id,
        court_id: court?.id,
        judge_id: judge?.id,
        representing_contact_id: representing_contact?.id,
        opponent_contact_id: opponent_contact?.id,
        status_id: status?.id,
        specialization_id: specialization?.id,
        category_id: category?.id,
        sector_id: sector?.id,
        jurisdriction_id: jurisdriction?.id,
        section_stage_id: section_stage?.id,
        case_manager_ids: case_manager?.map((e) => e.id),
        personnel_ids: personnel?.map((e) => e.id),
        ...updatedForm,
      })
    },
    ///////////////////////////////////////////////////////////////
    async handleGetContacts(search?: string) {
      this.$stores.contacts.handleGetContacts({
        keyword: search,
      })
    },
    handleSearchContacts(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts(search)
      }, 500)
    },
    //////////////////////////////////////////////////////////////
    async handleGetAllCountries() {
      this.countriesLoading = true
      const response = await getAllCountries()

      if (response.success) {
        this.$stores.generalData.countries = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.countriesLoading = false
    },
    ////////////////////////////////////////////////////
    async handleGetMembers(search?: string) {
      this.$stores.usersData.handleGetMembers({
        keyword: search,
      })
    },
    handleSearchMembers(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMembers(search)
      }, 500)
    },
    ////////////////////////////////////////////////////////////////////
    async handleGetMatters(search?: string) {
      this.$stores.matter.mattersLoading = true

      const response = await getAllMatters({
        per_page: this.mattersItemPerPage,
        page: this.matterPage,
        keyword: search,
      })

      if (response.success) {
        this.$stores.matter.matters = response.data?.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.$stores.matter.mattersLoading = false
    },

    handleSearchMatters(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetMatters(search)
      }, 500)
    },
    ////////////////////////////////////////////////////////////////////
  },
  watch: {
    isAddMatter: {
      handler(data) {
        this.isAddMatterBool = data

        if (data) {
          this.handleGetContacts()
          this.handleGetAllCountries()
          this.handleGetMembers()
          this.handleGetMatters()

          console.log({ subSections: this.subSections })

          this.subSection =
            this.subSections.find(
              (section) => section.name === this.$route.query.tab,
            ) || ({} as SectionsPresenter)

          this.formFields = this.subSection.form_columns

          this.$stores.matter.sectionStages = this.subSection?.section_stages

          this.form.section_stage = this.subSection?.section_stages?.[0]
        }
      },
    },
    subSection: {
      handler(data) {
        this.formFields = data.form_columns
      },
      deep: true,
    },
  },
  components: {
    DynamicForm,
  },
})
