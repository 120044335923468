import { defineComponent } from 'vue'
import TabsBar from '@/components/tabsBar/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import { useForm } from 'vuestic-ui'
import {
  deleteContact,
  getContactById,
  updateIndividualContact,
} from '@/services/contacts'
import ComingSoon from '@/components/ComingSoon/index.vue'
import { getAllCities, getAllCountries, getAllStates } from '@/services/address'
import {
  getOptionFullName,
  getOptionName,
  getOptionTruncateName50,
  truncateText,
  validateInput,
  validateInputEmail,
  validateSelect,
} from '@/lib/utils'
import { IndividualContactPresenter } from '@/presenters/contactPresenter'
import { useTabs } from '@/composables/useTabs'
import { IndividualSpecificData } from '@/presenters/interface'
import { AddressPresenter } from '@/presenters/AddressPresenter'
import { CityPresenter } from '@/presenters/AddressPresenter/CityPresenter'
import { StatePresenter } from '@/presenters/AddressPresenter/StatePresenter'
import { CountryPresenter } from '@/presenters/AddressPresenter/CountryPresenter'

const verifiedImage = new URL(
  '@/assets/icons/verified-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'ContactDetailsPage',
  components: {
    TabsBar,
    SharedHeader,
    ComingSoon,
  },
  data() {
    const { isValid, validate, reset, resetValidation } = useForm(
      'ContactDetailsFormRef',
    )
    const tabs = [
      { id: 0, name: 'DASHBOARD', icon: 'dashboard' },
      { id: 1, name: 'CONTACT INFO', icon: 'info' },
      { id: 2, name: 'LEADS', icon: 'my_location' },
      { id: 12, name: 'LEGAL MATTERS', icon: 'inventory_2' },
      { id: 3, name: 'TASKS', icon: 'assignment' },
      { id: 4, name: 'CALENDAR', icon: 'calendar_month' },
      { id: 5, name: 'MILESTONES', icon: 'flag' },
      { id: 6, name: 'FILES', icon: 'file_copy' },
      { id: 7, name: 'FINANCIAL', icon: 'attach_money' },
      { id: 8, name: 'HEARINGS', icon: 'gavel' },
      { id: 9, name: 'ENGAGEMENT LETTER', icon: 'article' },
      { id: 10, name: 'EMAILS', icon: 'mail' },
      { id: 11, name: 'KYC DOCUMENTS', icon: 'article' },
    ]

    const { activeTabId, activeTab, changeTab } = useTabs(tabs, 1)

    return {
      validateInput,
      validateInputEmail,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionTruncateName50,

      isValid,
      validate,
      reset,
      resetValidation,
      verifiedImage,
      loading: false,
      loadingUpdate: false,
      tabs,
      activeTabId,
      activeTab,
      changeTab,
      countries: [] as CountryPresenter[],
      states: [] as CountryPresenter[],
      cities: [] as CountryPresenter[],
      countriesLoading: false,
      statesLoading: false,
      citiesLoading: false,

      kyc_document: {},

      form: {
        specific_data: {
          individual_date_of_birth: '',
          individual_first_name: '',
          individual_gender: '',
          individual_job_title: '',
          individual_last_name: '',
          individual_middle_name: '',
          individual_nationality: '',
          individual_profile_picture: {},
          individual_suffix: '',
          individual_tax_identification_number: '',
          individual_title: '',
        } as IndividualSpecificData,
      } as IndividualContactPresenter,

      contactTypeOptions: [
        { id: 0, title: 'Person' },
        { id: 1, title: 'Organization' },
      ],
      isAddressAccordion: false,
      isDeleteModal: false,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,
      truncateText,
    }
  },
  watch: {
    form: {
      handler(data) {
        console.log({
          form: data,
        })
      },
      deep: true,
    },
  },
  mounted() {
    this.handleGetContactByID()
    this.handleGetMembers()
    this.handleGetAllCountries()
    this.handleGetContacts()
    this.handleGetOrganizations()
  },

  methods: {
    async handleGetContactByID() {
      this.loading = true
      const response = await getContactById(this.$route.params.id as string)
      if (response.success) {
        this.form = response.data
        console.log({ form: this.form })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.loading = false
    },
    ////////////////////////////////////////////////////////////////////////////////////////////
    async handleGetMembers(search?: string) {
      this.$stores.usersData.handleGetMembers({
        keyword: search,
      })
    },
    handleSearchMembers(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMembers(search)
      }, 500)
    },
    ////////////////////////////////////////////////////////////////////////////////////////////
    async handleGetAllCountries() {
      this.countriesLoading = true
      const response = await getAllCountries()

      if (response.success) {
        this.countries = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.countriesLoading = false
    },
    async handleGetState(value: CityPresenter) {
      this.statesLoading = true
      const response = await getAllStates(value.id as string)

      if (response.success) {
        this.states = response.data.list
      } else {
        // this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.statesLoading = false
    },
    async handleGetCities(value: StatePresenter) {
      this.citiesLoading = true
      const response = await getAllCities(value.id as string)

      if (response.success) {
        this.cities = response.data.list
      } else {
        // this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.citiesLoading = false
    },

    ////////////////////////////////////////////////////////////////////////////////////////////

    addEmailAddress() {
      this.form.email_addresses.push({ email_address: '', label: '' }) // Add a new email input
    },
    async handleDelete() {
      const response = await deleteContact(this.$route.params.id as string)
      if (response.success) {
        this.$router.push({ name: 'ContactsView' })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
    },
    handleBack() {
      this.$router.back()
    },

    async handleUpdate() {
      this.loadingUpdate = true

      const updatedEmailArr = this.form.email_addresses?.map(
        ({ email, ...rest }: any) => ({
          email_address: email,
          ...rest,
        }),
      )

      const {
        contact_addresses,
        designated_point_of_contact,
        specific_data,
        assigned_to,
        relationship_owner,
        referred_by,
        organization_contact,
        phone_numbers,
        kyc_completed,
        kyc_notes,
        kyc_document,
        notes,
        extra_notes,
        facebook_url,
        twitter_url,
        google_plus_url,
        linkedin_url,
        instagram_url,
        tiktok_url,
        youtube_url,
        website_url,
      } = this.form

      const {
        individual_gender,
        individual_profile_picture,
        ...specific_data_updated
      } = specific_data

      console.log({ kyc_document, individual_profile_picture })

      const response = await updateIndividualContact({
        id: this.$route.params?.id as string,
        body: {
          assigned_to_id: assigned_to?.id,
          relationship_owner_id: relationship_owner?.id,
          designated_point_of_contact_id: designated_point_of_contact?.id,
          referred_by_id: referred_by?.id,
          organization_contact_id: organization_contact?.id,
          ...specific_data_updated,
          individual_gender: individual_gender || 'male',
          email_addresses: updatedEmailArr,
          phone_numbers,
          ...(contact_addresses[0].city.id && {
            contact_addresses_attributes: contact_addresses.map(
              ({
                id,
                city,
                phone_number,
                floor,
                building_name,
                street_name,
                zip_code,
                google_maps_url,
              }: AddressPresenter) => ({
                id: id,
                city_id: city.id,
                phone_number,
                floor,
                building_name,
                street_name,
                zip_code,
                google_maps_url,
              }),
            ),
          }),
          kyc_completed,
          kyc_notes,
          // kyc_document,
          notes,
          extra_notes,
          facebook_url,
          twitter_url,
          google_plus_url,
          linkedin_url,
          instagram_url,
          tiktok_url,
          youtube_url,
          website_url,
        },
      })
      if (response.success) {
        this.$vaToast.init({
          message: 'Contact updates successfully!',
          color: 'success',
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.loadingUpdate = false
    },
    ////////////////////////////////////////////////////////////////
    async handleGetContacts(search?: string) {
      this.$stores.contacts.handleGetContacts({
        keyword: search,
      })
    },
    handleSearchContacts(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts(search)
      }, 500)
    },
    /////////////////////////////////////////////////////////////////////
    async handleGetOrganizations(search?: string) {
      this.$stores.contacts.handleGetOrganizations({
        keyword: search,
      })
    },
    handleSearchOrganizations(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetOrganizations(search)
      }, 500)
    },
  },
})
