import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b236bca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "name" }
const _hoisted_2 = { class: "description" }
const _hoisted_3 = { class: "currency" }
const _hoisted_4 = { class: "linkTo" }
const _hoisted_5 = { class: "billed_to" }
const _hoisted_6 = { class: "from_wallet" }
const _hoisted_7 = { class: "type" }
const _hoisted_8 = { class: "from_wallet" }
const _hoisted_9 = { class: "table-action-td" }
const _hoisted_10 = ["src"]
const _hoisted_11 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_va_dropdown_content = _resolveComponent("va-dropdown-content")!
  const _component_va_dropdown = _resolveComponent("va-dropdown")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!

  return (_openBlock(), _createBlock(_component_v_data_table_server, {
    selection: _ctx.selectedRows,
    "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedRows) = $event)),
    loading: _ctx.loading,
    headers: _ctx.headers,
    items: _ctx.expenses.list,
    "show-select": "",
    class: "custom-table",
    "items-length": _ctx.totalItems,
    "items-per-page": _ctx.itemsPerPage,
    "items-per-page-options": [10, 25, 50],
    "onUpdate:options": _ctx.handleLoadMore
  }, {
    [`item.name`]: _withCtx(({ item }) => [
      _createVNode(_component_router_link, {
        to: `/financial/expense/${item?.id}`
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_1, _toDisplayString(item.name), 1)
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    [`item.description`]: _withCtx(({ item }) => [
      _createElementVNode("p", _hoisted_2, _toDisplayString(item.description), 1)
    ]),
    [`item.currency`]: _withCtx(({ item }) => [
      _createElementVNode("p", _hoisted_3, _toDisplayString(item.currency.code), 1)
    ]),
    [`item.linkTo`]: _withCtx(({ item }) => [
      _createElementVNode("p", _hoisted_4, _toDisplayString(item?.linkTo || '-'), 1)
    ]),
    [`item.billed_to`]: _withCtx(({ item }) => [
      _createElementVNode("p", _hoisted_5, _toDisplayString(item.billed_to
            ? `${item.billed_to.specific_data?.individual_first_name ?? ''} ${
                item.billed_to.specific_data?.individual_last_name ?? ''
              }`.trim() || 'N/A'
            : 'N/A'), 1)
    ]),
    [`item.from_wallet`]: _withCtx(({ item }) => [
      _createElementVNode("p", _hoisted_6, _toDisplayString(item.from_wallet
            ? `${item.from_wallet.specific_data?.individual_first_name ?? ''} ${
                item.from_wallet.specific_data?.individual_last_name ?? ''
              }`.trim() || 'N/A'
            : 'N/A'), 1)
    ]),
    [`item.status`]: _withCtx(({ item }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["status", item?.status.toLowerCase()])
      }, _toDisplayString(item.status.split('_').join(' ')), 3)
    ]),
    [`item.expense_type`]: _withCtx(({ item }) => [
      _createElementVNode("p", _hoisted_7, _toDisplayString(item.expense_type), 1)
    ]),
    [`item.due_date`]: _withCtx(({ item }) => [
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.formatDate(item.due_date)), 1)
    ]),
    [`item.action`]: _withCtx(({ item }) => [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_va_dropdown, {
          offset: [0, -70],
          "close-on-content-click": ""
        }, {
          anchor: _withCtx(() => [
            _createElementVNode("img", {
              src: _ctx.MoreVertIcon,
              alt: "MoreVertIcon",
              class: "press-button-2"
            }, null, 8, _hoisted_10)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_va_dropdown_content, null, {
              default: _withCtx(() => [
                _createElementVNode("p", {
                  class: "table-action-option",
                  onClick: ($event: any) => (_ctx.viewProfile(item))
                }, [
                  _createVNode(_component_va_icon, { name: "person" }),
                  _createTextVNode(" View Invoice ")
                ], 8, _hoisted_11)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024)
      ])
    ]),
    _: 2
  }, 1032, ["selection", "loading", "headers", "items", "items-length", "items-per-page", "onUpdate:options"]))
}