import { getExpenseById, updateExpense } from '@/services/expenses'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'
import {
  getOptionFullName,
  getOptionName,
  getOptionTruncateName50,
  validateInput,
  validateInputEmail,
  validateSelect,
  getOptionCurrency,
} from '@/lib/utils'
import SharedHeader from '@/components/sharedHeader/index.vue'
import { ExpensesPresenter } from '@/presenters/ExpensesPresenter'
import { CurrenciesPresenter } from '@/presenters/CurrenciesPresenter'
import { getAllCurrencies } from '@/services/currencies'

export default defineComponent({
  name: 'AddExpenseFormInterface',
  components: {
    SharedHeader,
  },
  data() {
    const { isValid, validate, reset, resetValidation } = useForm('form')

    const currencies = [] as CurrenciesPresenter[]
    return {
      validateInput,
      validateInputEmail,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionCurrency,
      getOptionTruncateName50,

      isValid,
      validate,
      reset,
      resetValidation,

      expenseDetails: [] as any,
      loading: false,
      files: [],

      form: {} as ExpensesPresenter,
      updateLoading: false,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null, // Holds the debounce timeout ID

      currencies,
      currencyLoading: false,
    }
  },
  watch: {
    'form.unit_price': 'calculateDeclaredAmount',
    'form.quantity': 'calculateDeclaredAmount',
    'form.declared_amount': 'calculateTaxAndTotalAmount',
    'form.total_tax': 'calculateTaxAndTotalAmount',
    form: {
      handler(newForm) {
        console.log('Form updated:', newForm)
      },
      deep: true,
    },
  },
  mounted() {
    this.handleGetExpenseByID()
    this.handleGetContacts()
    this.handleGetMembers()
    this.handleGetCompanyCurrencies()
  },
  methods: {
    handleToggleAddModal() {
      this.$emit('handleToggleAddModal')
    },

    async handleGetExpenseByID() {
      this.loading = true
      const response = await getExpenseById(this.$route.params.id as string)
      if (response.success) {
        this.form = response.data
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.loading = false
    },

    ////////////////////////////////////////////////////////////////////////
    async handleGetMembers(search?: string) {
      this.$stores.usersData.handleGetMembers({
        keyword: search,
      })
    },

    handleSearchMembers(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMembers(search)
      }, 500)
    },
    ////////////////////////////////////////////////////////////////////////
    async handleGetContacts(search?: string) {
      this.$stores.contacts.handleGetContacts({
        keyword: search,
      })
    },
    handleSearchContacts(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts(search)
      }, 500)
    },
    ///////////////////////////////////////////////////////////////////////
    async handleUpdateExpense() {
      this.updateLoading = true

      const { billed_to, from_wallet, currency, ...updatedForm } = this.form

      const response = await updateExpense(this.$route.params.id as string, {
        ...updatedForm,
        billed_to_id: billed_to?.id,
        from_wallet_id: from_wallet?.id,
        currency_id: currency?.id,
      })
      if (response.success) {
        this.$vaToast.init({
          message: 'Expense updated successfully!',
          color: 'success',
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.updateLoading = false
    },
    ///////////////////////////////////////////////////////////////////////
    calculateDeclaredAmount() {
      const unitPrice = Number(this.form.unit_price) || 0
      const quantity = this.form.quantity || 0
      this.form.declared_amount = unitPrice * quantity
    },

    calculateTaxAndTotalAmount() {
      const declaredAmount = Number(this.form.declared_amount) || 0
      const taxPercentage = Number(this.form.total_tax) || 0
      const calculatedTax = (declaredAmount * taxPercentage) / 100
      this.form.total_amount = declaredAmount + calculatedTax
    },

    ////////////////////////////// Currencies ///////////////////////////////////

    async handleGetCompanyCurrencies() {
      this.currencyLoading = true

      const response = await getAllCurrencies({})

      if (response.success) {
        this.currencies = response.data.list
        // this.form.currency = this.currencies[0];
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.currencyLoading = false
    },
  },
})
