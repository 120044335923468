import { CurrencyPresenter } from '../companyConfigPresenters'
import { IndividualContactPresenter } from '../contactPresenter'
import { TeamMemberPresenter } from '../teamMember'

export class ExpensesPresenter {
  id!: number
  billed_to!: IndividualContactPresenter
  category!: string
  class_name!: string
  currency!: CurrencyPresenter
  declared_amount!: string | number
  description!: string
  due_date!: string
  expense_type!: string
  from_wallet!: TeamMemberPresenter
  name!: string
  quantity!: number
  status!: string
  total_amount!: string | number
  total_tax!: string | number
  unit_price!: string | number

  constructor(data: ExpensesPresenter) {
    Object.assign(this, data)

    if (data.billed_to) {
      this.billed_to = new IndividualContactPresenter(data.billed_to)
    }

    if (data.from_wallet) {
      this.from_wallet = new TeamMemberPresenter(data.from_wallet)
    }

    if (data.currency) {
      this.currency = new CurrencyPresenter(data.currency)
    }
  }
}
