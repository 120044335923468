<template>
  <TabsBar :tabs="tabs" :active-tab-id="activeTabId" @changeTab="changeTab" />

  <!-- <Members /> -->
  <Members v-if="activeTab === 'MEMBERS'" />
  <div v-else class="container">
    <ComingSoon />
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
