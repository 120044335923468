import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0165ce8b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "columns-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchAndViews = _resolveComponent("SearchAndViews")!
  const _component_MatterTable = _resolveComponent("MatterTable")!
  const _component_LegalMatterCard = _resolveComponent("LegalMatterCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SearchAndViews, {
      search: _ctx.matterSearchKeyword,
      onSearch: _ctx.searchMatterData
    }, null, 8, ["search", "onSearch"]),
    (_ctx.activeView === 'list')
      ? (_openBlock(), _createBlock(_component_MatterTable, {
          key: 0,
          loading: _ctx.matterTableLoading,
          "items-per-page": _ctx.mattersItemPerPage,
          "total-items": _ctx.matterTotalItems,
          matters: _ctx.matters,
          onSelectedRows: _ctx.getSelectedRows,
          onLoadMore: _ctx.handlePaginationMatters
        }, null, 8, ["loading", "items-per-page", "total-items", "matters", "onSelectedRows", "onLoadMore"]))
      : (_ctx.activeView === 'board')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (sec, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "matter-column"
              }, [
                _createElementVNode("div", {
                  class: "matter-column-header",
                  style: _normalizeStyle({
          color: sec?.color || 'black',
          borderBottom: '0.5rem solid ' + (sec?.color || 'red'),
        })
                }, [
                  _createElementVNode("h3", null, _toDisplayString(sec.name), 1)
                ], 4),
                _createVNode(_component_LegalMatterCard, { matter: {} })
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
  ], 64))
}