<template>
  <div>
    <div v-if="info.length" class="header">
      <div
        v-for="item in info"
        :key="item.id"
        class="info"
        :class="{ last: item.id === info.length }"
      >
        <p class="info-value">
          {{ item.value }}
        </p>
        <p class="info-title">
          {{ item.title }}
        </p>
      </div>
    </div>
    <div id="chart" class="chart-container">
      <apexchart
        type="bar"
        height="350"
        :options="chartOptions"
        :series="series"
      />
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style scoped lang="scss" src="./styles.scss"></style>
