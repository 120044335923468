import { CalendarItemsPresenter } from '@/presenters/calendarItemsPresenter'
import { apiRequest } from './apiUtils'
import { getAllCalendarItemsInterface } from '@/Interfaces/Services'
import { calendarItemsFormInterface } from '@/Interfaces/layout'

export const getAllCalendarItems = async ({
  per_page,
  page,
  start_after,
  start_before,
  end_after,
  end_before,
  sort_by_direction,
  sort_by_field,
  skip_limit,
  for_whole_company,
  company_user_id,
  linked_item_type,
  linked_item_id,
  keyword,
  item_type_id,
  with_user = true,
}: getAllCalendarItemsInterface) => {
  const response = await apiRequest('get', '/calendar_items', null, {
    per_page,
    page,
    start_after,
    start_before,
    end_after,
    end_before,
    sort_by_direction,
    sort_by_field,
    item_type_id,
    skip_limit,
    for_whole_company,
    company_user_id,
    linked_item_type,
    linked_item_id,
    keyword,
    with_user,
  })

  if (!response.success) {
    return response
  }

  const list = response.data?.list
  if (!Array.isArray(list)) {
    return response
  }

  return {
    ...response,
    data: {
      ...response.data,
      list: list.map((item) => new CalendarItemsPresenter(item)),
    },
  }
}

export const getCalendarItemById = async (id: string) => {
  const response = await apiRequest('get', `/calendar_items/${id}`)
  if (response.success) {
    return {
      ...response,
      data: new CalendarItemsPresenter(response.data),
    }
  } else {
    return response
  }
}

export const addCalendarItem = (body: calendarItemsFormInterface) => {
  return apiRequest('post', '/calendar_items', body)
}

export const updateCalendarItem = (id: number | undefined, body: any) => {
  return apiRequest('put', `/calendar_items/${id}`, body)
}

export const deleteCalendarItem = (id: number) => {
  return apiRequest('delete', `/calendar_items/${id}`)
}
