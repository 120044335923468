import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c52c844"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "scroll-container" }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddLeadModal = _resolveComponent("AddLeadModal")!
  const _component_SearchAndViews = _resolveComponent("SearchAndViews")!
  const _component_ActionsBar = _resolveComponent("ActionsBar")!
  const _component_AllContactsTable = _resolveComponent("AllContactsTable")!
  const _component_UpdateLayoutColumns = _resolveComponent("UpdateLayoutColumns")!
  const _component_KanbanInfiniteLoad = _resolveComponent("KanbanInfiniteLoad")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AddLeadModal, {
      addLeadLoading: _ctx.addLeadLoading,
      isAddLeadForm: _ctx.isAddLeadForm,
      stages: _ctx.stages,
      onToggleAddLeadForm: _ctx.toggleAddLeadForm,
      onHandleCreateLead: _ctx.handleCreateLead
    }, null, 8, ["addLeadLoading", "isAddLeadForm", "stages", "onToggleAddLeadForm", "onHandleCreateLead"]),
    _createVNode(_component_SearchAndViews, {
      search: _ctx.searchKeyword,
      onSearch: _ctx.handleSearchData,
      activeView: _ctx.activeView,
      withViews: true,
      onChangeView: _ctx.handleChangeView
    }, null, 8, ["search", "onSearch", "activeView", "onChangeView"]),
    _createVNode(_component_ActionsBar, {
      "show-select-button": true,
      "show-download-icon": true,
      "show-archive-icon": true,
      "show-label-icon": true,
      "show-share-icon": true,
      "add-button": 'Add Lead',
      "edit-columns": true,
      "is-filter-columns": true,
      onAddButtonAction: _ctx.toggleAddLeadForm
    }, null, 8, ["onAddButtonAction"]),
    (_ctx.activeView === 'list')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_AllContactsTable, {
              loading: _ctx.tableLoading,
              "items-per-page": _ctx.contactsActiveMattersItemPerPage,
              "total-items": _ctx.contactsActiveMatterTotalItems,
              items: _ctx.$stores.contacts.contactsActiveMatters,
              headers: _ctx.headers,
              onSelectedRows: _ctx.getSelectedRows,
              onLoadMore: _ctx.handlePaginationContactsActiveMatters
            }, null, 8, ["loading", "items-per-page", "total-items", "items", "headers", "onSelectedRows", "onLoadMore"])
          ])
        ]))
      : (_ctx.activeView === 'board')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_UpdateLayoutColumns, {
              "update-layout-loading": _ctx.isUpdateLayoutLoading,
              "is-update-layout-form": _ctx.isShowUpdateLayoutModal,
              section_stages: _ctx.stages,
              "is-delete-layout-loading": _ctx.isDeleteLayoutLoading,
              onHandleToggleUpdateLayoutModal: _ctx.handleToggleUpdateLayoutModal,
              onHandleUpdateStageData: _ctx.handleUpdateLayout,
              onOnDragEnd: _ctx.handleUpdateLayout,
              onHandleDeleteLayout: _ctx.handleDeleteLayout
            }, null, 8, ["update-layout-loading", "is-update-layout-form", "section_stages", "is-delete-layout-loading", "onHandleToggleUpdateLayoutModal", "onHandleUpdateStageData", "onOnDragEnd", "onHandleDeleteLayout"]),
            _createVNode(_component_KanbanInfiniteLoad, {
              isLegalMatters: true,
              stages: _ctx.stages,
              "is-cards-loading": _ctx.isLeadsLoading,
              "is-columns-loading": _ctx.isStagesLoading,
              onTicketMoved: _ctx.handleTicketMoved,
              onHandleAddStage: _ctx.handleToggleUpdateLayoutModal
            }, null, 8, ["stages", "is-cards-loading", "is-columns-loading", "onTicketMoved", "onHandleAddStage"])
          ]))
        : _createCommentVNode("", true)
  ]))
}