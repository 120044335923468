<template>
  <ActionsBar
    second-add-button="Add Milestone"
    @secondButtonAction="handleToggleAddMileStone"
  />

  <VaModal
    v-model="isAddMileStone"
    size="auto"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
  >
    <template #header>
      <h1 class="form-modal-header">
        Add Milestone
        <VaIcon
          name="close"
          size="30px"
          class="icon"
          @click="handleToggleAddMileStone"
        />
      </h1>
    </template>
    <VaForm ref="form" class="add-milestone-form-modal">
      <VaInput
        v-model="form.name"
        placeholder="Name"
        background="#ECF0F1"
        style="width: 50%"
        label="Name"
        required-mark
        :rules="[validateInput]"
      />
      <VaDateInput
        v-model="form.due_date"
        placeholder="MM/DD/YYYY"
        manual-input
        label="Due Date"
        clearable
        background="#ECF0F1"
        required-mark
        :rules="[validateInput]"
      />
      <VaSelect
        v-model="form.status"
        label="Status"
        placeholder="Status"
        background="#ECF0F1"
        :options="$stores.generalData.companyConfigs.milestonesStatusOption"
        text-by="name"
        :rules="[validateSelect]"
        required-mark
      />

      <div class="fees">
        <VaInput
          v-model="form.fees_amount"
          label="Fees"
          background="#ECF0F1"
          type="number"
          placeholder="Fees"
          min="0"
          required-mark
          :rules="[validateInput]"
        />
        <VaSelect
          v-model="form.fees_currency"
          label="currency"
          background="#ECF0F1"
          :options="$stores.generalData.currencies"
          :text-by="getOptionCurrency"
          placeholder="Currency"
          :rules="[validateSelect]"
          required-mark
        />
      </div>

      <div class="expenses">
        <VaInput
          v-model="form.expenses_amount"
          label="Expenses"
          background="#ECF0F1"
          type="number"
          placeholder="Expenses"
          min="0"
          required-mark
          :rules="[validateInput]"
        />
        <VaSelect
          v-model="form.expenses_currency"
          label="currency"
          background="#ECF0F1"
          :options="$stores.generalData.currencies"
          :text-by="getOptionCurrency"
          placeholder="Currency"
          :rules="[validateSelect]"
          required-mark
        />
      </div>

      <VaTextarea
        v-model="form.description"
        max-rows="11"
        label="description"
        placeholder="Add your description here..."
        style="width: 100%; height: 10rem"
        autosize
        background="#ECF0F1"
      />
    </VaForm>
    <template #footer>
      <div class="form-modal-buttons">
        <VaButton preset="primary" @click="handleToggleAddMileStone">
          Cancel
        </VaButton>

        <VaButton
          preset="primary"
          :loading="
            isAddFunction ? addMileStoneLoading : updateMileStoneLoading
          "
          @click="
            validate() && isAddFunction
              ? handleAddMileStone()
              : handleUpdateMileStone()
          "
        >
          Save
        </VaButton>
      </div>
    </template>
  </VaModal>

  <div class="table-container">
    <v-data-table-server
      v-model:selection="selectedRows"
      :loading="milestoneTableLoading || generateInvoiceLoading"
      :headers="headers"
      :items="milestones"
      show-select
      class="custom-table"
      :items-length="milestoneTotalItems"
      :items-per-page="milestonesItemPerPage"
      :items-per-page-options="[10, 25, 50]"
      @update:options="handleLoadMore"
    >
      <template #[`item.description`]="{ item }">
        <v-tooltip
          v-if="isLongDescription(item?.description, 100)"
          :text="item?.description"
          location="top"
        >
          <template #activator="{ props }">
            <p v-bind="props" class="description-text">
              {{ truncateText(item?.description, 100) }}
            </p>
          </template>
        </v-tooltip>

        <p v-else class="description-text">
          {{ truncateText(item?.description, 100) }}
        </p>
      </template>

      <template #[`item.fees_amount`]="{ item }">
        <p class="fees_amount">
          <span>{{ item?.fees_amount }}</span>
          <span>{{ item?.fees_currency.code }}</span>
        </p>
      </template>

      <template #[`item.expenses_amount`]="{ item }">
        <p class="expenses_amount">
          <span>{{ item?.expenses_amount }}</span>
          <span>{{ item?.expenses_currency.code }}</span>
        </p>
      </template>

      <template #[`item.action`]="{ item }">
        <td class="table-action-td">
          <va-dropdown :offset="[0, 50]" close-on-content-click>
            <template #anchor>
              <img
                :src="moreVertIcon"
                alt="MoreVertIcon"
                class="press-button-2"
              />
            </template>
            <va-dropdown-content>
              <p
                class="table-action-option"
                @click="handleToggleAddMileStone(item)"
              >
                <VaIcon name="edit" />
                Edit
              </p>
              <p
                v-if="!item.related_invoice_id"
                class="table-action-option"
                @click="handleGenerateInvoice(item.id)"
              >
                <VaIcon name="attach_money" />
                Generate Invoice
              </p>
              <p
                v-else
                class="table-action-option"
                @click="
                  $router.push(`/financial/invoice/${item?.related_invoice_id}`)
                "
              >
                <VaIcon name="person" />
                View Invoice
              </p>
            </va-dropdown-content>
          </va-dropdown>
        </td>
      </template>
    </v-data-table-server>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
