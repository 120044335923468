<template>
  <VaModal
    v-model="isAddItemBool"
    size="small"
    hide-default-actions
    fixed-layout
    @update:modelValue="handleToggleAddLink"
  >
    <template #header>
      <div class="add-link-header">
        <h1>Add Links</h1>
        <VaIcon
          class="header-icon press-button"
          name="close"
          size="30px"
          @click="handleToggleAddLink"
        />
      </div>

      <div class="search">
        <VaInput
          v-model="searchKeywordModal"
          placeholder="Search Tasks..."
          :loading="itemsListLoading"
          @input="handleSearch(searchKeywordModal)"
        >
          <template #prependInner>
            <VaIcon name="search" color="secondary" />
          </template>
        </VaInput>
      </div>
    </template>

    <div class="add-link-body">
      <h2>
        {{ selectedItem?.name }}
        <p v-if="selectedItem" @click="handleSelectItem(null)">
          Remove
          <VaIcon name="cancel" />
        </p>
      </h2>

      <h1>Recent</h1>
      <VaInnerLoading :loading="itemsListLoading" class="sharp-background">
        <div class="link-list">
          <ul v-for="(Task, index) in itemsList" :key="index">
            <li @click="handleSelectItem(Task)">
              <VaIcon name="inventory_2" color="primary" /> {{ Task.name }}
            </li>
          </ul>
        </div>
      </VaInnerLoading>
    </div>

    <template #footer>
      <div class="add-link-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleToggleAddLink"
        >
          Cancel
        </VaButton>
        <VaButton
          :loading="addLinkLoading"
          :disabled="!selectedItem"
          @click="handleLinkItem"
        >
          Link
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
