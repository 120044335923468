import ComingSoon from '@/components/ComingSoon/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'

export default {
  name: 'LegalLibrary',
  components: {
    ComingSoon,
    SharedHeader,
  },
}
