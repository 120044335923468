<template>
  <div class="tasks-table-container">
    <v-data-table-server
      v-model:selection="selectedRows"
      :loading="loading"
      :headers="headers"
      :items="items"
      show-select
      class="custom-table"
      :items-length="totalItems"
      :items-per-page="itemsPerPage"
      :items-per-page-options="[10, 25, 50]"
      @update:options="handleLoadMore"
    >
      <template #[`item.img`]="{ item }">
        <va-avatar
          v-if="item.client_avatar_letter"
          :src="item?.img"
          :alt="item?.fullName"
          size="small"
        >
          <span v-if="!item?.img" style="color: black">
            {{ item.client_avatar_letter }}
          </span>
        </va-avatar>
      </template>

      <template #[`item.firstName`]="{ item }">
        <router-link :to="`/contacts/people/${item?.id}`">
          <p>{{ item?.firstName }}</p>
        </router-link>
      </template>

      <template #[`item.middleName`]="{ item }">
        <router-link :to="`/contacts/people/${item?.id}`">
          <p>{{ item?.middleName }}</p>
        </router-link>
      </template>

      <template #[`item.lastName`]="{ item }">
        <router-link :to="`/contacts/people/${item?.id}`">
          <p>{{ item?.lastName }}</p>
        </router-link>
      </template>

      <template #[`item.kyc_completed`]="{ item }">
        <div class="kyc-status">
          <VaIcon
            :name="item?.kyc_completed ? 'check_circle' : 'cancel'"
            :color="item?.kyc_completed ? 'green' : 'red'"
          />
          <span :style="{ color: item?.kyc_completed ? 'green' : 'red' }">
            {{ item?.kyc_completed }}
          </span>
        </div>
      </template>

      <template #[`item.email_addresses`]="{ item }">
        <div v-for="(email, index) in item.email_addresses" :key="index">
          <a :href="`mailto:${email?.email_address}`" class="email-link">
            <VaIcon name="mail_outline" size="1.7rem" />
            {{ email?.email_address }}
          </a>
        </div>
      </template>

      <template #[`item.phone_numbers`]="{ item }">
        <div v-for="(phone, index) in item.phone_numbers" :key="index">
          <a :href="`tel:${phone?.phone_number}`" class="phone-link">
            <VaIcon name="call" size="1.7rem" />
            {{ phone?.phone_number }} ({{ phone?.label }})
          </a>
        </div>
      </template>

      <template #[`item.organization`]="{ item }">
        <div class="social-media-icons">
          <router-link
            :to="`/contacts/organization/${item?.organization_contact?.id}`"
          >
            <p class="organization">
              <va-avatar
                v-if="item.organization_contact?.name"
                :src="item?.img"
                :alt="item?.referred_by?.fullName"
                size="small"
              >
                <span v-if="!item?.img" style="color: black">
                  {{ item.organization_contact?.name[0] }}
                </span>
              </va-avatar>
              {{ item?.organization_contact?.name }}
            </p>
          </router-link>
        </div>
      </template>

      <template #[`item.website_url`]="{ item }">
        <div>
          <a v-if="item?.website_url" :href="item?.website_url" target="_blank">
            <p>{{ item?.website_url }}</p>
          </a>
        </div>
      </template>

      <template #[`item.referred_by`]="{ item }">
        <p class="referred-by">
          <va-avatar
            v-if="item.referred_by?.client_avatar_letter"
            :src="item?.img"
            :alt="item?.referred_by?.fullName"
            size="small"
          >
            <span v-if="!item?.img" style="color: black">
              {{ item.referred_by?.client_avatar_letter }}
            </span>
          </va-avatar>
          {{ item?.referred_by?.fullName }}
        </p>
      </template>

      <template #[`item.assigned_to`]="{ item }">
        <p class="assigned-to">
          <va-avatar
            v-if="item.assigned_to?.avatar_letter"
            :src="item?.img"
            :alt="item?.assigned_to?.fullName"
            size="small"
          >
            <span v-if="!item?.img" style="color: black">
              {{ item.assigned_to?.avatar_letter }}
            </span>
          </va-avatar>
          {{ item.assigned_to?.fullName }}
        </p>
      </template>

      <template #[`item.relationship_owner`]="{ item }">
        <p>
          {{ item?.relationship_owner?.fullName || '' }}
        </p>
      </template>

      <template #[`item.contact_type`]="{}">
        <p>Person</p>
      </template>

      <template #[`item.notes`]="{ item }">
        <p class="tasks-note-td">
          {{ item?.notes || 'No notes available' }}
        </p>
      </template>

      <template #[`item.kyc_notes`]="{ item }">
        <p class="tasks-note-td">
          {{ item?.kyc_notes || 'No KYC notes' }}
        </p>
      </template>

      <template #[`item.action`]="{ item }">
        <td class="table-action-td">
          <va-dropdown :offset="[0, -70]" close-on-content-click>
            <template #anchor>
              <img
                :src="MoreVertIcon"
                alt="MoreVertIcon"
                class="press-button-2"
              />
            </template>
            <va-dropdown-content>
              <p class="table-action-option" @click="viewProfile(item)">
                <VaIcon name="person" /> View Profile
              </p>
            </va-dropdown-content>
          </va-dropdown>
        </td>
      </template>
    </v-data-table-server>

    <va-modal v-model="deleteDialog" title="Confirm Deletion">
      <h1>Are you sure you want to delete this item?</h1>
    </va-modal>
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" scoped></style>
