import { IndividualContactPresenter } from '@/presenters/contactPresenter'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ContactCard',
  props: {
    contact: {
      type: Object as () => IndividualContactPresenter,
      required: true,
    },
  },
  mounted() {
    console.log(this.contact)
  },
})
