import { validateInput, deepCopy } from '@/lib/utils'
import { CurrenciesPresenter } from '@/presenters/CurrenciesPresenter'
import { defineComponent, PropType } from 'vue'
import { useForm } from 'vuestic-ui/web-components'

export default defineComponent({
  name: 'CurrencySettingModal',
  props: {
    isCurrency: {
      type: Boolean,
      default: false,
    },
    currencies: {
      type: Array as PropType<CurrenciesPresenter[]>,
      required: true,
    },
    newCurrency: {
      type: Object as PropType<CurrenciesPresenter>,
      required: true,
    },
    isCurrencyLoading: {
      type: Boolean,
      default: false,
    },
    isAddCurrencyLoading: {
      type: Boolean,
      default: false,
    },
    isUpdateCurrencyLoading: {
      type: Boolean,
      default: false,
    },
    showConfirmCurrencyId: {
      type: Number || null,
      required: true,
    },
    loadingCurrencyId: {
      type: Number || null,
      required: true,
    },
  },
  emits: [
    'toggleCurrency',
    'toggleConfirm',
    'confirmDelete',
    'cancelDelete',
    'handleAddCurrency',
    'handleUpdateCurrency',
  ],
  data() {
    const { validate, reset } = useForm('form')
    const { validate: updateValidate, reset: updateReset } =
      useForm('updateForm')

    return {
      validate,
      reset,
      validateInput,
      updateValidate,
      updateReset,

      currencySearch: '',
      isCurrencyBool: this.isCurrency,

      isCurrencyUpdate: false,
      selectedCurrency: {} as CurrenciesPresenter,
    }
  },

  watch: {
    isCurrency: {
      handler(data) {
        this.isCurrencyBool = data
      },
    },
    isUpdateCurrencyLoading: {
      handler(data) {
        if (!data) {
          this.toggleEdit()
        }
      },
    },
  },
  methods: {
    toggleCurrency() {
      this.isCurrencyBool = !this.isCurrencyBool
      this.reset()
      this.$emit('toggleCurrency')
    },

    toggleConfirm(id: number) {
      this.$emit('toggleConfirm', id)
    },

    confirmDelete(id: number) {
      this.$emit('confirmDelete', id)
    },
    cancelDelete(id: number) {
      this.$emit('cancelDelete', id)
    },

    handleUpdateCurrency() {
      if (this.updateValidate()) {
        this.$emit('handleUpdateCurrency', this.selectedCurrency)
      }
    },

    ///////////////////////////////// add ///////////////////////////////

    handleAddCurrency() {
      if (this.validate()) {
        console.log({ newCurrency: this.newCurrency })

        this.$emit('handleAddCurrency', this.newCurrency)
      }
    },

    ///////////////////////////////// update ///////////////////////////////

    toggleEdit(currency?: CurrenciesPresenter) {
      this.selectedCurrency = currency
        ? deepCopy(currency)
        : ({} as CurrenciesPresenter)
      this.isCurrencyUpdate = !this.isCurrencyUpdate
    },
  },
})
