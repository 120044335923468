import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    task: {
      type: Object,
      required: true,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    selectedCards: {
      type: Array as () => number[],
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const isChecked = computed({
      get: () => props.selectedCards.includes(props.task.id),
      set: (value: boolean) => {
        let updatedTasks = [...props.selectedCards]
        if (value) {
          if (!updatedTasks.includes(props.task.id)) {
            updatedTasks.push(props.task.id)
          }
        } else {
          updatedTasks = updatedTasks.filter((id) => id !== props.task.id)
        }
        console.log('Checked items:', updatedTasks) // Log the selected items
        emit('update:selectedCards', updatedTasks)
      },
    })

    const formattedDate = computed(() => {
      const date = new Date(props.task.created_at)
      return date.toLocaleDateString('en-US', {
        month: 'short', // "Sep" for September
        day: 'numeric',
        year: 'numeric',
      })
    })

    return {
      isChecked,
      formattedDate,
    }
  },
})
