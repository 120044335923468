import { defineComponent } from 'vue'

type ItemType = {
  id: number
  name: string
}

export default defineComponent({
  name: '',
  props: {
    isAddItem: {
      type: Boolean,
      required: true,
    },
    itemsListLoading: {
      type: Boolean,
      default: false,
    },
    itemsList: {
      type: Array as () => ItemType[],
      default: [] as ItemType[],
    },
    addLinkLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['handleToggleAddLink', 'handleSearch', 'handleLinkItem'],
  data() {
    return {
      isAddItemBool: this.isAddItem,
      searchKeywordModal: '',
      selectedItem: null as null | ItemType,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,

      data: [
        {
          date: '1/2/2025',
          name: 'name 1',
        },
        {
          date: '1/2/2025',
          name: 'name 2',
        },
        {
          date: '1/2/2025',
          name: 'name 3',
        },
        {
          date: '1/2/2025',
          name: 'name 4',
        },
      ],

      data1: [
        {
          title: 'date',
          data: [
            {
              date: '1/2/2025',
              name: 'name 1',
            },
            {
              date: '1/2/2025',
              name: 'name 2',
            },
          ],
        },
        {
          title: 'date 1',
          data: [
            {
              date: '1/2/2025',
              name: 'name 3',
            },
            {
              date: '1/2/2025',
              name: 'name 4',
            },
          ],
        },
      ],
    }
  },
  watch: {
    isAddItem: {
      handler(data) {
        this.isAddItemBool = data
        this.selectedItem = null
      },
    },
  },
  methods: {
    handleToggleAddLink() {
      this.isAddItemBool = !this.isAddItemBool
      this.$emit('handleToggleAddLink')
    },

    handleSearch(search?: string) {
      this.$emit('handleSearch', search)
    },

    handleSelectItem(item: ItemType | null) {
      this.selectedItem = item
    },

    handleLinkItem() {
      this.$emit('handleLinkItem', this.selectedItem)
    },
  },
})
