import { defineComponent } from 'vue'
import TabsBar from '@/components/tabsBar/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import InfoSecurity from '@/views/Settings/GeneralSettings/InfoSecurity/index.vue'

export default defineComponent({
  name: 'GeneralSettings',
  // mounted() {},
  components: {
    TabsBar,
    InfoSecurity,
    ComingSoon,
  },
  data() {
    const tabs = [
      { id: 0, name: 'INFO & SECURITY' },
      { id: 1, name: 'PLANS' },
      { id: 2, name: 'REFERRALS' },
      { id: 3, name: 'HELP CENTER' },
      { id: 4, name: 'INTEGRATIONS' },
      { id: 5, name: 'CHAT SETTINGS' },
      { id: 6, name: 'AVAILABLE LIST' },
      { id: 7, name: 'STORAGE' },
      { id: 8, name: 'BACKUP & RESTORE' },
    ]

    return {
      tabs,
      activeTabId: 0,
      activeTab: 'INFO & SECURITY',
    }
  },
  computed: {},
  methods: {
    /////////////////////////////////////////////////////////////////////////////
    changeTab(tab: { title: string; id: number }) {
      this.activeTabId = tab.id
      this.activeTab = tab.title
    },
  },
})
